@use "../global" as *;

@each $brand, $value in $brand-colors {
	.clr-#{$brand} {
		color: hsl(var(--#{$brand}));
	}
	.\:clr-#{$brand} {
		&:hover {
			color: hsl(var(--#{$brand}));
		}
	}
}

@each $color, $shades in $colors {
	@each $shade, $value in $shades {
		.clr-#{$color}-#{$shade} {
			color: hsl(var(--#{$color}-#{$shade}));
		}
		.\:clr-#{$color}-#{$shade}:hover {
			color: hsl(var(--#{$color}-#{$shade}));
		}
	}
}
.clr-primary {
	color: hsl(var(--primary));
}
.on-primary {
	color: hsl(var(--on-primary));
}
.clr-primary-container {
	color: hsl(var(--primary-container));
}
.on-primary-container {
	color: hsl(var(--on-primary-container));
}
.clr-secondary {
	color: hsl(var(--secondary));
}
.on-secondary {
	color: hsl(var(--on-secondary));
}
.clr-secondary-container {
	color: hsl(var(--secondary-container));
}
.on-secondary-container {
	color: hsl(var(--on-secondary-container));
}
.clr-tertiary {
	color: hsl(var(--tertiary));
}
.on-tertiary {
	color: hsl(var(--on-tertiary));
}
.clr-tertiary-container {
	color: hsl(var(--tertiary-container));
}
.on-tertiary-container {
	color: hsl(var(--on-tertiary-container));
}
.clr-error {
	color: hsl(var(--error));
}
.on-error {
	color: hsl(var(--on-error));
}
.clr-error-container {
	color: hsl(var(--error-container));
}
.on-error-container {
	color: hsl(var(--on-error-container));
}
.clr-background {
	color: hsl(var(--background));
}
.on-background {
	color: hsl(var(--on-background));
}
.clr-surface {
	color: hsl(var(--surface));
}
.on-surface {
	color: hsl(var(--on-surface));
}
.clr-surface-variant {
	color: hsl(var(--surface-variant));
}
.on-surface-variant {
	color: hsl(var(--on-surface-variant));
}
// Hover color
.\:clr-primary:hover {
	color: hsl(var(--primary));
}
.\:on-primary:hover {
	color: hsl(var(--on-primary));
}
.\:clr-primary-container:hover {
	color: hsl(var(--primary-container));
}
.\:on-primary-container:hover {
	color: hsl(var(--on-primary-container));
}
.\:clr-secondary:hover {
	color: hsl(var(--secondary));
}
.\:on-secondary:hover {
	color: hsl(var(--on-secondary));
}
.\:clr-secondary-container:hover {
	color: hsl(var(--secondary-container));
}
.\:on-secondary-container:hover {
	color: hsl(var(--on-secondary-container));
}
.\:clr-tertiary:hover {
	color: hsl(var(--tertiary));
}
.\:on-tertiary:hover {
	color: hsl(var(--on-tertiary));
}
.\:clr-tertiary-container:hover {
	color: hsl(var(--tertiary-container));
}
.\:on-tertiary-container:hover {
	color: hsl(var(--on-tertiary-container));
}
.\:clr-error:hover {
	color: hsl(var(--error));
}
.\:on-error:hover {
	color: hsl(var(--on-error));
}
.\:clr-error-container:hover {
	color: hsl(var(--error-container));
}
.\:on-error-container:hover {
	color: hsl(var(--on-error-container));
}
.\:clr-background:hover {
	color: hsl(var(--background));
}
.\:on-background:hover {
	color: hsl(var(--on-background));
}
.\:clr-surface:hover {
	color: hsl(var(--surface));
}
.\:on-surface:hover {
	color: hsl(var(--on-surface));
}
.\:clr-surface-variant:hover {
	color: hsl(var(--surface-variant));
}
.\:on-surface-variant:hover {
	color: hsl(var(--on-surface-variant));
}
