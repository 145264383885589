@use "../global" as *;
.logo {
	--logo-height: #{rem(36)};
	height: var(--logo-height);
	&__img {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}
}
