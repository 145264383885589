@use "../global" as *;
@use "../bootstrap" as bs;

@each $brand, $value in $brand-colors {
	.bg-#{$brand} {
		background: hsl(var(--#{$brand}));
	}
	.\:bg-#{$brand} {
		transition: all 0.3s ease;
		&:hover {
			background: hsl(var(--#{$brand}));
		}
	}
}

@each $color, $shades in $colors {
	@each $shade, $value in $shades {
		.bg-#{$color}-#{$shade} {
			--bg-opacity: 1;
			background-color: hsl(var(--#{$color}-#{$shade}) / var(--bg-opacity));
		}

		.\:bg-#{$color}-#{$shade}:hover {
			--bg-opacity: 1;
			background-color: hsl(var(--#{$color}-#{$shade}) / var(--bg-opacity));
		}
	}
}
.primary {
	--bg-opacity: 1;
	background-color: hsl(var(--primary) / var(--bg-opacity));
}
.primary-container {
	--bg-opacity: 1;
	background-color: hsl(var(--primary-container) / var(--bg-opacity));
}
.secondary {
	--bg-opacity: 1;
	background-color: hsl(var(--secondary) / var(--bg-opacity));
}
.secondary-container {
	--bg-opacity: 1;
	background-color: hsl(var(--secondary-container) / var(--bg-opacity));
}
.tertiary {
	--bg-opacity: 1;
	background-color: hsl(var(--tertiary) / var(--bg-opacity));
}
.tertiary-container {
	--bg-opacity: 1;
	background-color: hsl(var(--tertiary-container) / var(--bg-opacity));
}
.error {
	--bg-opacity: 1;
	background-color: hsl(var(--error) / var(--bg-opacity));
}
.error-container {
	--bg-opacity: 1;
	background-color: hsl(var(--error-container) / var(--bg-opacity));
}
.background {
	--bg-opacity: 1;
	background-color: hsl(var(--background) / var(--bg-opacity));
}
.surface {
	--bg-opacity: 1;
	background-color: hsl(var(--surface) / var(--bg-opacity));
}
.surface-variant {
	--bg-opacity: 1;
	background-color: hsl(var(--surface-variant) / var(--bg-opacity));
}
.surface-1 {
	--bg-opacity: 1;
	background-color: hsl(var(--surface-1) / var(--bg-opacity));
}
.surface-2 {
	--bg-opacity: 1;
	background-color: hsl(var(--surface-2) / var(--bg-opacity));
}
.surface-3 {
	--bg-opacity: 1;
	background-color: hsl(var(--surface-3) / var(--bg-opacity));
}
.surface-4 {
	--bg-opacity: 1;
	background-color: hsl(var(--surface-4) / var(--bg-opacity));
}
.surface-5 {
	--bg-opacity: 1;
	background-color: hsl(var(--surface-5) / var(--bg-opacity));
}
.\:primary:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--primary) / var(--bg-opacity));
}
.\:primary-container:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--primary-container) / var(--bg-opacity));
}
.\:secondary:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--secondary) / var(--bg-opacity));
}
.\:secondary-container:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--secondary-container) / var(--bg-opacity));
}
.\:tertiary:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--tertiary) / var(--bg-opacity));
}
.\:tertiary-container:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--tertiary-container) / var(--bg-opacity));
}
.\:error:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--error) / var(--bg-opacity));
}
.\:error-container:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--error-container));
}
.\:background:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--background) / var(--bg-opacity));
}
.\:surface:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--surface) / var(--bg-opacity));
}
.\:surface-1:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--surface-1) / var(--bg-opacity));
}
.\:surface-2:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--surface-2) / var(--bg-opacity));
}
.\:surface-3:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--surface-3) / var(--bg-opacity));
}
.\:surface-4:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--surface-4) / var(--bg-opacity));
}
.\:surface-5:hover {
	--bg-opacity: 1;
	background-color: hsl(var(--surface-5) / var(--bg-opacity));
}
.bg-overlay {
	--overlay-clr: hsl(var(--primary-10));
	background: linear-gradient(transparent, var(--overlay-clr));
}

.bg-opacity-05 {
	--bg-opacity: 0.05;
}

.bg-opacity-1 {
	--bg-opacity: 0.1;
}

.bg-opacity-2 {
	--bg-opacity: 0.2;
}

.bg-opacity-3 {
	--bg-opacity: 0.3;
}

.bg-opacity-4 {
	--bg-opacity: 0.4;
}

.bg-opacity-5 {
	--bg-opacity: 0.5;
}

.bg-opacity-6 {
	--bg-opacity: 0.6;
}

.bg-opacity-7 {
	--bg-opacity: 0.7;
}

.bg-opacity-8 {
	--bg-opacity: 0.8;
}

.bg-opacity-9 {
	--bg-opacity: 0.9;
}
.bg-opacity-100 {
	--bg-opacity: 1;
}

.\:bg-opacity-05:hover {
	--bg-opacity: 0.05;
}

.\:bg-opacity-1:hover {
	--bg-opacity: 0.1;
}

.\:bg-opacity-2:hover {
	--bg-opacity: 0.2;
}

.\:bg-opacity-3:hover {
	--bg-opacity: 0.3;
}

.\:bg-opacity-4:hover {
	--bg-opacity: 0.4;
}

.\:bg-opacity-5:hover {
	--bg-opacity: 0.5;
}

.\:bg-opacity-6:hover {
	--bg-opacity: 0.6;
}

.\:bg-opacity-7:hover {
	--bg-opacity: 0.7;
}

.\:bg-opacity-8:hover {
	--bg-opacity: 0.8;
}

.\:bg-opacity-9:hover {
	--bg-opacity: 0.9;
}
.\:bg-opacity-100:hover {
	--bg-opacity: 1;
}
