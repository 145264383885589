@use "../global" as *;

.header {
    z-index: 999;
    transition: all .3s ease;
    background-color: hsl(var(--primary-300)/.03);

    &-top {
        background-color: hsl(var(--primary-300)/.03);
    }

    &--light {
        .menu-link {
            @include screen(lg) {
                color: hsl(var(--neutral-10));
            }

            &.has-sub {
                @include screen(lg) {
                    color: hsl(var(--neutral-10));
                }
            }
        }

        [data-bs-toggle] {
            background-color: hsl(var(--primary-50));
        }
    }

    &--fixed {
        position: fixed;
        width: 100%;
    }

    &--sticky {
        position: sticky;
        top: 0;
    }

    &-crossed {
        .header--fixed {
            top: 0;
            border-color: transparent !important;

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                z-index: -1;
                background-color: hsl(var(--neutral-900));
            }
        }

        .header--sticky {
            background-color: hsl(var(--white));
            box-shadow: 0 .25rem 1rem hsl(var(--black)/.1);
            border-color: transparent !important;
        }
    }
}