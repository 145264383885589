.preloader {
    position: relative;
    display: grid;
    place-items: center;
    height: 100vh;
    background-color: hsl(var(--black));
    position: fixed;
    inset: 0;
    z-index: 9999;

    &__img {
        width: 80px;
        height: 80px;
        animation: imageBeat 2s infinite ease;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}