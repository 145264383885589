@keyframes pulse {
	100% {
		transform: scale(2);
		opacity: 0;
	}
}

@keyframes imageBeat {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(0.7);
	}

	100% {
		transform: scale(1);
	}
}
