@use "../global" as *;
.hero {
	@include screen(xmax) {
		padding-block: rem(200);
	}
	&::after {
		@include screen(lg) {
			content: "";
			position: absolute;
			inset-block: 0;
			inset-inline-start: 58%;
			inset-inline-end: 0;
			background-image: url(../img/hero-img.png);
			background-size: contain;
			background-repeat: no-repeat;
			z-index: -1;
		}
		@include screen(xmax) {
            inset-inline-end: rem(120);
            background-position: left bottom;
			inset-inline-start: 56%;
		}
	}
}
