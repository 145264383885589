@use "../bootstrap" as *;

@each $size, $value in $spacers {
	// Width
	.w-#{$size} {
		width: $value;
	}

	// Height
	.h-#{$size} {
		height: $value;
	}
}
