@use "../global" as *;
@each $color, $shades in $colors {
	@each $shade, $value in $shades {
		.border-#{$color}-#{$shade} {
			--border-opacity: 1;
			--bs-border-color: hsl(
				var(--#{$color}-#{$shade}) / var(--border-opacity)
			);
		}
		.\:border-#{$color}-#{$shade}:hover {
			--border-opacity: 1;
			--bs-border-color: hsl(
				var(--#{$color}-#{$shade}) / var(--border-opacity)
			);
		}
	}
}
.border-2x {
	--bs-border-width: 2px;
}
.border-dotted {
	--bs-border-style: dotted;
}
.border-dashed {
	--bs-border-style: dashed;
}
.border-opacity-1 {
	--border-opacity: 0.1;
}

.border-opacity-2 {
	--border-opacity: 0.2;
}

.border-opacity-3 {
	--border-opacity: 0.3;
}

.border-opacity-4 {
	--border-opacity: 0.4;
}

.border-opacity-5 {
	--border-opacity: 0.5;
}

.border-opacity-6 {
	--border-opacity: 0.6;
}

.border-opacity-7 {
	--border-opacity: 0.7;
}

.border-opacity-8 {
	--border-opacity: 0.8;
}

.border-opacity-9 {
	--border-opacity: 0.9;
}
.border-opacity-100 {
	--border-opacity: 1;
}

.\:border-opacity-1:hover {
	--border-opacity: 0.1;
}

.\:border-opacity-2:hover {
	--border-opacity: 0.2;
}

.\:border-opacity-3:hover {
	--border-opacity: 0.3;
}

.\:border-opacity-4:hover {
	--border-opacity: 0.4;
}

.\:border-opacity-5:hover {
	--border-opacity: 0.5;
}

.\:border-opacity-6:hover {
	--border-opacity: 0.6;
}

.\:border-opacity-7:hover {
	--border-opacity: 0.7;
}

.\:border-opacity-8:hover {
	--border-opacity: 0.8;
}

.\:border-opacity-9:hover {
	--border-opacity: 0.9;
}
.\:border-opacity-100:hover {
	--border-opacity: 1;
}
