@use "../global" as *;
.partner-tab {
	box-shadow: 10px 10px 30px hsl(var(--neutral-10) / 0.05);
	border-radius: 0 0 0.5rem 0.5rem;
	background-color: hsl(var(--neutral-0));
	&__body {
		padding: 40px 16px;
		border-block-start: 1px solid hsl(var(--neutral-10) / 0.2);
		background-color: hsl(var(--neutral-100) / 0.05);
		@include screen(sm) {
			padding-inline: 1.5rem;
		}
		@include screen(md) {
			padding-inline: 2.5rem;
		}
		li {
			@include screen(xsm) {
				width: calc(50% - .75rem);
			}
			@include screen(md) {
				width: calc(33% - .75rem);
			}
			@include screen(lg) {
				width: calc(25% - 1rem);
			}
			@include screen(xl) {
				width: calc(20% - 1rem);
			}
		}
	}
	&__nav {
		overflow: auto;
		@include screen(xl) {
			overflow: unset;
		}
	}
	&__list {
		flex-direction: row;
	}
	&__link {
		flex-shrink: 0;
		border: 1px solid hsl(var(--neutral-100) / 0.05);
		background-color: hsl(var(--neutral-100) / 0.05);
		color: hsl(var(--neutral-100));
		&.active {
			position: relative;
			isolation: isolate;
			background-color: hsl(var(--neutral-100));
			color: hsl(var(--neutral-0));
			&::after {
				@include screen(xl) {
					content: "";
					position: absolute;
					top: 100%;
					left: 50%;
					transform: translateX(-50%);
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px 8px 0 8px;
					border-color: hsl(var(--neutral-100)) transparent transparent transparent;
				}
			}
			.partner-tab__icon {
				fill: hsl(var(--neutral-0));
			}
		}
	}
	&__icon {
		fill: hsl(var(--neutral-100));
	}
}
