@use "../global" as *;
.ff-1 {
	font-family: var(--ff-1);
}
.ff-2 {
	font-family: var(--ff-2);
}
.active-clr {
	color: hsl(var(--primary-10)) !important;
}

.active-bg {
	background-color: hsl(var(--primary-10)) !important;
}

.cursor-pointer {
	cursor: pointer;
}

.form-control {
	&:focus {
		box-shadow: none;
		border-color: var(--bs-border-color);
	}
}

.form-select {
	&:focus {
		box-shadow: none;
		border-color: var(--bs-border-color);
	}
}

.link {
	text-decoration: none;
	transition: all 0.3s ease;
}

.overflow-x-auto {
	scrollbar-color: transparent transparent;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: transparent;
	}
}

.overflow-y-auto {
	scrollbar-color: transparent transparent;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: transparent;
	}
}

.inset-0 {
	inset: 0;
}

.pointer-none {
	pointer-events: none;
}

.transition {
	transition: all 0.3s ease;
}

.place-content-center {
	place-content: center;
}

.place-items-center {
	place-items: center;
}

.position-center {
	inset-inline-start: 50%;
	inset-block-start: 50%;
	transform: translate(-50%, -50%);
}

.\:focus-outline-0 {
	&:focus {
		outline: none;
	}
}
.grayscale {
	filter: grayscale(1);
}
.\:grayscale:hover {
	filter: grayscale(1);
}
.grayscale-0 {
	filter: grayscale(0);
}
.\:grayscale-0:hover {
	filter: grayscale(0);
}
