@charset "UTF-8";
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0a58ca;
  --bs-secondary: #7c41fb;
  --bs-success: #1ec892;
  --bs-info: #189ab4;
  --bs-warning: #ff8138;
  --bs-danger: #ff4268;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 10, 88, 202;
  --bs-secondary-rgb: 124, 65, 251;
  --bs-success-rgb: 30, 200, 146;
  --bs-info-rgb: 24, 154, 180;
  --bs-warning-rgb: 255, 129, 56;
  --bs-danger-rgb: 255, 66, 104;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #042351;
  --bs-secondary-text-emphasis: #321a64;
  --bs-success-text-emphasis: #0c503a;
  --bs-info-text-emphasis: #0a3e48;
  --bs-warning-text-emphasis: #663416;
  --bs-danger-text-emphasis: #661a2a;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cedef4;
  --bs-secondary-bg-subtle: #e5d9fe;
  --bs-success-bg-subtle: #d2f4e9;
  --bs-info-bg-subtle: #d1ebf0;
  --bs-warning-bg-subtle: #ffe6d7;
  --bs-danger-bg-subtle: #ffd9e1;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9dbcea;
  --bs-secondary-border-subtle: #cbb3fd;
  --bs-success-border-subtle: #a5e9d3;
  --bs-info-border-subtle: #a3d7e1;
  --bs-warning-border-subtle: #ffcdaf;
  --bs-danger-border-subtle: #ffb3c3;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0a58ca;
  --bs-link-color-rgb: 10, 88, 202;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0846a2;
  --bs-link-hover-color-rgb: 8, 70, 162;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.5rem;
  --bs-border-radius-lg: 0.75rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(10, 88, 202, 0.25);
  --bs-form-valid-color: #1ec892;
  --bs-form-valid-border-color: #1ec892;
  --bs-form-invalid-color: #ff4268;
  --bs-form-invalid-border-color: #ff4268;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6c9bdf;
  --bs-secondary-text-emphasis: #b08dfd;
  --bs-success-text-emphasis: #78debe;
  --bs-info-text-emphasis: #74c2d2;
  --bs-warning-text-emphasis: #ffb388;
  --bs-danger-text-emphasis: #ff8ea4;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #021228;
  --bs-secondary-bg-subtle: #190d32;
  --bs-success-bg-subtle: #06281d;
  --bs-info-bg-subtle: #051f24;
  --bs-warning-bg-subtle: #331a0b;
  --bs-danger-bg-subtle: #330d15;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #063579;
  --bs-secondary-border-subtle: #4a2797;
  --bs-success-border-subtle: #127858;
  --bs-info-border-subtle: #0e5c6c;
  --bs-warning-border-subtle: #994d22;
  --bs-danger-border-subtle: #99283e;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6c9bdf;
  --bs-link-hover-color: #89afe5;
  --bs-link-color-rgb: 108, 155, 223;
  --bs-link-hover-color-rgb: 137, 175, 229;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.5rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\a0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container-xsm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-xsm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-xsm: 375px;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
  --bs-breakpoint-max: 1600px;
  --bs-breakpoint-xmax: 1920px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 375px) {
  .col-xsm {
    flex: 1 0 0%;
  }
  .row-cols-xsm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xsm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xsm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xsm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xsm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xsm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xsm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xsm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xsm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xsm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xsm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xsm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xsm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xsm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xsm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xsm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xsm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xsm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xsm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xsm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xsm-0 {
    margin-left: 0;
  }
  .offset-xsm-1 {
    margin-left: 8.33333333%;
  }
  .offset-xsm-2 {
    margin-left: 16.66666667%;
  }
  .offset-xsm-3 {
    margin-left: 25%;
  }
  .offset-xsm-4 {
    margin-left: 33.33333333%;
  }
  .offset-xsm-5 {
    margin-left: 41.66666667%;
  }
  .offset-xsm-6 {
    margin-left: 50%;
  }
  .offset-xsm-7 {
    margin-left: 58.33333333%;
  }
  .offset-xsm-8 {
    margin-left: 66.66666667%;
  }
  .offset-xsm-9 {
    margin-left: 75%;
  }
  .offset-xsm-10 {
    margin-left: 83.33333333%;
  }
  .offset-xsm-11 {
    margin-left: 91.66666667%;
  }
  .g-xsm-0,
  .gx-xsm-0 {
    --bs-gutter-x: 0;
  }
  .g-xsm-0,
  .gy-xsm-0 {
    --bs-gutter-y: 0;
  }
  .g-xsm-1,
  .gx-xsm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xsm-1,
  .gy-xsm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xsm-2,
  .gx-xsm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xsm-2,
  .gy-xsm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xsm-3,
  .gx-xsm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xsm-3,
  .gy-xsm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xsm-4,
  .gx-xsm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xsm-4,
  .gy-xsm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xsm-5,
  .gx-xsm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xsm-5,
  .gy-xsm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1600px) {
  .col-max {
    flex: 1 0 0%;
  }
  .row-cols-max-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-max-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-max-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-max-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-max-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-max-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-max-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-max-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-max-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-max-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-max-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-max-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-max-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-max-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-max-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-max-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-max-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-max-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-max-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-max-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-max-0 {
    margin-left: 0;
  }
  .offset-max-1 {
    margin-left: 8.33333333%;
  }
  .offset-max-2 {
    margin-left: 16.66666667%;
  }
  .offset-max-3 {
    margin-left: 25%;
  }
  .offset-max-4 {
    margin-left: 33.33333333%;
  }
  .offset-max-5 {
    margin-left: 41.66666667%;
  }
  .offset-max-6 {
    margin-left: 50%;
  }
  .offset-max-7 {
    margin-left: 58.33333333%;
  }
  .offset-max-8 {
    margin-left: 66.66666667%;
  }
  .offset-max-9 {
    margin-left: 75%;
  }
  .offset-max-10 {
    margin-left: 83.33333333%;
  }
  .offset-max-11 {
    margin-left: 91.66666667%;
  }
  .g-max-0,
  .gx-max-0 {
    --bs-gutter-x: 0;
  }
  .g-max-0,
  .gy-max-0 {
    --bs-gutter-y: 0;
  }
  .g-max-1,
  .gx-max-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-max-1,
  .gy-max-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-max-2,
  .gx-max-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-max-2,
  .gy-max-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-max-3,
  .gx-max-3 {
    --bs-gutter-x: 1rem;
  }
  .g-max-3,
  .gy-max-3 {
    --bs-gutter-y: 1rem;
  }
  .g-max-4,
  .gx-max-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-max-4,
  .gy-max-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-max-5,
  .gx-max-5 {
    --bs-gutter-x: 3rem;
  }
  .g-max-5,
  .gy-max-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1920px) {
  .col-xmax {
    flex: 1 0 0%;
  }
  .row-cols-xmax-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xmax-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xmax-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xmax-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xmax-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xmax-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xmax-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xmax-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xmax-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xmax-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xmax-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xmax-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xmax-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xmax-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xmax-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xmax-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xmax-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xmax-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xmax-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xmax-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xmax-0 {
    margin-left: 0;
  }
  .offset-xmax-1 {
    margin-left: 8.33333333%;
  }
  .offset-xmax-2 {
    margin-left: 16.66666667%;
  }
  .offset-xmax-3 {
    margin-left: 25%;
  }
  .offset-xmax-4 {
    margin-left: 33.33333333%;
  }
  .offset-xmax-5 {
    margin-left: 41.66666667%;
  }
  .offset-xmax-6 {
    margin-left: 50%;
  }
  .offset-xmax-7 {
    margin-left: 58.33333333%;
  }
  .offset-xmax-8 {
    margin-left: 66.66666667%;
  }
  .offset-xmax-9 {
    margin-left: 75%;
  }
  .offset-xmax-10 {
    margin-left: 83.33333333%;
  }
  .offset-xmax-11 {
    margin-left: 91.66666667%;
  }
  .g-xmax-0,
  .gx-xmax-0 {
    --bs-gutter-x: 0;
  }
  .g-xmax-0,
  .gy-xmax-0 {
    --bs-gutter-y: 0;
  }
  .g-xmax-1,
  .gx-xmax-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xmax-1,
  .gy-xmax-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xmax-2,
  .gx-xmax-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xmax-2,
  .gy-xmax-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xmax-3,
  .gx-xmax-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xmax-3,
  .gy-xmax-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xmax-4,
  .gx-xmax-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xmax-4,
  .gy-xmax-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xmax-5,
  .gx-xmax-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xmax-5,
  .gy-xmax-5 {
    --bs-gutter-y: 3rem;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(10, 88, 202, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(124, 65, 251, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(30, 200, 146, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(24, 154, 180, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 129, 56, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(255, 66, 104, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(8, 70, 162, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(8, 70, 162, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(99, 52, 201, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(99, 52, 201, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(75, 211, 168, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(75, 211, 168, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(70, 174, 195, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(70, 174, 195, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 154, 96, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 154, 96, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(255, 104, 134, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 104, 134, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 375px) {
  .sticky-xsm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xsm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1600px) {
  .sticky-max-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-max-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1920px) {
  .sticky-xmax-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xmax-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23042351'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #85ace5;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(10, 88, 202, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236c9bdf'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236c9bdf'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0a58ca;
  --bs-btn-border-color: #0a58ca;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #094bac;
  --bs-btn-hover-border-color: #0846a2;
  --bs-btn-focus-shadow-rgb: 47, 113, 210;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0846a2;
  --bs-btn-active-border-color: #084298;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0a58ca;
  --bs-btn-disabled-border-color: #0a58ca;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #7c41fb;
  --bs-btn-border-color: #7c41fb;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6937d5;
  --bs-btn-hover-border-color: #6334c9;
  --bs-btn-focus-shadow-rgb: 144, 94, 252;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6334c9;
  --bs-btn-active-border-color: #5d31bc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #7c41fb;
  --bs-btn-disabled-border-color: #7c41fb;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #1ec892;
  --bs-btn-border-color: #1ec892;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #40d0a2;
  --bs-btn-hover-border-color: #35ce9d;
  --bs-btn-focus-shadow-rgb: 26, 170, 124;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #4bd3a8;
  --bs-btn-active-border-color: #35ce9d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #1ec892;
  --bs-btn-disabled-border-color: #1ec892;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #189ab4;
  --bs-btn-border-color: #189ab4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3ba9bf;
  --bs-btn-hover-border-color: #2fa4bc;
  --bs-btn-focus-shadow-rgb: 20, 131, 153;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #46aec3;
  --bs-btn-active-border-color: #2fa4bc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #189ab4;
  --bs-btn-disabled-border-color: #189ab4;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ff8138;
  --bs-btn-border-color: #ff8138;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff9456;
  --bs-btn-hover-border-color: #ff8e4c;
  --bs-btn-focus-shadow-rgb: 217, 110, 48;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff9a60;
  --bs-btn-active-border-color: #ff8e4c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ff8138;
  --bs-btn-disabled-border-color: #ff8138;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #ff4268;
  --bs-btn-border-color: #ff4268;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff5e7f;
  --bs-btn-hover-border-color: #ff5577;
  --bs-btn-focus-shadow-rgb: 217, 56, 88;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff6886;
  --bs-btn-active-border-color: #ff5577;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ff4268;
  --bs-btn-disabled-border-color: #ff4268;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0a58ca;
  --bs-btn-border-color: #0a58ca;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0a58ca;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 10, 88, 202;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a58ca;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0a58ca;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0a58ca;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #7c41fb;
  --bs-btn-border-color: #7c41fb;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #7c41fb;
  --bs-btn-hover-border-color: #7c41fb;
  --bs-btn-focus-shadow-rgb: 124, 65, 251;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #7c41fb;
  --bs-btn-active-border-color: #7c41fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7c41fb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7c41fb;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #1ec892;
  --bs-btn-border-color: #1ec892;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #1ec892;
  --bs-btn-hover-border-color: #1ec892;
  --bs-btn-focus-shadow-rgb: 30, 200, 146;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #1ec892;
  --bs-btn-active-border-color: #1ec892;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1ec892;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1ec892;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #189ab4;
  --bs-btn-border-color: #189ab4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #189ab4;
  --bs-btn-hover-border-color: #189ab4;
  --bs-btn-focus-shadow-rgb: 24, 154, 180;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #189ab4;
  --bs-btn-active-border-color: #189ab4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #189ab4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #189ab4;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ff8138;
  --bs-btn-border-color: #ff8138;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff8138;
  --bs-btn-hover-border-color: #ff8138;
  --bs-btn-focus-shadow-rgb: 255, 129, 56;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff8138;
  --bs-btn-active-border-color: #ff8138;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff8138;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff8138;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #ff4268;
  --bs-btn-border-color: #ff4268;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ff4268;
  --bs-btn-hover-border-color: #ff4268;
  --bs-btn-focus-shadow-rgb: 255, 66, 104;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ff4268;
  --bs-btn-active-border-color: #ff4268;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff4268;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff4268;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 47, 113, 210;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(10, 88, 202, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0a58ca;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 375px) {
  .dropdown-menu-xsm-start {
    --bs-position: start;
  }
  .dropdown-menu-xsm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xsm-end {
    --bs-position: end;
  }
  .dropdown-menu-xsm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-max-start {
    --bs-position: start;
  }
  .dropdown-menu-max-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-max-end {
    --bs-position: end;
  }
  .dropdown-menu-max-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1920px) {
  .dropdown-menu-xmax-start {
    --bs-position: start;
  }
  .dropdown-menu-xmax-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xmax-end {
    --bs-position: end;
  }
  .dropdown-menu-xmax-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0a58ca;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #85ace5;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(10, 88, 202, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #85ace5;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(10, 88, 202, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #85ace5;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(10, 88, 202, 0.25);
}
.form-check-input:checked {
  background-color: #0a58ca;
  border-color: #0a58ca;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0a58ca;
  border-color: #0a58ca;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2385ace5'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(10, 88, 202, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(10, 88, 202, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0a58ca;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6cdef;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0a58ca;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6cdef;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231ec892' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231ec892' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff4268'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff4268' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff4268'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff4268' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0a58ca;
  --bs-list-group-active-border-color: #0a58ca;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 375px) {
  .list-group-horizontal-xsm {
    flex-direction: row;
  }
  .list-group-horizontal-xsm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xsm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xsm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xsm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xsm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-max {
    flex-direction: row;
  }
  .list-group-horizontal-max > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-max > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-max > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-max > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-max > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1920px) {
  .list-group-horizontal-xmax {
    flex-direction: row;
  }
  .list-group-horizontal-xmax > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xmax > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xmax > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xmax > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xmax > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 374.98px) {
  .modal-fullscreen-xsm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xsm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xsm-down .modal-header,
  .modal-fullscreen-xsm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xsm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1599.98px) {
  .modal-fullscreen-max-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-max-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-max-down .modal-header,
  .modal-fullscreen-max-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-max-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1919.98px) {
  .modal-fullscreen-xmax-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xmax-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xmax-down .modal-header,
  .modal-fullscreen-xmax-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xmax-down .modal-body {
    overflow-y: auto;
  }
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(10, 88, 202, 0.25);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0a58ca;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 375px) {
  .navbar-expand-xsm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xsm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xsm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xsm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xsm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xsm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xsm .navbar-toggler {
    display: none;
  }
  .navbar-expand-xsm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xsm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xsm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-max {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-max .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-max .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-max .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-max .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-max .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-max .navbar-toggler {
    display: none;
  }
  .navbar-expand-max .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-max .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-max .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1920px) {
  .navbar-expand-xmax {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xmax .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xmax .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xmax .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xmax .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xmax .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xmax .navbar-toggler {
    display: none;
  }
  .navbar-expand-xmax .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xmax .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xmax .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.offcanvas, .offcanvas-xmax, .offcanvas-max, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm, .offcanvas-xsm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 374.98px) {
  .offcanvas-xsm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 374.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xsm {
    transition: none;
  }
}
@media (max-width: 374.98px) {
  .offcanvas-xsm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 374.98px) {
  .offcanvas-xsm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 374.98px) {
  .offcanvas-xsm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 374.98px) {
  .offcanvas-xsm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 374.98px) {
  .offcanvas-xsm.showing, .offcanvas-xsm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 374.98px) {
  .offcanvas-xsm.showing, .offcanvas-xsm.hiding, .offcanvas-xsm.show {
    visibility: visible;
  }
}
@media (min-width: 375px) {
  .offcanvas-xsm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xsm .offcanvas-header {
    display: none;
  }
  .offcanvas-xsm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1599.98px) {
  .offcanvas-max {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1599.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-max {
    transition: none;
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-max.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-max.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-max.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-max.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-max.showing, .offcanvas-max.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1599.98px) {
  .offcanvas-max.showing, .offcanvas-max.hiding, .offcanvas-max.show {
    visibility: visible;
  }
}
@media (min-width: 1600px) {
  .offcanvas-max {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-max .offcanvas-header {
    display: none;
  }
  .offcanvas-max .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1919.98px) {
  .offcanvas-xmax {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1919.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xmax {
    transition: none;
  }
}
@media (max-width: 1919.98px) {
  .offcanvas-xmax.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1919.98px) {
  .offcanvas-xmax.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1919.98px) {
  .offcanvas-xmax.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1919.98px) {
  .offcanvas-xmax.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1919.98px) {
  .offcanvas-xmax.showing, .offcanvas-xmax.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1919.98px) {
  .offcanvas-xmax.showing, .offcanvas-xmax.hiding, .offcanvas-xmax.show {
    visibility: visible;
  }
}
@media (min-width: 1920px) {
  .offcanvas-xmax {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xmax .offcanvas-header {
    display: none;
  }
  .offcanvas-xmax .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(10, 88, 202, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0a58ca;
  --bs-pagination-active-border-color: #0a58ca;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0a58ca;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cedef4;
  --bs-table-border-color: #b9c8dc;
  --bs-table-striped-bg: #c4d3e8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b9c8dc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfcde2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e5d9fe;
  --bs-table-border-color: #cec3e5;
  --bs-table-striped-bg: #dacef1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cec3e5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d4c9eb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d2f4e9;
  --bs-table-border-color: #bddcd2;
  --bs-table-striped-bg: #c8e8dd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bddcd2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2e2d8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #d1ebf0;
  --bs-table-border-color: #bcd4d8;
  --bs-table-striped-bg: #c7dfe4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd4d8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d9de;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #ffe6d7;
  --bs-table-border-color: #e6cfc2;
  --bs-table-striped-bg: #f2dbcc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6cfc2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd5c7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffd9e1;
  --bs-table-border-color: #e6c3cb;
  --bs-table-striped-bg: #f2ced6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c3cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecc9d0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 374.98px) {
  .table-responsive-xsm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-max {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1919.98px) {
  .table-responsive-xmax {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  inset-block-start: 0 !important;
}

.top-50 {
  inset-block-start: 50% !important;
}

.top-100 {
  inset-block-start: 100% !important;
}

.bottom-0 {
  inset-block-end: 0 !important;
}

.bottom-50 {
  inset-block-end: 50% !important;
}

.bottom-100 {
  inset-block-end: 100% !important;
}

.start-0 {
  inset-inline-start: 0 !important;
}

.start-50 {
  inset-inline-start: 50% !important;
}

.start-100 {
  inset-inline-start: 100% !important;
}

.end-0 {
  inset-inline-end: 0 !important;
}

.end-50 {
  inset-inline-end: 50% !important;
}

.end-100 {
  inset-inline-end: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-block-start: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-block-start: 0 !important;
}

.border-end {
  border-inline-end: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-inline-end: 0 !important;
}

.border-bottom {
  border-block-end: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-block-end: 0 !important;
}

.border-start {
  border-inline-start: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-inline-start: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-19 {
  margin: 4.75rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-21 {
  margin: 5.25rem !important;
}

.m-22 {
  margin: 5.5rem !important;
}

.m-23 {
  margin: 5.75rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.m-25 {
  margin: 6.25rem !important;
}

.m-26 {
  margin: 6.5rem !important;
}

.m-27 {
  margin: 6.75rem !important;
}

.m-28 {
  margin: 7rem !important;
}

.m-29 {
  margin: 7.25rem !important;
}

.m-30 {
  margin: 7.5rem !important;
}

.m-31 {
  margin: 7.75rem !important;
}

.m-32 {
  margin: 8rem !important;
}

.m-33 {
  margin: 8.25rem !important;
}

.m-34 {
  margin: 8.5rem !important;
}

.m-35 {
  margin: 8.75rem !important;
}

.m-36 {
  margin: 9rem !important;
}

.m-37 {
  margin: 9.25rem !important;
}

.m-38 {
  margin: 9.5rem !important;
}

.m-39 {
  margin: 9.75rem !important;
}

.m-40 {
  margin: 10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-inline: 0 !important;
}

.mx-1 {
  margin-inline: 0.25rem !important;
}

.mx-2 {
  margin-inline: 0.5rem !important;
}

.mx-3 {
  margin-inline: 0.75rem !important;
}

.mx-4 {
  margin-inline: 1rem !important;
}

.mx-5 {
  margin-inline: 1.25rem !important;
}

.mx-6 {
  margin-inline: 1.5rem !important;
}

.mx-7 {
  margin-inline: 1.75rem !important;
}

.mx-8 {
  margin-inline: 2rem !important;
}

.mx-9 {
  margin-inline: 2.25rem !important;
}

.mx-10 {
  margin-inline: 2.5rem !important;
}

.mx-11 {
  margin-inline: 2.75rem !important;
}

.mx-12 {
  margin-inline: 3rem !important;
}

.mx-13 {
  margin-inline: 3.25rem !important;
}

.mx-14 {
  margin-inline: 3.5rem !important;
}

.mx-15 {
  margin-inline: 3.75rem !important;
}

.mx-16 {
  margin-inline: 4rem !important;
}

.mx-17 {
  margin-inline: 4.25rem !important;
}

.mx-18 {
  margin-inline: 4.5rem !important;
}

.mx-19 {
  margin-inline: 4.75rem !important;
}

.mx-20 {
  margin-inline: 5rem !important;
}

.mx-21 {
  margin-inline: 5.25rem !important;
}

.mx-22 {
  margin-inline: 5.5rem !important;
}

.mx-23 {
  margin-inline: 5.75rem !important;
}

.mx-24 {
  margin-inline: 6rem !important;
}

.mx-25 {
  margin-inline: 6.25rem !important;
}

.mx-26 {
  margin-inline: 6.5rem !important;
}

.mx-27 {
  margin-inline: 6.75rem !important;
}

.mx-28 {
  margin-inline: 7rem !important;
}

.mx-29 {
  margin-inline: 7.25rem !important;
}

.mx-30 {
  margin-inline: 7.5rem !important;
}

.mx-31 {
  margin-inline: 7.75rem !important;
}

.mx-32 {
  margin-inline: 8rem !important;
}

.mx-33 {
  margin-inline: 8.25rem !important;
}

.mx-34 {
  margin-inline: 8.5rem !important;
}

.mx-35 {
  margin-inline: 8.75rem !important;
}

.mx-36 {
  margin-inline: 9rem !important;
}

.mx-37 {
  margin-inline: 9.25rem !important;
}

.mx-38 {
  margin-inline: 9.5rem !important;
}

.mx-39 {
  margin-inline: 9.75rem !important;
}

.mx-40 {
  margin-inline: 10rem !important;
}

.mx-auto {
  margin-inline: auto !important;
}

.my-0 {
  margin-block: 0 !important;
}

.my-1 {
  margin-block: 0.25rem !important;
}

.my-2 {
  margin-block: 0.5rem !important;
}

.my-3 {
  margin-block: 0.75rem !important;
}

.my-4 {
  margin-block: 1rem !important;
}

.my-5 {
  margin-block: 1.25rem !important;
}

.my-6 {
  margin-block: 1.5rem !important;
}

.my-7 {
  margin-block: 1.75rem !important;
}

.my-8 {
  margin-block: 2rem !important;
}

.my-9 {
  margin-block: 2.25rem !important;
}

.my-10 {
  margin-block: 2.5rem !important;
}

.my-11 {
  margin-block: 2.75rem !important;
}

.my-12 {
  margin-block: 3rem !important;
}

.my-13 {
  margin-block: 3.25rem !important;
}

.my-14 {
  margin-block: 3.5rem !important;
}

.my-15 {
  margin-block: 3.75rem !important;
}

.my-16 {
  margin-block: 4rem !important;
}

.my-17 {
  margin-block: 4.25rem !important;
}

.my-18 {
  margin-block: 4.5rem !important;
}

.my-19 {
  margin-block: 4.75rem !important;
}

.my-20 {
  margin-block: 5rem !important;
}

.my-21 {
  margin-block: 5.25rem !important;
}

.my-22 {
  margin-block: 5.5rem !important;
}

.my-23 {
  margin-block: 5.75rem !important;
}

.my-24 {
  margin-block: 6rem !important;
}

.my-25 {
  margin-block: 6.25rem !important;
}

.my-26 {
  margin-block: 6.5rem !important;
}

.my-27 {
  margin-block: 6.75rem !important;
}

.my-28 {
  margin-block: 7rem !important;
}

.my-29 {
  margin-block: 7.25rem !important;
}

.my-30 {
  margin-block: 7.5rem !important;
}

.my-31 {
  margin-block: 7.75rem !important;
}

.my-32 {
  margin-block: 8rem !important;
}

.my-33 {
  margin-block: 8.25rem !important;
}

.my-34 {
  margin-block: 8.5rem !important;
}

.my-35 {
  margin-block: 8.75rem !important;
}

.my-36 {
  margin-block: 9rem !important;
}

.my-37 {
  margin-block: 9.25rem !important;
}

.my-38 {
  margin-block: 9.5rem !important;
}

.my-39 {
  margin-block: 9.75rem !important;
}

.my-40 {
  margin-block: 10rem !important;
}

.my-auto {
  margin-block: auto !important;
}

.mt-0 {
  margin-block-start: 0 !important;
}

.mt-1 {
  margin-block-start: 0.25rem !important;
}

.mt-2 {
  margin-block-start: 0.5rem !important;
}

.mt-3 {
  margin-block-start: 0.75rem !important;
}

.mt-4 {
  margin-block-start: 1rem !important;
}

.mt-5 {
  margin-block-start: 1.25rem !important;
}

.mt-6 {
  margin-block-start: 1.5rem !important;
}

.mt-7 {
  margin-block-start: 1.75rem !important;
}

.mt-8 {
  margin-block-start: 2rem !important;
}

.mt-9 {
  margin-block-start: 2.25rem !important;
}

.mt-10 {
  margin-block-start: 2.5rem !important;
}

.mt-11 {
  margin-block-start: 2.75rem !important;
}

.mt-12 {
  margin-block-start: 3rem !important;
}

.mt-13 {
  margin-block-start: 3.25rem !important;
}

.mt-14 {
  margin-block-start: 3.5rem !important;
}

.mt-15 {
  margin-block-start: 3.75rem !important;
}

.mt-16 {
  margin-block-start: 4rem !important;
}

.mt-17 {
  margin-block-start: 4.25rem !important;
}

.mt-18 {
  margin-block-start: 4.5rem !important;
}

.mt-19 {
  margin-block-start: 4.75rem !important;
}

.mt-20 {
  margin-block-start: 5rem !important;
}

.mt-21 {
  margin-block-start: 5.25rem !important;
}

.mt-22 {
  margin-block-start: 5.5rem !important;
}

.mt-23 {
  margin-block-start: 5.75rem !important;
}

.mt-24 {
  margin-block-start: 6rem !important;
}

.mt-25 {
  margin-block-start: 6.25rem !important;
}

.mt-26 {
  margin-block-start: 6.5rem !important;
}

.mt-27 {
  margin-block-start: 6.75rem !important;
}

.mt-28 {
  margin-block-start: 7rem !important;
}

.mt-29 {
  margin-block-start: 7.25rem !important;
}

.mt-30 {
  margin-block-start: 7.5rem !important;
}

.mt-31 {
  margin-block-start: 7.75rem !important;
}

.mt-32 {
  margin-block-start: 8rem !important;
}

.mt-33 {
  margin-block-start: 8.25rem !important;
}

.mt-34 {
  margin-block-start: 8.5rem !important;
}

.mt-35 {
  margin-block-start: 8.75rem !important;
}

.mt-36 {
  margin-block-start: 9rem !important;
}

.mt-37 {
  margin-block-start: 9.25rem !important;
}

.mt-38 {
  margin-block-start: 9.5rem !important;
}

.mt-39 {
  margin-block-start: 9.75rem !important;
}

.mt-40 {
  margin-block-start: 10rem !important;
}

.mt-auto {
  margin-block-start: auto !important;
}

.me-0 {
  margin-inline-end: 0 !important;
}

.me-1 {
  margin-inline-end: 0.25rem !important;
}

.me-2 {
  margin-inline-end: 0.5rem !important;
}

.me-3 {
  margin-inline-end: 0.75rem !important;
}

.me-4 {
  margin-inline-end: 1rem !important;
}

.me-5 {
  margin-inline-end: 1.25rem !important;
}

.me-6 {
  margin-inline-end: 1.5rem !important;
}

.me-7 {
  margin-inline-end: 1.75rem !important;
}

.me-8 {
  margin-inline-end: 2rem !important;
}

.me-9 {
  margin-inline-end: 2.25rem !important;
}

.me-10 {
  margin-inline-end: 2.5rem !important;
}

.me-11 {
  margin-inline-end: 2.75rem !important;
}

.me-12 {
  margin-inline-end: 3rem !important;
}

.me-13 {
  margin-inline-end: 3.25rem !important;
}

.me-14 {
  margin-inline-end: 3.5rem !important;
}

.me-15 {
  margin-inline-end: 3.75rem !important;
}

.me-16 {
  margin-inline-end: 4rem !important;
}

.me-17 {
  margin-inline-end: 4.25rem !important;
}

.me-18 {
  margin-inline-end: 4.5rem !important;
}

.me-19 {
  margin-inline-end: 4.75rem !important;
}

.me-20 {
  margin-inline-end: 5rem !important;
}

.me-21 {
  margin-inline-end: 5.25rem !important;
}

.me-22 {
  margin-inline-end: 5.5rem !important;
}

.me-23 {
  margin-inline-end: 5.75rem !important;
}

.me-24 {
  margin-inline-end: 6rem !important;
}

.me-25 {
  margin-inline-end: 6.25rem !important;
}

.me-26 {
  margin-inline-end: 6.5rem !important;
}

.me-27 {
  margin-inline-end: 6.75rem !important;
}

.me-28 {
  margin-inline-end: 7rem !important;
}

.me-29 {
  margin-inline-end: 7.25rem !important;
}

.me-30 {
  margin-inline-end: 7.5rem !important;
}

.me-31 {
  margin-inline-end: 7.75rem !important;
}

.me-32 {
  margin-inline-end: 8rem !important;
}

.me-33 {
  margin-inline-end: 8.25rem !important;
}

.me-34 {
  margin-inline-end: 8.5rem !important;
}

.me-35 {
  margin-inline-end: 8.75rem !important;
}

.me-36 {
  margin-inline-end: 9rem !important;
}

.me-37 {
  margin-inline-end: 9.25rem !important;
}

.me-38 {
  margin-inline-end: 9.5rem !important;
}

.me-39 {
  margin-inline-end: 9.75rem !important;
}

.me-40 {
  margin-inline-end: 10rem !important;
}

.me-auto {
  margin-inline-end: auto !important;
}

.mb-0 {
  margin-block-end: 0 !important;
}

.mb-1 {
  margin-block-end: 0.25rem !important;
}

.mb-2 {
  margin-block-end: 0.5rem !important;
}

.mb-3 {
  margin-block-end: 0.75rem !important;
}

.mb-4 {
  margin-block-end: 1rem !important;
}

.mb-5 {
  margin-block-end: 1.25rem !important;
}

.mb-6 {
  margin-block-end: 1.5rem !important;
}

.mb-7 {
  margin-block-end: 1.75rem !important;
}

.mb-8 {
  margin-block-end: 2rem !important;
}

.mb-9 {
  margin-block-end: 2.25rem !important;
}

.mb-10 {
  margin-block-end: 2.5rem !important;
}

.mb-11 {
  margin-block-end: 2.75rem !important;
}

.mb-12 {
  margin-block-end: 3rem !important;
}

.mb-13 {
  margin-block-end: 3.25rem !important;
}

.mb-14 {
  margin-block-end: 3.5rem !important;
}

.mb-15 {
  margin-block-end: 3.75rem !important;
}

.mb-16 {
  margin-block-end: 4rem !important;
}

.mb-17 {
  margin-block-end: 4.25rem !important;
}

.mb-18 {
  margin-block-end: 4.5rem !important;
}

.mb-19 {
  margin-block-end: 4.75rem !important;
}

.mb-20 {
  margin-block-end: 5rem !important;
}

.mb-21 {
  margin-block-end: 5.25rem !important;
}

.mb-22 {
  margin-block-end: 5.5rem !important;
}

.mb-23 {
  margin-block-end: 5.75rem !important;
}

.mb-24 {
  margin-block-end: 6rem !important;
}

.mb-25 {
  margin-block-end: 6.25rem !important;
}

.mb-26 {
  margin-block-end: 6.5rem !important;
}

.mb-27 {
  margin-block-end: 6.75rem !important;
}

.mb-28 {
  margin-block-end: 7rem !important;
}

.mb-29 {
  margin-block-end: 7.25rem !important;
}

.mb-30 {
  margin-block-end: 7.5rem !important;
}

.mb-31 {
  margin-block-end: 7.75rem !important;
}

.mb-32 {
  margin-block-end: 8rem !important;
}

.mb-33 {
  margin-block-end: 8.25rem !important;
}

.mb-34 {
  margin-block-end: 8.5rem !important;
}

.mb-35 {
  margin-block-end: 8.75rem !important;
}

.mb-36 {
  margin-block-end: 9rem !important;
}

.mb-37 {
  margin-block-end: 9.25rem !important;
}

.mb-38 {
  margin-block-end: 9.5rem !important;
}

.mb-39 {
  margin-block-end: 9.75rem !important;
}

.mb-40 {
  margin-block-end: 10rem !important;
}

.mb-auto {
  margin-block-end: auto !important;
}

.ms-0 {
  margin-inline-start: 0 !important;
}

.ms-1 {
  margin-inline-start: 0.25rem !important;
}

.ms-2 {
  margin-inline-start: 0.5rem !important;
}

.ms-3 {
  margin-inline-start: 0.75rem !important;
}

.ms-4 {
  margin-inline-start: 1rem !important;
}

.ms-5 {
  margin-inline-start: 1.25rem !important;
}

.ms-6 {
  margin-inline-start: 1.5rem !important;
}

.ms-7 {
  margin-inline-start: 1.75rem !important;
}

.ms-8 {
  margin-inline-start: 2rem !important;
}

.ms-9 {
  margin-inline-start: 2.25rem !important;
}

.ms-10 {
  margin-inline-start: 2.5rem !important;
}

.ms-11 {
  margin-inline-start: 2.75rem !important;
}

.ms-12 {
  margin-inline-start: 3rem !important;
}

.ms-13 {
  margin-inline-start: 3.25rem !important;
}

.ms-14 {
  margin-inline-start: 3.5rem !important;
}

.ms-15 {
  margin-inline-start: 3.75rem !important;
}

.ms-16 {
  margin-inline-start: 4rem !important;
}

.ms-17 {
  margin-inline-start: 4.25rem !important;
}

.ms-18 {
  margin-inline-start: 4.5rem !important;
}

.ms-19 {
  margin-inline-start: 4.75rem !important;
}

.ms-20 {
  margin-inline-start: 5rem !important;
}

.ms-21 {
  margin-inline-start: 5.25rem !important;
}

.ms-22 {
  margin-inline-start: 5.5rem !important;
}

.ms-23 {
  margin-inline-start: 5.75rem !important;
}

.ms-24 {
  margin-inline-start: 6rem !important;
}

.ms-25 {
  margin-inline-start: 6.25rem !important;
}

.ms-26 {
  margin-inline-start: 6.5rem !important;
}

.ms-27 {
  margin-inline-start: 6.75rem !important;
}

.ms-28 {
  margin-inline-start: 7rem !important;
}

.ms-29 {
  margin-inline-start: 7.25rem !important;
}

.ms-30 {
  margin-inline-start: 7.5rem !important;
}

.ms-31 {
  margin-inline-start: 7.75rem !important;
}

.ms-32 {
  margin-inline-start: 8rem !important;
}

.ms-33 {
  margin-inline-start: 8.25rem !important;
}

.ms-34 {
  margin-inline-start: 8.5rem !important;
}

.ms-35 {
  margin-inline-start: 8.75rem !important;
}

.ms-36 {
  margin-inline-start: 9rem !important;
}

.ms-37 {
  margin-inline-start: 9.25rem !important;
}

.ms-38 {
  margin-inline-start: 9.5rem !important;
}

.ms-39 {
  margin-inline-start: 9.75rem !important;
}

.ms-40 {
  margin-inline-start: 10rem !important;
}

.ms-auto {
  margin-inline-start: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-17 {
  padding: 4.25rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-19 {
  padding: 4.75rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.p-21 {
  padding: 5.25rem !important;
}

.p-22 {
  padding: 5.5rem !important;
}

.p-23 {
  padding: 5.75rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.p-25 {
  padding: 6.25rem !important;
}

.p-26 {
  padding: 6.5rem !important;
}

.p-27 {
  padding: 6.75rem !important;
}

.p-28 {
  padding: 7rem !important;
}

.p-29 {
  padding: 7.25rem !important;
}

.p-30 {
  padding: 7.5rem !important;
}

.p-31 {
  padding: 7.75rem !important;
}

.p-32 {
  padding: 8rem !important;
}

.p-33 {
  padding: 8.25rem !important;
}

.p-34 {
  padding: 8.5rem !important;
}

.p-35 {
  padding: 8.75rem !important;
}

.p-36 {
  padding: 9rem !important;
}

.p-37 {
  padding: 9.25rem !important;
}

.p-38 {
  padding: 9.5rem !important;
}

.p-39 {
  padding: 9.75rem !important;
}

.p-40 {
  padding: 10rem !important;
}

.px-0 {
  padding-inline: 0 !important;
}

.px-1 {
  padding-inline: 0.25rem !important;
}

.px-2 {
  padding-inline: 0.5rem !important;
}

.px-3 {
  padding-inline: 0.75rem !important;
}

.px-4 {
  padding-inline: 1rem !important;
}

.px-5 {
  padding-inline: 1.25rem !important;
}

.px-6 {
  padding-inline: 1.5rem !important;
}

.px-7 {
  padding-inline: 1.75rem !important;
}

.px-8 {
  padding-inline: 2rem !important;
}

.px-9 {
  padding-inline: 2.25rem !important;
}

.px-10 {
  padding-inline: 2.5rem !important;
}

.px-11 {
  padding-inline: 2.75rem !important;
}

.px-12 {
  padding-inline: 3rem !important;
}

.px-13 {
  padding-inline: 3.25rem !important;
}

.px-14 {
  padding-inline: 3.5rem !important;
}

.px-15 {
  padding-inline: 3.75rem !important;
}

.px-16 {
  padding-inline: 4rem !important;
}

.px-17 {
  padding-inline: 4.25rem !important;
}

.px-18 {
  padding-inline: 4.5rem !important;
}

.px-19 {
  padding-inline: 4.75rem !important;
}

.px-20 {
  padding-inline: 5rem !important;
}

.px-21 {
  padding-inline: 5.25rem !important;
}

.px-22 {
  padding-inline: 5.5rem !important;
}

.px-23 {
  padding-inline: 5.75rem !important;
}

.px-24 {
  padding-inline: 6rem !important;
}

.px-25 {
  padding-inline: 6.25rem !important;
}

.px-26 {
  padding-inline: 6.5rem !important;
}

.px-27 {
  padding-inline: 6.75rem !important;
}

.px-28 {
  padding-inline: 7rem !important;
}

.px-29 {
  padding-inline: 7.25rem !important;
}

.px-30 {
  padding-inline: 7.5rem !important;
}

.px-31 {
  padding-inline: 7.75rem !important;
}

.px-32 {
  padding-inline: 8rem !important;
}

.px-33 {
  padding-inline: 8.25rem !important;
}

.px-34 {
  padding-inline: 8.5rem !important;
}

.px-35 {
  padding-inline: 8.75rem !important;
}

.px-36 {
  padding-inline: 9rem !important;
}

.px-37 {
  padding-inline: 9.25rem !important;
}

.px-38 {
  padding-inline: 9.5rem !important;
}

.px-39 {
  padding-inline: 9.75rem !important;
}

.px-40 {
  padding-inline: 10rem !important;
}

.py-0 {
  padding-block: 0 !important;
}

.py-1 {
  padding-block: 0.25rem !important;
}

.py-2 {
  padding-block: 0.5rem !important;
}

.py-3 {
  padding-block: 0.75rem !important;
}

.py-4 {
  padding-block: 1rem !important;
}

.py-5 {
  padding-block: 1.25rem !important;
}

.py-6 {
  padding-block: 1.5rem !important;
}

.py-7 {
  padding-block: 1.75rem !important;
}

.py-8 {
  padding-block: 2rem !important;
}

.py-9 {
  padding-block: 2.25rem !important;
}

.py-10 {
  padding-block: 2.5rem !important;
}

.py-11 {
  padding-block: 2.75rem !important;
}

.py-12 {
  padding-block: 3rem !important;
}

.py-13 {
  padding-block: 3.25rem !important;
}

.py-14 {
  padding-block: 3.5rem !important;
}

.py-15 {
  padding-block: 3.75rem !important;
}

.py-16 {
  padding-block: 4rem !important;
}

.py-17 {
  padding-block: 4.25rem !important;
}

.py-18 {
  padding-block: 4.5rem !important;
}

.py-19 {
  padding-block: 4.75rem !important;
}

.py-20 {
  padding-block: 5rem !important;
}

.py-21 {
  padding-block: 5.25rem !important;
}

.py-22 {
  padding-block: 5.5rem !important;
}

.py-23 {
  padding-block: 5.75rem !important;
}

.py-24 {
  padding-block: 6rem !important;
}

.py-25 {
  padding-block: 6.25rem !important;
}

.py-26 {
  padding-block: 6.5rem !important;
}

.py-27 {
  padding-block: 6.75rem !important;
}

.py-28 {
  padding-block: 7rem !important;
}

.py-29 {
  padding-block: 7.25rem !important;
}

.py-30 {
  padding-block: 7.5rem !important;
}

.py-31 {
  padding-block: 7.75rem !important;
}

.py-32 {
  padding-block: 8rem !important;
}

.py-33 {
  padding-block: 8.25rem !important;
}

.py-34 {
  padding-block: 8.5rem !important;
}

.py-35 {
  padding-block: 8.75rem !important;
}

.py-36 {
  padding-block: 9rem !important;
}

.py-37 {
  padding-block: 9.25rem !important;
}

.py-38 {
  padding-block: 9.5rem !important;
}

.py-39 {
  padding-block: 9.75rem !important;
}

.py-40 {
  padding-block: 10rem !important;
}

.pt-0 {
  padding-block-start: 0 !important;
}

.pt-1 {
  padding-block-start: 0.25rem !important;
}

.pt-2 {
  padding-block-start: 0.5rem !important;
}

.pt-3 {
  padding-block-start: 0.75rem !important;
}

.pt-4 {
  padding-block-start: 1rem !important;
}

.pt-5 {
  padding-block-start: 1.25rem !important;
}

.pt-6 {
  padding-block-start: 1.5rem !important;
}

.pt-7 {
  padding-block-start: 1.75rem !important;
}

.pt-8 {
  padding-block-start: 2rem !important;
}

.pt-9 {
  padding-block-start: 2.25rem !important;
}

.pt-10 {
  padding-block-start: 2.5rem !important;
}

.pt-11 {
  padding-block-start: 2.75rem !important;
}

.pt-12 {
  padding-block-start: 3rem !important;
}

.pt-13 {
  padding-block-start: 3.25rem !important;
}

.pt-14 {
  padding-block-start: 3.5rem !important;
}

.pt-15 {
  padding-block-start: 3.75rem !important;
}

.pt-16 {
  padding-block-start: 4rem !important;
}

.pt-17 {
  padding-block-start: 4.25rem !important;
}

.pt-18 {
  padding-block-start: 4.5rem !important;
}

.pt-19 {
  padding-block-start: 4.75rem !important;
}

.pt-20 {
  padding-block-start: 5rem !important;
}

.pt-21 {
  padding-block-start: 5.25rem !important;
}

.pt-22 {
  padding-block-start: 5.5rem !important;
}

.pt-23 {
  padding-block-start: 5.75rem !important;
}

.pt-24 {
  padding-block-start: 6rem !important;
}

.pt-25 {
  padding-block-start: 6.25rem !important;
}

.pt-26 {
  padding-block-start: 6.5rem !important;
}

.pt-27 {
  padding-block-start: 6.75rem !important;
}

.pt-28 {
  padding-block-start: 7rem !important;
}

.pt-29 {
  padding-block-start: 7.25rem !important;
}

.pt-30 {
  padding-block-start: 7.5rem !important;
}

.pt-31 {
  padding-block-start: 7.75rem !important;
}

.pt-32 {
  padding-block-start: 8rem !important;
}

.pt-33 {
  padding-block-start: 8.25rem !important;
}

.pt-34 {
  padding-block-start: 8.5rem !important;
}

.pt-35 {
  padding-block-start: 8.75rem !important;
}

.pt-36 {
  padding-block-start: 9rem !important;
}

.pt-37 {
  padding-block-start: 9.25rem !important;
}

.pt-38 {
  padding-block-start: 9.5rem !important;
}

.pt-39 {
  padding-block-start: 9.75rem !important;
}

.pt-40 {
  padding-block-start: 10rem !important;
}

.pe-0 {
  padding-inline-end: 0 !important;
}

.pe-1 {
  padding-inline-end: 0.25rem !important;
}

.pe-2 {
  padding-inline-end: 0.5rem !important;
}

.pe-3 {
  padding-inline-end: 0.75rem !important;
}

.pe-4 {
  padding-inline-end: 1rem !important;
}

.pe-5 {
  padding-inline-end: 1.25rem !important;
}

.pe-6 {
  padding-inline-end: 1.5rem !important;
}

.pe-7 {
  padding-inline-end: 1.75rem !important;
}

.pe-8 {
  padding-inline-end: 2rem !important;
}

.pe-9 {
  padding-inline-end: 2.25rem !important;
}

.pe-10 {
  padding-inline-end: 2.5rem !important;
}

.pe-11 {
  padding-inline-end: 2.75rem !important;
}

.pe-12 {
  padding-inline-end: 3rem !important;
}

.pe-13 {
  padding-inline-end: 3.25rem !important;
}

.pe-14 {
  padding-inline-end: 3.5rem !important;
}

.pe-15 {
  padding-inline-end: 3.75rem !important;
}

.pe-16 {
  padding-inline-end: 4rem !important;
}

.pe-17 {
  padding-inline-end: 4.25rem !important;
}

.pe-18 {
  padding-inline-end: 4.5rem !important;
}

.pe-19 {
  padding-inline-end: 4.75rem !important;
}

.pe-20 {
  padding-inline-end: 5rem !important;
}

.pe-21 {
  padding-inline-end: 5.25rem !important;
}

.pe-22 {
  padding-inline-end: 5.5rem !important;
}

.pe-23 {
  padding-inline-end: 5.75rem !important;
}

.pe-24 {
  padding-inline-end: 6rem !important;
}

.pe-25 {
  padding-inline-end: 6.25rem !important;
}

.pe-26 {
  padding-inline-end: 6.5rem !important;
}

.pe-27 {
  padding-inline-end: 6.75rem !important;
}

.pe-28 {
  padding-inline-end: 7rem !important;
}

.pe-29 {
  padding-inline-end: 7.25rem !important;
}

.pe-30 {
  padding-inline-end: 7.5rem !important;
}

.pe-31 {
  padding-inline-end: 7.75rem !important;
}

.pe-32 {
  padding-inline-end: 8rem !important;
}

.pe-33 {
  padding-inline-end: 8.25rem !important;
}

.pe-34 {
  padding-inline-end: 8.5rem !important;
}

.pe-35 {
  padding-inline-end: 8.75rem !important;
}

.pe-36 {
  padding-inline-end: 9rem !important;
}

.pe-37 {
  padding-inline-end: 9.25rem !important;
}

.pe-38 {
  padding-inline-end: 9.5rem !important;
}

.pe-39 {
  padding-inline-end: 9.75rem !important;
}

.pe-40 {
  padding-inline-end: 10rem !important;
}

.pb-0 {
  padding-block-end: 0 !important;
}

.pb-1 {
  padding-block-end: 0.25rem !important;
}

.pb-2 {
  padding-block-end: 0.5rem !important;
}

.pb-3 {
  padding-block-end: 0.75rem !important;
}

.pb-4 {
  padding-block-end: 1rem !important;
}

.pb-5 {
  padding-block-end: 1.25rem !important;
}

.pb-6 {
  padding-block-end: 1.5rem !important;
}

.pb-7 {
  padding-block-end: 1.75rem !important;
}

.pb-8 {
  padding-block-end: 2rem !important;
}

.pb-9 {
  padding-block-end: 2.25rem !important;
}

.pb-10 {
  padding-block-end: 2.5rem !important;
}

.pb-11 {
  padding-block-end: 2.75rem !important;
}

.pb-12 {
  padding-block-end: 3rem !important;
}

.pb-13 {
  padding-block-end: 3.25rem !important;
}

.pb-14 {
  padding-block-end: 3.5rem !important;
}

.pb-15 {
  padding-block-end: 3.75rem !important;
}

.pb-16 {
  padding-block-end: 4rem !important;
}

.pb-17 {
  padding-block-end: 4.25rem !important;
}

.pb-18 {
  padding-block-end: 4.5rem !important;
}

.pb-19 {
  padding-block-end: 4.75rem !important;
}

.pb-20 {
  padding-block-end: 5rem !important;
}

.pb-21 {
  padding-block-end: 5.25rem !important;
}

.pb-22 {
  padding-block-end: 5.5rem !important;
}

.pb-23 {
  padding-block-end: 5.75rem !important;
}

.pb-24 {
  padding-block-end: 6rem !important;
}

.pb-25 {
  padding-block-end: 6.25rem !important;
}

.pb-26 {
  padding-block-end: 6.5rem !important;
}

.pb-27 {
  padding-block-end: 6.75rem !important;
}

.pb-28 {
  padding-block-end: 7rem !important;
}

.pb-29 {
  padding-block-end: 7.25rem !important;
}

.pb-30 {
  padding-block-end: 7.5rem !important;
}

.pb-31 {
  padding-block-end: 7.75rem !important;
}

.pb-32 {
  padding-block-end: 8rem !important;
}

.pb-33 {
  padding-block-end: 8.25rem !important;
}

.pb-34 {
  padding-block-end: 8.5rem !important;
}

.pb-35 {
  padding-block-end: 8.75rem !important;
}

.pb-36 {
  padding-block-end: 9rem !important;
}

.pb-37 {
  padding-block-end: 9.25rem !important;
}

.pb-38 {
  padding-block-end: 9.5rem !important;
}

.pb-39 {
  padding-block-end: 9.75rem !important;
}

.pb-40 {
  padding-block-end: 10rem !important;
}

.ps-0 {
  padding-inline-start: 0 !important;
}

.ps-1 {
  padding-inline-start: 0.25rem !important;
}

.ps-2 {
  padding-inline-start: 0.5rem !important;
}

.ps-3 {
  padding-inline-start: 0.75rem !important;
}

.ps-4 {
  padding-inline-start: 1rem !important;
}

.ps-5 {
  padding-inline-start: 1.25rem !important;
}

.ps-6 {
  padding-inline-start: 1.5rem !important;
}

.ps-7 {
  padding-inline-start: 1.75rem !important;
}

.ps-8 {
  padding-inline-start: 2rem !important;
}

.ps-9 {
  padding-inline-start: 2.25rem !important;
}

.ps-10 {
  padding-inline-start: 2.5rem !important;
}

.ps-11 {
  padding-inline-start: 2.75rem !important;
}

.ps-12 {
  padding-inline-start: 3rem !important;
}

.ps-13 {
  padding-inline-start: 3.25rem !important;
}

.ps-14 {
  padding-inline-start: 3.5rem !important;
}

.ps-15 {
  padding-inline-start: 3.75rem !important;
}

.ps-16 {
  padding-inline-start: 4rem !important;
}

.ps-17 {
  padding-inline-start: 4.25rem !important;
}

.ps-18 {
  padding-inline-start: 4.5rem !important;
}

.ps-19 {
  padding-inline-start: 4.75rem !important;
}

.ps-20 {
  padding-inline-start: 5rem !important;
}

.ps-21 {
  padding-inline-start: 5.25rem !important;
}

.ps-22 {
  padding-inline-start: 5.5rem !important;
}

.ps-23 {
  padding-inline-start: 5.75rem !important;
}

.ps-24 {
  padding-inline-start: 6rem !important;
}

.ps-25 {
  padding-inline-start: 6.25rem !important;
}

.ps-26 {
  padding-inline-start: 6.5rem !important;
}

.ps-27 {
  padding-inline-start: 6.75rem !important;
}

.ps-28 {
  padding-inline-start: 7rem !important;
}

.ps-29 {
  padding-inline-start: 7.25rem !important;
}

.ps-30 {
  padding-inline-start: 7.5rem !important;
}

.ps-31 {
  padding-inline-start: 7.75rem !important;
}

.ps-32 {
  padding-inline-start: 8rem !important;
}

.ps-33 {
  padding-inline-start: 8.25rem !important;
}

.ps-34 {
  padding-inline-start: 8.5rem !important;
}

.ps-35 {
  padding-inline-start: 8.75rem !important;
}

.ps-36 {
  padding-inline-start: 9rem !important;
}

.ps-37 {
  padding-inline-start: 9.25rem !important;
}

.ps-38 {
  padding-inline-start: 9.5rem !important;
}

.ps-39 {
  padding-inline-start: 9.75rem !important;
}

.ps-40 {
  padding-inline-start: 10rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-11 {
  gap: 2.75rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-13 {
  gap: 3.25rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-15 {
  gap: 3.75rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.gap-17 {
  gap: 4.25rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.gap-19 {
  gap: 4.75rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.gap-21 {
  gap: 5.25rem !important;
}

.gap-22 {
  gap: 5.5rem !important;
}

.gap-23 {
  gap: 5.75rem !important;
}

.gap-24 {
  gap: 6rem !important;
}

.gap-25 {
  gap: 6.25rem !important;
}

.gap-26 {
  gap: 6.5rem !important;
}

.gap-27 {
  gap: 6.75rem !important;
}

.gap-28 {
  gap: 7rem !important;
}

.gap-29 {
  gap: 7.25rem !important;
}

.gap-30 {
  gap: 7.5rem !important;
}

.gap-31 {
  gap: 7.75rem !important;
}

.gap-32 {
  gap: 8rem !important;
}

.gap-33 {
  gap: 8.25rem !important;
}

.gap-34 {
  gap: 8.5rem !important;
}

.gap-35 {
  gap: 8.75rem !important;
}

.gap-36 {
  gap: 9rem !important;
}

.gap-37 {
  gap: 9.25rem !important;
}

.gap-38 {
  gap: 9.5rem !important;
}

.gap-39 {
  gap: 9.75rem !important;
}

.gap-40 {
  gap: 10rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 0.75rem !important;
}

.row-gap-4 {
  row-gap: 1rem !important;
}

.row-gap-5 {
  row-gap: 1.25rem !important;
}

.row-gap-6 {
  row-gap: 1.5rem !important;
}

.row-gap-7 {
  row-gap: 1.75rem !important;
}

.row-gap-8 {
  row-gap: 2rem !important;
}

.row-gap-9 {
  row-gap: 2.25rem !important;
}

.row-gap-10 {
  row-gap: 2.5rem !important;
}

.row-gap-11 {
  row-gap: 2.75rem !important;
}

.row-gap-12 {
  row-gap: 3rem !important;
}

.row-gap-13 {
  row-gap: 3.25rem !important;
}

.row-gap-14 {
  row-gap: 3.5rem !important;
}

.row-gap-15 {
  row-gap: 3.75rem !important;
}

.row-gap-16 {
  row-gap: 4rem !important;
}

.row-gap-17 {
  row-gap: 4.25rem !important;
}

.row-gap-18 {
  row-gap: 4.5rem !important;
}

.row-gap-19 {
  row-gap: 4.75rem !important;
}

.row-gap-20 {
  row-gap: 5rem !important;
}

.row-gap-21 {
  row-gap: 5.25rem !important;
}

.row-gap-22 {
  row-gap: 5.5rem !important;
}

.row-gap-23 {
  row-gap: 5.75rem !important;
}

.row-gap-24 {
  row-gap: 6rem !important;
}

.row-gap-25 {
  row-gap: 6.25rem !important;
}

.row-gap-26 {
  row-gap: 6.5rem !important;
}

.row-gap-27 {
  row-gap: 6.75rem !important;
}

.row-gap-28 {
  row-gap: 7rem !important;
}

.row-gap-29 {
  row-gap: 7.25rem !important;
}

.row-gap-30 {
  row-gap: 7.5rem !important;
}

.row-gap-31 {
  row-gap: 7.75rem !important;
}

.row-gap-32 {
  row-gap: 8rem !important;
}

.row-gap-33 {
  row-gap: 8.25rem !important;
}

.row-gap-34 {
  row-gap: 8.5rem !important;
}

.row-gap-35 {
  row-gap: 8.75rem !important;
}

.row-gap-36 {
  row-gap: 9rem !important;
}

.row-gap-37 {
  row-gap: 9.25rem !important;
}

.row-gap-38 {
  row-gap: 9.5rem !important;
}

.row-gap-39 {
  row-gap: 9.75rem !important;
}

.row-gap-40 {
  row-gap: 10rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 0.75rem !important;
}

.column-gap-4 {
  column-gap: 1rem !important;
}

.column-gap-5 {
  column-gap: 1.25rem !important;
}

.column-gap-6 {
  column-gap: 1.5rem !important;
}

.column-gap-7 {
  column-gap: 1.75rem !important;
}

.column-gap-8 {
  column-gap: 2rem !important;
}

.column-gap-9 {
  column-gap: 2.25rem !important;
}

.column-gap-10 {
  column-gap: 2.5rem !important;
}

.column-gap-11 {
  column-gap: 2.75rem !important;
}

.column-gap-12 {
  column-gap: 3rem !important;
}

.column-gap-13 {
  column-gap: 3.25rem !important;
}

.column-gap-14 {
  column-gap: 3.5rem !important;
}

.column-gap-15 {
  column-gap: 3.75rem !important;
}

.column-gap-16 {
  column-gap: 4rem !important;
}

.column-gap-17 {
  column-gap: 4.25rem !important;
}

.column-gap-18 {
  column-gap: 4.5rem !important;
}

.column-gap-19 {
  column-gap: 4.75rem !important;
}

.column-gap-20 {
  column-gap: 5rem !important;
}

.column-gap-21 {
  column-gap: 5.25rem !important;
}

.column-gap-22 {
  column-gap: 5.5rem !important;
}

.column-gap-23 {
  column-gap: 5.75rem !important;
}

.column-gap-24 {
  column-gap: 6rem !important;
}

.column-gap-25 {
  column-gap: 6.25rem !important;
}

.column-gap-26 {
  column-gap: 6.5rem !important;
}

.column-gap-27 {
  column-gap: 6.75rem !important;
}

.column-gap-28 {
  column-gap: 7rem !important;
}

.column-gap-29 {
  column-gap: 7.25rem !important;
}

.column-gap-30 {
  column-gap: 7.5rem !important;
}

.column-gap-31 {
  column-gap: 7.75rem !important;
}

.column-gap-32 {
  column-gap: 8rem !important;
}

.column-gap-33 {
  column-gap: 8.25rem !important;
}

.column-gap-34 {
  column-gap: 8.5rem !important;
}

.column-gap-35 {
  column-gap: 8.75rem !important;
}

.column-gap-36 {
  column-gap: 9rem !important;
}

.column-gap-37 {
  column-gap: 9.25rem !important;
}

.column-gap-38 {
  column-gap: 9.5rem !important;
}

.column-gap-39 {
  column-gap: 9.75rem !important;
}

.column-gap-40 {
  column-gap: 10rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-start-start-radius: var(--bs-border-radius) !important;
  border-start-end-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-start-start-radius: 0 !important;
  border-start-end-radius: 0 !important;
}

.rounded-top-1 {
  border-start-start-radius: var(--bs-border-radius-sm) !important;
  border-start-end-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-start-start-radius: var(--bs-border-radius) !important;
  border-start-end-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-start-start-radius: var(--bs-border-radius-lg) !important;
  border-start-end-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-start-start-radius: var(--bs-border-radius-xl) !important;
  border-start-end-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-start-start-radius: var(--bs-border-radius-xxl) !important;
  border-start-end-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-start-start-radius: 50% !important;
  border-start-end-radius: 50% !important;
}

.rounded-top-pill {
  border-start-start-radius: var(--bs-border-radius-pill) !important;
  border-start-end-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-start-end-radius: var(--bs-border-radius) !important;
  border-end-end-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
}

.rounded-end-1 {
  border-start-end-radius: var(--bs-border-radius-sm) !important;
  border-end-end-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-start-end-radius: var(--bs-border-radius) !important;
  border-end-end-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-start-end-radius: var(--bs-border-radius-lg) !important;
  border-end-end-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-start-end-radius: var(--bs-border-radius-xl) !important;
  border-end-end-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-start-end-radius: var(--bs-border-radius-xxl) !important;
  border-end-end-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-start-end-radius: 50% !important;
  border-end-end-radius: 50% !important;
}

.rounded-end-pill {
  border-start-end-radius: var(--bs-border-radius-pill) !important;
  border-end-end-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-end-end-radius: var(--bs-border-radius) !important;
  border-end-start-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-end-end-radius: 0 !important;
  border-end-start-radius: 0 !important;
}

.rounded-bottom-1 {
  border-end-end-radius: var(--bs-border-radius-sm) !important;
  border-end-start-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-end-end-radius: var(--bs-border-radius) !important;
  border-end-start-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-end-end-radius: var(--bs-border-radius-lg) !important;
  border-end-start-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-end-end-radius: var(--bs-border-radius-xl) !important;
  border-end-start-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-end-end-radius: var(--bs-border-radius-xxl) !important;
  border-end-start-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-end-end-radius: 50% !important;
  border-end-start-radius: 50% !important;
}

.rounded-bottom-pill {
  border-end-end-radius: var(--bs-border-radius-pill) !important;
  border-end-start-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-end-start-radius: var(--bs-border-radius) !important;
  border-start-start-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-end-start-radius: 0 !important;
  border-start-start-radius: 0 !important;
}

.rounded-start-1 {
  border-end-start-radius: var(--bs-border-radius-sm) !important;
  border-start-start-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-end-start-radius: var(--bs-border-radius) !important;
  border-start-start-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-end-start-radius: var(--bs-border-radius-lg) !important;
  border-start-start-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-end-start-radius: var(--bs-border-radius-xl) !important;
  border-start-start-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-end-start-radius: var(--bs-border-radius-xxl) !important;
  border-start-start-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-end-start-radius: 50% !important;
  border-start-start-radius: 50% !important;
}

.rounded-start-pill {
  border-end-start-radius: var(--bs-border-radius-pill) !important;
  border-start-start-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.rounded-top-start {
  border-start-start-radius: var(--bs-border-radius) !important;
}

.rounded-top-start-0 {
  border-start-start-radius: 0 !important;
}

.rounded-top-start-1 {
  border-start-start-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-start-2 {
  border-start-start-radius: var(--bs-border-radius) !important;
}

.rounded-top-start-3 {
  border-start-start-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-start-4 {
  border-start-start-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-start-5 {
  border-start-start-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-top-start-circle {
  border-start-start-radius: 50% !important;
}

.rounded-top-start-pill {
  border-start-start-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top-end {
  border-start-end-radius: var(--bs-border-radius) !important;
}

.rounded-top-end-0 {
  border-start-end-radius: 0 !important;
}

.rounded-top-end-1 {
  border-start-end-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-end-2 {
  border-start-end-radius: var(--bs-border-radius) !important;
}

.rounded-top-end-3 {
  border-start-end-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-end-4 {
  border-start-end-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-end-5 {
  border-start-end-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-top-end-circle {
  border-start-end-radius: 50% !important;
}

.rounded-top-end-pill {
  border-start-end-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom-start {
  border-end-start-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-start-0 {
  border-end-start-radius: 0 !important;
}

.rounded-bottom-start-1 {
  border-end-start-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-start-2 {
  border-end-start-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-start-3 {
  border-end-start-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-start-4 {
  border-end-start-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-start-5 {
  border-end-start-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-bottom-start-circle {
  border-end-start-radius: 50% !important;
}

.rounded-bottom-start-pill {
  border-end-start-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom-end {
  border-end-end-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-end-0 {
  border-end-end-radius: 0 !important;
}

.rounded-bottom-end-1 {
  border-end-end-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-end-2 {
  border-end-end-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-end-3 {
  border-end-end-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-end-4 {
  border-end-end-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-end-5 {
  border-end-end-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-bottom-end-circle {
  border-end-end-radius: 50% !important;
}

.rounded-bottom-end-pill {
  border-end-end-radius: var(--bs-border-radius-pill) !important;
}

@media (min-width: 375px) {
  .float-xsm-start {
    float: left !important;
  }
  .float-xsm-end {
    float: right !important;
  }
  .float-xsm-none {
    float: none !important;
  }
  .object-fit-xsm-contain {
    object-fit: contain !important;
  }
  .object-fit-xsm-cover {
    object-fit: cover !important;
  }
  .object-fit-xsm-fill {
    object-fit: fill !important;
  }
  .object-fit-xsm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xsm-none {
    object-fit: none !important;
  }
  .d-xsm-inline {
    display: inline !important;
  }
  .d-xsm-inline-block {
    display: inline-block !important;
  }
  .d-xsm-block {
    display: block !important;
  }
  .d-xsm-grid {
    display: grid !important;
  }
  .d-xsm-inline-grid {
    display: inline-grid !important;
  }
  .d-xsm-table {
    display: table !important;
  }
  .d-xsm-table-row {
    display: table-row !important;
  }
  .d-xsm-table-cell {
    display: table-cell !important;
  }
  .d-xsm-flex {
    display: flex !important;
  }
  .d-xsm-inline-flex {
    display: inline-flex !important;
  }
  .d-xsm-none {
    display: none !important;
  }
  .border-xsm {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xsm-0 {
    border: 0 !important;
  }
  .flex-xsm-fill {
    flex: 1 1 auto !important;
  }
  .flex-xsm-row {
    flex-direction: row !important;
  }
  .flex-xsm-column {
    flex-direction: column !important;
  }
  .flex-xsm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xsm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xsm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xsm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xsm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xsm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xsm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xsm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xsm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xsm-start {
    justify-content: flex-start !important;
  }
  .justify-content-xsm-end {
    justify-content: flex-end !important;
  }
  .justify-content-xsm-center {
    justify-content: center !important;
  }
  .justify-content-xsm-between {
    justify-content: space-between !important;
  }
  .justify-content-xsm-around {
    justify-content: space-around !important;
  }
  .justify-content-xsm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xsm-start {
    align-items: flex-start !important;
  }
  .align-items-xsm-end {
    align-items: flex-end !important;
  }
  .align-items-xsm-center {
    align-items: center !important;
  }
  .align-items-xsm-baseline {
    align-items: baseline !important;
  }
  .align-items-xsm-stretch {
    align-items: stretch !important;
  }
  .align-content-xsm-start {
    align-content: flex-start !important;
  }
  .align-content-xsm-end {
    align-content: flex-end !important;
  }
  .align-content-xsm-center {
    align-content: center !important;
  }
  .align-content-xsm-between {
    align-content: space-between !important;
  }
  .align-content-xsm-around {
    align-content: space-around !important;
  }
  .align-content-xsm-stretch {
    align-content: stretch !important;
  }
  .align-self-xsm-auto {
    align-self: auto !important;
  }
  .align-self-xsm-start {
    align-self: flex-start !important;
  }
  .align-self-xsm-end {
    align-self: flex-end !important;
  }
  .align-self-xsm-center {
    align-self: center !important;
  }
  .align-self-xsm-baseline {
    align-self: baseline !important;
  }
  .align-self-xsm-stretch {
    align-self: stretch !important;
  }
  .order-xsm-first {
    order: -1 !important;
  }
  .order-xsm-0 {
    order: 0 !important;
  }
  .order-xsm-1 {
    order: 1 !important;
  }
  .order-xsm-2 {
    order: 2 !important;
  }
  .order-xsm-3 {
    order: 3 !important;
  }
  .order-xsm-4 {
    order: 4 !important;
  }
  .order-xsm-5 {
    order: 5 !important;
  }
  .order-xsm-last {
    order: 6 !important;
  }
  .m-xsm-0 {
    margin: 0 !important;
  }
  .m-xsm-1 {
    margin: 0.25rem !important;
  }
  .m-xsm-2 {
    margin: 0.5rem !important;
  }
  .m-xsm-3 {
    margin: 0.75rem !important;
  }
  .m-xsm-4 {
    margin: 1rem !important;
  }
  .m-xsm-5 {
    margin: 1.25rem !important;
  }
  .m-xsm-6 {
    margin: 1.5rem !important;
  }
  .m-xsm-7 {
    margin: 1.75rem !important;
  }
  .m-xsm-8 {
    margin: 2rem !important;
  }
  .m-xsm-9 {
    margin: 2.25rem !important;
  }
  .m-xsm-10 {
    margin: 2.5rem !important;
  }
  .m-xsm-11 {
    margin: 2.75rem !important;
  }
  .m-xsm-12 {
    margin: 3rem !important;
  }
  .m-xsm-13 {
    margin: 3.25rem !important;
  }
  .m-xsm-14 {
    margin: 3.5rem !important;
  }
  .m-xsm-15 {
    margin: 3.75rem !important;
  }
  .m-xsm-16 {
    margin: 4rem !important;
  }
  .m-xsm-17 {
    margin: 4.25rem !important;
  }
  .m-xsm-18 {
    margin: 4.5rem !important;
  }
  .m-xsm-19 {
    margin: 4.75rem !important;
  }
  .m-xsm-20 {
    margin: 5rem !important;
  }
  .m-xsm-21 {
    margin: 5.25rem !important;
  }
  .m-xsm-22 {
    margin: 5.5rem !important;
  }
  .m-xsm-23 {
    margin: 5.75rem !important;
  }
  .m-xsm-24 {
    margin: 6rem !important;
  }
  .m-xsm-25 {
    margin: 6.25rem !important;
  }
  .m-xsm-26 {
    margin: 6.5rem !important;
  }
  .m-xsm-27 {
    margin: 6.75rem !important;
  }
  .m-xsm-28 {
    margin: 7rem !important;
  }
  .m-xsm-29 {
    margin: 7.25rem !important;
  }
  .m-xsm-30 {
    margin: 7.5rem !important;
  }
  .m-xsm-31 {
    margin: 7.75rem !important;
  }
  .m-xsm-32 {
    margin: 8rem !important;
  }
  .m-xsm-33 {
    margin: 8.25rem !important;
  }
  .m-xsm-34 {
    margin: 8.5rem !important;
  }
  .m-xsm-35 {
    margin: 8.75rem !important;
  }
  .m-xsm-36 {
    margin: 9rem !important;
  }
  .m-xsm-37 {
    margin: 9.25rem !important;
  }
  .m-xsm-38 {
    margin: 9.5rem !important;
  }
  .m-xsm-39 {
    margin: 9.75rem !important;
  }
  .m-xsm-40 {
    margin: 10rem !important;
  }
  .m-xsm-auto {
    margin: auto !important;
  }
  .mx-xsm-0 {
    margin-inline: 0 !important;
  }
  .mx-xsm-1 {
    margin-inline: 0.25rem !important;
  }
  .mx-xsm-2 {
    margin-inline: 0.5rem !important;
  }
  .mx-xsm-3 {
    margin-inline: 0.75rem !important;
  }
  .mx-xsm-4 {
    margin-inline: 1rem !important;
  }
  .mx-xsm-5 {
    margin-inline: 1.25rem !important;
  }
  .mx-xsm-6 {
    margin-inline: 1.5rem !important;
  }
  .mx-xsm-7 {
    margin-inline: 1.75rem !important;
  }
  .mx-xsm-8 {
    margin-inline: 2rem !important;
  }
  .mx-xsm-9 {
    margin-inline: 2.25rem !important;
  }
  .mx-xsm-10 {
    margin-inline: 2.5rem !important;
  }
  .mx-xsm-11 {
    margin-inline: 2.75rem !important;
  }
  .mx-xsm-12 {
    margin-inline: 3rem !important;
  }
  .mx-xsm-13 {
    margin-inline: 3.25rem !important;
  }
  .mx-xsm-14 {
    margin-inline: 3.5rem !important;
  }
  .mx-xsm-15 {
    margin-inline: 3.75rem !important;
  }
  .mx-xsm-16 {
    margin-inline: 4rem !important;
  }
  .mx-xsm-17 {
    margin-inline: 4.25rem !important;
  }
  .mx-xsm-18 {
    margin-inline: 4.5rem !important;
  }
  .mx-xsm-19 {
    margin-inline: 4.75rem !important;
  }
  .mx-xsm-20 {
    margin-inline: 5rem !important;
  }
  .mx-xsm-21 {
    margin-inline: 5.25rem !important;
  }
  .mx-xsm-22 {
    margin-inline: 5.5rem !important;
  }
  .mx-xsm-23 {
    margin-inline: 5.75rem !important;
  }
  .mx-xsm-24 {
    margin-inline: 6rem !important;
  }
  .mx-xsm-25 {
    margin-inline: 6.25rem !important;
  }
  .mx-xsm-26 {
    margin-inline: 6.5rem !important;
  }
  .mx-xsm-27 {
    margin-inline: 6.75rem !important;
  }
  .mx-xsm-28 {
    margin-inline: 7rem !important;
  }
  .mx-xsm-29 {
    margin-inline: 7.25rem !important;
  }
  .mx-xsm-30 {
    margin-inline: 7.5rem !important;
  }
  .mx-xsm-31 {
    margin-inline: 7.75rem !important;
  }
  .mx-xsm-32 {
    margin-inline: 8rem !important;
  }
  .mx-xsm-33 {
    margin-inline: 8.25rem !important;
  }
  .mx-xsm-34 {
    margin-inline: 8.5rem !important;
  }
  .mx-xsm-35 {
    margin-inline: 8.75rem !important;
  }
  .mx-xsm-36 {
    margin-inline: 9rem !important;
  }
  .mx-xsm-37 {
    margin-inline: 9.25rem !important;
  }
  .mx-xsm-38 {
    margin-inline: 9.5rem !important;
  }
  .mx-xsm-39 {
    margin-inline: 9.75rem !important;
  }
  .mx-xsm-40 {
    margin-inline: 10rem !important;
  }
  .mx-xsm-auto {
    margin-inline: auto !important;
  }
  .my-xsm-0 {
    margin-block: 0 !important;
  }
  .my-xsm-1 {
    margin-block: 0.25rem !important;
  }
  .my-xsm-2 {
    margin-block: 0.5rem !important;
  }
  .my-xsm-3 {
    margin-block: 0.75rem !important;
  }
  .my-xsm-4 {
    margin-block: 1rem !important;
  }
  .my-xsm-5 {
    margin-block: 1.25rem !important;
  }
  .my-xsm-6 {
    margin-block: 1.5rem !important;
  }
  .my-xsm-7 {
    margin-block: 1.75rem !important;
  }
  .my-xsm-8 {
    margin-block: 2rem !important;
  }
  .my-xsm-9 {
    margin-block: 2.25rem !important;
  }
  .my-xsm-10 {
    margin-block: 2.5rem !important;
  }
  .my-xsm-11 {
    margin-block: 2.75rem !important;
  }
  .my-xsm-12 {
    margin-block: 3rem !important;
  }
  .my-xsm-13 {
    margin-block: 3.25rem !important;
  }
  .my-xsm-14 {
    margin-block: 3.5rem !important;
  }
  .my-xsm-15 {
    margin-block: 3.75rem !important;
  }
  .my-xsm-16 {
    margin-block: 4rem !important;
  }
  .my-xsm-17 {
    margin-block: 4.25rem !important;
  }
  .my-xsm-18 {
    margin-block: 4.5rem !important;
  }
  .my-xsm-19 {
    margin-block: 4.75rem !important;
  }
  .my-xsm-20 {
    margin-block: 5rem !important;
  }
  .my-xsm-21 {
    margin-block: 5.25rem !important;
  }
  .my-xsm-22 {
    margin-block: 5.5rem !important;
  }
  .my-xsm-23 {
    margin-block: 5.75rem !important;
  }
  .my-xsm-24 {
    margin-block: 6rem !important;
  }
  .my-xsm-25 {
    margin-block: 6.25rem !important;
  }
  .my-xsm-26 {
    margin-block: 6.5rem !important;
  }
  .my-xsm-27 {
    margin-block: 6.75rem !important;
  }
  .my-xsm-28 {
    margin-block: 7rem !important;
  }
  .my-xsm-29 {
    margin-block: 7.25rem !important;
  }
  .my-xsm-30 {
    margin-block: 7.5rem !important;
  }
  .my-xsm-31 {
    margin-block: 7.75rem !important;
  }
  .my-xsm-32 {
    margin-block: 8rem !important;
  }
  .my-xsm-33 {
    margin-block: 8.25rem !important;
  }
  .my-xsm-34 {
    margin-block: 8.5rem !important;
  }
  .my-xsm-35 {
    margin-block: 8.75rem !important;
  }
  .my-xsm-36 {
    margin-block: 9rem !important;
  }
  .my-xsm-37 {
    margin-block: 9.25rem !important;
  }
  .my-xsm-38 {
    margin-block: 9.5rem !important;
  }
  .my-xsm-39 {
    margin-block: 9.75rem !important;
  }
  .my-xsm-40 {
    margin-block: 10rem !important;
  }
  .my-xsm-auto {
    margin-block: auto !important;
  }
  .mt-xsm-0 {
    margin-block-start: 0 !important;
  }
  .mt-xsm-1 {
    margin-block-start: 0.25rem !important;
  }
  .mt-xsm-2 {
    margin-block-start: 0.5rem !important;
  }
  .mt-xsm-3 {
    margin-block-start: 0.75rem !important;
  }
  .mt-xsm-4 {
    margin-block-start: 1rem !important;
  }
  .mt-xsm-5 {
    margin-block-start: 1.25rem !important;
  }
  .mt-xsm-6 {
    margin-block-start: 1.5rem !important;
  }
  .mt-xsm-7 {
    margin-block-start: 1.75rem !important;
  }
  .mt-xsm-8 {
    margin-block-start: 2rem !important;
  }
  .mt-xsm-9 {
    margin-block-start: 2.25rem !important;
  }
  .mt-xsm-10 {
    margin-block-start: 2.5rem !important;
  }
  .mt-xsm-11 {
    margin-block-start: 2.75rem !important;
  }
  .mt-xsm-12 {
    margin-block-start: 3rem !important;
  }
  .mt-xsm-13 {
    margin-block-start: 3.25rem !important;
  }
  .mt-xsm-14 {
    margin-block-start: 3.5rem !important;
  }
  .mt-xsm-15 {
    margin-block-start: 3.75rem !important;
  }
  .mt-xsm-16 {
    margin-block-start: 4rem !important;
  }
  .mt-xsm-17 {
    margin-block-start: 4.25rem !important;
  }
  .mt-xsm-18 {
    margin-block-start: 4.5rem !important;
  }
  .mt-xsm-19 {
    margin-block-start: 4.75rem !important;
  }
  .mt-xsm-20 {
    margin-block-start: 5rem !important;
  }
  .mt-xsm-21 {
    margin-block-start: 5.25rem !important;
  }
  .mt-xsm-22 {
    margin-block-start: 5.5rem !important;
  }
  .mt-xsm-23 {
    margin-block-start: 5.75rem !important;
  }
  .mt-xsm-24 {
    margin-block-start: 6rem !important;
  }
  .mt-xsm-25 {
    margin-block-start: 6.25rem !important;
  }
  .mt-xsm-26 {
    margin-block-start: 6.5rem !important;
  }
  .mt-xsm-27 {
    margin-block-start: 6.75rem !important;
  }
  .mt-xsm-28 {
    margin-block-start: 7rem !important;
  }
  .mt-xsm-29 {
    margin-block-start: 7.25rem !important;
  }
  .mt-xsm-30 {
    margin-block-start: 7.5rem !important;
  }
  .mt-xsm-31 {
    margin-block-start: 7.75rem !important;
  }
  .mt-xsm-32 {
    margin-block-start: 8rem !important;
  }
  .mt-xsm-33 {
    margin-block-start: 8.25rem !important;
  }
  .mt-xsm-34 {
    margin-block-start: 8.5rem !important;
  }
  .mt-xsm-35 {
    margin-block-start: 8.75rem !important;
  }
  .mt-xsm-36 {
    margin-block-start: 9rem !important;
  }
  .mt-xsm-37 {
    margin-block-start: 9.25rem !important;
  }
  .mt-xsm-38 {
    margin-block-start: 9.5rem !important;
  }
  .mt-xsm-39 {
    margin-block-start: 9.75rem !important;
  }
  .mt-xsm-40 {
    margin-block-start: 10rem !important;
  }
  .mt-xsm-auto {
    margin-block-start: auto !important;
  }
  .me-xsm-0 {
    margin-inline-end: 0 !important;
  }
  .me-xsm-1 {
    margin-inline-end: 0.25rem !important;
  }
  .me-xsm-2 {
    margin-inline-end: 0.5rem !important;
  }
  .me-xsm-3 {
    margin-inline-end: 0.75rem !important;
  }
  .me-xsm-4 {
    margin-inline-end: 1rem !important;
  }
  .me-xsm-5 {
    margin-inline-end: 1.25rem !important;
  }
  .me-xsm-6 {
    margin-inline-end: 1.5rem !important;
  }
  .me-xsm-7 {
    margin-inline-end: 1.75rem !important;
  }
  .me-xsm-8 {
    margin-inline-end: 2rem !important;
  }
  .me-xsm-9 {
    margin-inline-end: 2.25rem !important;
  }
  .me-xsm-10 {
    margin-inline-end: 2.5rem !important;
  }
  .me-xsm-11 {
    margin-inline-end: 2.75rem !important;
  }
  .me-xsm-12 {
    margin-inline-end: 3rem !important;
  }
  .me-xsm-13 {
    margin-inline-end: 3.25rem !important;
  }
  .me-xsm-14 {
    margin-inline-end: 3.5rem !important;
  }
  .me-xsm-15 {
    margin-inline-end: 3.75rem !important;
  }
  .me-xsm-16 {
    margin-inline-end: 4rem !important;
  }
  .me-xsm-17 {
    margin-inline-end: 4.25rem !important;
  }
  .me-xsm-18 {
    margin-inline-end: 4.5rem !important;
  }
  .me-xsm-19 {
    margin-inline-end: 4.75rem !important;
  }
  .me-xsm-20 {
    margin-inline-end: 5rem !important;
  }
  .me-xsm-21 {
    margin-inline-end: 5.25rem !important;
  }
  .me-xsm-22 {
    margin-inline-end: 5.5rem !important;
  }
  .me-xsm-23 {
    margin-inline-end: 5.75rem !important;
  }
  .me-xsm-24 {
    margin-inline-end: 6rem !important;
  }
  .me-xsm-25 {
    margin-inline-end: 6.25rem !important;
  }
  .me-xsm-26 {
    margin-inline-end: 6.5rem !important;
  }
  .me-xsm-27 {
    margin-inline-end: 6.75rem !important;
  }
  .me-xsm-28 {
    margin-inline-end: 7rem !important;
  }
  .me-xsm-29 {
    margin-inline-end: 7.25rem !important;
  }
  .me-xsm-30 {
    margin-inline-end: 7.5rem !important;
  }
  .me-xsm-31 {
    margin-inline-end: 7.75rem !important;
  }
  .me-xsm-32 {
    margin-inline-end: 8rem !important;
  }
  .me-xsm-33 {
    margin-inline-end: 8.25rem !important;
  }
  .me-xsm-34 {
    margin-inline-end: 8.5rem !important;
  }
  .me-xsm-35 {
    margin-inline-end: 8.75rem !important;
  }
  .me-xsm-36 {
    margin-inline-end: 9rem !important;
  }
  .me-xsm-37 {
    margin-inline-end: 9.25rem !important;
  }
  .me-xsm-38 {
    margin-inline-end: 9.5rem !important;
  }
  .me-xsm-39 {
    margin-inline-end: 9.75rem !important;
  }
  .me-xsm-40 {
    margin-inline-end: 10rem !important;
  }
  .me-xsm-auto {
    margin-inline-end: auto !important;
  }
  .mb-xsm-0 {
    margin-block-end: 0 !important;
  }
  .mb-xsm-1 {
    margin-block-end: 0.25rem !important;
  }
  .mb-xsm-2 {
    margin-block-end: 0.5rem !important;
  }
  .mb-xsm-3 {
    margin-block-end: 0.75rem !important;
  }
  .mb-xsm-4 {
    margin-block-end: 1rem !important;
  }
  .mb-xsm-5 {
    margin-block-end: 1.25rem !important;
  }
  .mb-xsm-6 {
    margin-block-end: 1.5rem !important;
  }
  .mb-xsm-7 {
    margin-block-end: 1.75rem !important;
  }
  .mb-xsm-8 {
    margin-block-end: 2rem !important;
  }
  .mb-xsm-9 {
    margin-block-end: 2.25rem !important;
  }
  .mb-xsm-10 {
    margin-block-end: 2.5rem !important;
  }
  .mb-xsm-11 {
    margin-block-end: 2.75rem !important;
  }
  .mb-xsm-12 {
    margin-block-end: 3rem !important;
  }
  .mb-xsm-13 {
    margin-block-end: 3.25rem !important;
  }
  .mb-xsm-14 {
    margin-block-end: 3.5rem !important;
  }
  .mb-xsm-15 {
    margin-block-end: 3.75rem !important;
  }
  .mb-xsm-16 {
    margin-block-end: 4rem !important;
  }
  .mb-xsm-17 {
    margin-block-end: 4.25rem !important;
  }
  .mb-xsm-18 {
    margin-block-end: 4.5rem !important;
  }
  .mb-xsm-19 {
    margin-block-end: 4.75rem !important;
  }
  .mb-xsm-20 {
    margin-block-end: 5rem !important;
  }
  .mb-xsm-21 {
    margin-block-end: 5.25rem !important;
  }
  .mb-xsm-22 {
    margin-block-end: 5.5rem !important;
  }
  .mb-xsm-23 {
    margin-block-end: 5.75rem !important;
  }
  .mb-xsm-24 {
    margin-block-end: 6rem !important;
  }
  .mb-xsm-25 {
    margin-block-end: 6.25rem !important;
  }
  .mb-xsm-26 {
    margin-block-end: 6.5rem !important;
  }
  .mb-xsm-27 {
    margin-block-end: 6.75rem !important;
  }
  .mb-xsm-28 {
    margin-block-end: 7rem !important;
  }
  .mb-xsm-29 {
    margin-block-end: 7.25rem !important;
  }
  .mb-xsm-30 {
    margin-block-end: 7.5rem !important;
  }
  .mb-xsm-31 {
    margin-block-end: 7.75rem !important;
  }
  .mb-xsm-32 {
    margin-block-end: 8rem !important;
  }
  .mb-xsm-33 {
    margin-block-end: 8.25rem !important;
  }
  .mb-xsm-34 {
    margin-block-end: 8.5rem !important;
  }
  .mb-xsm-35 {
    margin-block-end: 8.75rem !important;
  }
  .mb-xsm-36 {
    margin-block-end: 9rem !important;
  }
  .mb-xsm-37 {
    margin-block-end: 9.25rem !important;
  }
  .mb-xsm-38 {
    margin-block-end: 9.5rem !important;
  }
  .mb-xsm-39 {
    margin-block-end: 9.75rem !important;
  }
  .mb-xsm-40 {
    margin-block-end: 10rem !important;
  }
  .mb-xsm-auto {
    margin-block-end: auto !important;
  }
  .ms-xsm-0 {
    margin-inline-start: 0 !important;
  }
  .ms-xsm-1 {
    margin-inline-start: 0.25rem !important;
  }
  .ms-xsm-2 {
    margin-inline-start: 0.5rem !important;
  }
  .ms-xsm-3 {
    margin-inline-start: 0.75rem !important;
  }
  .ms-xsm-4 {
    margin-inline-start: 1rem !important;
  }
  .ms-xsm-5 {
    margin-inline-start: 1.25rem !important;
  }
  .ms-xsm-6 {
    margin-inline-start: 1.5rem !important;
  }
  .ms-xsm-7 {
    margin-inline-start: 1.75rem !important;
  }
  .ms-xsm-8 {
    margin-inline-start: 2rem !important;
  }
  .ms-xsm-9 {
    margin-inline-start: 2.25rem !important;
  }
  .ms-xsm-10 {
    margin-inline-start: 2.5rem !important;
  }
  .ms-xsm-11 {
    margin-inline-start: 2.75rem !important;
  }
  .ms-xsm-12 {
    margin-inline-start: 3rem !important;
  }
  .ms-xsm-13 {
    margin-inline-start: 3.25rem !important;
  }
  .ms-xsm-14 {
    margin-inline-start: 3.5rem !important;
  }
  .ms-xsm-15 {
    margin-inline-start: 3.75rem !important;
  }
  .ms-xsm-16 {
    margin-inline-start: 4rem !important;
  }
  .ms-xsm-17 {
    margin-inline-start: 4.25rem !important;
  }
  .ms-xsm-18 {
    margin-inline-start: 4.5rem !important;
  }
  .ms-xsm-19 {
    margin-inline-start: 4.75rem !important;
  }
  .ms-xsm-20 {
    margin-inline-start: 5rem !important;
  }
  .ms-xsm-21 {
    margin-inline-start: 5.25rem !important;
  }
  .ms-xsm-22 {
    margin-inline-start: 5.5rem !important;
  }
  .ms-xsm-23 {
    margin-inline-start: 5.75rem !important;
  }
  .ms-xsm-24 {
    margin-inline-start: 6rem !important;
  }
  .ms-xsm-25 {
    margin-inline-start: 6.25rem !important;
  }
  .ms-xsm-26 {
    margin-inline-start: 6.5rem !important;
  }
  .ms-xsm-27 {
    margin-inline-start: 6.75rem !important;
  }
  .ms-xsm-28 {
    margin-inline-start: 7rem !important;
  }
  .ms-xsm-29 {
    margin-inline-start: 7.25rem !important;
  }
  .ms-xsm-30 {
    margin-inline-start: 7.5rem !important;
  }
  .ms-xsm-31 {
    margin-inline-start: 7.75rem !important;
  }
  .ms-xsm-32 {
    margin-inline-start: 8rem !important;
  }
  .ms-xsm-33 {
    margin-inline-start: 8.25rem !important;
  }
  .ms-xsm-34 {
    margin-inline-start: 8.5rem !important;
  }
  .ms-xsm-35 {
    margin-inline-start: 8.75rem !important;
  }
  .ms-xsm-36 {
    margin-inline-start: 9rem !important;
  }
  .ms-xsm-37 {
    margin-inline-start: 9.25rem !important;
  }
  .ms-xsm-38 {
    margin-inline-start: 9.5rem !important;
  }
  .ms-xsm-39 {
    margin-inline-start: 9.75rem !important;
  }
  .ms-xsm-40 {
    margin-inline-start: 10rem !important;
  }
  .ms-xsm-auto {
    margin-inline-start: auto !important;
  }
  .p-xsm-0 {
    padding: 0 !important;
  }
  .p-xsm-1 {
    padding: 0.25rem !important;
  }
  .p-xsm-2 {
    padding: 0.5rem !important;
  }
  .p-xsm-3 {
    padding: 0.75rem !important;
  }
  .p-xsm-4 {
    padding: 1rem !important;
  }
  .p-xsm-5 {
    padding: 1.25rem !important;
  }
  .p-xsm-6 {
    padding: 1.5rem !important;
  }
  .p-xsm-7 {
    padding: 1.75rem !important;
  }
  .p-xsm-8 {
    padding: 2rem !important;
  }
  .p-xsm-9 {
    padding: 2.25rem !important;
  }
  .p-xsm-10 {
    padding: 2.5rem !important;
  }
  .p-xsm-11 {
    padding: 2.75rem !important;
  }
  .p-xsm-12 {
    padding: 3rem !important;
  }
  .p-xsm-13 {
    padding: 3.25rem !important;
  }
  .p-xsm-14 {
    padding: 3.5rem !important;
  }
  .p-xsm-15 {
    padding: 3.75rem !important;
  }
  .p-xsm-16 {
    padding: 4rem !important;
  }
  .p-xsm-17 {
    padding: 4.25rem !important;
  }
  .p-xsm-18 {
    padding: 4.5rem !important;
  }
  .p-xsm-19 {
    padding: 4.75rem !important;
  }
  .p-xsm-20 {
    padding: 5rem !important;
  }
  .p-xsm-21 {
    padding: 5.25rem !important;
  }
  .p-xsm-22 {
    padding: 5.5rem !important;
  }
  .p-xsm-23 {
    padding: 5.75rem !important;
  }
  .p-xsm-24 {
    padding: 6rem !important;
  }
  .p-xsm-25 {
    padding: 6.25rem !important;
  }
  .p-xsm-26 {
    padding: 6.5rem !important;
  }
  .p-xsm-27 {
    padding: 6.75rem !important;
  }
  .p-xsm-28 {
    padding: 7rem !important;
  }
  .p-xsm-29 {
    padding: 7.25rem !important;
  }
  .p-xsm-30 {
    padding: 7.5rem !important;
  }
  .p-xsm-31 {
    padding: 7.75rem !important;
  }
  .p-xsm-32 {
    padding: 8rem !important;
  }
  .p-xsm-33 {
    padding: 8.25rem !important;
  }
  .p-xsm-34 {
    padding: 8.5rem !important;
  }
  .p-xsm-35 {
    padding: 8.75rem !important;
  }
  .p-xsm-36 {
    padding: 9rem !important;
  }
  .p-xsm-37 {
    padding: 9.25rem !important;
  }
  .p-xsm-38 {
    padding: 9.5rem !important;
  }
  .p-xsm-39 {
    padding: 9.75rem !important;
  }
  .p-xsm-40 {
    padding: 10rem !important;
  }
  .px-xsm-0 {
    padding-inline: 0 !important;
  }
  .px-xsm-1 {
    padding-inline: 0.25rem !important;
  }
  .px-xsm-2 {
    padding-inline: 0.5rem !important;
  }
  .px-xsm-3 {
    padding-inline: 0.75rem !important;
  }
  .px-xsm-4 {
    padding-inline: 1rem !important;
  }
  .px-xsm-5 {
    padding-inline: 1.25rem !important;
  }
  .px-xsm-6 {
    padding-inline: 1.5rem !important;
  }
  .px-xsm-7 {
    padding-inline: 1.75rem !important;
  }
  .px-xsm-8 {
    padding-inline: 2rem !important;
  }
  .px-xsm-9 {
    padding-inline: 2.25rem !important;
  }
  .px-xsm-10 {
    padding-inline: 2.5rem !important;
  }
  .px-xsm-11 {
    padding-inline: 2.75rem !important;
  }
  .px-xsm-12 {
    padding-inline: 3rem !important;
  }
  .px-xsm-13 {
    padding-inline: 3.25rem !important;
  }
  .px-xsm-14 {
    padding-inline: 3.5rem !important;
  }
  .px-xsm-15 {
    padding-inline: 3.75rem !important;
  }
  .px-xsm-16 {
    padding-inline: 4rem !important;
  }
  .px-xsm-17 {
    padding-inline: 4.25rem !important;
  }
  .px-xsm-18 {
    padding-inline: 4.5rem !important;
  }
  .px-xsm-19 {
    padding-inline: 4.75rem !important;
  }
  .px-xsm-20 {
    padding-inline: 5rem !important;
  }
  .px-xsm-21 {
    padding-inline: 5.25rem !important;
  }
  .px-xsm-22 {
    padding-inline: 5.5rem !important;
  }
  .px-xsm-23 {
    padding-inline: 5.75rem !important;
  }
  .px-xsm-24 {
    padding-inline: 6rem !important;
  }
  .px-xsm-25 {
    padding-inline: 6.25rem !important;
  }
  .px-xsm-26 {
    padding-inline: 6.5rem !important;
  }
  .px-xsm-27 {
    padding-inline: 6.75rem !important;
  }
  .px-xsm-28 {
    padding-inline: 7rem !important;
  }
  .px-xsm-29 {
    padding-inline: 7.25rem !important;
  }
  .px-xsm-30 {
    padding-inline: 7.5rem !important;
  }
  .px-xsm-31 {
    padding-inline: 7.75rem !important;
  }
  .px-xsm-32 {
    padding-inline: 8rem !important;
  }
  .px-xsm-33 {
    padding-inline: 8.25rem !important;
  }
  .px-xsm-34 {
    padding-inline: 8.5rem !important;
  }
  .px-xsm-35 {
    padding-inline: 8.75rem !important;
  }
  .px-xsm-36 {
    padding-inline: 9rem !important;
  }
  .px-xsm-37 {
    padding-inline: 9.25rem !important;
  }
  .px-xsm-38 {
    padding-inline: 9.5rem !important;
  }
  .px-xsm-39 {
    padding-inline: 9.75rem !important;
  }
  .px-xsm-40 {
    padding-inline: 10rem !important;
  }
  .py-xsm-0 {
    padding-block: 0 !important;
  }
  .py-xsm-1 {
    padding-block: 0.25rem !important;
  }
  .py-xsm-2 {
    padding-block: 0.5rem !important;
  }
  .py-xsm-3 {
    padding-block: 0.75rem !important;
  }
  .py-xsm-4 {
    padding-block: 1rem !important;
  }
  .py-xsm-5 {
    padding-block: 1.25rem !important;
  }
  .py-xsm-6 {
    padding-block: 1.5rem !important;
  }
  .py-xsm-7 {
    padding-block: 1.75rem !important;
  }
  .py-xsm-8 {
    padding-block: 2rem !important;
  }
  .py-xsm-9 {
    padding-block: 2.25rem !important;
  }
  .py-xsm-10 {
    padding-block: 2.5rem !important;
  }
  .py-xsm-11 {
    padding-block: 2.75rem !important;
  }
  .py-xsm-12 {
    padding-block: 3rem !important;
  }
  .py-xsm-13 {
    padding-block: 3.25rem !important;
  }
  .py-xsm-14 {
    padding-block: 3.5rem !important;
  }
  .py-xsm-15 {
    padding-block: 3.75rem !important;
  }
  .py-xsm-16 {
    padding-block: 4rem !important;
  }
  .py-xsm-17 {
    padding-block: 4.25rem !important;
  }
  .py-xsm-18 {
    padding-block: 4.5rem !important;
  }
  .py-xsm-19 {
    padding-block: 4.75rem !important;
  }
  .py-xsm-20 {
    padding-block: 5rem !important;
  }
  .py-xsm-21 {
    padding-block: 5.25rem !important;
  }
  .py-xsm-22 {
    padding-block: 5.5rem !important;
  }
  .py-xsm-23 {
    padding-block: 5.75rem !important;
  }
  .py-xsm-24 {
    padding-block: 6rem !important;
  }
  .py-xsm-25 {
    padding-block: 6.25rem !important;
  }
  .py-xsm-26 {
    padding-block: 6.5rem !important;
  }
  .py-xsm-27 {
    padding-block: 6.75rem !important;
  }
  .py-xsm-28 {
    padding-block: 7rem !important;
  }
  .py-xsm-29 {
    padding-block: 7.25rem !important;
  }
  .py-xsm-30 {
    padding-block: 7.5rem !important;
  }
  .py-xsm-31 {
    padding-block: 7.75rem !important;
  }
  .py-xsm-32 {
    padding-block: 8rem !important;
  }
  .py-xsm-33 {
    padding-block: 8.25rem !important;
  }
  .py-xsm-34 {
    padding-block: 8.5rem !important;
  }
  .py-xsm-35 {
    padding-block: 8.75rem !important;
  }
  .py-xsm-36 {
    padding-block: 9rem !important;
  }
  .py-xsm-37 {
    padding-block: 9.25rem !important;
  }
  .py-xsm-38 {
    padding-block: 9.5rem !important;
  }
  .py-xsm-39 {
    padding-block: 9.75rem !important;
  }
  .py-xsm-40 {
    padding-block: 10rem !important;
  }
  .pt-xsm-0 {
    padding-block-start: 0 !important;
  }
  .pt-xsm-1 {
    padding-block-start: 0.25rem !important;
  }
  .pt-xsm-2 {
    padding-block-start: 0.5rem !important;
  }
  .pt-xsm-3 {
    padding-block-start: 0.75rem !important;
  }
  .pt-xsm-4 {
    padding-block-start: 1rem !important;
  }
  .pt-xsm-5 {
    padding-block-start: 1.25rem !important;
  }
  .pt-xsm-6 {
    padding-block-start: 1.5rem !important;
  }
  .pt-xsm-7 {
    padding-block-start: 1.75rem !important;
  }
  .pt-xsm-8 {
    padding-block-start: 2rem !important;
  }
  .pt-xsm-9 {
    padding-block-start: 2.25rem !important;
  }
  .pt-xsm-10 {
    padding-block-start: 2.5rem !important;
  }
  .pt-xsm-11 {
    padding-block-start: 2.75rem !important;
  }
  .pt-xsm-12 {
    padding-block-start: 3rem !important;
  }
  .pt-xsm-13 {
    padding-block-start: 3.25rem !important;
  }
  .pt-xsm-14 {
    padding-block-start: 3.5rem !important;
  }
  .pt-xsm-15 {
    padding-block-start: 3.75rem !important;
  }
  .pt-xsm-16 {
    padding-block-start: 4rem !important;
  }
  .pt-xsm-17 {
    padding-block-start: 4.25rem !important;
  }
  .pt-xsm-18 {
    padding-block-start: 4.5rem !important;
  }
  .pt-xsm-19 {
    padding-block-start: 4.75rem !important;
  }
  .pt-xsm-20 {
    padding-block-start: 5rem !important;
  }
  .pt-xsm-21 {
    padding-block-start: 5.25rem !important;
  }
  .pt-xsm-22 {
    padding-block-start: 5.5rem !important;
  }
  .pt-xsm-23 {
    padding-block-start: 5.75rem !important;
  }
  .pt-xsm-24 {
    padding-block-start: 6rem !important;
  }
  .pt-xsm-25 {
    padding-block-start: 6.25rem !important;
  }
  .pt-xsm-26 {
    padding-block-start: 6.5rem !important;
  }
  .pt-xsm-27 {
    padding-block-start: 6.75rem !important;
  }
  .pt-xsm-28 {
    padding-block-start: 7rem !important;
  }
  .pt-xsm-29 {
    padding-block-start: 7.25rem !important;
  }
  .pt-xsm-30 {
    padding-block-start: 7.5rem !important;
  }
  .pt-xsm-31 {
    padding-block-start: 7.75rem !important;
  }
  .pt-xsm-32 {
    padding-block-start: 8rem !important;
  }
  .pt-xsm-33 {
    padding-block-start: 8.25rem !important;
  }
  .pt-xsm-34 {
    padding-block-start: 8.5rem !important;
  }
  .pt-xsm-35 {
    padding-block-start: 8.75rem !important;
  }
  .pt-xsm-36 {
    padding-block-start: 9rem !important;
  }
  .pt-xsm-37 {
    padding-block-start: 9.25rem !important;
  }
  .pt-xsm-38 {
    padding-block-start: 9.5rem !important;
  }
  .pt-xsm-39 {
    padding-block-start: 9.75rem !important;
  }
  .pt-xsm-40 {
    padding-block-start: 10rem !important;
  }
  .pe-xsm-0 {
    padding-inline-end: 0 !important;
  }
  .pe-xsm-1 {
    padding-inline-end: 0.25rem !important;
  }
  .pe-xsm-2 {
    padding-inline-end: 0.5rem !important;
  }
  .pe-xsm-3 {
    padding-inline-end: 0.75rem !important;
  }
  .pe-xsm-4 {
    padding-inline-end: 1rem !important;
  }
  .pe-xsm-5 {
    padding-inline-end: 1.25rem !important;
  }
  .pe-xsm-6 {
    padding-inline-end: 1.5rem !important;
  }
  .pe-xsm-7 {
    padding-inline-end: 1.75rem !important;
  }
  .pe-xsm-8 {
    padding-inline-end: 2rem !important;
  }
  .pe-xsm-9 {
    padding-inline-end: 2.25rem !important;
  }
  .pe-xsm-10 {
    padding-inline-end: 2.5rem !important;
  }
  .pe-xsm-11 {
    padding-inline-end: 2.75rem !important;
  }
  .pe-xsm-12 {
    padding-inline-end: 3rem !important;
  }
  .pe-xsm-13 {
    padding-inline-end: 3.25rem !important;
  }
  .pe-xsm-14 {
    padding-inline-end: 3.5rem !important;
  }
  .pe-xsm-15 {
    padding-inline-end: 3.75rem !important;
  }
  .pe-xsm-16 {
    padding-inline-end: 4rem !important;
  }
  .pe-xsm-17 {
    padding-inline-end: 4.25rem !important;
  }
  .pe-xsm-18 {
    padding-inline-end: 4.5rem !important;
  }
  .pe-xsm-19 {
    padding-inline-end: 4.75rem !important;
  }
  .pe-xsm-20 {
    padding-inline-end: 5rem !important;
  }
  .pe-xsm-21 {
    padding-inline-end: 5.25rem !important;
  }
  .pe-xsm-22 {
    padding-inline-end: 5.5rem !important;
  }
  .pe-xsm-23 {
    padding-inline-end: 5.75rem !important;
  }
  .pe-xsm-24 {
    padding-inline-end: 6rem !important;
  }
  .pe-xsm-25 {
    padding-inline-end: 6.25rem !important;
  }
  .pe-xsm-26 {
    padding-inline-end: 6.5rem !important;
  }
  .pe-xsm-27 {
    padding-inline-end: 6.75rem !important;
  }
  .pe-xsm-28 {
    padding-inline-end: 7rem !important;
  }
  .pe-xsm-29 {
    padding-inline-end: 7.25rem !important;
  }
  .pe-xsm-30 {
    padding-inline-end: 7.5rem !important;
  }
  .pe-xsm-31 {
    padding-inline-end: 7.75rem !important;
  }
  .pe-xsm-32 {
    padding-inline-end: 8rem !important;
  }
  .pe-xsm-33 {
    padding-inline-end: 8.25rem !important;
  }
  .pe-xsm-34 {
    padding-inline-end: 8.5rem !important;
  }
  .pe-xsm-35 {
    padding-inline-end: 8.75rem !important;
  }
  .pe-xsm-36 {
    padding-inline-end: 9rem !important;
  }
  .pe-xsm-37 {
    padding-inline-end: 9.25rem !important;
  }
  .pe-xsm-38 {
    padding-inline-end: 9.5rem !important;
  }
  .pe-xsm-39 {
    padding-inline-end: 9.75rem !important;
  }
  .pe-xsm-40 {
    padding-inline-end: 10rem !important;
  }
  .pb-xsm-0 {
    padding-block-end: 0 !important;
  }
  .pb-xsm-1 {
    padding-block-end: 0.25rem !important;
  }
  .pb-xsm-2 {
    padding-block-end: 0.5rem !important;
  }
  .pb-xsm-3 {
    padding-block-end: 0.75rem !important;
  }
  .pb-xsm-4 {
    padding-block-end: 1rem !important;
  }
  .pb-xsm-5 {
    padding-block-end: 1.25rem !important;
  }
  .pb-xsm-6 {
    padding-block-end: 1.5rem !important;
  }
  .pb-xsm-7 {
    padding-block-end: 1.75rem !important;
  }
  .pb-xsm-8 {
    padding-block-end: 2rem !important;
  }
  .pb-xsm-9 {
    padding-block-end: 2.25rem !important;
  }
  .pb-xsm-10 {
    padding-block-end: 2.5rem !important;
  }
  .pb-xsm-11 {
    padding-block-end: 2.75rem !important;
  }
  .pb-xsm-12 {
    padding-block-end: 3rem !important;
  }
  .pb-xsm-13 {
    padding-block-end: 3.25rem !important;
  }
  .pb-xsm-14 {
    padding-block-end: 3.5rem !important;
  }
  .pb-xsm-15 {
    padding-block-end: 3.75rem !important;
  }
  .pb-xsm-16 {
    padding-block-end: 4rem !important;
  }
  .pb-xsm-17 {
    padding-block-end: 4.25rem !important;
  }
  .pb-xsm-18 {
    padding-block-end: 4.5rem !important;
  }
  .pb-xsm-19 {
    padding-block-end: 4.75rem !important;
  }
  .pb-xsm-20 {
    padding-block-end: 5rem !important;
  }
  .pb-xsm-21 {
    padding-block-end: 5.25rem !important;
  }
  .pb-xsm-22 {
    padding-block-end: 5.5rem !important;
  }
  .pb-xsm-23 {
    padding-block-end: 5.75rem !important;
  }
  .pb-xsm-24 {
    padding-block-end: 6rem !important;
  }
  .pb-xsm-25 {
    padding-block-end: 6.25rem !important;
  }
  .pb-xsm-26 {
    padding-block-end: 6.5rem !important;
  }
  .pb-xsm-27 {
    padding-block-end: 6.75rem !important;
  }
  .pb-xsm-28 {
    padding-block-end: 7rem !important;
  }
  .pb-xsm-29 {
    padding-block-end: 7.25rem !important;
  }
  .pb-xsm-30 {
    padding-block-end: 7.5rem !important;
  }
  .pb-xsm-31 {
    padding-block-end: 7.75rem !important;
  }
  .pb-xsm-32 {
    padding-block-end: 8rem !important;
  }
  .pb-xsm-33 {
    padding-block-end: 8.25rem !important;
  }
  .pb-xsm-34 {
    padding-block-end: 8.5rem !important;
  }
  .pb-xsm-35 {
    padding-block-end: 8.75rem !important;
  }
  .pb-xsm-36 {
    padding-block-end: 9rem !important;
  }
  .pb-xsm-37 {
    padding-block-end: 9.25rem !important;
  }
  .pb-xsm-38 {
    padding-block-end: 9.5rem !important;
  }
  .pb-xsm-39 {
    padding-block-end: 9.75rem !important;
  }
  .pb-xsm-40 {
    padding-block-end: 10rem !important;
  }
  .ps-xsm-0 {
    padding-inline-start: 0 !important;
  }
  .ps-xsm-1 {
    padding-inline-start: 0.25rem !important;
  }
  .ps-xsm-2 {
    padding-inline-start: 0.5rem !important;
  }
  .ps-xsm-3 {
    padding-inline-start: 0.75rem !important;
  }
  .ps-xsm-4 {
    padding-inline-start: 1rem !important;
  }
  .ps-xsm-5 {
    padding-inline-start: 1.25rem !important;
  }
  .ps-xsm-6 {
    padding-inline-start: 1.5rem !important;
  }
  .ps-xsm-7 {
    padding-inline-start: 1.75rem !important;
  }
  .ps-xsm-8 {
    padding-inline-start: 2rem !important;
  }
  .ps-xsm-9 {
    padding-inline-start: 2.25rem !important;
  }
  .ps-xsm-10 {
    padding-inline-start: 2.5rem !important;
  }
  .ps-xsm-11 {
    padding-inline-start: 2.75rem !important;
  }
  .ps-xsm-12 {
    padding-inline-start: 3rem !important;
  }
  .ps-xsm-13 {
    padding-inline-start: 3.25rem !important;
  }
  .ps-xsm-14 {
    padding-inline-start: 3.5rem !important;
  }
  .ps-xsm-15 {
    padding-inline-start: 3.75rem !important;
  }
  .ps-xsm-16 {
    padding-inline-start: 4rem !important;
  }
  .ps-xsm-17 {
    padding-inline-start: 4.25rem !important;
  }
  .ps-xsm-18 {
    padding-inline-start: 4.5rem !important;
  }
  .ps-xsm-19 {
    padding-inline-start: 4.75rem !important;
  }
  .ps-xsm-20 {
    padding-inline-start: 5rem !important;
  }
  .ps-xsm-21 {
    padding-inline-start: 5.25rem !important;
  }
  .ps-xsm-22 {
    padding-inline-start: 5.5rem !important;
  }
  .ps-xsm-23 {
    padding-inline-start: 5.75rem !important;
  }
  .ps-xsm-24 {
    padding-inline-start: 6rem !important;
  }
  .ps-xsm-25 {
    padding-inline-start: 6.25rem !important;
  }
  .ps-xsm-26 {
    padding-inline-start: 6.5rem !important;
  }
  .ps-xsm-27 {
    padding-inline-start: 6.75rem !important;
  }
  .ps-xsm-28 {
    padding-inline-start: 7rem !important;
  }
  .ps-xsm-29 {
    padding-inline-start: 7.25rem !important;
  }
  .ps-xsm-30 {
    padding-inline-start: 7.5rem !important;
  }
  .ps-xsm-31 {
    padding-inline-start: 7.75rem !important;
  }
  .ps-xsm-32 {
    padding-inline-start: 8rem !important;
  }
  .ps-xsm-33 {
    padding-inline-start: 8.25rem !important;
  }
  .ps-xsm-34 {
    padding-inline-start: 8.5rem !important;
  }
  .ps-xsm-35 {
    padding-inline-start: 8.75rem !important;
  }
  .ps-xsm-36 {
    padding-inline-start: 9rem !important;
  }
  .ps-xsm-37 {
    padding-inline-start: 9.25rem !important;
  }
  .ps-xsm-38 {
    padding-inline-start: 9.5rem !important;
  }
  .ps-xsm-39 {
    padding-inline-start: 9.75rem !important;
  }
  .ps-xsm-40 {
    padding-inline-start: 10rem !important;
  }
  .gap-xsm-0 {
    gap: 0 !important;
  }
  .gap-xsm-1 {
    gap: 0.25rem !important;
  }
  .gap-xsm-2 {
    gap: 0.5rem !important;
  }
  .gap-xsm-3 {
    gap: 0.75rem !important;
  }
  .gap-xsm-4 {
    gap: 1rem !important;
  }
  .gap-xsm-5 {
    gap: 1.25rem !important;
  }
  .gap-xsm-6 {
    gap: 1.5rem !important;
  }
  .gap-xsm-7 {
    gap: 1.75rem !important;
  }
  .gap-xsm-8 {
    gap: 2rem !important;
  }
  .gap-xsm-9 {
    gap: 2.25rem !important;
  }
  .gap-xsm-10 {
    gap: 2.5rem !important;
  }
  .gap-xsm-11 {
    gap: 2.75rem !important;
  }
  .gap-xsm-12 {
    gap: 3rem !important;
  }
  .gap-xsm-13 {
    gap: 3.25rem !important;
  }
  .gap-xsm-14 {
    gap: 3.5rem !important;
  }
  .gap-xsm-15 {
    gap: 3.75rem !important;
  }
  .gap-xsm-16 {
    gap: 4rem !important;
  }
  .gap-xsm-17 {
    gap: 4.25rem !important;
  }
  .gap-xsm-18 {
    gap: 4.5rem !important;
  }
  .gap-xsm-19 {
    gap: 4.75rem !important;
  }
  .gap-xsm-20 {
    gap: 5rem !important;
  }
  .gap-xsm-21 {
    gap: 5.25rem !important;
  }
  .gap-xsm-22 {
    gap: 5.5rem !important;
  }
  .gap-xsm-23 {
    gap: 5.75rem !important;
  }
  .gap-xsm-24 {
    gap: 6rem !important;
  }
  .gap-xsm-25 {
    gap: 6.25rem !important;
  }
  .gap-xsm-26 {
    gap: 6.5rem !important;
  }
  .gap-xsm-27 {
    gap: 6.75rem !important;
  }
  .gap-xsm-28 {
    gap: 7rem !important;
  }
  .gap-xsm-29 {
    gap: 7.25rem !important;
  }
  .gap-xsm-30 {
    gap: 7.5rem !important;
  }
  .gap-xsm-31 {
    gap: 7.75rem !important;
  }
  .gap-xsm-32 {
    gap: 8rem !important;
  }
  .gap-xsm-33 {
    gap: 8.25rem !important;
  }
  .gap-xsm-34 {
    gap: 8.5rem !important;
  }
  .gap-xsm-35 {
    gap: 8.75rem !important;
  }
  .gap-xsm-36 {
    gap: 9rem !important;
  }
  .gap-xsm-37 {
    gap: 9.25rem !important;
  }
  .gap-xsm-38 {
    gap: 9.5rem !important;
  }
  .gap-xsm-39 {
    gap: 9.75rem !important;
  }
  .gap-xsm-40 {
    gap: 10rem !important;
  }
  .row-gap-xsm-0 {
    row-gap: 0 !important;
  }
  .row-gap-xsm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xsm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xsm-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xsm-4 {
    row-gap: 1rem !important;
  }
  .row-gap-xsm-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xsm-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xsm-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-xsm-8 {
    row-gap: 2rem !important;
  }
  .row-gap-xsm-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xsm-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xsm-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-xsm-12 {
    row-gap: 3rem !important;
  }
  .row-gap-xsm-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-xsm-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xsm-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-xsm-16 {
    row-gap: 4rem !important;
  }
  .row-gap-xsm-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-xsm-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xsm-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-xsm-20 {
    row-gap: 5rem !important;
  }
  .row-gap-xsm-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-xsm-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-xsm-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-xsm-24 {
    row-gap: 6rem !important;
  }
  .row-gap-xsm-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-xsm-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-xsm-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-xsm-28 {
    row-gap: 7rem !important;
  }
  .row-gap-xsm-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-xsm-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-xsm-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-xsm-32 {
    row-gap: 8rem !important;
  }
  .row-gap-xsm-33 {
    row-gap: 8.25rem !important;
  }
  .row-gap-xsm-34 {
    row-gap: 8.5rem !important;
  }
  .row-gap-xsm-35 {
    row-gap: 8.75rem !important;
  }
  .row-gap-xsm-36 {
    row-gap: 9rem !important;
  }
  .row-gap-xsm-37 {
    row-gap: 9.25rem !important;
  }
  .row-gap-xsm-38 {
    row-gap: 9.5rem !important;
  }
  .row-gap-xsm-39 {
    row-gap: 9.75rem !important;
  }
  .row-gap-xsm-40 {
    row-gap: 10rem !important;
  }
  .column-gap-xsm-0 {
    column-gap: 0 !important;
  }
  .column-gap-xsm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xsm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xsm-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xsm-4 {
    column-gap: 1rem !important;
  }
  .column-gap-xsm-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xsm-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xsm-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-xsm-8 {
    column-gap: 2rem !important;
  }
  .column-gap-xsm-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xsm-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xsm-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-xsm-12 {
    column-gap: 3rem !important;
  }
  .column-gap-xsm-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-xsm-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xsm-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-xsm-16 {
    column-gap: 4rem !important;
  }
  .column-gap-xsm-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-xsm-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xsm-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-xsm-20 {
    column-gap: 5rem !important;
  }
  .column-gap-xsm-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-xsm-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-xsm-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-xsm-24 {
    column-gap: 6rem !important;
  }
  .column-gap-xsm-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-xsm-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-xsm-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-xsm-28 {
    column-gap: 7rem !important;
  }
  .column-gap-xsm-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-xsm-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-xsm-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-xsm-32 {
    column-gap: 8rem !important;
  }
  .column-gap-xsm-33 {
    column-gap: 8.25rem !important;
  }
  .column-gap-xsm-34 {
    column-gap: 8.5rem !important;
  }
  .column-gap-xsm-35 {
    column-gap: 8.75rem !important;
  }
  .column-gap-xsm-36 {
    column-gap: 9rem !important;
  }
  .column-gap-xsm-37 {
    column-gap: 9.25rem !important;
  }
  .column-gap-xsm-38 {
    column-gap: 9.5rem !important;
  }
  .column-gap-xsm-39 {
    column-gap: 9.75rem !important;
  }
  .column-gap-xsm-40 {
    column-gap: 10rem !important;
  }
  .text-xsm-start {
    text-align: start !important;
  }
  .text-xsm-end {
    text-align: end !important;
  }
  .text-xsm-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .border-sm {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.25rem !important;
  }
  .m-sm-6 {
    margin: 1.5rem !important;
  }
  .m-sm-7 {
    margin: 1.75rem !important;
  }
  .m-sm-8 {
    margin: 2rem !important;
  }
  .m-sm-9 {
    margin: 2.25rem !important;
  }
  .m-sm-10 {
    margin: 2.5rem !important;
  }
  .m-sm-11 {
    margin: 2.75rem !important;
  }
  .m-sm-12 {
    margin: 3rem !important;
  }
  .m-sm-13 {
    margin: 3.25rem !important;
  }
  .m-sm-14 {
    margin: 3.5rem !important;
  }
  .m-sm-15 {
    margin: 3.75rem !important;
  }
  .m-sm-16 {
    margin: 4rem !important;
  }
  .m-sm-17 {
    margin: 4.25rem !important;
  }
  .m-sm-18 {
    margin: 4.5rem !important;
  }
  .m-sm-19 {
    margin: 4.75rem !important;
  }
  .m-sm-20 {
    margin: 5rem !important;
  }
  .m-sm-21 {
    margin: 5.25rem !important;
  }
  .m-sm-22 {
    margin: 5.5rem !important;
  }
  .m-sm-23 {
    margin: 5.75rem !important;
  }
  .m-sm-24 {
    margin: 6rem !important;
  }
  .m-sm-25 {
    margin: 6.25rem !important;
  }
  .m-sm-26 {
    margin: 6.5rem !important;
  }
  .m-sm-27 {
    margin: 6.75rem !important;
  }
  .m-sm-28 {
    margin: 7rem !important;
  }
  .m-sm-29 {
    margin: 7.25rem !important;
  }
  .m-sm-30 {
    margin: 7.5rem !important;
  }
  .m-sm-31 {
    margin: 7.75rem !important;
  }
  .m-sm-32 {
    margin: 8rem !important;
  }
  .m-sm-33 {
    margin: 8.25rem !important;
  }
  .m-sm-34 {
    margin: 8.5rem !important;
  }
  .m-sm-35 {
    margin: 8.75rem !important;
  }
  .m-sm-36 {
    margin: 9rem !important;
  }
  .m-sm-37 {
    margin: 9.25rem !important;
  }
  .m-sm-38 {
    margin: 9.5rem !important;
  }
  .m-sm-39 {
    margin: 9.75rem !important;
  }
  .m-sm-40 {
    margin: 10rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-inline: 0 !important;
  }
  .mx-sm-1 {
    margin-inline: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-inline: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-inline: 0.75rem !important;
  }
  .mx-sm-4 {
    margin-inline: 1rem !important;
  }
  .mx-sm-5 {
    margin-inline: 1.25rem !important;
  }
  .mx-sm-6 {
    margin-inline: 1.5rem !important;
  }
  .mx-sm-7 {
    margin-inline: 1.75rem !important;
  }
  .mx-sm-8 {
    margin-inline: 2rem !important;
  }
  .mx-sm-9 {
    margin-inline: 2.25rem !important;
  }
  .mx-sm-10 {
    margin-inline: 2.5rem !important;
  }
  .mx-sm-11 {
    margin-inline: 2.75rem !important;
  }
  .mx-sm-12 {
    margin-inline: 3rem !important;
  }
  .mx-sm-13 {
    margin-inline: 3.25rem !important;
  }
  .mx-sm-14 {
    margin-inline: 3.5rem !important;
  }
  .mx-sm-15 {
    margin-inline: 3.75rem !important;
  }
  .mx-sm-16 {
    margin-inline: 4rem !important;
  }
  .mx-sm-17 {
    margin-inline: 4.25rem !important;
  }
  .mx-sm-18 {
    margin-inline: 4.5rem !important;
  }
  .mx-sm-19 {
    margin-inline: 4.75rem !important;
  }
  .mx-sm-20 {
    margin-inline: 5rem !important;
  }
  .mx-sm-21 {
    margin-inline: 5.25rem !important;
  }
  .mx-sm-22 {
    margin-inline: 5.5rem !important;
  }
  .mx-sm-23 {
    margin-inline: 5.75rem !important;
  }
  .mx-sm-24 {
    margin-inline: 6rem !important;
  }
  .mx-sm-25 {
    margin-inline: 6.25rem !important;
  }
  .mx-sm-26 {
    margin-inline: 6.5rem !important;
  }
  .mx-sm-27 {
    margin-inline: 6.75rem !important;
  }
  .mx-sm-28 {
    margin-inline: 7rem !important;
  }
  .mx-sm-29 {
    margin-inline: 7.25rem !important;
  }
  .mx-sm-30 {
    margin-inline: 7.5rem !important;
  }
  .mx-sm-31 {
    margin-inline: 7.75rem !important;
  }
  .mx-sm-32 {
    margin-inline: 8rem !important;
  }
  .mx-sm-33 {
    margin-inline: 8.25rem !important;
  }
  .mx-sm-34 {
    margin-inline: 8.5rem !important;
  }
  .mx-sm-35 {
    margin-inline: 8.75rem !important;
  }
  .mx-sm-36 {
    margin-inline: 9rem !important;
  }
  .mx-sm-37 {
    margin-inline: 9.25rem !important;
  }
  .mx-sm-38 {
    margin-inline: 9.5rem !important;
  }
  .mx-sm-39 {
    margin-inline: 9.75rem !important;
  }
  .mx-sm-40 {
    margin-inline: 10rem !important;
  }
  .mx-sm-auto {
    margin-inline: auto !important;
  }
  .my-sm-0 {
    margin-block: 0 !important;
  }
  .my-sm-1 {
    margin-block: 0.25rem !important;
  }
  .my-sm-2 {
    margin-block: 0.5rem !important;
  }
  .my-sm-3 {
    margin-block: 0.75rem !important;
  }
  .my-sm-4 {
    margin-block: 1rem !important;
  }
  .my-sm-5 {
    margin-block: 1.25rem !important;
  }
  .my-sm-6 {
    margin-block: 1.5rem !important;
  }
  .my-sm-7 {
    margin-block: 1.75rem !important;
  }
  .my-sm-8 {
    margin-block: 2rem !important;
  }
  .my-sm-9 {
    margin-block: 2.25rem !important;
  }
  .my-sm-10 {
    margin-block: 2.5rem !important;
  }
  .my-sm-11 {
    margin-block: 2.75rem !important;
  }
  .my-sm-12 {
    margin-block: 3rem !important;
  }
  .my-sm-13 {
    margin-block: 3.25rem !important;
  }
  .my-sm-14 {
    margin-block: 3.5rem !important;
  }
  .my-sm-15 {
    margin-block: 3.75rem !important;
  }
  .my-sm-16 {
    margin-block: 4rem !important;
  }
  .my-sm-17 {
    margin-block: 4.25rem !important;
  }
  .my-sm-18 {
    margin-block: 4.5rem !important;
  }
  .my-sm-19 {
    margin-block: 4.75rem !important;
  }
  .my-sm-20 {
    margin-block: 5rem !important;
  }
  .my-sm-21 {
    margin-block: 5.25rem !important;
  }
  .my-sm-22 {
    margin-block: 5.5rem !important;
  }
  .my-sm-23 {
    margin-block: 5.75rem !important;
  }
  .my-sm-24 {
    margin-block: 6rem !important;
  }
  .my-sm-25 {
    margin-block: 6.25rem !important;
  }
  .my-sm-26 {
    margin-block: 6.5rem !important;
  }
  .my-sm-27 {
    margin-block: 6.75rem !important;
  }
  .my-sm-28 {
    margin-block: 7rem !important;
  }
  .my-sm-29 {
    margin-block: 7.25rem !important;
  }
  .my-sm-30 {
    margin-block: 7.5rem !important;
  }
  .my-sm-31 {
    margin-block: 7.75rem !important;
  }
  .my-sm-32 {
    margin-block: 8rem !important;
  }
  .my-sm-33 {
    margin-block: 8.25rem !important;
  }
  .my-sm-34 {
    margin-block: 8.5rem !important;
  }
  .my-sm-35 {
    margin-block: 8.75rem !important;
  }
  .my-sm-36 {
    margin-block: 9rem !important;
  }
  .my-sm-37 {
    margin-block: 9.25rem !important;
  }
  .my-sm-38 {
    margin-block: 9.5rem !important;
  }
  .my-sm-39 {
    margin-block: 9.75rem !important;
  }
  .my-sm-40 {
    margin-block: 10rem !important;
  }
  .my-sm-auto {
    margin-block: auto !important;
  }
  .mt-sm-0 {
    margin-block-start: 0 !important;
  }
  .mt-sm-1 {
    margin-block-start: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-block-start: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-block-start: 0.75rem !important;
  }
  .mt-sm-4 {
    margin-block-start: 1rem !important;
  }
  .mt-sm-5 {
    margin-block-start: 1.25rem !important;
  }
  .mt-sm-6 {
    margin-block-start: 1.5rem !important;
  }
  .mt-sm-7 {
    margin-block-start: 1.75rem !important;
  }
  .mt-sm-8 {
    margin-block-start: 2rem !important;
  }
  .mt-sm-9 {
    margin-block-start: 2.25rem !important;
  }
  .mt-sm-10 {
    margin-block-start: 2.5rem !important;
  }
  .mt-sm-11 {
    margin-block-start: 2.75rem !important;
  }
  .mt-sm-12 {
    margin-block-start: 3rem !important;
  }
  .mt-sm-13 {
    margin-block-start: 3.25rem !important;
  }
  .mt-sm-14 {
    margin-block-start: 3.5rem !important;
  }
  .mt-sm-15 {
    margin-block-start: 3.75rem !important;
  }
  .mt-sm-16 {
    margin-block-start: 4rem !important;
  }
  .mt-sm-17 {
    margin-block-start: 4.25rem !important;
  }
  .mt-sm-18 {
    margin-block-start: 4.5rem !important;
  }
  .mt-sm-19 {
    margin-block-start: 4.75rem !important;
  }
  .mt-sm-20 {
    margin-block-start: 5rem !important;
  }
  .mt-sm-21 {
    margin-block-start: 5.25rem !important;
  }
  .mt-sm-22 {
    margin-block-start: 5.5rem !important;
  }
  .mt-sm-23 {
    margin-block-start: 5.75rem !important;
  }
  .mt-sm-24 {
    margin-block-start: 6rem !important;
  }
  .mt-sm-25 {
    margin-block-start: 6.25rem !important;
  }
  .mt-sm-26 {
    margin-block-start: 6.5rem !important;
  }
  .mt-sm-27 {
    margin-block-start: 6.75rem !important;
  }
  .mt-sm-28 {
    margin-block-start: 7rem !important;
  }
  .mt-sm-29 {
    margin-block-start: 7.25rem !important;
  }
  .mt-sm-30 {
    margin-block-start: 7.5rem !important;
  }
  .mt-sm-31 {
    margin-block-start: 7.75rem !important;
  }
  .mt-sm-32 {
    margin-block-start: 8rem !important;
  }
  .mt-sm-33 {
    margin-block-start: 8.25rem !important;
  }
  .mt-sm-34 {
    margin-block-start: 8.5rem !important;
  }
  .mt-sm-35 {
    margin-block-start: 8.75rem !important;
  }
  .mt-sm-36 {
    margin-block-start: 9rem !important;
  }
  .mt-sm-37 {
    margin-block-start: 9.25rem !important;
  }
  .mt-sm-38 {
    margin-block-start: 9.5rem !important;
  }
  .mt-sm-39 {
    margin-block-start: 9.75rem !important;
  }
  .mt-sm-40 {
    margin-block-start: 10rem !important;
  }
  .mt-sm-auto {
    margin-block-start: auto !important;
  }
  .me-sm-0 {
    margin-inline-end: 0 !important;
  }
  .me-sm-1 {
    margin-inline-end: 0.25rem !important;
  }
  .me-sm-2 {
    margin-inline-end: 0.5rem !important;
  }
  .me-sm-3 {
    margin-inline-end: 0.75rem !important;
  }
  .me-sm-4 {
    margin-inline-end: 1rem !important;
  }
  .me-sm-5 {
    margin-inline-end: 1.25rem !important;
  }
  .me-sm-6 {
    margin-inline-end: 1.5rem !important;
  }
  .me-sm-7 {
    margin-inline-end: 1.75rem !important;
  }
  .me-sm-8 {
    margin-inline-end: 2rem !important;
  }
  .me-sm-9 {
    margin-inline-end: 2.25rem !important;
  }
  .me-sm-10 {
    margin-inline-end: 2.5rem !important;
  }
  .me-sm-11 {
    margin-inline-end: 2.75rem !important;
  }
  .me-sm-12 {
    margin-inline-end: 3rem !important;
  }
  .me-sm-13 {
    margin-inline-end: 3.25rem !important;
  }
  .me-sm-14 {
    margin-inline-end: 3.5rem !important;
  }
  .me-sm-15 {
    margin-inline-end: 3.75rem !important;
  }
  .me-sm-16 {
    margin-inline-end: 4rem !important;
  }
  .me-sm-17 {
    margin-inline-end: 4.25rem !important;
  }
  .me-sm-18 {
    margin-inline-end: 4.5rem !important;
  }
  .me-sm-19 {
    margin-inline-end: 4.75rem !important;
  }
  .me-sm-20 {
    margin-inline-end: 5rem !important;
  }
  .me-sm-21 {
    margin-inline-end: 5.25rem !important;
  }
  .me-sm-22 {
    margin-inline-end: 5.5rem !important;
  }
  .me-sm-23 {
    margin-inline-end: 5.75rem !important;
  }
  .me-sm-24 {
    margin-inline-end: 6rem !important;
  }
  .me-sm-25 {
    margin-inline-end: 6.25rem !important;
  }
  .me-sm-26 {
    margin-inline-end: 6.5rem !important;
  }
  .me-sm-27 {
    margin-inline-end: 6.75rem !important;
  }
  .me-sm-28 {
    margin-inline-end: 7rem !important;
  }
  .me-sm-29 {
    margin-inline-end: 7.25rem !important;
  }
  .me-sm-30 {
    margin-inline-end: 7.5rem !important;
  }
  .me-sm-31 {
    margin-inline-end: 7.75rem !important;
  }
  .me-sm-32 {
    margin-inline-end: 8rem !important;
  }
  .me-sm-33 {
    margin-inline-end: 8.25rem !important;
  }
  .me-sm-34 {
    margin-inline-end: 8.5rem !important;
  }
  .me-sm-35 {
    margin-inline-end: 8.75rem !important;
  }
  .me-sm-36 {
    margin-inline-end: 9rem !important;
  }
  .me-sm-37 {
    margin-inline-end: 9.25rem !important;
  }
  .me-sm-38 {
    margin-inline-end: 9.5rem !important;
  }
  .me-sm-39 {
    margin-inline-end: 9.75rem !important;
  }
  .me-sm-40 {
    margin-inline-end: 10rem !important;
  }
  .me-sm-auto {
    margin-inline-end: auto !important;
  }
  .mb-sm-0 {
    margin-block-end: 0 !important;
  }
  .mb-sm-1 {
    margin-block-end: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-block-end: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-block-end: 0.75rem !important;
  }
  .mb-sm-4 {
    margin-block-end: 1rem !important;
  }
  .mb-sm-5 {
    margin-block-end: 1.25rem !important;
  }
  .mb-sm-6 {
    margin-block-end: 1.5rem !important;
  }
  .mb-sm-7 {
    margin-block-end: 1.75rem !important;
  }
  .mb-sm-8 {
    margin-block-end: 2rem !important;
  }
  .mb-sm-9 {
    margin-block-end: 2.25rem !important;
  }
  .mb-sm-10 {
    margin-block-end: 2.5rem !important;
  }
  .mb-sm-11 {
    margin-block-end: 2.75rem !important;
  }
  .mb-sm-12 {
    margin-block-end: 3rem !important;
  }
  .mb-sm-13 {
    margin-block-end: 3.25rem !important;
  }
  .mb-sm-14 {
    margin-block-end: 3.5rem !important;
  }
  .mb-sm-15 {
    margin-block-end: 3.75rem !important;
  }
  .mb-sm-16 {
    margin-block-end: 4rem !important;
  }
  .mb-sm-17 {
    margin-block-end: 4.25rem !important;
  }
  .mb-sm-18 {
    margin-block-end: 4.5rem !important;
  }
  .mb-sm-19 {
    margin-block-end: 4.75rem !important;
  }
  .mb-sm-20 {
    margin-block-end: 5rem !important;
  }
  .mb-sm-21 {
    margin-block-end: 5.25rem !important;
  }
  .mb-sm-22 {
    margin-block-end: 5.5rem !important;
  }
  .mb-sm-23 {
    margin-block-end: 5.75rem !important;
  }
  .mb-sm-24 {
    margin-block-end: 6rem !important;
  }
  .mb-sm-25 {
    margin-block-end: 6.25rem !important;
  }
  .mb-sm-26 {
    margin-block-end: 6.5rem !important;
  }
  .mb-sm-27 {
    margin-block-end: 6.75rem !important;
  }
  .mb-sm-28 {
    margin-block-end: 7rem !important;
  }
  .mb-sm-29 {
    margin-block-end: 7.25rem !important;
  }
  .mb-sm-30 {
    margin-block-end: 7.5rem !important;
  }
  .mb-sm-31 {
    margin-block-end: 7.75rem !important;
  }
  .mb-sm-32 {
    margin-block-end: 8rem !important;
  }
  .mb-sm-33 {
    margin-block-end: 8.25rem !important;
  }
  .mb-sm-34 {
    margin-block-end: 8.5rem !important;
  }
  .mb-sm-35 {
    margin-block-end: 8.75rem !important;
  }
  .mb-sm-36 {
    margin-block-end: 9rem !important;
  }
  .mb-sm-37 {
    margin-block-end: 9.25rem !important;
  }
  .mb-sm-38 {
    margin-block-end: 9.5rem !important;
  }
  .mb-sm-39 {
    margin-block-end: 9.75rem !important;
  }
  .mb-sm-40 {
    margin-block-end: 10rem !important;
  }
  .mb-sm-auto {
    margin-block-end: auto !important;
  }
  .ms-sm-0 {
    margin-inline-start: 0 !important;
  }
  .ms-sm-1 {
    margin-inline-start: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-inline-start: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-inline-start: 0.75rem !important;
  }
  .ms-sm-4 {
    margin-inline-start: 1rem !important;
  }
  .ms-sm-5 {
    margin-inline-start: 1.25rem !important;
  }
  .ms-sm-6 {
    margin-inline-start: 1.5rem !important;
  }
  .ms-sm-7 {
    margin-inline-start: 1.75rem !important;
  }
  .ms-sm-8 {
    margin-inline-start: 2rem !important;
  }
  .ms-sm-9 {
    margin-inline-start: 2.25rem !important;
  }
  .ms-sm-10 {
    margin-inline-start: 2.5rem !important;
  }
  .ms-sm-11 {
    margin-inline-start: 2.75rem !important;
  }
  .ms-sm-12 {
    margin-inline-start: 3rem !important;
  }
  .ms-sm-13 {
    margin-inline-start: 3.25rem !important;
  }
  .ms-sm-14 {
    margin-inline-start: 3.5rem !important;
  }
  .ms-sm-15 {
    margin-inline-start: 3.75rem !important;
  }
  .ms-sm-16 {
    margin-inline-start: 4rem !important;
  }
  .ms-sm-17 {
    margin-inline-start: 4.25rem !important;
  }
  .ms-sm-18 {
    margin-inline-start: 4.5rem !important;
  }
  .ms-sm-19 {
    margin-inline-start: 4.75rem !important;
  }
  .ms-sm-20 {
    margin-inline-start: 5rem !important;
  }
  .ms-sm-21 {
    margin-inline-start: 5.25rem !important;
  }
  .ms-sm-22 {
    margin-inline-start: 5.5rem !important;
  }
  .ms-sm-23 {
    margin-inline-start: 5.75rem !important;
  }
  .ms-sm-24 {
    margin-inline-start: 6rem !important;
  }
  .ms-sm-25 {
    margin-inline-start: 6.25rem !important;
  }
  .ms-sm-26 {
    margin-inline-start: 6.5rem !important;
  }
  .ms-sm-27 {
    margin-inline-start: 6.75rem !important;
  }
  .ms-sm-28 {
    margin-inline-start: 7rem !important;
  }
  .ms-sm-29 {
    margin-inline-start: 7.25rem !important;
  }
  .ms-sm-30 {
    margin-inline-start: 7.5rem !important;
  }
  .ms-sm-31 {
    margin-inline-start: 7.75rem !important;
  }
  .ms-sm-32 {
    margin-inline-start: 8rem !important;
  }
  .ms-sm-33 {
    margin-inline-start: 8.25rem !important;
  }
  .ms-sm-34 {
    margin-inline-start: 8.5rem !important;
  }
  .ms-sm-35 {
    margin-inline-start: 8.75rem !important;
  }
  .ms-sm-36 {
    margin-inline-start: 9rem !important;
  }
  .ms-sm-37 {
    margin-inline-start: 9.25rem !important;
  }
  .ms-sm-38 {
    margin-inline-start: 9.5rem !important;
  }
  .ms-sm-39 {
    margin-inline-start: 9.75rem !important;
  }
  .ms-sm-40 {
    margin-inline-start: 10rem !important;
  }
  .ms-sm-auto {
    margin-inline-start: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.25rem !important;
  }
  .p-sm-6 {
    padding: 1.5rem !important;
  }
  .p-sm-7 {
    padding: 1.75rem !important;
  }
  .p-sm-8 {
    padding: 2rem !important;
  }
  .p-sm-9 {
    padding: 2.25rem !important;
  }
  .p-sm-10 {
    padding: 2.5rem !important;
  }
  .p-sm-11 {
    padding: 2.75rem !important;
  }
  .p-sm-12 {
    padding: 3rem !important;
  }
  .p-sm-13 {
    padding: 3.25rem !important;
  }
  .p-sm-14 {
    padding: 3.5rem !important;
  }
  .p-sm-15 {
    padding: 3.75rem !important;
  }
  .p-sm-16 {
    padding: 4rem !important;
  }
  .p-sm-17 {
    padding: 4.25rem !important;
  }
  .p-sm-18 {
    padding: 4.5rem !important;
  }
  .p-sm-19 {
    padding: 4.75rem !important;
  }
  .p-sm-20 {
    padding: 5rem !important;
  }
  .p-sm-21 {
    padding: 5.25rem !important;
  }
  .p-sm-22 {
    padding: 5.5rem !important;
  }
  .p-sm-23 {
    padding: 5.75rem !important;
  }
  .p-sm-24 {
    padding: 6rem !important;
  }
  .p-sm-25 {
    padding: 6.25rem !important;
  }
  .p-sm-26 {
    padding: 6.5rem !important;
  }
  .p-sm-27 {
    padding: 6.75rem !important;
  }
  .p-sm-28 {
    padding: 7rem !important;
  }
  .p-sm-29 {
    padding: 7.25rem !important;
  }
  .p-sm-30 {
    padding: 7.5rem !important;
  }
  .p-sm-31 {
    padding: 7.75rem !important;
  }
  .p-sm-32 {
    padding: 8rem !important;
  }
  .p-sm-33 {
    padding: 8.25rem !important;
  }
  .p-sm-34 {
    padding: 8.5rem !important;
  }
  .p-sm-35 {
    padding: 8.75rem !important;
  }
  .p-sm-36 {
    padding: 9rem !important;
  }
  .p-sm-37 {
    padding: 9.25rem !important;
  }
  .p-sm-38 {
    padding: 9.5rem !important;
  }
  .p-sm-39 {
    padding: 9.75rem !important;
  }
  .p-sm-40 {
    padding: 10rem !important;
  }
  .px-sm-0 {
    padding-inline: 0 !important;
  }
  .px-sm-1 {
    padding-inline: 0.25rem !important;
  }
  .px-sm-2 {
    padding-inline: 0.5rem !important;
  }
  .px-sm-3 {
    padding-inline: 0.75rem !important;
  }
  .px-sm-4 {
    padding-inline: 1rem !important;
  }
  .px-sm-5 {
    padding-inline: 1.25rem !important;
  }
  .px-sm-6 {
    padding-inline: 1.5rem !important;
  }
  .px-sm-7 {
    padding-inline: 1.75rem !important;
  }
  .px-sm-8 {
    padding-inline: 2rem !important;
  }
  .px-sm-9 {
    padding-inline: 2.25rem !important;
  }
  .px-sm-10 {
    padding-inline: 2.5rem !important;
  }
  .px-sm-11 {
    padding-inline: 2.75rem !important;
  }
  .px-sm-12 {
    padding-inline: 3rem !important;
  }
  .px-sm-13 {
    padding-inline: 3.25rem !important;
  }
  .px-sm-14 {
    padding-inline: 3.5rem !important;
  }
  .px-sm-15 {
    padding-inline: 3.75rem !important;
  }
  .px-sm-16 {
    padding-inline: 4rem !important;
  }
  .px-sm-17 {
    padding-inline: 4.25rem !important;
  }
  .px-sm-18 {
    padding-inline: 4.5rem !important;
  }
  .px-sm-19 {
    padding-inline: 4.75rem !important;
  }
  .px-sm-20 {
    padding-inline: 5rem !important;
  }
  .px-sm-21 {
    padding-inline: 5.25rem !important;
  }
  .px-sm-22 {
    padding-inline: 5.5rem !important;
  }
  .px-sm-23 {
    padding-inline: 5.75rem !important;
  }
  .px-sm-24 {
    padding-inline: 6rem !important;
  }
  .px-sm-25 {
    padding-inline: 6.25rem !important;
  }
  .px-sm-26 {
    padding-inline: 6.5rem !important;
  }
  .px-sm-27 {
    padding-inline: 6.75rem !important;
  }
  .px-sm-28 {
    padding-inline: 7rem !important;
  }
  .px-sm-29 {
    padding-inline: 7.25rem !important;
  }
  .px-sm-30 {
    padding-inline: 7.5rem !important;
  }
  .px-sm-31 {
    padding-inline: 7.75rem !important;
  }
  .px-sm-32 {
    padding-inline: 8rem !important;
  }
  .px-sm-33 {
    padding-inline: 8.25rem !important;
  }
  .px-sm-34 {
    padding-inline: 8.5rem !important;
  }
  .px-sm-35 {
    padding-inline: 8.75rem !important;
  }
  .px-sm-36 {
    padding-inline: 9rem !important;
  }
  .px-sm-37 {
    padding-inline: 9.25rem !important;
  }
  .px-sm-38 {
    padding-inline: 9.5rem !important;
  }
  .px-sm-39 {
    padding-inline: 9.75rem !important;
  }
  .px-sm-40 {
    padding-inline: 10rem !important;
  }
  .py-sm-0 {
    padding-block: 0 !important;
  }
  .py-sm-1 {
    padding-block: 0.25rem !important;
  }
  .py-sm-2 {
    padding-block: 0.5rem !important;
  }
  .py-sm-3 {
    padding-block: 0.75rem !important;
  }
  .py-sm-4 {
    padding-block: 1rem !important;
  }
  .py-sm-5 {
    padding-block: 1.25rem !important;
  }
  .py-sm-6 {
    padding-block: 1.5rem !important;
  }
  .py-sm-7 {
    padding-block: 1.75rem !important;
  }
  .py-sm-8 {
    padding-block: 2rem !important;
  }
  .py-sm-9 {
    padding-block: 2.25rem !important;
  }
  .py-sm-10 {
    padding-block: 2.5rem !important;
  }
  .py-sm-11 {
    padding-block: 2.75rem !important;
  }
  .py-sm-12 {
    padding-block: 3rem !important;
  }
  .py-sm-13 {
    padding-block: 3.25rem !important;
  }
  .py-sm-14 {
    padding-block: 3.5rem !important;
  }
  .py-sm-15 {
    padding-block: 3.75rem !important;
  }
  .py-sm-16 {
    padding-block: 4rem !important;
  }
  .py-sm-17 {
    padding-block: 4.25rem !important;
  }
  .py-sm-18 {
    padding-block: 4.5rem !important;
  }
  .py-sm-19 {
    padding-block: 4.75rem !important;
  }
  .py-sm-20 {
    padding-block: 5rem !important;
  }
  .py-sm-21 {
    padding-block: 5.25rem !important;
  }
  .py-sm-22 {
    padding-block: 5.5rem !important;
  }
  .py-sm-23 {
    padding-block: 5.75rem !important;
  }
  .py-sm-24 {
    padding-block: 6rem !important;
  }
  .py-sm-25 {
    padding-block: 6.25rem !important;
  }
  .py-sm-26 {
    padding-block: 6.5rem !important;
  }
  .py-sm-27 {
    padding-block: 6.75rem !important;
  }
  .py-sm-28 {
    padding-block: 7rem !important;
  }
  .py-sm-29 {
    padding-block: 7.25rem !important;
  }
  .py-sm-30 {
    padding-block: 7.5rem !important;
  }
  .py-sm-31 {
    padding-block: 7.75rem !important;
  }
  .py-sm-32 {
    padding-block: 8rem !important;
  }
  .py-sm-33 {
    padding-block: 8.25rem !important;
  }
  .py-sm-34 {
    padding-block: 8.5rem !important;
  }
  .py-sm-35 {
    padding-block: 8.75rem !important;
  }
  .py-sm-36 {
    padding-block: 9rem !important;
  }
  .py-sm-37 {
    padding-block: 9.25rem !important;
  }
  .py-sm-38 {
    padding-block: 9.5rem !important;
  }
  .py-sm-39 {
    padding-block: 9.75rem !important;
  }
  .py-sm-40 {
    padding-block: 10rem !important;
  }
  .pt-sm-0 {
    padding-block-start: 0 !important;
  }
  .pt-sm-1 {
    padding-block-start: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-block-start: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-block-start: 0.75rem !important;
  }
  .pt-sm-4 {
    padding-block-start: 1rem !important;
  }
  .pt-sm-5 {
    padding-block-start: 1.25rem !important;
  }
  .pt-sm-6 {
    padding-block-start: 1.5rem !important;
  }
  .pt-sm-7 {
    padding-block-start: 1.75rem !important;
  }
  .pt-sm-8 {
    padding-block-start: 2rem !important;
  }
  .pt-sm-9 {
    padding-block-start: 2.25rem !important;
  }
  .pt-sm-10 {
    padding-block-start: 2.5rem !important;
  }
  .pt-sm-11 {
    padding-block-start: 2.75rem !important;
  }
  .pt-sm-12 {
    padding-block-start: 3rem !important;
  }
  .pt-sm-13 {
    padding-block-start: 3.25rem !important;
  }
  .pt-sm-14 {
    padding-block-start: 3.5rem !important;
  }
  .pt-sm-15 {
    padding-block-start: 3.75rem !important;
  }
  .pt-sm-16 {
    padding-block-start: 4rem !important;
  }
  .pt-sm-17 {
    padding-block-start: 4.25rem !important;
  }
  .pt-sm-18 {
    padding-block-start: 4.5rem !important;
  }
  .pt-sm-19 {
    padding-block-start: 4.75rem !important;
  }
  .pt-sm-20 {
    padding-block-start: 5rem !important;
  }
  .pt-sm-21 {
    padding-block-start: 5.25rem !important;
  }
  .pt-sm-22 {
    padding-block-start: 5.5rem !important;
  }
  .pt-sm-23 {
    padding-block-start: 5.75rem !important;
  }
  .pt-sm-24 {
    padding-block-start: 6rem !important;
  }
  .pt-sm-25 {
    padding-block-start: 6.25rem !important;
  }
  .pt-sm-26 {
    padding-block-start: 6.5rem !important;
  }
  .pt-sm-27 {
    padding-block-start: 6.75rem !important;
  }
  .pt-sm-28 {
    padding-block-start: 7rem !important;
  }
  .pt-sm-29 {
    padding-block-start: 7.25rem !important;
  }
  .pt-sm-30 {
    padding-block-start: 7.5rem !important;
  }
  .pt-sm-31 {
    padding-block-start: 7.75rem !important;
  }
  .pt-sm-32 {
    padding-block-start: 8rem !important;
  }
  .pt-sm-33 {
    padding-block-start: 8.25rem !important;
  }
  .pt-sm-34 {
    padding-block-start: 8.5rem !important;
  }
  .pt-sm-35 {
    padding-block-start: 8.75rem !important;
  }
  .pt-sm-36 {
    padding-block-start: 9rem !important;
  }
  .pt-sm-37 {
    padding-block-start: 9.25rem !important;
  }
  .pt-sm-38 {
    padding-block-start: 9.5rem !important;
  }
  .pt-sm-39 {
    padding-block-start: 9.75rem !important;
  }
  .pt-sm-40 {
    padding-block-start: 10rem !important;
  }
  .pe-sm-0 {
    padding-inline-end: 0 !important;
  }
  .pe-sm-1 {
    padding-inline-end: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-inline-end: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-inline-end: 0.75rem !important;
  }
  .pe-sm-4 {
    padding-inline-end: 1rem !important;
  }
  .pe-sm-5 {
    padding-inline-end: 1.25rem !important;
  }
  .pe-sm-6 {
    padding-inline-end: 1.5rem !important;
  }
  .pe-sm-7 {
    padding-inline-end: 1.75rem !important;
  }
  .pe-sm-8 {
    padding-inline-end: 2rem !important;
  }
  .pe-sm-9 {
    padding-inline-end: 2.25rem !important;
  }
  .pe-sm-10 {
    padding-inline-end: 2.5rem !important;
  }
  .pe-sm-11 {
    padding-inline-end: 2.75rem !important;
  }
  .pe-sm-12 {
    padding-inline-end: 3rem !important;
  }
  .pe-sm-13 {
    padding-inline-end: 3.25rem !important;
  }
  .pe-sm-14 {
    padding-inline-end: 3.5rem !important;
  }
  .pe-sm-15 {
    padding-inline-end: 3.75rem !important;
  }
  .pe-sm-16 {
    padding-inline-end: 4rem !important;
  }
  .pe-sm-17 {
    padding-inline-end: 4.25rem !important;
  }
  .pe-sm-18 {
    padding-inline-end: 4.5rem !important;
  }
  .pe-sm-19 {
    padding-inline-end: 4.75rem !important;
  }
  .pe-sm-20 {
    padding-inline-end: 5rem !important;
  }
  .pe-sm-21 {
    padding-inline-end: 5.25rem !important;
  }
  .pe-sm-22 {
    padding-inline-end: 5.5rem !important;
  }
  .pe-sm-23 {
    padding-inline-end: 5.75rem !important;
  }
  .pe-sm-24 {
    padding-inline-end: 6rem !important;
  }
  .pe-sm-25 {
    padding-inline-end: 6.25rem !important;
  }
  .pe-sm-26 {
    padding-inline-end: 6.5rem !important;
  }
  .pe-sm-27 {
    padding-inline-end: 6.75rem !important;
  }
  .pe-sm-28 {
    padding-inline-end: 7rem !important;
  }
  .pe-sm-29 {
    padding-inline-end: 7.25rem !important;
  }
  .pe-sm-30 {
    padding-inline-end: 7.5rem !important;
  }
  .pe-sm-31 {
    padding-inline-end: 7.75rem !important;
  }
  .pe-sm-32 {
    padding-inline-end: 8rem !important;
  }
  .pe-sm-33 {
    padding-inline-end: 8.25rem !important;
  }
  .pe-sm-34 {
    padding-inline-end: 8.5rem !important;
  }
  .pe-sm-35 {
    padding-inline-end: 8.75rem !important;
  }
  .pe-sm-36 {
    padding-inline-end: 9rem !important;
  }
  .pe-sm-37 {
    padding-inline-end: 9.25rem !important;
  }
  .pe-sm-38 {
    padding-inline-end: 9.5rem !important;
  }
  .pe-sm-39 {
    padding-inline-end: 9.75rem !important;
  }
  .pe-sm-40 {
    padding-inline-end: 10rem !important;
  }
  .pb-sm-0 {
    padding-block-end: 0 !important;
  }
  .pb-sm-1 {
    padding-block-end: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-block-end: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-block-end: 0.75rem !important;
  }
  .pb-sm-4 {
    padding-block-end: 1rem !important;
  }
  .pb-sm-5 {
    padding-block-end: 1.25rem !important;
  }
  .pb-sm-6 {
    padding-block-end: 1.5rem !important;
  }
  .pb-sm-7 {
    padding-block-end: 1.75rem !important;
  }
  .pb-sm-8 {
    padding-block-end: 2rem !important;
  }
  .pb-sm-9 {
    padding-block-end: 2.25rem !important;
  }
  .pb-sm-10 {
    padding-block-end: 2.5rem !important;
  }
  .pb-sm-11 {
    padding-block-end: 2.75rem !important;
  }
  .pb-sm-12 {
    padding-block-end: 3rem !important;
  }
  .pb-sm-13 {
    padding-block-end: 3.25rem !important;
  }
  .pb-sm-14 {
    padding-block-end: 3.5rem !important;
  }
  .pb-sm-15 {
    padding-block-end: 3.75rem !important;
  }
  .pb-sm-16 {
    padding-block-end: 4rem !important;
  }
  .pb-sm-17 {
    padding-block-end: 4.25rem !important;
  }
  .pb-sm-18 {
    padding-block-end: 4.5rem !important;
  }
  .pb-sm-19 {
    padding-block-end: 4.75rem !important;
  }
  .pb-sm-20 {
    padding-block-end: 5rem !important;
  }
  .pb-sm-21 {
    padding-block-end: 5.25rem !important;
  }
  .pb-sm-22 {
    padding-block-end: 5.5rem !important;
  }
  .pb-sm-23 {
    padding-block-end: 5.75rem !important;
  }
  .pb-sm-24 {
    padding-block-end: 6rem !important;
  }
  .pb-sm-25 {
    padding-block-end: 6.25rem !important;
  }
  .pb-sm-26 {
    padding-block-end: 6.5rem !important;
  }
  .pb-sm-27 {
    padding-block-end: 6.75rem !important;
  }
  .pb-sm-28 {
    padding-block-end: 7rem !important;
  }
  .pb-sm-29 {
    padding-block-end: 7.25rem !important;
  }
  .pb-sm-30 {
    padding-block-end: 7.5rem !important;
  }
  .pb-sm-31 {
    padding-block-end: 7.75rem !important;
  }
  .pb-sm-32 {
    padding-block-end: 8rem !important;
  }
  .pb-sm-33 {
    padding-block-end: 8.25rem !important;
  }
  .pb-sm-34 {
    padding-block-end: 8.5rem !important;
  }
  .pb-sm-35 {
    padding-block-end: 8.75rem !important;
  }
  .pb-sm-36 {
    padding-block-end: 9rem !important;
  }
  .pb-sm-37 {
    padding-block-end: 9.25rem !important;
  }
  .pb-sm-38 {
    padding-block-end: 9.5rem !important;
  }
  .pb-sm-39 {
    padding-block-end: 9.75rem !important;
  }
  .pb-sm-40 {
    padding-block-end: 10rem !important;
  }
  .ps-sm-0 {
    padding-inline-start: 0 !important;
  }
  .ps-sm-1 {
    padding-inline-start: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-inline-start: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-inline-start: 0.75rem !important;
  }
  .ps-sm-4 {
    padding-inline-start: 1rem !important;
  }
  .ps-sm-5 {
    padding-inline-start: 1.25rem !important;
  }
  .ps-sm-6 {
    padding-inline-start: 1.5rem !important;
  }
  .ps-sm-7 {
    padding-inline-start: 1.75rem !important;
  }
  .ps-sm-8 {
    padding-inline-start: 2rem !important;
  }
  .ps-sm-9 {
    padding-inline-start: 2.25rem !important;
  }
  .ps-sm-10 {
    padding-inline-start: 2.5rem !important;
  }
  .ps-sm-11 {
    padding-inline-start: 2.75rem !important;
  }
  .ps-sm-12 {
    padding-inline-start: 3rem !important;
  }
  .ps-sm-13 {
    padding-inline-start: 3.25rem !important;
  }
  .ps-sm-14 {
    padding-inline-start: 3.5rem !important;
  }
  .ps-sm-15 {
    padding-inline-start: 3.75rem !important;
  }
  .ps-sm-16 {
    padding-inline-start: 4rem !important;
  }
  .ps-sm-17 {
    padding-inline-start: 4.25rem !important;
  }
  .ps-sm-18 {
    padding-inline-start: 4.5rem !important;
  }
  .ps-sm-19 {
    padding-inline-start: 4.75rem !important;
  }
  .ps-sm-20 {
    padding-inline-start: 5rem !important;
  }
  .ps-sm-21 {
    padding-inline-start: 5.25rem !important;
  }
  .ps-sm-22 {
    padding-inline-start: 5.5rem !important;
  }
  .ps-sm-23 {
    padding-inline-start: 5.75rem !important;
  }
  .ps-sm-24 {
    padding-inline-start: 6rem !important;
  }
  .ps-sm-25 {
    padding-inline-start: 6.25rem !important;
  }
  .ps-sm-26 {
    padding-inline-start: 6.5rem !important;
  }
  .ps-sm-27 {
    padding-inline-start: 6.75rem !important;
  }
  .ps-sm-28 {
    padding-inline-start: 7rem !important;
  }
  .ps-sm-29 {
    padding-inline-start: 7.25rem !important;
  }
  .ps-sm-30 {
    padding-inline-start: 7.5rem !important;
  }
  .ps-sm-31 {
    padding-inline-start: 7.75rem !important;
  }
  .ps-sm-32 {
    padding-inline-start: 8rem !important;
  }
  .ps-sm-33 {
    padding-inline-start: 8.25rem !important;
  }
  .ps-sm-34 {
    padding-inline-start: 8.5rem !important;
  }
  .ps-sm-35 {
    padding-inline-start: 8.75rem !important;
  }
  .ps-sm-36 {
    padding-inline-start: 9rem !important;
  }
  .ps-sm-37 {
    padding-inline-start: 9.25rem !important;
  }
  .ps-sm-38 {
    padding-inline-start: 9.5rem !important;
  }
  .ps-sm-39 {
    padding-inline-start: 9.75rem !important;
  }
  .ps-sm-40 {
    padding-inline-start: 10rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 0.75rem !important;
  }
  .gap-sm-4 {
    gap: 1rem !important;
  }
  .gap-sm-5 {
    gap: 1.25rem !important;
  }
  .gap-sm-6 {
    gap: 1.5rem !important;
  }
  .gap-sm-7 {
    gap: 1.75rem !important;
  }
  .gap-sm-8 {
    gap: 2rem !important;
  }
  .gap-sm-9 {
    gap: 2.25rem !important;
  }
  .gap-sm-10 {
    gap: 2.5rem !important;
  }
  .gap-sm-11 {
    gap: 2.75rem !important;
  }
  .gap-sm-12 {
    gap: 3rem !important;
  }
  .gap-sm-13 {
    gap: 3.25rem !important;
  }
  .gap-sm-14 {
    gap: 3.5rem !important;
  }
  .gap-sm-15 {
    gap: 3.75rem !important;
  }
  .gap-sm-16 {
    gap: 4rem !important;
  }
  .gap-sm-17 {
    gap: 4.25rem !important;
  }
  .gap-sm-18 {
    gap: 4.5rem !important;
  }
  .gap-sm-19 {
    gap: 4.75rem !important;
  }
  .gap-sm-20 {
    gap: 5rem !important;
  }
  .gap-sm-21 {
    gap: 5.25rem !important;
  }
  .gap-sm-22 {
    gap: 5.5rem !important;
  }
  .gap-sm-23 {
    gap: 5.75rem !important;
  }
  .gap-sm-24 {
    gap: 6rem !important;
  }
  .gap-sm-25 {
    gap: 6.25rem !important;
  }
  .gap-sm-26 {
    gap: 6.5rem !important;
  }
  .gap-sm-27 {
    gap: 6.75rem !important;
  }
  .gap-sm-28 {
    gap: 7rem !important;
  }
  .gap-sm-29 {
    gap: 7.25rem !important;
  }
  .gap-sm-30 {
    gap: 7.5rem !important;
  }
  .gap-sm-31 {
    gap: 7.75rem !important;
  }
  .gap-sm-32 {
    gap: 8rem !important;
  }
  .gap-sm-33 {
    gap: 8.25rem !important;
  }
  .gap-sm-34 {
    gap: 8.5rem !important;
  }
  .gap-sm-35 {
    gap: 8.75rem !important;
  }
  .gap-sm-36 {
    gap: 9rem !important;
  }
  .gap-sm-37 {
    gap: 9.25rem !important;
  }
  .gap-sm-38 {
    gap: 9.5rem !important;
  }
  .gap-sm-39 {
    gap: 9.75rem !important;
  }
  .gap-sm-40 {
    gap: 10rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 2rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-sm-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-sm-12 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-sm-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-sm-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-sm-16 {
    row-gap: 4rem !important;
  }
  .row-gap-sm-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-sm-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-sm-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-sm-20 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-sm-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-sm-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-sm-24 {
    row-gap: 6rem !important;
  }
  .row-gap-sm-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-sm-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-sm-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-sm-28 {
    row-gap: 7rem !important;
  }
  .row-gap-sm-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-sm-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-sm-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-sm-32 {
    row-gap: 8rem !important;
  }
  .row-gap-sm-33 {
    row-gap: 8.25rem !important;
  }
  .row-gap-sm-34 {
    row-gap: 8.5rem !important;
  }
  .row-gap-sm-35 {
    row-gap: 8.75rem !important;
  }
  .row-gap-sm-36 {
    row-gap: 9rem !important;
  }
  .row-gap-sm-37 {
    row-gap: 9.25rem !important;
  }
  .row-gap-sm-38 {
    row-gap: 9.5rem !important;
  }
  .row-gap-sm-39 {
    row-gap: 9.75rem !important;
  }
  .row-gap-sm-40 {
    row-gap: 10rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 2rem !important;
  }
  .column-gap-sm-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-sm-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-sm-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-sm-12 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-sm-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-sm-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-sm-16 {
    column-gap: 4rem !important;
  }
  .column-gap-sm-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-sm-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-sm-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-sm-20 {
    column-gap: 5rem !important;
  }
  .column-gap-sm-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-sm-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-sm-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-sm-24 {
    column-gap: 6rem !important;
  }
  .column-gap-sm-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-sm-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-sm-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-sm-28 {
    column-gap: 7rem !important;
  }
  .column-gap-sm-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-sm-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-sm-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-sm-32 {
    column-gap: 8rem !important;
  }
  .column-gap-sm-33 {
    column-gap: 8.25rem !important;
  }
  .column-gap-sm-34 {
    column-gap: 8.5rem !important;
  }
  .column-gap-sm-35 {
    column-gap: 8.75rem !important;
  }
  .column-gap-sm-36 {
    column-gap: 9rem !important;
  }
  .column-gap-sm-37 {
    column-gap: 9.25rem !important;
  }
  .column-gap-sm-38 {
    column-gap: 9.5rem !important;
  }
  .column-gap-sm-39 {
    column-gap: 9.75rem !important;
  }
  .column-gap-sm-40 {
    column-gap: 10rem !important;
  }
  .text-sm-start {
    text-align: start !important;
  }
  .text-sm-end {
    text-align: end !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .border-md {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .m-md-5 {
    margin: 1.25rem !important;
  }
  .m-md-6 {
    margin: 1.5rem !important;
  }
  .m-md-7 {
    margin: 1.75rem !important;
  }
  .m-md-8 {
    margin: 2rem !important;
  }
  .m-md-9 {
    margin: 2.25rem !important;
  }
  .m-md-10 {
    margin: 2.5rem !important;
  }
  .m-md-11 {
    margin: 2.75rem !important;
  }
  .m-md-12 {
    margin: 3rem !important;
  }
  .m-md-13 {
    margin: 3.25rem !important;
  }
  .m-md-14 {
    margin: 3.5rem !important;
  }
  .m-md-15 {
    margin: 3.75rem !important;
  }
  .m-md-16 {
    margin: 4rem !important;
  }
  .m-md-17 {
    margin: 4.25rem !important;
  }
  .m-md-18 {
    margin: 4.5rem !important;
  }
  .m-md-19 {
    margin: 4.75rem !important;
  }
  .m-md-20 {
    margin: 5rem !important;
  }
  .m-md-21 {
    margin: 5.25rem !important;
  }
  .m-md-22 {
    margin: 5.5rem !important;
  }
  .m-md-23 {
    margin: 5.75rem !important;
  }
  .m-md-24 {
    margin: 6rem !important;
  }
  .m-md-25 {
    margin: 6.25rem !important;
  }
  .m-md-26 {
    margin: 6.5rem !important;
  }
  .m-md-27 {
    margin: 6.75rem !important;
  }
  .m-md-28 {
    margin: 7rem !important;
  }
  .m-md-29 {
    margin: 7.25rem !important;
  }
  .m-md-30 {
    margin: 7.5rem !important;
  }
  .m-md-31 {
    margin: 7.75rem !important;
  }
  .m-md-32 {
    margin: 8rem !important;
  }
  .m-md-33 {
    margin: 8.25rem !important;
  }
  .m-md-34 {
    margin: 8.5rem !important;
  }
  .m-md-35 {
    margin: 8.75rem !important;
  }
  .m-md-36 {
    margin: 9rem !important;
  }
  .m-md-37 {
    margin: 9.25rem !important;
  }
  .m-md-38 {
    margin: 9.5rem !important;
  }
  .m-md-39 {
    margin: 9.75rem !important;
  }
  .m-md-40 {
    margin: 10rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-inline: 0 !important;
  }
  .mx-md-1 {
    margin-inline: 0.25rem !important;
  }
  .mx-md-2 {
    margin-inline: 0.5rem !important;
  }
  .mx-md-3 {
    margin-inline: 0.75rem !important;
  }
  .mx-md-4 {
    margin-inline: 1rem !important;
  }
  .mx-md-5 {
    margin-inline: 1.25rem !important;
  }
  .mx-md-6 {
    margin-inline: 1.5rem !important;
  }
  .mx-md-7 {
    margin-inline: 1.75rem !important;
  }
  .mx-md-8 {
    margin-inline: 2rem !important;
  }
  .mx-md-9 {
    margin-inline: 2.25rem !important;
  }
  .mx-md-10 {
    margin-inline: 2.5rem !important;
  }
  .mx-md-11 {
    margin-inline: 2.75rem !important;
  }
  .mx-md-12 {
    margin-inline: 3rem !important;
  }
  .mx-md-13 {
    margin-inline: 3.25rem !important;
  }
  .mx-md-14 {
    margin-inline: 3.5rem !important;
  }
  .mx-md-15 {
    margin-inline: 3.75rem !important;
  }
  .mx-md-16 {
    margin-inline: 4rem !important;
  }
  .mx-md-17 {
    margin-inline: 4.25rem !important;
  }
  .mx-md-18 {
    margin-inline: 4.5rem !important;
  }
  .mx-md-19 {
    margin-inline: 4.75rem !important;
  }
  .mx-md-20 {
    margin-inline: 5rem !important;
  }
  .mx-md-21 {
    margin-inline: 5.25rem !important;
  }
  .mx-md-22 {
    margin-inline: 5.5rem !important;
  }
  .mx-md-23 {
    margin-inline: 5.75rem !important;
  }
  .mx-md-24 {
    margin-inline: 6rem !important;
  }
  .mx-md-25 {
    margin-inline: 6.25rem !important;
  }
  .mx-md-26 {
    margin-inline: 6.5rem !important;
  }
  .mx-md-27 {
    margin-inline: 6.75rem !important;
  }
  .mx-md-28 {
    margin-inline: 7rem !important;
  }
  .mx-md-29 {
    margin-inline: 7.25rem !important;
  }
  .mx-md-30 {
    margin-inline: 7.5rem !important;
  }
  .mx-md-31 {
    margin-inline: 7.75rem !important;
  }
  .mx-md-32 {
    margin-inline: 8rem !important;
  }
  .mx-md-33 {
    margin-inline: 8.25rem !important;
  }
  .mx-md-34 {
    margin-inline: 8.5rem !important;
  }
  .mx-md-35 {
    margin-inline: 8.75rem !important;
  }
  .mx-md-36 {
    margin-inline: 9rem !important;
  }
  .mx-md-37 {
    margin-inline: 9.25rem !important;
  }
  .mx-md-38 {
    margin-inline: 9.5rem !important;
  }
  .mx-md-39 {
    margin-inline: 9.75rem !important;
  }
  .mx-md-40 {
    margin-inline: 10rem !important;
  }
  .mx-md-auto {
    margin-inline: auto !important;
  }
  .my-md-0 {
    margin-block: 0 !important;
  }
  .my-md-1 {
    margin-block: 0.25rem !important;
  }
  .my-md-2 {
    margin-block: 0.5rem !important;
  }
  .my-md-3 {
    margin-block: 0.75rem !important;
  }
  .my-md-4 {
    margin-block: 1rem !important;
  }
  .my-md-5 {
    margin-block: 1.25rem !important;
  }
  .my-md-6 {
    margin-block: 1.5rem !important;
  }
  .my-md-7 {
    margin-block: 1.75rem !important;
  }
  .my-md-8 {
    margin-block: 2rem !important;
  }
  .my-md-9 {
    margin-block: 2.25rem !important;
  }
  .my-md-10 {
    margin-block: 2.5rem !important;
  }
  .my-md-11 {
    margin-block: 2.75rem !important;
  }
  .my-md-12 {
    margin-block: 3rem !important;
  }
  .my-md-13 {
    margin-block: 3.25rem !important;
  }
  .my-md-14 {
    margin-block: 3.5rem !important;
  }
  .my-md-15 {
    margin-block: 3.75rem !important;
  }
  .my-md-16 {
    margin-block: 4rem !important;
  }
  .my-md-17 {
    margin-block: 4.25rem !important;
  }
  .my-md-18 {
    margin-block: 4.5rem !important;
  }
  .my-md-19 {
    margin-block: 4.75rem !important;
  }
  .my-md-20 {
    margin-block: 5rem !important;
  }
  .my-md-21 {
    margin-block: 5.25rem !important;
  }
  .my-md-22 {
    margin-block: 5.5rem !important;
  }
  .my-md-23 {
    margin-block: 5.75rem !important;
  }
  .my-md-24 {
    margin-block: 6rem !important;
  }
  .my-md-25 {
    margin-block: 6.25rem !important;
  }
  .my-md-26 {
    margin-block: 6.5rem !important;
  }
  .my-md-27 {
    margin-block: 6.75rem !important;
  }
  .my-md-28 {
    margin-block: 7rem !important;
  }
  .my-md-29 {
    margin-block: 7.25rem !important;
  }
  .my-md-30 {
    margin-block: 7.5rem !important;
  }
  .my-md-31 {
    margin-block: 7.75rem !important;
  }
  .my-md-32 {
    margin-block: 8rem !important;
  }
  .my-md-33 {
    margin-block: 8.25rem !important;
  }
  .my-md-34 {
    margin-block: 8.5rem !important;
  }
  .my-md-35 {
    margin-block: 8.75rem !important;
  }
  .my-md-36 {
    margin-block: 9rem !important;
  }
  .my-md-37 {
    margin-block: 9.25rem !important;
  }
  .my-md-38 {
    margin-block: 9.5rem !important;
  }
  .my-md-39 {
    margin-block: 9.75rem !important;
  }
  .my-md-40 {
    margin-block: 10rem !important;
  }
  .my-md-auto {
    margin-block: auto !important;
  }
  .mt-md-0 {
    margin-block-start: 0 !important;
  }
  .mt-md-1 {
    margin-block-start: 0.25rem !important;
  }
  .mt-md-2 {
    margin-block-start: 0.5rem !important;
  }
  .mt-md-3 {
    margin-block-start: 0.75rem !important;
  }
  .mt-md-4 {
    margin-block-start: 1rem !important;
  }
  .mt-md-5 {
    margin-block-start: 1.25rem !important;
  }
  .mt-md-6 {
    margin-block-start: 1.5rem !important;
  }
  .mt-md-7 {
    margin-block-start: 1.75rem !important;
  }
  .mt-md-8 {
    margin-block-start: 2rem !important;
  }
  .mt-md-9 {
    margin-block-start: 2.25rem !important;
  }
  .mt-md-10 {
    margin-block-start: 2.5rem !important;
  }
  .mt-md-11 {
    margin-block-start: 2.75rem !important;
  }
  .mt-md-12 {
    margin-block-start: 3rem !important;
  }
  .mt-md-13 {
    margin-block-start: 3.25rem !important;
  }
  .mt-md-14 {
    margin-block-start: 3.5rem !important;
  }
  .mt-md-15 {
    margin-block-start: 3.75rem !important;
  }
  .mt-md-16 {
    margin-block-start: 4rem !important;
  }
  .mt-md-17 {
    margin-block-start: 4.25rem !important;
  }
  .mt-md-18 {
    margin-block-start: 4.5rem !important;
  }
  .mt-md-19 {
    margin-block-start: 4.75rem !important;
  }
  .mt-md-20 {
    margin-block-start: 5rem !important;
  }
  .mt-md-21 {
    margin-block-start: 5.25rem !important;
  }
  .mt-md-22 {
    margin-block-start: 5.5rem !important;
  }
  .mt-md-23 {
    margin-block-start: 5.75rem !important;
  }
  .mt-md-24 {
    margin-block-start: 6rem !important;
  }
  .mt-md-25 {
    margin-block-start: 6.25rem !important;
  }
  .mt-md-26 {
    margin-block-start: 6.5rem !important;
  }
  .mt-md-27 {
    margin-block-start: 6.75rem !important;
  }
  .mt-md-28 {
    margin-block-start: 7rem !important;
  }
  .mt-md-29 {
    margin-block-start: 7.25rem !important;
  }
  .mt-md-30 {
    margin-block-start: 7.5rem !important;
  }
  .mt-md-31 {
    margin-block-start: 7.75rem !important;
  }
  .mt-md-32 {
    margin-block-start: 8rem !important;
  }
  .mt-md-33 {
    margin-block-start: 8.25rem !important;
  }
  .mt-md-34 {
    margin-block-start: 8.5rem !important;
  }
  .mt-md-35 {
    margin-block-start: 8.75rem !important;
  }
  .mt-md-36 {
    margin-block-start: 9rem !important;
  }
  .mt-md-37 {
    margin-block-start: 9.25rem !important;
  }
  .mt-md-38 {
    margin-block-start: 9.5rem !important;
  }
  .mt-md-39 {
    margin-block-start: 9.75rem !important;
  }
  .mt-md-40 {
    margin-block-start: 10rem !important;
  }
  .mt-md-auto {
    margin-block-start: auto !important;
  }
  .me-md-0 {
    margin-inline-end: 0 !important;
  }
  .me-md-1 {
    margin-inline-end: 0.25rem !important;
  }
  .me-md-2 {
    margin-inline-end: 0.5rem !important;
  }
  .me-md-3 {
    margin-inline-end: 0.75rem !important;
  }
  .me-md-4 {
    margin-inline-end: 1rem !important;
  }
  .me-md-5 {
    margin-inline-end: 1.25rem !important;
  }
  .me-md-6 {
    margin-inline-end: 1.5rem !important;
  }
  .me-md-7 {
    margin-inline-end: 1.75rem !important;
  }
  .me-md-8 {
    margin-inline-end: 2rem !important;
  }
  .me-md-9 {
    margin-inline-end: 2.25rem !important;
  }
  .me-md-10 {
    margin-inline-end: 2.5rem !important;
  }
  .me-md-11 {
    margin-inline-end: 2.75rem !important;
  }
  .me-md-12 {
    margin-inline-end: 3rem !important;
  }
  .me-md-13 {
    margin-inline-end: 3.25rem !important;
  }
  .me-md-14 {
    margin-inline-end: 3.5rem !important;
  }
  .me-md-15 {
    margin-inline-end: 3.75rem !important;
  }
  .me-md-16 {
    margin-inline-end: 4rem !important;
  }
  .me-md-17 {
    margin-inline-end: 4.25rem !important;
  }
  .me-md-18 {
    margin-inline-end: 4.5rem !important;
  }
  .me-md-19 {
    margin-inline-end: 4.75rem !important;
  }
  .me-md-20 {
    margin-inline-end: 5rem !important;
  }
  .me-md-21 {
    margin-inline-end: 5.25rem !important;
  }
  .me-md-22 {
    margin-inline-end: 5.5rem !important;
  }
  .me-md-23 {
    margin-inline-end: 5.75rem !important;
  }
  .me-md-24 {
    margin-inline-end: 6rem !important;
  }
  .me-md-25 {
    margin-inline-end: 6.25rem !important;
  }
  .me-md-26 {
    margin-inline-end: 6.5rem !important;
  }
  .me-md-27 {
    margin-inline-end: 6.75rem !important;
  }
  .me-md-28 {
    margin-inline-end: 7rem !important;
  }
  .me-md-29 {
    margin-inline-end: 7.25rem !important;
  }
  .me-md-30 {
    margin-inline-end: 7.5rem !important;
  }
  .me-md-31 {
    margin-inline-end: 7.75rem !important;
  }
  .me-md-32 {
    margin-inline-end: 8rem !important;
  }
  .me-md-33 {
    margin-inline-end: 8.25rem !important;
  }
  .me-md-34 {
    margin-inline-end: 8.5rem !important;
  }
  .me-md-35 {
    margin-inline-end: 8.75rem !important;
  }
  .me-md-36 {
    margin-inline-end: 9rem !important;
  }
  .me-md-37 {
    margin-inline-end: 9.25rem !important;
  }
  .me-md-38 {
    margin-inline-end: 9.5rem !important;
  }
  .me-md-39 {
    margin-inline-end: 9.75rem !important;
  }
  .me-md-40 {
    margin-inline-end: 10rem !important;
  }
  .me-md-auto {
    margin-inline-end: auto !important;
  }
  .mb-md-0 {
    margin-block-end: 0 !important;
  }
  .mb-md-1 {
    margin-block-end: 0.25rem !important;
  }
  .mb-md-2 {
    margin-block-end: 0.5rem !important;
  }
  .mb-md-3 {
    margin-block-end: 0.75rem !important;
  }
  .mb-md-4 {
    margin-block-end: 1rem !important;
  }
  .mb-md-5 {
    margin-block-end: 1.25rem !important;
  }
  .mb-md-6 {
    margin-block-end: 1.5rem !important;
  }
  .mb-md-7 {
    margin-block-end: 1.75rem !important;
  }
  .mb-md-8 {
    margin-block-end: 2rem !important;
  }
  .mb-md-9 {
    margin-block-end: 2.25rem !important;
  }
  .mb-md-10 {
    margin-block-end: 2.5rem !important;
  }
  .mb-md-11 {
    margin-block-end: 2.75rem !important;
  }
  .mb-md-12 {
    margin-block-end: 3rem !important;
  }
  .mb-md-13 {
    margin-block-end: 3.25rem !important;
  }
  .mb-md-14 {
    margin-block-end: 3.5rem !important;
  }
  .mb-md-15 {
    margin-block-end: 3.75rem !important;
  }
  .mb-md-16 {
    margin-block-end: 4rem !important;
  }
  .mb-md-17 {
    margin-block-end: 4.25rem !important;
  }
  .mb-md-18 {
    margin-block-end: 4.5rem !important;
  }
  .mb-md-19 {
    margin-block-end: 4.75rem !important;
  }
  .mb-md-20 {
    margin-block-end: 5rem !important;
  }
  .mb-md-21 {
    margin-block-end: 5.25rem !important;
  }
  .mb-md-22 {
    margin-block-end: 5.5rem !important;
  }
  .mb-md-23 {
    margin-block-end: 5.75rem !important;
  }
  .mb-md-24 {
    margin-block-end: 6rem !important;
  }
  .mb-md-25 {
    margin-block-end: 6.25rem !important;
  }
  .mb-md-26 {
    margin-block-end: 6.5rem !important;
  }
  .mb-md-27 {
    margin-block-end: 6.75rem !important;
  }
  .mb-md-28 {
    margin-block-end: 7rem !important;
  }
  .mb-md-29 {
    margin-block-end: 7.25rem !important;
  }
  .mb-md-30 {
    margin-block-end: 7.5rem !important;
  }
  .mb-md-31 {
    margin-block-end: 7.75rem !important;
  }
  .mb-md-32 {
    margin-block-end: 8rem !important;
  }
  .mb-md-33 {
    margin-block-end: 8.25rem !important;
  }
  .mb-md-34 {
    margin-block-end: 8.5rem !important;
  }
  .mb-md-35 {
    margin-block-end: 8.75rem !important;
  }
  .mb-md-36 {
    margin-block-end: 9rem !important;
  }
  .mb-md-37 {
    margin-block-end: 9.25rem !important;
  }
  .mb-md-38 {
    margin-block-end: 9.5rem !important;
  }
  .mb-md-39 {
    margin-block-end: 9.75rem !important;
  }
  .mb-md-40 {
    margin-block-end: 10rem !important;
  }
  .mb-md-auto {
    margin-block-end: auto !important;
  }
  .ms-md-0 {
    margin-inline-start: 0 !important;
  }
  .ms-md-1 {
    margin-inline-start: 0.25rem !important;
  }
  .ms-md-2 {
    margin-inline-start: 0.5rem !important;
  }
  .ms-md-3 {
    margin-inline-start: 0.75rem !important;
  }
  .ms-md-4 {
    margin-inline-start: 1rem !important;
  }
  .ms-md-5 {
    margin-inline-start: 1.25rem !important;
  }
  .ms-md-6 {
    margin-inline-start: 1.5rem !important;
  }
  .ms-md-7 {
    margin-inline-start: 1.75rem !important;
  }
  .ms-md-8 {
    margin-inline-start: 2rem !important;
  }
  .ms-md-9 {
    margin-inline-start: 2.25rem !important;
  }
  .ms-md-10 {
    margin-inline-start: 2.5rem !important;
  }
  .ms-md-11 {
    margin-inline-start: 2.75rem !important;
  }
  .ms-md-12 {
    margin-inline-start: 3rem !important;
  }
  .ms-md-13 {
    margin-inline-start: 3.25rem !important;
  }
  .ms-md-14 {
    margin-inline-start: 3.5rem !important;
  }
  .ms-md-15 {
    margin-inline-start: 3.75rem !important;
  }
  .ms-md-16 {
    margin-inline-start: 4rem !important;
  }
  .ms-md-17 {
    margin-inline-start: 4.25rem !important;
  }
  .ms-md-18 {
    margin-inline-start: 4.5rem !important;
  }
  .ms-md-19 {
    margin-inline-start: 4.75rem !important;
  }
  .ms-md-20 {
    margin-inline-start: 5rem !important;
  }
  .ms-md-21 {
    margin-inline-start: 5.25rem !important;
  }
  .ms-md-22 {
    margin-inline-start: 5.5rem !important;
  }
  .ms-md-23 {
    margin-inline-start: 5.75rem !important;
  }
  .ms-md-24 {
    margin-inline-start: 6rem !important;
  }
  .ms-md-25 {
    margin-inline-start: 6.25rem !important;
  }
  .ms-md-26 {
    margin-inline-start: 6.5rem !important;
  }
  .ms-md-27 {
    margin-inline-start: 6.75rem !important;
  }
  .ms-md-28 {
    margin-inline-start: 7rem !important;
  }
  .ms-md-29 {
    margin-inline-start: 7.25rem !important;
  }
  .ms-md-30 {
    margin-inline-start: 7.5rem !important;
  }
  .ms-md-31 {
    margin-inline-start: 7.75rem !important;
  }
  .ms-md-32 {
    margin-inline-start: 8rem !important;
  }
  .ms-md-33 {
    margin-inline-start: 8.25rem !important;
  }
  .ms-md-34 {
    margin-inline-start: 8.5rem !important;
  }
  .ms-md-35 {
    margin-inline-start: 8.75rem !important;
  }
  .ms-md-36 {
    margin-inline-start: 9rem !important;
  }
  .ms-md-37 {
    margin-inline-start: 9.25rem !important;
  }
  .ms-md-38 {
    margin-inline-start: 9.5rem !important;
  }
  .ms-md-39 {
    margin-inline-start: 9.75rem !important;
  }
  .ms-md-40 {
    margin-inline-start: 10rem !important;
  }
  .ms-md-auto {
    margin-inline-start: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .p-md-5 {
    padding: 1.25rem !important;
  }
  .p-md-6 {
    padding: 1.5rem !important;
  }
  .p-md-7 {
    padding: 1.75rem !important;
  }
  .p-md-8 {
    padding: 2rem !important;
  }
  .p-md-9 {
    padding: 2.25rem !important;
  }
  .p-md-10 {
    padding: 2.5rem !important;
  }
  .p-md-11 {
    padding: 2.75rem !important;
  }
  .p-md-12 {
    padding: 3rem !important;
  }
  .p-md-13 {
    padding: 3.25rem !important;
  }
  .p-md-14 {
    padding: 3.5rem !important;
  }
  .p-md-15 {
    padding: 3.75rem !important;
  }
  .p-md-16 {
    padding: 4rem !important;
  }
  .p-md-17 {
    padding: 4.25rem !important;
  }
  .p-md-18 {
    padding: 4.5rem !important;
  }
  .p-md-19 {
    padding: 4.75rem !important;
  }
  .p-md-20 {
    padding: 5rem !important;
  }
  .p-md-21 {
    padding: 5.25rem !important;
  }
  .p-md-22 {
    padding: 5.5rem !important;
  }
  .p-md-23 {
    padding: 5.75rem !important;
  }
  .p-md-24 {
    padding: 6rem !important;
  }
  .p-md-25 {
    padding: 6.25rem !important;
  }
  .p-md-26 {
    padding: 6.5rem !important;
  }
  .p-md-27 {
    padding: 6.75rem !important;
  }
  .p-md-28 {
    padding: 7rem !important;
  }
  .p-md-29 {
    padding: 7.25rem !important;
  }
  .p-md-30 {
    padding: 7.5rem !important;
  }
  .p-md-31 {
    padding: 7.75rem !important;
  }
  .p-md-32 {
    padding: 8rem !important;
  }
  .p-md-33 {
    padding: 8.25rem !important;
  }
  .p-md-34 {
    padding: 8.5rem !important;
  }
  .p-md-35 {
    padding: 8.75rem !important;
  }
  .p-md-36 {
    padding: 9rem !important;
  }
  .p-md-37 {
    padding: 9.25rem !important;
  }
  .p-md-38 {
    padding: 9.5rem !important;
  }
  .p-md-39 {
    padding: 9.75rem !important;
  }
  .p-md-40 {
    padding: 10rem !important;
  }
  .px-md-0 {
    padding-inline: 0 !important;
  }
  .px-md-1 {
    padding-inline: 0.25rem !important;
  }
  .px-md-2 {
    padding-inline: 0.5rem !important;
  }
  .px-md-3 {
    padding-inline: 0.75rem !important;
  }
  .px-md-4 {
    padding-inline: 1rem !important;
  }
  .px-md-5 {
    padding-inline: 1.25rem !important;
  }
  .px-md-6 {
    padding-inline: 1.5rem !important;
  }
  .px-md-7 {
    padding-inline: 1.75rem !important;
  }
  .px-md-8 {
    padding-inline: 2rem !important;
  }
  .px-md-9 {
    padding-inline: 2.25rem !important;
  }
  .px-md-10 {
    padding-inline: 2.5rem !important;
  }
  .px-md-11 {
    padding-inline: 2.75rem !important;
  }
  .px-md-12 {
    padding-inline: 3rem !important;
  }
  .px-md-13 {
    padding-inline: 3.25rem !important;
  }
  .px-md-14 {
    padding-inline: 3.5rem !important;
  }
  .px-md-15 {
    padding-inline: 3.75rem !important;
  }
  .px-md-16 {
    padding-inline: 4rem !important;
  }
  .px-md-17 {
    padding-inline: 4.25rem !important;
  }
  .px-md-18 {
    padding-inline: 4.5rem !important;
  }
  .px-md-19 {
    padding-inline: 4.75rem !important;
  }
  .px-md-20 {
    padding-inline: 5rem !important;
  }
  .px-md-21 {
    padding-inline: 5.25rem !important;
  }
  .px-md-22 {
    padding-inline: 5.5rem !important;
  }
  .px-md-23 {
    padding-inline: 5.75rem !important;
  }
  .px-md-24 {
    padding-inline: 6rem !important;
  }
  .px-md-25 {
    padding-inline: 6.25rem !important;
  }
  .px-md-26 {
    padding-inline: 6.5rem !important;
  }
  .px-md-27 {
    padding-inline: 6.75rem !important;
  }
  .px-md-28 {
    padding-inline: 7rem !important;
  }
  .px-md-29 {
    padding-inline: 7.25rem !important;
  }
  .px-md-30 {
    padding-inline: 7.5rem !important;
  }
  .px-md-31 {
    padding-inline: 7.75rem !important;
  }
  .px-md-32 {
    padding-inline: 8rem !important;
  }
  .px-md-33 {
    padding-inline: 8.25rem !important;
  }
  .px-md-34 {
    padding-inline: 8.5rem !important;
  }
  .px-md-35 {
    padding-inline: 8.75rem !important;
  }
  .px-md-36 {
    padding-inline: 9rem !important;
  }
  .px-md-37 {
    padding-inline: 9.25rem !important;
  }
  .px-md-38 {
    padding-inline: 9.5rem !important;
  }
  .px-md-39 {
    padding-inline: 9.75rem !important;
  }
  .px-md-40 {
    padding-inline: 10rem !important;
  }
  .py-md-0 {
    padding-block: 0 !important;
  }
  .py-md-1 {
    padding-block: 0.25rem !important;
  }
  .py-md-2 {
    padding-block: 0.5rem !important;
  }
  .py-md-3 {
    padding-block: 0.75rem !important;
  }
  .py-md-4 {
    padding-block: 1rem !important;
  }
  .py-md-5 {
    padding-block: 1.25rem !important;
  }
  .py-md-6 {
    padding-block: 1.5rem !important;
  }
  .py-md-7 {
    padding-block: 1.75rem !important;
  }
  .py-md-8 {
    padding-block: 2rem !important;
  }
  .py-md-9 {
    padding-block: 2.25rem !important;
  }
  .py-md-10 {
    padding-block: 2.5rem !important;
  }
  .py-md-11 {
    padding-block: 2.75rem !important;
  }
  .py-md-12 {
    padding-block: 3rem !important;
  }
  .py-md-13 {
    padding-block: 3.25rem !important;
  }
  .py-md-14 {
    padding-block: 3.5rem !important;
  }
  .py-md-15 {
    padding-block: 3.75rem !important;
  }
  .py-md-16 {
    padding-block: 4rem !important;
  }
  .py-md-17 {
    padding-block: 4.25rem !important;
  }
  .py-md-18 {
    padding-block: 4.5rem !important;
  }
  .py-md-19 {
    padding-block: 4.75rem !important;
  }
  .py-md-20 {
    padding-block: 5rem !important;
  }
  .py-md-21 {
    padding-block: 5.25rem !important;
  }
  .py-md-22 {
    padding-block: 5.5rem !important;
  }
  .py-md-23 {
    padding-block: 5.75rem !important;
  }
  .py-md-24 {
    padding-block: 6rem !important;
  }
  .py-md-25 {
    padding-block: 6.25rem !important;
  }
  .py-md-26 {
    padding-block: 6.5rem !important;
  }
  .py-md-27 {
    padding-block: 6.75rem !important;
  }
  .py-md-28 {
    padding-block: 7rem !important;
  }
  .py-md-29 {
    padding-block: 7.25rem !important;
  }
  .py-md-30 {
    padding-block: 7.5rem !important;
  }
  .py-md-31 {
    padding-block: 7.75rem !important;
  }
  .py-md-32 {
    padding-block: 8rem !important;
  }
  .py-md-33 {
    padding-block: 8.25rem !important;
  }
  .py-md-34 {
    padding-block: 8.5rem !important;
  }
  .py-md-35 {
    padding-block: 8.75rem !important;
  }
  .py-md-36 {
    padding-block: 9rem !important;
  }
  .py-md-37 {
    padding-block: 9.25rem !important;
  }
  .py-md-38 {
    padding-block: 9.5rem !important;
  }
  .py-md-39 {
    padding-block: 9.75rem !important;
  }
  .py-md-40 {
    padding-block: 10rem !important;
  }
  .pt-md-0 {
    padding-block-start: 0 !important;
  }
  .pt-md-1 {
    padding-block-start: 0.25rem !important;
  }
  .pt-md-2 {
    padding-block-start: 0.5rem !important;
  }
  .pt-md-3 {
    padding-block-start: 0.75rem !important;
  }
  .pt-md-4 {
    padding-block-start: 1rem !important;
  }
  .pt-md-5 {
    padding-block-start: 1.25rem !important;
  }
  .pt-md-6 {
    padding-block-start: 1.5rem !important;
  }
  .pt-md-7 {
    padding-block-start: 1.75rem !important;
  }
  .pt-md-8 {
    padding-block-start: 2rem !important;
  }
  .pt-md-9 {
    padding-block-start: 2.25rem !important;
  }
  .pt-md-10 {
    padding-block-start: 2.5rem !important;
  }
  .pt-md-11 {
    padding-block-start: 2.75rem !important;
  }
  .pt-md-12 {
    padding-block-start: 3rem !important;
  }
  .pt-md-13 {
    padding-block-start: 3.25rem !important;
  }
  .pt-md-14 {
    padding-block-start: 3.5rem !important;
  }
  .pt-md-15 {
    padding-block-start: 3.75rem !important;
  }
  .pt-md-16 {
    padding-block-start: 4rem !important;
  }
  .pt-md-17 {
    padding-block-start: 4.25rem !important;
  }
  .pt-md-18 {
    padding-block-start: 4.5rem !important;
  }
  .pt-md-19 {
    padding-block-start: 4.75rem !important;
  }
  .pt-md-20 {
    padding-block-start: 5rem !important;
  }
  .pt-md-21 {
    padding-block-start: 5.25rem !important;
  }
  .pt-md-22 {
    padding-block-start: 5.5rem !important;
  }
  .pt-md-23 {
    padding-block-start: 5.75rem !important;
  }
  .pt-md-24 {
    padding-block-start: 6rem !important;
  }
  .pt-md-25 {
    padding-block-start: 6.25rem !important;
  }
  .pt-md-26 {
    padding-block-start: 6.5rem !important;
  }
  .pt-md-27 {
    padding-block-start: 6.75rem !important;
  }
  .pt-md-28 {
    padding-block-start: 7rem !important;
  }
  .pt-md-29 {
    padding-block-start: 7.25rem !important;
  }
  .pt-md-30 {
    padding-block-start: 7.5rem !important;
  }
  .pt-md-31 {
    padding-block-start: 7.75rem !important;
  }
  .pt-md-32 {
    padding-block-start: 8rem !important;
  }
  .pt-md-33 {
    padding-block-start: 8.25rem !important;
  }
  .pt-md-34 {
    padding-block-start: 8.5rem !important;
  }
  .pt-md-35 {
    padding-block-start: 8.75rem !important;
  }
  .pt-md-36 {
    padding-block-start: 9rem !important;
  }
  .pt-md-37 {
    padding-block-start: 9.25rem !important;
  }
  .pt-md-38 {
    padding-block-start: 9.5rem !important;
  }
  .pt-md-39 {
    padding-block-start: 9.75rem !important;
  }
  .pt-md-40 {
    padding-block-start: 10rem !important;
  }
  .pe-md-0 {
    padding-inline-end: 0 !important;
  }
  .pe-md-1 {
    padding-inline-end: 0.25rem !important;
  }
  .pe-md-2 {
    padding-inline-end: 0.5rem !important;
  }
  .pe-md-3 {
    padding-inline-end: 0.75rem !important;
  }
  .pe-md-4 {
    padding-inline-end: 1rem !important;
  }
  .pe-md-5 {
    padding-inline-end: 1.25rem !important;
  }
  .pe-md-6 {
    padding-inline-end: 1.5rem !important;
  }
  .pe-md-7 {
    padding-inline-end: 1.75rem !important;
  }
  .pe-md-8 {
    padding-inline-end: 2rem !important;
  }
  .pe-md-9 {
    padding-inline-end: 2.25rem !important;
  }
  .pe-md-10 {
    padding-inline-end: 2.5rem !important;
  }
  .pe-md-11 {
    padding-inline-end: 2.75rem !important;
  }
  .pe-md-12 {
    padding-inline-end: 3rem !important;
  }
  .pe-md-13 {
    padding-inline-end: 3.25rem !important;
  }
  .pe-md-14 {
    padding-inline-end: 3.5rem !important;
  }
  .pe-md-15 {
    padding-inline-end: 3.75rem !important;
  }
  .pe-md-16 {
    padding-inline-end: 4rem !important;
  }
  .pe-md-17 {
    padding-inline-end: 4.25rem !important;
  }
  .pe-md-18 {
    padding-inline-end: 4.5rem !important;
  }
  .pe-md-19 {
    padding-inline-end: 4.75rem !important;
  }
  .pe-md-20 {
    padding-inline-end: 5rem !important;
  }
  .pe-md-21 {
    padding-inline-end: 5.25rem !important;
  }
  .pe-md-22 {
    padding-inline-end: 5.5rem !important;
  }
  .pe-md-23 {
    padding-inline-end: 5.75rem !important;
  }
  .pe-md-24 {
    padding-inline-end: 6rem !important;
  }
  .pe-md-25 {
    padding-inline-end: 6.25rem !important;
  }
  .pe-md-26 {
    padding-inline-end: 6.5rem !important;
  }
  .pe-md-27 {
    padding-inline-end: 6.75rem !important;
  }
  .pe-md-28 {
    padding-inline-end: 7rem !important;
  }
  .pe-md-29 {
    padding-inline-end: 7.25rem !important;
  }
  .pe-md-30 {
    padding-inline-end: 7.5rem !important;
  }
  .pe-md-31 {
    padding-inline-end: 7.75rem !important;
  }
  .pe-md-32 {
    padding-inline-end: 8rem !important;
  }
  .pe-md-33 {
    padding-inline-end: 8.25rem !important;
  }
  .pe-md-34 {
    padding-inline-end: 8.5rem !important;
  }
  .pe-md-35 {
    padding-inline-end: 8.75rem !important;
  }
  .pe-md-36 {
    padding-inline-end: 9rem !important;
  }
  .pe-md-37 {
    padding-inline-end: 9.25rem !important;
  }
  .pe-md-38 {
    padding-inline-end: 9.5rem !important;
  }
  .pe-md-39 {
    padding-inline-end: 9.75rem !important;
  }
  .pe-md-40 {
    padding-inline-end: 10rem !important;
  }
  .pb-md-0 {
    padding-block-end: 0 !important;
  }
  .pb-md-1 {
    padding-block-end: 0.25rem !important;
  }
  .pb-md-2 {
    padding-block-end: 0.5rem !important;
  }
  .pb-md-3 {
    padding-block-end: 0.75rem !important;
  }
  .pb-md-4 {
    padding-block-end: 1rem !important;
  }
  .pb-md-5 {
    padding-block-end: 1.25rem !important;
  }
  .pb-md-6 {
    padding-block-end: 1.5rem !important;
  }
  .pb-md-7 {
    padding-block-end: 1.75rem !important;
  }
  .pb-md-8 {
    padding-block-end: 2rem !important;
  }
  .pb-md-9 {
    padding-block-end: 2.25rem !important;
  }
  .pb-md-10 {
    padding-block-end: 2.5rem !important;
  }
  .pb-md-11 {
    padding-block-end: 2.75rem !important;
  }
  .pb-md-12 {
    padding-block-end: 3rem !important;
  }
  .pb-md-13 {
    padding-block-end: 3.25rem !important;
  }
  .pb-md-14 {
    padding-block-end: 3.5rem !important;
  }
  .pb-md-15 {
    padding-block-end: 3.75rem !important;
  }
  .pb-md-16 {
    padding-block-end: 4rem !important;
  }
  .pb-md-17 {
    padding-block-end: 4.25rem !important;
  }
  .pb-md-18 {
    padding-block-end: 4.5rem !important;
  }
  .pb-md-19 {
    padding-block-end: 4.75rem !important;
  }
  .pb-md-20 {
    padding-block-end: 5rem !important;
  }
  .pb-md-21 {
    padding-block-end: 5.25rem !important;
  }
  .pb-md-22 {
    padding-block-end: 5.5rem !important;
  }
  .pb-md-23 {
    padding-block-end: 5.75rem !important;
  }
  .pb-md-24 {
    padding-block-end: 6rem !important;
  }
  .pb-md-25 {
    padding-block-end: 6.25rem !important;
  }
  .pb-md-26 {
    padding-block-end: 6.5rem !important;
  }
  .pb-md-27 {
    padding-block-end: 6.75rem !important;
  }
  .pb-md-28 {
    padding-block-end: 7rem !important;
  }
  .pb-md-29 {
    padding-block-end: 7.25rem !important;
  }
  .pb-md-30 {
    padding-block-end: 7.5rem !important;
  }
  .pb-md-31 {
    padding-block-end: 7.75rem !important;
  }
  .pb-md-32 {
    padding-block-end: 8rem !important;
  }
  .pb-md-33 {
    padding-block-end: 8.25rem !important;
  }
  .pb-md-34 {
    padding-block-end: 8.5rem !important;
  }
  .pb-md-35 {
    padding-block-end: 8.75rem !important;
  }
  .pb-md-36 {
    padding-block-end: 9rem !important;
  }
  .pb-md-37 {
    padding-block-end: 9.25rem !important;
  }
  .pb-md-38 {
    padding-block-end: 9.5rem !important;
  }
  .pb-md-39 {
    padding-block-end: 9.75rem !important;
  }
  .pb-md-40 {
    padding-block-end: 10rem !important;
  }
  .ps-md-0 {
    padding-inline-start: 0 !important;
  }
  .ps-md-1 {
    padding-inline-start: 0.25rem !important;
  }
  .ps-md-2 {
    padding-inline-start: 0.5rem !important;
  }
  .ps-md-3 {
    padding-inline-start: 0.75rem !important;
  }
  .ps-md-4 {
    padding-inline-start: 1rem !important;
  }
  .ps-md-5 {
    padding-inline-start: 1.25rem !important;
  }
  .ps-md-6 {
    padding-inline-start: 1.5rem !important;
  }
  .ps-md-7 {
    padding-inline-start: 1.75rem !important;
  }
  .ps-md-8 {
    padding-inline-start: 2rem !important;
  }
  .ps-md-9 {
    padding-inline-start: 2.25rem !important;
  }
  .ps-md-10 {
    padding-inline-start: 2.5rem !important;
  }
  .ps-md-11 {
    padding-inline-start: 2.75rem !important;
  }
  .ps-md-12 {
    padding-inline-start: 3rem !important;
  }
  .ps-md-13 {
    padding-inline-start: 3.25rem !important;
  }
  .ps-md-14 {
    padding-inline-start: 3.5rem !important;
  }
  .ps-md-15 {
    padding-inline-start: 3.75rem !important;
  }
  .ps-md-16 {
    padding-inline-start: 4rem !important;
  }
  .ps-md-17 {
    padding-inline-start: 4.25rem !important;
  }
  .ps-md-18 {
    padding-inline-start: 4.5rem !important;
  }
  .ps-md-19 {
    padding-inline-start: 4.75rem !important;
  }
  .ps-md-20 {
    padding-inline-start: 5rem !important;
  }
  .ps-md-21 {
    padding-inline-start: 5.25rem !important;
  }
  .ps-md-22 {
    padding-inline-start: 5.5rem !important;
  }
  .ps-md-23 {
    padding-inline-start: 5.75rem !important;
  }
  .ps-md-24 {
    padding-inline-start: 6rem !important;
  }
  .ps-md-25 {
    padding-inline-start: 6.25rem !important;
  }
  .ps-md-26 {
    padding-inline-start: 6.5rem !important;
  }
  .ps-md-27 {
    padding-inline-start: 6.75rem !important;
  }
  .ps-md-28 {
    padding-inline-start: 7rem !important;
  }
  .ps-md-29 {
    padding-inline-start: 7.25rem !important;
  }
  .ps-md-30 {
    padding-inline-start: 7.5rem !important;
  }
  .ps-md-31 {
    padding-inline-start: 7.75rem !important;
  }
  .ps-md-32 {
    padding-inline-start: 8rem !important;
  }
  .ps-md-33 {
    padding-inline-start: 8.25rem !important;
  }
  .ps-md-34 {
    padding-inline-start: 8.5rem !important;
  }
  .ps-md-35 {
    padding-inline-start: 8.75rem !important;
  }
  .ps-md-36 {
    padding-inline-start: 9rem !important;
  }
  .ps-md-37 {
    padding-inline-start: 9.25rem !important;
  }
  .ps-md-38 {
    padding-inline-start: 9.5rem !important;
  }
  .ps-md-39 {
    padding-inline-start: 9.75rem !important;
  }
  .ps-md-40 {
    padding-inline-start: 10rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 0.75rem !important;
  }
  .gap-md-4 {
    gap: 1rem !important;
  }
  .gap-md-5 {
    gap: 1.25rem !important;
  }
  .gap-md-6 {
    gap: 1.5rem !important;
  }
  .gap-md-7 {
    gap: 1.75rem !important;
  }
  .gap-md-8 {
    gap: 2rem !important;
  }
  .gap-md-9 {
    gap: 2.25rem !important;
  }
  .gap-md-10 {
    gap: 2.5rem !important;
  }
  .gap-md-11 {
    gap: 2.75rem !important;
  }
  .gap-md-12 {
    gap: 3rem !important;
  }
  .gap-md-13 {
    gap: 3.25rem !important;
  }
  .gap-md-14 {
    gap: 3.5rem !important;
  }
  .gap-md-15 {
    gap: 3.75rem !important;
  }
  .gap-md-16 {
    gap: 4rem !important;
  }
  .gap-md-17 {
    gap: 4.25rem !important;
  }
  .gap-md-18 {
    gap: 4.5rem !important;
  }
  .gap-md-19 {
    gap: 4.75rem !important;
  }
  .gap-md-20 {
    gap: 5rem !important;
  }
  .gap-md-21 {
    gap: 5.25rem !important;
  }
  .gap-md-22 {
    gap: 5.5rem !important;
  }
  .gap-md-23 {
    gap: 5.75rem !important;
  }
  .gap-md-24 {
    gap: 6rem !important;
  }
  .gap-md-25 {
    gap: 6.25rem !important;
  }
  .gap-md-26 {
    gap: 6.5rem !important;
  }
  .gap-md-27 {
    gap: 6.75rem !important;
  }
  .gap-md-28 {
    gap: 7rem !important;
  }
  .gap-md-29 {
    gap: 7.25rem !important;
  }
  .gap-md-30 {
    gap: 7.5rem !important;
  }
  .gap-md-31 {
    gap: 7.75rem !important;
  }
  .gap-md-32 {
    gap: 8rem !important;
  }
  .gap-md-33 {
    gap: 8.25rem !important;
  }
  .gap-md-34 {
    gap: 8.5rem !important;
  }
  .gap-md-35 {
    gap: 8.75rem !important;
  }
  .gap-md-36 {
    gap: 9rem !important;
  }
  .gap-md-37 {
    gap: 9.25rem !important;
  }
  .gap-md-38 {
    gap: 9.5rem !important;
  }
  .gap-md-39 {
    gap: 9.75rem !important;
  }
  .gap-md-40 {
    gap: 10rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1rem !important;
  }
  .row-gap-md-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-md-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-md-8 {
    row-gap: 2rem !important;
  }
  .row-gap-md-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-md-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-md-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-md-12 {
    row-gap: 3rem !important;
  }
  .row-gap-md-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-md-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-md-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-md-16 {
    row-gap: 4rem !important;
  }
  .row-gap-md-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-md-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-md-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-md-20 {
    row-gap: 5rem !important;
  }
  .row-gap-md-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-md-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-md-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-md-24 {
    row-gap: 6rem !important;
  }
  .row-gap-md-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-md-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-md-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-md-28 {
    row-gap: 7rem !important;
  }
  .row-gap-md-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-md-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-md-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-md-32 {
    row-gap: 8rem !important;
  }
  .row-gap-md-33 {
    row-gap: 8.25rem !important;
  }
  .row-gap-md-34 {
    row-gap: 8.5rem !important;
  }
  .row-gap-md-35 {
    row-gap: 8.75rem !important;
  }
  .row-gap-md-36 {
    row-gap: 9rem !important;
  }
  .row-gap-md-37 {
    row-gap: 9.25rem !important;
  }
  .row-gap-md-38 {
    row-gap: 9.5rem !important;
  }
  .row-gap-md-39 {
    row-gap: 9.75rem !important;
  }
  .row-gap-md-40 {
    row-gap: 10rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1rem !important;
  }
  .column-gap-md-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-md-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-md-8 {
    column-gap: 2rem !important;
  }
  .column-gap-md-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-md-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-md-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-md-12 {
    column-gap: 3rem !important;
  }
  .column-gap-md-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-md-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-md-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-md-16 {
    column-gap: 4rem !important;
  }
  .column-gap-md-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-md-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-md-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-md-20 {
    column-gap: 5rem !important;
  }
  .column-gap-md-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-md-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-md-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-md-24 {
    column-gap: 6rem !important;
  }
  .column-gap-md-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-md-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-md-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-md-28 {
    column-gap: 7rem !important;
  }
  .column-gap-md-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-md-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-md-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-md-32 {
    column-gap: 8rem !important;
  }
  .column-gap-md-33 {
    column-gap: 8.25rem !important;
  }
  .column-gap-md-34 {
    column-gap: 8.5rem !important;
  }
  .column-gap-md-35 {
    column-gap: 8.75rem !important;
  }
  .column-gap-md-36 {
    column-gap: 9rem !important;
  }
  .column-gap-md-37 {
    column-gap: 9.25rem !important;
  }
  .column-gap-md-38 {
    column-gap: 9.5rem !important;
  }
  .column-gap-md-39 {
    column-gap: 9.75rem !important;
  }
  .column-gap-md-40 {
    column-gap: 10rem !important;
  }
  .text-md-start {
    text-align: start !important;
  }
  .text-md-end {
    text-align: end !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .border-lg {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.25rem !important;
  }
  .m-lg-6 {
    margin: 1.5rem !important;
  }
  .m-lg-7 {
    margin: 1.75rem !important;
  }
  .m-lg-8 {
    margin: 2rem !important;
  }
  .m-lg-9 {
    margin: 2.25rem !important;
  }
  .m-lg-10 {
    margin: 2.5rem !important;
  }
  .m-lg-11 {
    margin: 2.75rem !important;
  }
  .m-lg-12 {
    margin: 3rem !important;
  }
  .m-lg-13 {
    margin: 3.25rem !important;
  }
  .m-lg-14 {
    margin: 3.5rem !important;
  }
  .m-lg-15 {
    margin: 3.75rem !important;
  }
  .m-lg-16 {
    margin: 4rem !important;
  }
  .m-lg-17 {
    margin: 4.25rem !important;
  }
  .m-lg-18 {
    margin: 4.5rem !important;
  }
  .m-lg-19 {
    margin: 4.75rem !important;
  }
  .m-lg-20 {
    margin: 5rem !important;
  }
  .m-lg-21 {
    margin: 5.25rem !important;
  }
  .m-lg-22 {
    margin: 5.5rem !important;
  }
  .m-lg-23 {
    margin: 5.75rem !important;
  }
  .m-lg-24 {
    margin: 6rem !important;
  }
  .m-lg-25 {
    margin: 6.25rem !important;
  }
  .m-lg-26 {
    margin: 6.5rem !important;
  }
  .m-lg-27 {
    margin: 6.75rem !important;
  }
  .m-lg-28 {
    margin: 7rem !important;
  }
  .m-lg-29 {
    margin: 7.25rem !important;
  }
  .m-lg-30 {
    margin: 7.5rem !important;
  }
  .m-lg-31 {
    margin: 7.75rem !important;
  }
  .m-lg-32 {
    margin: 8rem !important;
  }
  .m-lg-33 {
    margin: 8.25rem !important;
  }
  .m-lg-34 {
    margin: 8.5rem !important;
  }
  .m-lg-35 {
    margin: 8.75rem !important;
  }
  .m-lg-36 {
    margin: 9rem !important;
  }
  .m-lg-37 {
    margin: 9.25rem !important;
  }
  .m-lg-38 {
    margin: 9.5rem !important;
  }
  .m-lg-39 {
    margin: 9.75rem !important;
  }
  .m-lg-40 {
    margin: 10rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-inline: 0 !important;
  }
  .mx-lg-1 {
    margin-inline: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-inline: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-inline: 0.75rem !important;
  }
  .mx-lg-4 {
    margin-inline: 1rem !important;
  }
  .mx-lg-5 {
    margin-inline: 1.25rem !important;
  }
  .mx-lg-6 {
    margin-inline: 1.5rem !important;
  }
  .mx-lg-7 {
    margin-inline: 1.75rem !important;
  }
  .mx-lg-8 {
    margin-inline: 2rem !important;
  }
  .mx-lg-9 {
    margin-inline: 2.25rem !important;
  }
  .mx-lg-10 {
    margin-inline: 2.5rem !important;
  }
  .mx-lg-11 {
    margin-inline: 2.75rem !important;
  }
  .mx-lg-12 {
    margin-inline: 3rem !important;
  }
  .mx-lg-13 {
    margin-inline: 3.25rem !important;
  }
  .mx-lg-14 {
    margin-inline: 3.5rem !important;
  }
  .mx-lg-15 {
    margin-inline: 3.75rem !important;
  }
  .mx-lg-16 {
    margin-inline: 4rem !important;
  }
  .mx-lg-17 {
    margin-inline: 4.25rem !important;
  }
  .mx-lg-18 {
    margin-inline: 4.5rem !important;
  }
  .mx-lg-19 {
    margin-inline: 4.75rem !important;
  }
  .mx-lg-20 {
    margin-inline: 5rem !important;
  }
  .mx-lg-21 {
    margin-inline: 5.25rem !important;
  }
  .mx-lg-22 {
    margin-inline: 5.5rem !important;
  }
  .mx-lg-23 {
    margin-inline: 5.75rem !important;
  }
  .mx-lg-24 {
    margin-inline: 6rem !important;
  }
  .mx-lg-25 {
    margin-inline: 6.25rem !important;
  }
  .mx-lg-26 {
    margin-inline: 6.5rem !important;
  }
  .mx-lg-27 {
    margin-inline: 6.75rem !important;
  }
  .mx-lg-28 {
    margin-inline: 7rem !important;
  }
  .mx-lg-29 {
    margin-inline: 7.25rem !important;
  }
  .mx-lg-30 {
    margin-inline: 7.5rem !important;
  }
  .mx-lg-31 {
    margin-inline: 7.75rem !important;
  }
  .mx-lg-32 {
    margin-inline: 8rem !important;
  }
  .mx-lg-33 {
    margin-inline: 8.25rem !important;
  }
  .mx-lg-34 {
    margin-inline: 8.5rem !important;
  }
  .mx-lg-35 {
    margin-inline: 8.75rem !important;
  }
  .mx-lg-36 {
    margin-inline: 9rem !important;
  }
  .mx-lg-37 {
    margin-inline: 9.25rem !important;
  }
  .mx-lg-38 {
    margin-inline: 9.5rem !important;
  }
  .mx-lg-39 {
    margin-inline: 9.75rem !important;
  }
  .mx-lg-40 {
    margin-inline: 10rem !important;
  }
  .mx-lg-auto {
    margin-inline: auto !important;
  }
  .my-lg-0 {
    margin-block: 0 !important;
  }
  .my-lg-1 {
    margin-block: 0.25rem !important;
  }
  .my-lg-2 {
    margin-block: 0.5rem !important;
  }
  .my-lg-3 {
    margin-block: 0.75rem !important;
  }
  .my-lg-4 {
    margin-block: 1rem !important;
  }
  .my-lg-5 {
    margin-block: 1.25rem !important;
  }
  .my-lg-6 {
    margin-block: 1.5rem !important;
  }
  .my-lg-7 {
    margin-block: 1.75rem !important;
  }
  .my-lg-8 {
    margin-block: 2rem !important;
  }
  .my-lg-9 {
    margin-block: 2.25rem !important;
  }
  .my-lg-10 {
    margin-block: 2.5rem !important;
  }
  .my-lg-11 {
    margin-block: 2.75rem !important;
  }
  .my-lg-12 {
    margin-block: 3rem !important;
  }
  .my-lg-13 {
    margin-block: 3.25rem !important;
  }
  .my-lg-14 {
    margin-block: 3.5rem !important;
  }
  .my-lg-15 {
    margin-block: 3.75rem !important;
  }
  .my-lg-16 {
    margin-block: 4rem !important;
  }
  .my-lg-17 {
    margin-block: 4.25rem !important;
  }
  .my-lg-18 {
    margin-block: 4.5rem !important;
  }
  .my-lg-19 {
    margin-block: 4.75rem !important;
  }
  .my-lg-20 {
    margin-block: 5rem !important;
  }
  .my-lg-21 {
    margin-block: 5.25rem !important;
  }
  .my-lg-22 {
    margin-block: 5.5rem !important;
  }
  .my-lg-23 {
    margin-block: 5.75rem !important;
  }
  .my-lg-24 {
    margin-block: 6rem !important;
  }
  .my-lg-25 {
    margin-block: 6.25rem !important;
  }
  .my-lg-26 {
    margin-block: 6.5rem !important;
  }
  .my-lg-27 {
    margin-block: 6.75rem !important;
  }
  .my-lg-28 {
    margin-block: 7rem !important;
  }
  .my-lg-29 {
    margin-block: 7.25rem !important;
  }
  .my-lg-30 {
    margin-block: 7.5rem !important;
  }
  .my-lg-31 {
    margin-block: 7.75rem !important;
  }
  .my-lg-32 {
    margin-block: 8rem !important;
  }
  .my-lg-33 {
    margin-block: 8.25rem !important;
  }
  .my-lg-34 {
    margin-block: 8.5rem !important;
  }
  .my-lg-35 {
    margin-block: 8.75rem !important;
  }
  .my-lg-36 {
    margin-block: 9rem !important;
  }
  .my-lg-37 {
    margin-block: 9.25rem !important;
  }
  .my-lg-38 {
    margin-block: 9.5rem !important;
  }
  .my-lg-39 {
    margin-block: 9.75rem !important;
  }
  .my-lg-40 {
    margin-block: 10rem !important;
  }
  .my-lg-auto {
    margin-block: auto !important;
  }
  .mt-lg-0 {
    margin-block-start: 0 !important;
  }
  .mt-lg-1 {
    margin-block-start: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-block-start: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-block-start: 0.75rem !important;
  }
  .mt-lg-4 {
    margin-block-start: 1rem !important;
  }
  .mt-lg-5 {
    margin-block-start: 1.25rem !important;
  }
  .mt-lg-6 {
    margin-block-start: 1.5rem !important;
  }
  .mt-lg-7 {
    margin-block-start: 1.75rem !important;
  }
  .mt-lg-8 {
    margin-block-start: 2rem !important;
  }
  .mt-lg-9 {
    margin-block-start: 2.25rem !important;
  }
  .mt-lg-10 {
    margin-block-start: 2.5rem !important;
  }
  .mt-lg-11 {
    margin-block-start: 2.75rem !important;
  }
  .mt-lg-12 {
    margin-block-start: 3rem !important;
  }
  .mt-lg-13 {
    margin-block-start: 3.25rem !important;
  }
  .mt-lg-14 {
    margin-block-start: 3.5rem !important;
  }
  .mt-lg-15 {
    margin-block-start: 3.75rem !important;
  }
  .mt-lg-16 {
    margin-block-start: 4rem !important;
  }
  .mt-lg-17 {
    margin-block-start: 4.25rem !important;
  }
  .mt-lg-18 {
    margin-block-start: 4.5rem !important;
  }
  .mt-lg-19 {
    margin-block-start: 4.75rem !important;
  }
  .mt-lg-20 {
    margin-block-start: 5rem !important;
  }
  .mt-lg-21 {
    margin-block-start: 5.25rem !important;
  }
  .mt-lg-22 {
    margin-block-start: 5.5rem !important;
  }
  .mt-lg-23 {
    margin-block-start: 5.75rem !important;
  }
  .mt-lg-24 {
    margin-block-start: 6rem !important;
  }
  .mt-lg-25 {
    margin-block-start: 6.25rem !important;
  }
  .mt-lg-26 {
    margin-block-start: 6.5rem !important;
  }
  .mt-lg-27 {
    margin-block-start: 6.75rem !important;
  }
  .mt-lg-28 {
    margin-block-start: 7rem !important;
  }
  .mt-lg-29 {
    margin-block-start: 7.25rem !important;
  }
  .mt-lg-30 {
    margin-block-start: 7.5rem !important;
  }
  .mt-lg-31 {
    margin-block-start: 7.75rem !important;
  }
  .mt-lg-32 {
    margin-block-start: 8rem !important;
  }
  .mt-lg-33 {
    margin-block-start: 8.25rem !important;
  }
  .mt-lg-34 {
    margin-block-start: 8.5rem !important;
  }
  .mt-lg-35 {
    margin-block-start: 8.75rem !important;
  }
  .mt-lg-36 {
    margin-block-start: 9rem !important;
  }
  .mt-lg-37 {
    margin-block-start: 9.25rem !important;
  }
  .mt-lg-38 {
    margin-block-start: 9.5rem !important;
  }
  .mt-lg-39 {
    margin-block-start: 9.75rem !important;
  }
  .mt-lg-40 {
    margin-block-start: 10rem !important;
  }
  .mt-lg-auto {
    margin-block-start: auto !important;
  }
  .me-lg-0 {
    margin-inline-end: 0 !important;
  }
  .me-lg-1 {
    margin-inline-end: 0.25rem !important;
  }
  .me-lg-2 {
    margin-inline-end: 0.5rem !important;
  }
  .me-lg-3 {
    margin-inline-end: 0.75rem !important;
  }
  .me-lg-4 {
    margin-inline-end: 1rem !important;
  }
  .me-lg-5 {
    margin-inline-end: 1.25rem !important;
  }
  .me-lg-6 {
    margin-inline-end: 1.5rem !important;
  }
  .me-lg-7 {
    margin-inline-end: 1.75rem !important;
  }
  .me-lg-8 {
    margin-inline-end: 2rem !important;
  }
  .me-lg-9 {
    margin-inline-end: 2.25rem !important;
  }
  .me-lg-10 {
    margin-inline-end: 2.5rem !important;
  }
  .me-lg-11 {
    margin-inline-end: 2.75rem !important;
  }
  .me-lg-12 {
    margin-inline-end: 3rem !important;
  }
  .me-lg-13 {
    margin-inline-end: 3.25rem !important;
  }
  .me-lg-14 {
    margin-inline-end: 3.5rem !important;
  }
  .me-lg-15 {
    margin-inline-end: 3.75rem !important;
  }
  .me-lg-16 {
    margin-inline-end: 4rem !important;
  }
  .me-lg-17 {
    margin-inline-end: 4.25rem !important;
  }
  .me-lg-18 {
    margin-inline-end: 4.5rem !important;
  }
  .me-lg-19 {
    margin-inline-end: 4.75rem !important;
  }
  .me-lg-20 {
    margin-inline-end: 5rem !important;
  }
  .me-lg-21 {
    margin-inline-end: 5.25rem !important;
  }
  .me-lg-22 {
    margin-inline-end: 5.5rem !important;
  }
  .me-lg-23 {
    margin-inline-end: 5.75rem !important;
  }
  .me-lg-24 {
    margin-inline-end: 6rem !important;
  }
  .me-lg-25 {
    margin-inline-end: 6.25rem !important;
  }
  .me-lg-26 {
    margin-inline-end: 6.5rem !important;
  }
  .me-lg-27 {
    margin-inline-end: 6.75rem !important;
  }
  .me-lg-28 {
    margin-inline-end: 7rem !important;
  }
  .me-lg-29 {
    margin-inline-end: 7.25rem !important;
  }
  .me-lg-30 {
    margin-inline-end: 7.5rem !important;
  }
  .me-lg-31 {
    margin-inline-end: 7.75rem !important;
  }
  .me-lg-32 {
    margin-inline-end: 8rem !important;
  }
  .me-lg-33 {
    margin-inline-end: 8.25rem !important;
  }
  .me-lg-34 {
    margin-inline-end: 8.5rem !important;
  }
  .me-lg-35 {
    margin-inline-end: 8.75rem !important;
  }
  .me-lg-36 {
    margin-inline-end: 9rem !important;
  }
  .me-lg-37 {
    margin-inline-end: 9.25rem !important;
  }
  .me-lg-38 {
    margin-inline-end: 9.5rem !important;
  }
  .me-lg-39 {
    margin-inline-end: 9.75rem !important;
  }
  .me-lg-40 {
    margin-inline-end: 10rem !important;
  }
  .me-lg-auto {
    margin-inline-end: auto !important;
  }
  .mb-lg-0 {
    margin-block-end: 0 !important;
  }
  .mb-lg-1 {
    margin-block-end: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-block-end: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-block-end: 0.75rem !important;
  }
  .mb-lg-4 {
    margin-block-end: 1rem !important;
  }
  .mb-lg-5 {
    margin-block-end: 1.25rem !important;
  }
  .mb-lg-6 {
    margin-block-end: 1.5rem !important;
  }
  .mb-lg-7 {
    margin-block-end: 1.75rem !important;
  }
  .mb-lg-8 {
    margin-block-end: 2rem !important;
  }
  .mb-lg-9 {
    margin-block-end: 2.25rem !important;
  }
  .mb-lg-10 {
    margin-block-end: 2.5rem !important;
  }
  .mb-lg-11 {
    margin-block-end: 2.75rem !important;
  }
  .mb-lg-12 {
    margin-block-end: 3rem !important;
  }
  .mb-lg-13 {
    margin-block-end: 3.25rem !important;
  }
  .mb-lg-14 {
    margin-block-end: 3.5rem !important;
  }
  .mb-lg-15 {
    margin-block-end: 3.75rem !important;
  }
  .mb-lg-16 {
    margin-block-end: 4rem !important;
  }
  .mb-lg-17 {
    margin-block-end: 4.25rem !important;
  }
  .mb-lg-18 {
    margin-block-end: 4.5rem !important;
  }
  .mb-lg-19 {
    margin-block-end: 4.75rem !important;
  }
  .mb-lg-20 {
    margin-block-end: 5rem !important;
  }
  .mb-lg-21 {
    margin-block-end: 5.25rem !important;
  }
  .mb-lg-22 {
    margin-block-end: 5.5rem !important;
  }
  .mb-lg-23 {
    margin-block-end: 5.75rem !important;
  }
  .mb-lg-24 {
    margin-block-end: 6rem !important;
  }
  .mb-lg-25 {
    margin-block-end: 6.25rem !important;
  }
  .mb-lg-26 {
    margin-block-end: 6.5rem !important;
  }
  .mb-lg-27 {
    margin-block-end: 6.75rem !important;
  }
  .mb-lg-28 {
    margin-block-end: 7rem !important;
  }
  .mb-lg-29 {
    margin-block-end: 7.25rem !important;
  }
  .mb-lg-30 {
    margin-block-end: 7.5rem !important;
  }
  .mb-lg-31 {
    margin-block-end: 7.75rem !important;
  }
  .mb-lg-32 {
    margin-block-end: 8rem !important;
  }
  .mb-lg-33 {
    margin-block-end: 8.25rem !important;
  }
  .mb-lg-34 {
    margin-block-end: 8.5rem !important;
  }
  .mb-lg-35 {
    margin-block-end: 8.75rem !important;
  }
  .mb-lg-36 {
    margin-block-end: 9rem !important;
  }
  .mb-lg-37 {
    margin-block-end: 9.25rem !important;
  }
  .mb-lg-38 {
    margin-block-end: 9.5rem !important;
  }
  .mb-lg-39 {
    margin-block-end: 9.75rem !important;
  }
  .mb-lg-40 {
    margin-block-end: 10rem !important;
  }
  .mb-lg-auto {
    margin-block-end: auto !important;
  }
  .ms-lg-0 {
    margin-inline-start: 0 !important;
  }
  .ms-lg-1 {
    margin-inline-start: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-inline-start: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-inline-start: 0.75rem !important;
  }
  .ms-lg-4 {
    margin-inline-start: 1rem !important;
  }
  .ms-lg-5 {
    margin-inline-start: 1.25rem !important;
  }
  .ms-lg-6 {
    margin-inline-start: 1.5rem !important;
  }
  .ms-lg-7 {
    margin-inline-start: 1.75rem !important;
  }
  .ms-lg-8 {
    margin-inline-start: 2rem !important;
  }
  .ms-lg-9 {
    margin-inline-start: 2.25rem !important;
  }
  .ms-lg-10 {
    margin-inline-start: 2.5rem !important;
  }
  .ms-lg-11 {
    margin-inline-start: 2.75rem !important;
  }
  .ms-lg-12 {
    margin-inline-start: 3rem !important;
  }
  .ms-lg-13 {
    margin-inline-start: 3.25rem !important;
  }
  .ms-lg-14 {
    margin-inline-start: 3.5rem !important;
  }
  .ms-lg-15 {
    margin-inline-start: 3.75rem !important;
  }
  .ms-lg-16 {
    margin-inline-start: 4rem !important;
  }
  .ms-lg-17 {
    margin-inline-start: 4.25rem !important;
  }
  .ms-lg-18 {
    margin-inline-start: 4.5rem !important;
  }
  .ms-lg-19 {
    margin-inline-start: 4.75rem !important;
  }
  .ms-lg-20 {
    margin-inline-start: 5rem !important;
  }
  .ms-lg-21 {
    margin-inline-start: 5.25rem !important;
  }
  .ms-lg-22 {
    margin-inline-start: 5.5rem !important;
  }
  .ms-lg-23 {
    margin-inline-start: 5.75rem !important;
  }
  .ms-lg-24 {
    margin-inline-start: 6rem !important;
  }
  .ms-lg-25 {
    margin-inline-start: 6.25rem !important;
  }
  .ms-lg-26 {
    margin-inline-start: 6.5rem !important;
  }
  .ms-lg-27 {
    margin-inline-start: 6.75rem !important;
  }
  .ms-lg-28 {
    margin-inline-start: 7rem !important;
  }
  .ms-lg-29 {
    margin-inline-start: 7.25rem !important;
  }
  .ms-lg-30 {
    margin-inline-start: 7.5rem !important;
  }
  .ms-lg-31 {
    margin-inline-start: 7.75rem !important;
  }
  .ms-lg-32 {
    margin-inline-start: 8rem !important;
  }
  .ms-lg-33 {
    margin-inline-start: 8.25rem !important;
  }
  .ms-lg-34 {
    margin-inline-start: 8.5rem !important;
  }
  .ms-lg-35 {
    margin-inline-start: 8.75rem !important;
  }
  .ms-lg-36 {
    margin-inline-start: 9rem !important;
  }
  .ms-lg-37 {
    margin-inline-start: 9.25rem !important;
  }
  .ms-lg-38 {
    margin-inline-start: 9.5rem !important;
  }
  .ms-lg-39 {
    margin-inline-start: 9.75rem !important;
  }
  .ms-lg-40 {
    margin-inline-start: 10rem !important;
  }
  .ms-lg-auto {
    margin-inline-start: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.25rem !important;
  }
  .p-lg-6 {
    padding: 1.5rem !important;
  }
  .p-lg-7 {
    padding: 1.75rem !important;
  }
  .p-lg-8 {
    padding: 2rem !important;
  }
  .p-lg-9 {
    padding: 2.25rem !important;
  }
  .p-lg-10 {
    padding: 2.5rem !important;
  }
  .p-lg-11 {
    padding: 2.75rem !important;
  }
  .p-lg-12 {
    padding: 3rem !important;
  }
  .p-lg-13 {
    padding: 3.25rem !important;
  }
  .p-lg-14 {
    padding: 3.5rem !important;
  }
  .p-lg-15 {
    padding: 3.75rem !important;
  }
  .p-lg-16 {
    padding: 4rem !important;
  }
  .p-lg-17 {
    padding: 4.25rem !important;
  }
  .p-lg-18 {
    padding: 4.5rem !important;
  }
  .p-lg-19 {
    padding: 4.75rem !important;
  }
  .p-lg-20 {
    padding: 5rem !important;
  }
  .p-lg-21 {
    padding: 5.25rem !important;
  }
  .p-lg-22 {
    padding: 5.5rem !important;
  }
  .p-lg-23 {
    padding: 5.75rem !important;
  }
  .p-lg-24 {
    padding: 6rem !important;
  }
  .p-lg-25 {
    padding: 6.25rem !important;
  }
  .p-lg-26 {
    padding: 6.5rem !important;
  }
  .p-lg-27 {
    padding: 6.75rem !important;
  }
  .p-lg-28 {
    padding: 7rem !important;
  }
  .p-lg-29 {
    padding: 7.25rem !important;
  }
  .p-lg-30 {
    padding: 7.5rem !important;
  }
  .p-lg-31 {
    padding: 7.75rem !important;
  }
  .p-lg-32 {
    padding: 8rem !important;
  }
  .p-lg-33 {
    padding: 8.25rem !important;
  }
  .p-lg-34 {
    padding: 8.5rem !important;
  }
  .p-lg-35 {
    padding: 8.75rem !important;
  }
  .p-lg-36 {
    padding: 9rem !important;
  }
  .p-lg-37 {
    padding: 9.25rem !important;
  }
  .p-lg-38 {
    padding: 9.5rem !important;
  }
  .p-lg-39 {
    padding: 9.75rem !important;
  }
  .p-lg-40 {
    padding: 10rem !important;
  }
  .px-lg-0 {
    padding-inline: 0 !important;
  }
  .px-lg-1 {
    padding-inline: 0.25rem !important;
  }
  .px-lg-2 {
    padding-inline: 0.5rem !important;
  }
  .px-lg-3 {
    padding-inline: 0.75rem !important;
  }
  .px-lg-4 {
    padding-inline: 1rem !important;
  }
  .px-lg-5 {
    padding-inline: 1.25rem !important;
  }
  .px-lg-6 {
    padding-inline: 1.5rem !important;
  }
  .px-lg-7 {
    padding-inline: 1.75rem !important;
  }
  .px-lg-8 {
    padding-inline: 2rem !important;
  }
  .px-lg-9 {
    padding-inline: 2.25rem !important;
  }
  .px-lg-10 {
    padding-inline: 2.5rem !important;
  }
  .px-lg-11 {
    padding-inline: 2.75rem !important;
  }
  .px-lg-12 {
    padding-inline: 3rem !important;
  }
  .px-lg-13 {
    padding-inline: 3.25rem !important;
  }
  .px-lg-14 {
    padding-inline: 3.5rem !important;
  }
  .px-lg-15 {
    padding-inline: 3.75rem !important;
  }
  .px-lg-16 {
    padding-inline: 4rem !important;
  }
  .px-lg-17 {
    padding-inline: 4.25rem !important;
  }
  .px-lg-18 {
    padding-inline: 4.5rem !important;
  }
  .px-lg-19 {
    padding-inline: 4.75rem !important;
  }
  .px-lg-20 {
    padding-inline: 5rem !important;
  }
  .px-lg-21 {
    padding-inline: 5.25rem !important;
  }
  .px-lg-22 {
    padding-inline: 5.5rem !important;
  }
  .px-lg-23 {
    padding-inline: 5.75rem !important;
  }
  .px-lg-24 {
    padding-inline: 6rem !important;
  }
  .px-lg-25 {
    padding-inline: 6.25rem !important;
  }
  .px-lg-26 {
    padding-inline: 6.5rem !important;
  }
  .px-lg-27 {
    padding-inline: 6.75rem !important;
  }
  .px-lg-28 {
    padding-inline: 7rem !important;
  }
  .px-lg-29 {
    padding-inline: 7.25rem !important;
  }
  .px-lg-30 {
    padding-inline: 7.5rem !important;
  }
  .px-lg-31 {
    padding-inline: 7.75rem !important;
  }
  .px-lg-32 {
    padding-inline: 8rem !important;
  }
  .px-lg-33 {
    padding-inline: 8.25rem !important;
  }
  .px-lg-34 {
    padding-inline: 8.5rem !important;
  }
  .px-lg-35 {
    padding-inline: 8.75rem !important;
  }
  .px-lg-36 {
    padding-inline: 9rem !important;
  }
  .px-lg-37 {
    padding-inline: 9.25rem !important;
  }
  .px-lg-38 {
    padding-inline: 9.5rem !important;
  }
  .px-lg-39 {
    padding-inline: 9.75rem !important;
  }
  .px-lg-40 {
    padding-inline: 10rem !important;
  }
  .py-lg-0 {
    padding-block: 0 !important;
  }
  .py-lg-1 {
    padding-block: 0.25rem !important;
  }
  .py-lg-2 {
    padding-block: 0.5rem !important;
  }
  .py-lg-3 {
    padding-block: 0.75rem !important;
  }
  .py-lg-4 {
    padding-block: 1rem !important;
  }
  .py-lg-5 {
    padding-block: 1.25rem !important;
  }
  .py-lg-6 {
    padding-block: 1.5rem !important;
  }
  .py-lg-7 {
    padding-block: 1.75rem !important;
  }
  .py-lg-8 {
    padding-block: 2rem !important;
  }
  .py-lg-9 {
    padding-block: 2.25rem !important;
  }
  .py-lg-10 {
    padding-block: 2.5rem !important;
  }
  .py-lg-11 {
    padding-block: 2.75rem !important;
  }
  .py-lg-12 {
    padding-block: 3rem !important;
  }
  .py-lg-13 {
    padding-block: 3.25rem !important;
  }
  .py-lg-14 {
    padding-block: 3.5rem !important;
  }
  .py-lg-15 {
    padding-block: 3.75rem !important;
  }
  .py-lg-16 {
    padding-block: 4rem !important;
  }
  .py-lg-17 {
    padding-block: 4.25rem !important;
  }
  .py-lg-18 {
    padding-block: 4.5rem !important;
  }
  .py-lg-19 {
    padding-block: 4.75rem !important;
  }
  .py-lg-20 {
    padding-block: 5rem !important;
  }
  .py-lg-21 {
    padding-block: 5.25rem !important;
  }
  .py-lg-22 {
    padding-block: 5.5rem !important;
  }
  .py-lg-23 {
    padding-block: 5.75rem !important;
  }
  .py-lg-24 {
    padding-block: 6rem !important;
  }
  .py-lg-25 {
    padding-block: 6.25rem !important;
  }
  .py-lg-26 {
    padding-block: 6.5rem !important;
  }
  .py-lg-27 {
    padding-block: 6.75rem !important;
  }
  .py-lg-28 {
    padding-block: 7rem !important;
  }
  .py-lg-29 {
    padding-block: 7.25rem !important;
  }
  .py-lg-30 {
    padding-block: 7.5rem !important;
  }
  .py-lg-31 {
    padding-block: 7.75rem !important;
  }
  .py-lg-32 {
    padding-block: 8rem !important;
  }
  .py-lg-33 {
    padding-block: 8.25rem !important;
  }
  .py-lg-34 {
    padding-block: 8.5rem !important;
  }
  .py-lg-35 {
    padding-block: 8.75rem !important;
  }
  .py-lg-36 {
    padding-block: 9rem !important;
  }
  .py-lg-37 {
    padding-block: 9.25rem !important;
  }
  .py-lg-38 {
    padding-block: 9.5rem !important;
  }
  .py-lg-39 {
    padding-block: 9.75rem !important;
  }
  .py-lg-40 {
    padding-block: 10rem !important;
  }
  .pt-lg-0 {
    padding-block-start: 0 !important;
  }
  .pt-lg-1 {
    padding-block-start: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-block-start: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-block-start: 0.75rem !important;
  }
  .pt-lg-4 {
    padding-block-start: 1rem !important;
  }
  .pt-lg-5 {
    padding-block-start: 1.25rem !important;
  }
  .pt-lg-6 {
    padding-block-start: 1.5rem !important;
  }
  .pt-lg-7 {
    padding-block-start: 1.75rem !important;
  }
  .pt-lg-8 {
    padding-block-start: 2rem !important;
  }
  .pt-lg-9 {
    padding-block-start: 2.25rem !important;
  }
  .pt-lg-10 {
    padding-block-start: 2.5rem !important;
  }
  .pt-lg-11 {
    padding-block-start: 2.75rem !important;
  }
  .pt-lg-12 {
    padding-block-start: 3rem !important;
  }
  .pt-lg-13 {
    padding-block-start: 3.25rem !important;
  }
  .pt-lg-14 {
    padding-block-start: 3.5rem !important;
  }
  .pt-lg-15 {
    padding-block-start: 3.75rem !important;
  }
  .pt-lg-16 {
    padding-block-start: 4rem !important;
  }
  .pt-lg-17 {
    padding-block-start: 4.25rem !important;
  }
  .pt-lg-18 {
    padding-block-start: 4.5rem !important;
  }
  .pt-lg-19 {
    padding-block-start: 4.75rem !important;
  }
  .pt-lg-20 {
    padding-block-start: 5rem !important;
  }
  .pt-lg-21 {
    padding-block-start: 5.25rem !important;
  }
  .pt-lg-22 {
    padding-block-start: 5.5rem !important;
  }
  .pt-lg-23 {
    padding-block-start: 5.75rem !important;
  }
  .pt-lg-24 {
    padding-block-start: 6rem !important;
  }
  .pt-lg-25 {
    padding-block-start: 6.25rem !important;
  }
  .pt-lg-26 {
    padding-block-start: 6.5rem !important;
  }
  .pt-lg-27 {
    padding-block-start: 6.75rem !important;
  }
  .pt-lg-28 {
    padding-block-start: 7rem !important;
  }
  .pt-lg-29 {
    padding-block-start: 7.25rem !important;
  }
  .pt-lg-30 {
    padding-block-start: 7.5rem !important;
  }
  .pt-lg-31 {
    padding-block-start: 7.75rem !important;
  }
  .pt-lg-32 {
    padding-block-start: 8rem !important;
  }
  .pt-lg-33 {
    padding-block-start: 8.25rem !important;
  }
  .pt-lg-34 {
    padding-block-start: 8.5rem !important;
  }
  .pt-lg-35 {
    padding-block-start: 8.75rem !important;
  }
  .pt-lg-36 {
    padding-block-start: 9rem !important;
  }
  .pt-lg-37 {
    padding-block-start: 9.25rem !important;
  }
  .pt-lg-38 {
    padding-block-start: 9.5rem !important;
  }
  .pt-lg-39 {
    padding-block-start: 9.75rem !important;
  }
  .pt-lg-40 {
    padding-block-start: 10rem !important;
  }
  .pe-lg-0 {
    padding-inline-end: 0 !important;
  }
  .pe-lg-1 {
    padding-inline-end: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-inline-end: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-inline-end: 0.75rem !important;
  }
  .pe-lg-4 {
    padding-inline-end: 1rem !important;
  }
  .pe-lg-5 {
    padding-inline-end: 1.25rem !important;
  }
  .pe-lg-6 {
    padding-inline-end: 1.5rem !important;
  }
  .pe-lg-7 {
    padding-inline-end: 1.75rem !important;
  }
  .pe-lg-8 {
    padding-inline-end: 2rem !important;
  }
  .pe-lg-9 {
    padding-inline-end: 2.25rem !important;
  }
  .pe-lg-10 {
    padding-inline-end: 2.5rem !important;
  }
  .pe-lg-11 {
    padding-inline-end: 2.75rem !important;
  }
  .pe-lg-12 {
    padding-inline-end: 3rem !important;
  }
  .pe-lg-13 {
    padding-inline-end: 3.25rem !important;
  }
  .pe-lg-14 {
    padding-inline-end: 3.5rem !important;
  }
  .pe-lg-15 {
    padding-inline-end: 3.75rem !important;
  }
  .pe-lg-16 {
    padding-inline-end: 4rem !important;
  }
  .pe-lg-17 {
    padding-inline-end: 4.25rem !important;
  }
  .pe-lg-18 {
    padding-inline-end: 4.5rem !important;
  }
  .pe-lg-19 {
    padding-inline-end: 4.75rem !important;
  }
  .pe-lg-20 {
    padding-inline-end: 5rem !important;
  }
  .pe-lg-21 {
    padding-inline-end: 5.25rem !important;
  }
  .pe-lg-22 {
    padding-inline-end: 5.5rem !important;
  }
  .pe-lg-23 {
    padding-inline-end: 5.75rem !important;
  }
  .pe-lg-24 {
    padding-inline-end: 6rem !important;
  }
  .pe-lg-25 {
    padding-inline-end: 6.25rem !important;
  }
  .pe-lg-26 {
    padding-inline-end: 6.5rem !important;
  }
  .pe-lg-27 {
    padding-inline-end: 6.75rem !important;
  }
  .pe-lg-28 {
    padding-inline-end: 7rem !important;
  }
  .pe-lg-29 {
    padding-inline-end: 7.25rem !important;
  }
  .pe-lg-30 {
    padding-inline-end: 7.5rem !important;
  }
  .pe-lg-31 {
    padding-inline-end: 7.75rem !important;
  }
  .pe-lg-32 {
    padding-inline-end: 8rem !important;
  }
  .pe-lg-33 {
    padding-inline-end: 8.25rem !important;
  }
  .pe-lg-34 {
    padding-inline-end: 8.5rem !important;
  }
  .pe-lg-35 {
    padding-inline-end: 8.75rem !important;
  }
  .pe-lg-36 {
    padding-inline-end: 9rem !important;
  }
  .pe-lg-37 {
    padding-inline-end: 9.25rem !important;
  }
  .pe-lg-38 {
    padding-inline-end: 9.5rem !important;
  }
  .pe-lg-39 {
    padding-inline-end: 9.75rem !important;
  }
  .pe-lg-40 {
    padding-inline-end: 10rem !important;
  }
  .pb-lg-0 {
    padding-block-end: 0 !important;
  }
  .pb-lg-1 {
    padding-block-end: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-block-end: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-block-end: 0.75rem !important;
  }
  .pb-lg-4 {
    padding-block-end: 1rem !important;
  }
  .pb-lg-5 {
    padding-block-end: 1.25rem !important;
  }
  .pb-lg-6 {
    padding-block-end: 1.5rem !important;
  }
  .pb-lg-7 {
    padding-block-end: 1.75rem !important;
  }
  .pb-lg-8 {
    padding-block-end: 2rem !important;
  }
  .pb-lg-9 {
    padding-block-end: 2.25rem !important;
  }
  .pb-lg-10 {
    padding-block-end: 2.5rem !important;
  }
  .pb-lg-11 {
    padding-block-end: 2.75rem !important;
  }
  .pb-lg-12 {
    padding-block-end: 3rem !important;
  }
  .pb-lg-13 {
    padding-block-end: 3.25rem !important;
  }
  .pb-lg-14 {
    padding-block-end: 3.5rem !important;
  }
  .pb-lg-15 {
    padding-block-end: 3.75rem !important;
  }
  .pb-lg-16 {
    padding-block-end: 4rem !important;
  }
  .pb-lg-17 {
    padding-block-end: 4.25rem !important;
  }
  .pb-lg-18 {
    padding-block-end: 4.5rem !important;
  }
  .pb-lg-19 {
    padding-block-end: 4.75rem !important;
  }
  .pb-lg-20 {
    padding-block-end: 5rem !important;
  }
  .pb-lg-21 {
    padding-block-end: 5.25rem !important;
  }
  .pb-lg-22 {
    padding-block-end: 5.5rem !important;
  }
  .pb-lg-23 {
    padding-block-end: 5.75rem !important;
  }
  .pb-lg-24 {
    padding-block-end: 6rem !important;
  }
  .pb-lg-25 {
    padding-block-end: 6.25rem !important;
  }
  .pb-lg-26 {
    padding-block-end: 6.5rem !important;
  }
  .pb-lg-27 {
    padding-block-end: 6.75rem !important;
  }
  .pb-lg-28 {
    padding-block-end: 7rem !important;
  }
  .pb-lg-29 {
    padding-block-end: 7.25rem !important;
  }
  .pb-lg-30 {
    padding-block-end: 7.5rem !important;
  }
  .pb-lg-31 {
    padding-block-end: 7.75rem !important;
  }
  .pb-lg-32 {
    padding-block-end: 8rem !important;
  }
  .pb-lg-33 {
    padding-block-end: 8.25rem !important;
  }
  .pb-lg-34 {
    padding-block-end: 8.5rem !important;
  }
  .pb-lg-35 {
    padding-block-end: 8.75rem !important;
  }
  .pb-lg-36 {
    padding-block-end: 9rem !important;
  }
  .pb-lg-37 {
    padding-block-end: 9.25rem !important;
  }
  .pb-lg-38 {
    padding-block-end: 9.5rem !important;
  }
  .pb-lg-39 {
    padding-block-end: 9.75rem !important;
  }
  .pb-lg-40 {
    padding-block-end: 10rem !important;
  }
  .ps-lg-0 {
    padding-inline-start: 0 !important;
  }
  .ps-lg-1 {
    padding-inline-start: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-inline-start: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-inline-start: 0.75rem !important;
  }
  .ps-lg-4 {
    padding-inline-start: 1rem !important;
  }
  .ps-lg-5 {
    padding-inline-start: 1.25rem !important;
  }
  .ps-lg-6 {
    padding-inline-start: 1.5rem !important;
  }
  .ps-lg-7 {
    padding-inline-start: 1.75rem !important;
  }
  .ps-lg-8 {
    padding-inline-start: 2rem !important;
  }
  .ps-lg-9 {
    padding-inline-start: 2.25rem !important;
  }
  .ps-lg-10 {
    padding-inline-start: 2.5rem !important;
  }
  .ps-lg-11 {
    padding-inline-start: 2.75rem !important;
  }
  .ps-lg-12 {
    padding-inline-start: 3rem !important;
  }
  .ps-lg-13 {
    padding-inline-start: 3.25rem !important;
  }
  .ps-lg-14 {
    padding-inline-start: 3.5rem !important;
  }
  .ps-lg-15 {
    padding-inline-start: 3.75rem !important;
  }
  .ps-lg-16 {
    padding-inline-start: 4rem !important;
  }
  .ps-lg-17 {
    padding-inline-start: 4.25rem !important;
  }
  .ps-lg-18 {
    padding-inline-start: 4.5rem !important;
  }
  .ps-lg-19 {
    padding-inline-start: 4.75rem !important;
  }
  .ps-lg-20 {
    padding-inline-start: 5rem !important;
  }
  .ps-lg-21 {
    padding-inline-start: 5.25rem !important;
  }
  .ps-lg-22 {
    padding-inline-start: 5.5rem !important;
  }
  .ps-lg-23 {
    padding-inline-start: 5.75rem !important;
  }
  .ps-lg-24 {
    padding-inline-start: 6rem !important;
  }
  .ps-lg-25 {
    padding-inline-start: 6.25rem !important;
  }
  .ps-lg-26 {
    padding-inline-start: 6.5rem !important;
  }
  .ps-lg-27 {
    padding-inline-start: 6.75rem !important;
  }
  .ps-lg-28 {
    padding-inline-start: 7rem !important;
  }
  .ps-lg-29 {
    padding-inline-start: 7.25rem !important;
  }
  .ps-lg-30 {
    padding-inline-start: 7.5rem !important;
  }
  .ps-lg-31 {
    padding-inline-start: 7.75rem !important;
  }
  .ps-lg-32 {
    padding-inline-start: 8rem !important;
  }
  .ps-lg-33 {
    padding-inline-start: 8.25rem !important;
  }
  .ps-lg-34 {
    padding-inline-start: 8.5rem !important;
  }
  .ps-lg-35 {
    padding-inline-start: 8.75rem !important;
  }
  .ps-lg-36 {
    padding-inline-start: 9rem !important;
  }
  .ps-lg-37 {
    padding-inline-start: 9.25rem !important;
  }
  .ps-lg-38 {
    padding-inline-start: 9.5rem !important;
  }
  .ps-lg-39 {
    padding-inline-start: 9.75rem !important;
  }
  .ps-lg-40 {
    padding-inline-start: 10rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 0.75rem !important;
  }
  .gap-lg-4 {
    gap: 1rem !important;
  }
  .gap-lg-5 {
    gap: 1.25rem !important;
  }
  .gap-lg-6 {
    gap: 1.5rem !important;
  }
  .gap-lg-7 {
    gap: 1.75rem !important;
  }
  .gap-lg-8 {
    gap: 2rem !important;
  }
  .gap-lg-9 {
    gap: 2.25rem !important;
  }
  .gap-lg-10 {
    gap: 2.5rem !important;
  }
  .gap-lg-11 {
    gap: 2.75rem !important;
  }
  .gap-lg-12 {
    gap: 3rem !important;
  }
  .gap-lg-13 {
    gap: 3.25rem !important;
  }
  .gap-lg-14 {
    gap: 3.5rem !important;
  }
  .gap-lg-15 {
    gap: 3.75rem !important;
  }
  .gap-lg-16 {
    gap: 4rem !important;
  }
  .gap-lg-17 {
    gap: 4.25rem !important;
  }
  .gap-lg-18 {
    gap: 4.5rem !important;
  }
  .gap-lg-19 {
    gap: 4.75rem !important;
  }
  .gap-lg-20 {
    gap: 5rem !important;
  }
  .gap-lg-21 {
    gap: 5.25rem !important;
  }
  .gap-lg-22 {
    gap: 5.5rem !important;
  }
  .gap-lg-23 {
    gap: 5.75rem !important;
  }
  .gap-lg-24 {
    gap: 6rem !important;
  }
  .gap-lg-25 {
    gap: 6.25rem !important;
  }
  .gap-lg-26 {
    gap: 6.5rem !important;
  }
  .gap-lg-27 {
    gap: 6.75rem !important;
  }
  .gap-lg-28 {
    gap: 7rem !important;
  }
  .gap-lg-29 {
    gap: 7.25rem !important;
  }
  .gap-lg-30 {
    gap: 7.5rem !important;
  }
  .gap-lg-31 {
    gap: 7.75rem !important;
  }
  .gap-lg-32 {
    gap: 8rem !important;
  }
  .gap-lg-33 {
    gap: 8.25rem !important;
  }
  .gap-lg-34 {
    gap: 8.5rem !important;
  }
  .gap-lg-35 {
    gap: 8.75rem !important;
  }
  .gap-lg-36 {
    gap: 9rem !important;
  }
  .gap-lg-37 {
    gap: 9.25rem !important;
  }
  .gap-lg-38 {
    gap: 9.5rem !important;
  }
  .gap-lg-39 {
    gap: 9.75rem !important;
  }
  .gap-lg-40 {
    gap: 10rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 2rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-lg-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-lg-12 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-lg-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-lg-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-lg-16 {
    row-gap: 4rem !important;
  }
  .row-gap-lg-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-lg-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-lg-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-lg-20 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-lg-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-lg-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-lg-24 {
    row-gap: 6rem !important;
  }
  .row-gap-lg-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-lg-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-lg-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-lg-28 {
    row-gap: 7rem !important;
  }
  .row-gap-lg-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-lg-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-lg-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-lg-32 {
    row-gap: 8rem !important;
  }
  .row-gap-lg-33 {
    row-gap: 8.25rem !important;
  }
  .row-gap-lg-34 {
    row-gap: 8.5rem !important;
  }
  .row-gap-lg-35 {
    row-gap: 8.75rem !important;
  }
  .row-gap-lg-36 {
    row-gap: 9rem !important;
  }
  .row-gap-lg-37 {
    row-gap: 9.25rem !important;
  }
  .row-gap-lg-38 {
    row-gap: 9.5rem !important;
  }
  .row-gap-lg-39 {
    row-gap: 9.75rem !important;
  }
  .row-gap-lg-40 {
    row-gap: 10rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 2rem !important;
  }
  .column-gap-lg-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-lg-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-lg-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-lg-12 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-lg-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-lg-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-lg-16 {
    column-gap: 4rem !important;
  }
  .column-gap-lg-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-lg-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-lg-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-lg-20 {
    column-gap: 5rem !important;
  }
  .column-gap-lg-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-lg-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-lg-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-lg-24 {
    column-gap: 6rem !important;
  }
  .column-gap-lg-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-lg-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-lg-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-lg-28 {
    column-gap: 7rem !important;
  }
  .column-gap-lg-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-lg-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-lg-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-lg-32 {
    column-gap: 8rem !important;
  }
  .column-gap-lg-33 {
    column-gap: 8.25rem !important;
  }
  .column-gap-lg-34 {
    column-gap: 8.5rem !important;
  }
  .column-gap-lg-35 {
    column-gap: 8.75rem !important;
  }
  .column-gap-lg-36 {
    column-gap: 9rem !important;
  }
  .column-gap-lg-37 {
    column-gap: 9.25rem !important;
  }
  .column-gap-lg-38 {
    column-gap: 9.5rem !important;
  }
  .column-gap-lg-39 {
    column-gap: 9.75rem !important;
  }
  .column-gap-lg-40 {
    column-gap: 10rem !important;
  }
  .text-lg-start {
    text-align: start !important;
  }
  .text-lg-end {
    text-align: end !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .border-xl {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.25rem !important;
  }
  .m-xl-6 {
    margin: 1.5rem !important;
  }
  .m-xl-7 {
    margin: 1.75rem !important;
  }
  .m-xl-8 {
    margin: 2rem !important;
  }
  .m-xl-9 {
    margin: 2.25rem !important;
  }
  .m-xl-10 {
    margin: 2.5rem !important;
  }
  .m-xl-11 {
    margin: 2.75rem !important;
  }
  .m-xl-12 {
    margin: 3rem !important;
  }
  .m-xl-13 {
    margin: 3.25rem !important;
  }
  .m-xl-14 {
    margin: 3.5rem !important;
  }
  .m-xl-15 {
    margin: 3.75rem !important;
  }
  .m-xl-16 {
    margin: 4rem !important;
  }
  .m-xl-17 {
    margin: 4.25rem !important;
  }
  .m-xl-18 {
    margin: 4.5rem !important;
  }
  .m-xl-19 {
    margin: 4.75rem !important;
  }
  .m-xl-20 {
    margin: 5rem !important;
  }
  .m-xl-21 {
    margin: 5.25rem !important;
  }
  .m-xl-22 {
    margin: 5.5rem !important;
  }
  .m-xl-23 {
    margin: 5.75rem !important;
  }
  .m-xl-24 {
    margin: 6rem !important;
  }
  .m-xl-25 {
    margin: 6.25rem !important;
  }
  .m-xl-26 {
    margin: 6.5rem !important;
  }
  .m-xl-27 {
    margin: 6.75rem !important;
  }
  .m-xl-28 {
    margin: 7rem !important;
  }
  .m-xl-29 {
    margin: 7.25rem !important;
  }
  .m-xl-30 {
    margin: 7.5rem !important;
  }
  .m-xl-31 {
    margin: 7.75rem !important;
  }
  .m-xl-32 {
    margin: 8rem !important;
  }
  .m-xl-33 {
    margin: 8.25rem !important;
  }
  .m-xl-34 {
    margin: 8.5rem !important;
  }
  .m-xl-35 {
    margin: 8.75rem !important;
  }
  .m-xl-36 {
    margin: 9rem !important;
  }
  .m-xl-37 {
    margin: 9.25rem !important;
  }
  .m-xl-38 {
    margin: 9.5rem !important;
  }
  .m-xl-39 {
    margin: 9.75rem !important;
  }
  .m-xl-40 {
    margin: 10rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-inline: 0 !important;
  }
  .mx-xl-1 {
    margin-inline: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-inline: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-inline: 0.75rem !important;
  }
  .mx-xl-4 {
    margin-inline: 1rem !important;
  }
  .mx-xl-5 {
    margin-inline: 1.25rem !important;
  }
  .mx-xl-6 {
    margin-inline: 1.5rem !important;
  }
  .mx-xl-7 {
    margin-inline: 1.75rem !important;
  }
  .mx-xl-8 {
    margin-inline: 2rem !important;
  }
  .mx-xl-9 {
    margin-inline: 2.25rem !important;
  }
  .mx-xl-10 {
    margin-inline: 2.5rem !important;
  }
  .mx-xl-11 {
    margin-inline: 2.75rem !important;
  }
  .mx-xl-12 {
    margin-inline: 3rem !important;
  }
  .mx-xl-13 {
    margin-inline: 3.25rem !important;
  }
  .mx-xl-14 {
    margin-inline: 3.5rem !important;
  }
  .mx-xl-15 {
    margin-inline: 3.75rem !important;
  }
  .mx-xl-16 {
    margin-inline: 4rem !important;
  }
  .mx-xl-17 {
    margin-inline: 4.25rem !important;
  }
  .mx-xl-18 {
    margin-inline: 4.5rem !important;
  }
  .mx-xl-19 {
    margin-inline: 4.75rem !important;
  }
  .mx-xl-20 {
    margin-inline: 5rem !important;
  }
  .mx-xl-21 {
    margin-inline: 5.25rem !important;
  }
  .mx-xl-22 {
    margin-inline: 5.5rem !important;
  }
  .mx-xl-23 {
    margin-inline: 5.75rem !important;
  }
  .mx-xl-24 {
    margin-inline: 6rem !important;
  }
  .mx-xl-25 {
    margin-inline: 6.25rem !important;
  }
  .mx-xl-26 {
    margin-inline: 6.5rem !important;
  }
  .mx-xl-27 {
    margin-inline: 6.75rem !important;
  }
  .mx-xl-28 {
    margin-inline: 7rem !important;
  }
  .mx-xl-29 {
    margin-inline: 7.25rem !important;
  }
  .mx-xl-30 {
    margin-inline: 7.5rem !important;
  }
  .mx-xl-31 {
    margin-inline: 7.75rem !important;
  }
  .mx-xl-32 {
    margin-inline: 8rem !important;
  }
  .mx-xl-33 {
    margin-inline: 8.25rem !important;
  }
  .mx-xl-34 {
    margin-inline: 8.5rem !important;
  }
  .mx-xl-35 {
    margin-inline: 8.75rem !important;
  }
  .mx-xl-36 {
    margin-inline: 9rem !important;
  }
  .mx-xl-37 {
    margin-inline: 9.25rem !important;
  }
  .mx-xl-38 {
    margin-inline: 9.5rem !important;
  }
  .mx-xl-39 {
    margin-inline: 9.75rem !important;
  }
  .mx-xl-40 {
    margin-inline: 10rem !important;
  }
  .mx-xl-auto {
    margin-inline: auto !important;
  }
  .my-xl-0 {
    margin-block: 0 !important;
  }
  .my-xl-1 {
    margin-block: 0.25rem !important;
  }
  .my-xl-2 {
    margin-block: 0.5rem !important;
  }
  .my-xl-3 {
    margin-block: 0.75rem !important;
  }
  .my-xl-4 {
    margin-block: 1rem !important;
  }
  .my-xl-5 {
    margin-block: 1.25rem !important;
  }
  .my-xl-6 {
    margin-block: 1.5rem !important;
  }
  .my-xl-7 {
    margin-block: 1.75rem !important;
  }
  .my-xl-8 {
    margin-block: 2rem !important;
  }
  .my-xl-9 {
    margin-block: 2.25rem !important;
  }
  .my-xl-10 {
    margin-block: 2.5rem !important;
  }
  .my-xl-11 {
    margin-block: 2.75rem !important;
  }
  .my-xl-12 {
    margin-block: 3rem !important;
  }
  .my-xl-13 {
    margin-block: 3.25rem !important;
  }
  .my-xl-14 {
    margin-block: 3.5rem !important;
  }
  .my-xl-15 {
    margin-block: 3.75rem !important;
  }
  .my-xl-16 {
    margin-block: 4rem !important;
  }
  .my-xl-17 {
    margin-block: 4.25rem !important;
  }
  .my-xl-18 {
    margin-block: 4.5rem !important;
  }
  .my-xl-19 {
    margin-block: 4.75rem !important;
  }
  .my-xl-20 {
    margin-block: 5rem !important;
  }
  .my-xl-21 {
    margin-block: 5.25rem !important;
  }
  .my-xl-22 {
    margin-block: 5.5rem !important;
  }
  .my-xl-23 {
    margin-block: 5.75rem !important;
  }
  .my-xl-24 {
    margin-block: 6rem !important;
  }
  .my-xl-25 {
    margin-block: 6.25rem !important;
  }
  .my-xl-26 {
    margin-block: 6.5rem !important;
  }
  .my-xl-27 {
    margin-block: 6.75rem !important;
  }
  .my-xl-28 {
    margin-block: 7rem !important;
  }
  .my-xl-29 {
    margin-block: 7.25rem !important;
  }
  .my-xl-30 {
    margin-block: 7.5rem !important;
  }
  .my-xl-31 {
    margin-block: 7.75rem !important;
  }
  .my-xl-32 {
    margin-block: 8rem !important;
  }
  .my-xl-33 {
    margin-block: 8.25rem !important;
  }
  .my-xl-34 {
    margin-block: 8.5rem !important;
  }
  .my-xl-35 {
    margin-block: 8.75rem !important;
  }
  .my-xl-36 {
    margin-block: 9rem !important;
  }
  .my-xl-37 {
    margin-block: 9.25rem !important;
  }
  .my-xl-38 {
    margin-block: 9.5rem !important;
  }
  .my-xl-39 {
    margin-block: 9.75rem !important;
  }
  .my-xl-40 {
    margin-block: 10rem !important;
  }
  .my-xl-auto {
    margin-block: auto !important;
  }
  .mt-xl-0 {
    margin-block-start: 0 !important;
  }
  .mt-xl-1 {
    margin-block-start: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-block-start: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-block-start: 0.75rem !important;
  }
  .mt-xl-4 {
    margin-block-start: 1rem !important;
  }
  .mt-xl-5 {
    margin-block-start: 1.25rem !important;
  }
  .mt-xl-6 {
    margin-block-start: 1.5rem !important;
  }
  .mt-xl-7 {
    margin-block-start: 1.75rem !important;
  }
  .mt-xl-8 {
    margin-block-start: 2rem !important;
  }
  .mt-xl-9 {
    margin-block-start: 2.25rem !important;
  }
  .mt-xl-10 {
    margin-block-start: 2.5rem !important;
  }
  .mt-xl-11 {
    margin-block-start: 2.75rem !important;
  }
  .mt-xl-12 {
    margin-block-start: 3rem !important;
  }
  .mt-xl-13 {
    margin-block-start: 3.25rem !important;
  }
  .mt-xl-14 {
    margin-block-start: 3.5rem !important;
  }
  .mt-xl-15 {
    margin-block-start: 3.75rem !important;
  }
  .mt-xl-16 {
    margin-block-start: 4rem !important;
  }
  .mt-xl-17 {
    margin-block-start: 4.25rem !important;
  }
  .mt-xl-18 {
    margin-block-start: 4.5rem !important;
  }
  .mt-xl-19 {
    margin-block-start: 4.75rem !important;
  }
  .mt-xl-20 {
    margin-block-start: 5rem !important;
  }
  .mt-xl-21 {
    margin-block-start: 5.25rem !important;
  }
  .mt-xl-22 {
    margin-block-start: 5.5rem !important;
  }
  .mt-xl-23 {
    margin-block-start: 5.75rem !important;
  }
  .mt-xl-24 {
    margin-block-start: 6rem !important;
  }
  .mt-xl-25 {
    margin-block-start: 6.25rem !important;
  }
  .mt-xl-26 {
    margin-block-start: 6.5rem !important;
  }
  .mt-xl-27 {
    margin-block-start: 6.75rem !important;
  }
  .mt-xl-28 {
    margin-block-start: 7rem !important;
  }
  .mt-xl-29 {
    margin-block-start: 7.25rem !important;
  }
  .mt-xl-30 {
    margin-block-start: 7.5rem !important;
  }
  .mt-xl-31 {
    margin-block-start: 7.75rem !important;
  }
  .mt-xl-32 {
    margin-block-start: 8rem !important;
  }
  .mt-xl-33 {
    margin-block-start: 8.25rem !important;
  }
  .mt-xl-34 {
    margin-block-start: 8.5rem !important;
  }
  .mt-xl-35 {
    margin-block-start: 8.75rem !important;
  }
  .mt-xl-36 {
    margin-block-start: 9rem !important;
  }
  .mt-xl-37 {
    margin-block-start: 9.25rem !important;
  }
  .mt-xl-38 {
    margin-block-start: 9.5rem !important;
  }
  .mt-xl-39 {
    margin-block-start: 9.75rem !important;
  }
  .mt-xl-40 {
    margin-block-start: 10rem !important;
  }
  .mt-xl-auto {
    margin-block-start: auto !important;
  }
  .me-xl-0 {
    margin-inline-end: 0 !important;
  }
  .me-xl-1 {
    margin-inline-end: 0.25rem !important;
  }
  .me-xl-2 {
    margin-inline-end: 0.5rem !important;
  }
  .me-xl-3 {
    margin-inline-end: 0.75rem !important;
  }
  .me-xl-4 {
    margin-inline-end: 1rem !important;
  }
  .me-xl-5 {
    margin-inline-end: 1.25rem !important;
  }
  .me-xl-6 {
    margin-inline-end: 1.5rem !important;
  }
  .me-xl-7 {
    margin-inline-end: 1.75rem !important;
  }
  .me-xl-8 {
    margin-inline-end: 2rem !important;
  }
  .me-xl-9 {
    margin-inline-end: 2.25rem !important;
  }
  .me-xl-10 {
    margin-inline-end: 2.5rem !important;
  }
  .me-xl-11 {
    margin-inline-end: 2.75rem !important;
  }
  .me-xl-12 {
    margin-inline-end: 3rem !important;
  }
  .me-xl-13 {
    margin-inline-end: 3.25rem !important;
  }
  .me-xl-14 {
    margin-inline-end: 3.5rem !important;
  }
  .me-xl-15 {
    margin-inline-end: 3.75rem !important;
  }
  .me-xl-16 {
    margin-inline-end: 4rem !important;
  }
  .me-xl-17 {
    margin-inline-end: 4.25rem !important;
  }
  .me-xl-18 {
    margin-inline-end: 4.5rem !important;
  }
  .me-xl-19 {
    margin-inline-end: 4.75rem !important;
  }
  .me-xl-20 {
    margin-inline-end: 5rem !important;
  }
  .me-xl-21 {
    margin-inline-end: 5.25rem !important;
  }
  .me-xl-22 {
    margin-inline-end: 5.5rem !important;
  }
  .me-xl-23 {
    margin-inline-end: 5.75rem !important;
  }
  .me-xl-24 {
    margin-inline-end: 6rem !important;
  }
  .me-xl-25 {
    margin-inline-end: 6.25rem !important;
  }
  .me-xl-26 {
    margin-inline-end: 6.5rem !important;
  }
  .me-xl-27 {
    margin-inline-end: 6.75rem !important;
  }
  .me-xl-28 {
    margin-inline-end: 7rem !important;
  }
  .me-xl-29 {
    margin-inline-end: 7.25rem !important;
  }
  .me-xl-30 {
    margin-inline-end: 7.5rem !important;
  }
  .me-xl-31 {
    margin-inline-end: 7.75rem !important;
  }
  .me-xl-32 {
    margin-inline-end: 8rem !important;
  }
  .me-xl-33 {
    margin-inline-end: 8.25rem !important;
  }
  .me-xl-34 {
    margin-inline-end: 8.5rem !important;
  }
  .me-xl-35 {
    margin-inline-end: 8.75rem !important;
  }
  .me-xl-36 {
    margin-inline-end: 9rem !important;
  }
  .me-xl-37 {
    margin-inline-end: 9.25rem !important;
  }
  .me-xl-38 {
    margin-inline-end: 9.5rem !important;
  }
  .me-xl-39 {
    margin-inline-end: 9.75rem !important;
  }
  .me-xl-40 {
    margin-inline-end: 10rem !important;
  }
  .me-xl-auto {
    margin-inline-end: auto !important;
  }
  .mb-xl-0 {
    margin-block-end: 0 !important;
  }
  .mb-xl-1 {
    margin-block-end: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-block-end: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-block-end: 0.75rem !important;
  }
  .mb-xl-4 {
    margin-block-end: 1rem !important;
  }
  .mb-xl-5 {
    margin-block-end: 1.25rem !important;
  }
  .mb-xl-6 {
    margin-block-end: 1.5rem !important;
  }
  .mb-xl-7 {
    margin-block-end: 1.75rem !important;
  }
  .mb-xl-8 {
    margin-block-end: 2rem !important;
  }
  .mb-xl-9 {
    margin-block-end: 2.25rem !important;
  }
  .mb-xl-10 {
    margin-block-end: 2.5rem !important;
  }
  .mb-xl-11 {
    margin-block-end: 2.75rem !important;
  }
  .mb-xl-12 {
    margin-block-end: 3rem !important;
  }
  .mb-xl-13 {
    margin-block-end: 3.25rem !important;
  }
  .mb-xl-14 {
    margin-block-end: 3.5rem !important;
  }
  .mb-xl-15 {
    margin-block-end: 3.75rem !important;
  }
  .mb-xl-16 {
    margin-block-end: 4rem !important;
  }
  .mb-xl-17 {
    margin-block-end: 4.25rem !important;
  }
  .mb-xl-18 {
    margin-block-end: 4.5rem !important;
  }
  .mb-xl-19 {
    margin-block-end: 4.75rem !important;
  }
  .mb-xl-20 {
    margin-block-end: 5rem !important;
  }
  .mb-xl-21 {
    margin-block-end: 5.25rem !important;
  }
  .mb-xl-22 {
    margin-block-end: 5.5rem !important;
  }
  .mb-xl-23 {
    margin-block-end: 5.75rem !important;
  }
  .mb-xl-24 {
    margin-block-end: 6rem !important;
  }
  .mb-xl-25 {
    margin-block-end: 6.25rem !important;
  }
  .mb-xl-26 {
    margin-block-end: 6.5rem !important;
  }
  .mb-xl-27 {
    margin-block-end: 6.75rem !important;
  }
  .mb-xl-28 {
    margin-block-end: 7rem !important;
  }
  .mb-xl-29 {
    margin-block-end: 7.25rem !important;
  }
  .mb-xl-30 {
    margin-block-end: 7.5rem !important;
  }
  .mb-xl-31 {
    margin-block-end: 7.75rem !important;
  }
  .mb-xl-32 {
    margin-block-end: 8rem !important;
  }
  .mb-xl-33 {
    margin-block-end: 8.25rem !important;
  }
  .mb-xl-34 {
    margin-block-end: 8.5rem !important;
  }
  .mb-xl-35 {
    margin-block-end: 8.75rem !important;
  }
  .mb-xl-36 {
    margin-block-end: 9rem !important;
  }
  .mb-xl-37 {
    margin-block-end: 9.25rem !important;
  }
  .mb-xl-38 {
    margin-block-end: 9.5rem !important;
  }
  .mb-xl-39 {
    margin-block-end: 9.75rem !important;
  }
  .mb-xl-40 {
    margin-block-end: 10rem !important;
  }
  .mb-xl-auto {
    margin-block-end: auto !important;
  }
  .ms-xl-0 {
    margin-inline-start: 0 !important;
  }
  .ms-xl-1 {
    margin-inline-start: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-inline-start: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-inline-start: 0.75rem !important;
  }
  .ms-xl-4 {
    margin-inline-start: 1rem !important;
  }
  .ms-xl-5 {
    margin-inline-start: 1.25rem !important;
  }
  .ms-xl-6 {
    margin-inline-start: 1.5rem !important;
  }
  .ms-xl-7 {
    margin-inline-start: 1.75rem !important;
  }
  .ms-xl-8 {
    margin-inline-start: 2rem !important;
  }
  .ms-xl-9 {
    margin-inline-start: 2.25rem !important;
  }
  .ms-xl-10 {
    margin-inline-start: 2.5rem !important;
  }
  .ms-xl-11 {
    margin-inline-start: 2.75rem !important;
  }
  .ms-xl-12 {
    margin-inline-start: 3rem !important;
  }
  .ms-xl-13 {
    margin-inline-start: 3.25rem !important;
  }
  .ms-xl-14 {
    margin-inline-start: 3.5rem !important;
  }
  .ms-xl-15 {
    margin-inline-start: 3.75rem !important;
  }
  .ms-xl-16 {
    margin-inline-start: 4rem !important;
  }
  .ms-xl-17 {
    margin-inline-start: 4.25rem !important;
  }
  .ms-xl-18 {
    margin-inline-start: 4.5rem !important;
  }
  .ms-xl-19 {
    margin-inline-start: 4.75rem !important;
  }
  .ms-xl-20 {
    margin-inline-start: 5rem !important;
  }
  .ms-xl-21 {
    margin-inline-start: 5.25rem !important;
  }
  .ms-xl-22 {
    margin-inline-start: 5.5rem !important;
  }
  .ms-xl-23 {
    margin-inline-start: 5.75rem !important;
  }
  .ms-xl-24 {
    margin-inline-start: 6rem !important;
  }
  .ms-xl-25 {
    margin-inline-start: 6.25rem !important;
  }
  .ms-xl-26 {
    margin-inline-start: 6.5rem !important;
  }
  .ms-xl-27 {
    margin-inline-start: 6.75rem !important;
  }
  .ms-xl-28 {
    margin-inline-start: 7rem !important;
  }
  .ms-xl-29 {
    margin-inline-start: 7.25rem !important;
  }
  .ms-xl-30 {
    margin-inline-start: 7.5rem !important;
  }
  .ms-xl-31 {
    margin-inline-start: 7.75rem !important;
  }
  .ms-xl-32 {
    margin-inline-start: 8rem !important;
  }
  .ms-xl-33 {
    margin-inline-start: 8.25rem !important;
  }
  .ms-xl-34 {
    margin-inline-start: 8.5rem !important;
  }
  .ms-xl-35 {
    margin-inline-start: 8.75rem !important;
  }
  .ms-xl-36 {
    margin-inline-start: 9rem !important;
  }
  .ms-xl-37 {
    margin-inline-start: 9.25rem !important;
  }
  .ms-xl-38 {
    margin-inline-start: 9.5rem !important;
  }
  .ms-xl-39 {
    margin-inline-start: 9.75rem !important;
  }
  .ms-xl-40 {
    margin-inline-start: 10rem !important;
  }
  .ms-xl-auto {
    margin-inline-start: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.25rem !important;
  }
  .p-xl-6 {
    padding: 1.5rem !important;
  }
  .p-xl-7 {
    padding: 1.75rem !important;
  }
  .p-xl-8 {
    padding: 2rem !important;
  }
  .p-xl-9 {
    padding: 2.25rem !important;
  }
  .p-xl-10 {
    padding: 2.5rem !important;
  }
  .p-xl-11 {
    padding: 2.75rem !important;
  }
  .p-xl-12 {
    padding: 3rem !important;
  }
  .p-xl-13 {
    padding: 3.25rem !important;
  }
  .p-xl-14 {
    padding: 3.5rem !important;
  }
  .p-xl-15 {
    padding: 3.75rem !important;
  }
  .p-xl-16 {
    padding: 4rem !important;
  }
  .p-xl-17 {
    padding: 4.25rem !important;
  }
  .p-xl-18 {
    padding: 4.5rem !important;
  }
  .p-xl-19 {
    padding: 4.75rem !important;
  }
  .p-xl-20 {
    padding: 5rem !important;
  }
  .p-xl-21 {
    padding: 5.25rem !important;
  }
  .p-xl-22 {
    padding: 5.5rem !important;
  }
  .p-xl-23 {
    padding: 5.75rem !important;
  }
  .p-xl-24 {
    padding: 6rem !important;
  }
  .p-xl-25 {
    padding: 6.25rem !important;
  }
  .p-xl-26 {
    padding: 6.5rem !important;
  }
  .p-xl-27 {
    padding: 6.75rem !important;
  }
  .p-xl-28 {
    padding: 7rem !important;
  }
  .p-xl-29 {
    padding: 7.25rem !important;
  }
  .p-xl-30 {
    padding: 7.5rem !important;
  }
  .p-xl-31 {
    padding: 7.75rem !important;
  }
  .p-xl-32 {
    padding: 8rem !important;
  }
  .p-xl-33 {
    padding: 8.25rem !important;
  }
  .p-xl-34 {
    padding: 8.5rem !important;
  }
  .p-xl-35 {
    padding: 8.75rem !important;
  }
  .p-xl-36 {
    padding: 9rem !important;
  }
  .p-xl-37 {
    padding: 9.25rem !important;
  }
  .p-xl-38 {
    padding: 9.5rem !important;
  }
  .p-xl-39 {
    padding: 9.75rem !important;
  }
  .p-xl-40 {
    padding: 10rem !important;
  }
  .px-xl-0 {
    padding-inline: 0 !important;
  }
  .px-xl-1 {
    padding-inline: 0.25rem !important;
  }
  .px-xl-2 {
    padding-inline: 0.5rem !important;
  }
  .px-xl-3 {
    padding-inline: 0.75rem !important;
  }
  .px-xl-4 {
    padding-inline: 1rem !important;
  }
  .px-xl-5 {
    padding-inline: 1.25rem !important;
  }
  .px-xl-6 {
    padding-inline: 1.5rem !important;
  }
  .px-xl-7 {
    padding-inline: 1.75rem !important;
  }
  .px-xl-8 {
    padding-inline: 2rem !important;
  }
  .px-xl-9 {
    padding-inline: 2.25rem !important;
  }
  .px-xl-10 {
    padding-inline: 2.5rem !important;
  }
  .px-xl-11 {
    padding-inline: 2.75rem !important;
  }
  .px-xl-12 {
    padding-inline: 3rem !important;
  }
  .px-xl-13 {
    padding-inline: 3.25rem !important;
  }
  .px-xl-14 {
    padding-inline: 3.5rem !important;
  }
  .px-xl-15 {
    padding-inline: 3.75rem !important;
  }
  .px-xl-16 {
    padding-inline: 4rem !important;
  }
  .px-xl-17 {
    padding-inline: 4.25rem !important;
  }
  .px-xl-18 {
    padding-inline: 4.5rem !important;
  }
  .px-xl-19 {
    padding-inline: 4.75rem !important;
  }
  .px-xl-20 {
    padding-inline: 5rem !important;
  }
  .px-xl-21 {
    padding-inline: 5.25rem !important;
  }
  .px-xl-22 {
    padding-inline: 5.5rem !important;
  }
  .px-xl-23 {
    padding-inline: 5.75rem !important;
  }
  .px-xl-24 {
    padding-inline: 6rem !important;
  }
  .px-xl-25 {
    padding-inline: 6.25rem !important;
  }
  .px-xl-26 {
    padding-inline: 6.5rem !important;
  }
  .px-xl-27 {
    padding-inline: 6.75rem !important;
  }
  .px-xl-28 {
    padding-inline: 7rem !important;
  }
  .px-xl-29 {
    padding-inline: 7.25rem !important;
  }
  .px-xl-30 {
    padding-inline: 7.5rem !important;
  }
  .px-xl-31 {
    padding-inline: 7.75rem !important;
  }
  .px-xl-32 {
    padding-inline: 8rem !important;
  }
  .px-xl-33 {
    padding-inline: 8.25rem !important;
  }
  .px-xl-34 {
    padding-inline: 8.5rem !important;
  }
  .px-xl-35 {
    padding-inline: 8.75rem !important;
  }
  .px-xl-36 {
    padding-inline: 9rem !important;
  }
  .px-xl-37 {
    padding-inline: 9.25rem !important;
  }
  .px-xl-38 {
    padding-inline: 9.5rem !important;
  }
  .px-xl-39 {
    padding-inline: 9.75rem !important;
  }
  .px-xl-40 {
    padding-inline: 10rem !important;
  }
  .py-xl-0 {
    padding-block: 0 !important;
  }
  .py-xl-1 {
    padding-block: 0.25rem !important;
  }
  .py-xl-2 {
    padding-block: 0.5rem !important;
  }
  .py-xl-3 {
    padding-block: 0.75rem !important;
  }
  .py-xl-4 {
    padding-block: 1rem !important;
  }
  .py-xl-5 {
    padding-block: 1.25rem !important;
  }
  .py-xl-6 {
    padding-block: 1.5rem !important;
  }
  .py-xl-7 {
    padding-block: 1.75rem !important;
  }
  .py-xl-8 {
    padding-block: 2rem !important;
  }
  .py-xl-9 {
    padding-block: 2.25rem !important;
  }
  .py-xl-10 {
    padding-block: 2.5rem !important;
  }
  .py-xl-11 {
    padding-block: 2.75rem !important;
  }
  .py-xl-12 {
    padding-block: 3rem !important;
  }
  .py-xl-13 {
    padding-block: 3.25rem !important;
  }
  .py-xl-14 {
    padding-block: 3.5rem !important;
  }
  .py-xl-15 {
    padding-block: 3.75rem !important;
  }
  .py-xl-16 {
    padding-block: 4rem !important;
  }
  .py-xl-17 {
    padding-block: 4.25rem !important;
  }
  .py-xl-18 {
    padding-block: 4.5rem !important;
  }
  .py-xl-19 {
    padding-block: 4.75rem !important;
  }
  .py-xl-20 {
    padding-block: 5rem !important;
  }
  .py-xl-21 {
    padding-block: 5.25rem !important;
  }
  .py-xl-22 {
    padding-block: 5.5rem !important;
  }
  .py-xl-23 {
    padding-block: 5.75rem !important;
  }
  .py-xl-24 {
    padding-block: 6rem !important;
  }
  .py-xl-25 {
    padding-block: 6.25rem !important;
  }
  .py-xl-26 {
    padding-block: 6.5rem !important;
  }
  .py-xl-27 {
    padding-block: 6.75rem !important;
  }
  .py-xl-28 {
    padding-block: 7rem !important;
  }
  .py-xl-29 {
    padding-block: 7.25rem !important;
  }
  .py-xl-30 {
    padding-block: 7.5rem !important;
  }
  .py-xl-31 {
    padding-block: 7.75rem !important;
  }
  .py-xl-32 {
    padding-block: 8rem !important;
  }
  .py-xl-33 {
    padding-block: 8.25rem !important;
  }
  .py-xl-34 {
    padding-block: 8.5rem !important;
  }
  .py-xl-35 {
    padding-block: 8.75rem !important;
  }
  .py-xl-36 {
    padding-block: 9rem !important;
  }
  .py-xl-37 {
    padding-block: 9.25rem !important;
  }
  .py-xl-38 {
    padding-block: 9.5rem !important;
  }
  .py-xl-39 {
    padding-block: 9.75rem !important;
  }
  .py-xl-40 {
    padding-block: 10rem !important;
  }
  .pt-xl-0 {
    padding-block-start: 0 !important;
  }
  .pt-xl-1 {
    padding-block-start: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-block-start: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-block-start: 0.75rem !important;
  }
  .pt-xl-4 {
    padding-block-start: 1rem !important;
  }
  .pt-xl-5 {
    padding-block-start: 1.25rem !important;
  }
  .pt-xl-6 {
    padding-block-start: 1.5rem !important;
  }
  .pt-xl-7 {
    padding-block-start: 1.75rem !important;
  }
  .pt-xl-8 {
    padding-block-start: 2rem !important;
  }
  .pt-xl-9 {
    padding-block-start: 2.25rem !important;
  }
  .pt-xl-10 {
    padding-block-start: 2.5rem !important;
  }
  .pt-xl-11 {
    padding-block-start: 2.75rem !important;
  }
  .pt-xl-12 {
    padding-block-start: 3rem !important;
  }
  .pt-xl-13 {
    padding-block-start: 3.25rem !important;
  }
  .pt-xl-14 {
    padding-block-start: 3.5rem !important;
  }
  .pt-xl-15 {
    padding-block-start: 3.75rem !important;
  }
  .pt-xl-16 {
    padding-block-start: 4rem !important;
  }
  .pt-xl-17 {
    padding-block-start: 4.25rem !important;
  }
  .pt-xl-18 {
    padding-block-start: 4.5rem !important;
  }
  .pt-xl-19 {
    padding-block-start: 4.75rem !important;
  }
  .pt-xl-20 {
    padding-block-start: 5rem !important;
  }
  .pt-xl-21 {
    padding-block-start: 5.25rem !important;
  }
  .pt-xl-22 {
    padding-block-start: 5.5rem !important;
  }
  .pt-xl-23 {
    padding-block-start: 5.75rem !important;
  }
  .pt-xl-24 {
    padding-block-start: 6rem !important;
  }
  .pt-xl-25 {
    padding-block-start: 6.25rem !important;
  }
  .pt-xl-26 {
    padding-block-start: 6.5rem !important;
  }
  .pt-xl-27 {
    padding-block-start: 6.75rem !important;
  }
  .pt-xl-28 {
    padding-block-start: 7rem !important;
  }
  .pt-xl-29 {
    padding-block-start: 7.25rem !important;
  }
  .pt-xl-30 {
    padding-block-start: 7.5rem !important;
  }
  .pt-xl-31 {
    padding-block-start: 7.75rem !important;
  }
  .pt-xl-32 {
    padding-block-start: 8rem !important;
  }
  .pt-xl-33 {
    padding-block-start: 8.25rem !important;
  }
  .pt-xl-34 {
    padding-block-start: 8.5rem !important;
  }
  .pt-xl-35 {
    padding-block-start: 8.75rem !important;
  }
  .pt-xl-36 {
    padding-block-start: 9rem !important;
  }
  .pt-xl-37 {
    padding-block-start: 9.25rem !important;
  }
  .pt-xl-38 {
    padding-block-start: 9.5rem !important;
  }
  .pt-xl-39 {
    padding-block-start: 9.75rem !important;
  }
  .pt-xl-40 {
    padding-block-start: 10rem !important;
  }
  .pe-xl-0 {
    padding-inline-end: 0 !important;
  }
  .pe-xl-1 {
    padding-inline-end: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-inline-end: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-inline-end: 0.75rem !important;
  }
  .pe-xl-4 {
    padding-inline-end: 1rem !important;
  }
  .pe-xl-5 {
    padding-inline-end: 1.25rem !important;
  }
  .pe-xl-6 {
    padding-inline-end: 1.5rem !important;
  }
  .pe-xl-7 {
    padding-inline-end: 1.75rem !important;
  }
  .pe-xl-8 {
    padding-inline-end: 2rem !important;
  }
  .pe-xl-9 {
    padding-inline-end: 2.25rem !important;
  }
  .pe-xl-10 {
    padding-inline-end: 2.5rem !important;
  }
  .pe-xl-11 {
    padding-inline-end: 2.75rem !important;
  }
  .pe-xl-12 {
    padding-inline-end: 3rem !important;
  }
  .pe-xl-13 {
    padding-inline-end: 3.25rem !important;
  }
  .pe-xl-14 {
    padding-inline-end: 3.5rem !important;
  }
  .pe-xl-15 {
    padding-inline-end: 3.75rem !important;
  }
  .pe-xl-16 {
    padding-inline-end: 4rem !important;
  }
  .pe-xl-17 {
    padding-inline-end: 4.25rem !important;
  }
  .pe-xl-18 {
    padding-inline-end: 4.5rem !important;
  }
  .pe-xl-19 {
    padding-inline-end: 4.75rem !important;
  }
  .pe-xl-20 {
    padding-inline-end: 5rem !important;
  }
  .pe-xl-21 {
    padding-inline-end: 5.25rem !important;
  }
  .pe-xl-22 {
    padding-inline-end: 5.5rem !important;
  }
  .pe-xl-23 {
    padding-inline-end: 5.75rem !important;
  }
  .pe-xl-24 {
    padding-inline-end: 6rem !important;
  }
  .pe-xl-25 {
    padding-inline-end: 6.25rem !important;
  }
  .pe-xl-26 {
    padding-inline-end: 6.5rem !important;
  }
  .pe-xl-27 {
    padding-inline-end: 6.75rem !important;
  }
  .pe-xl-28 {
    padding-inline-end: 7rem !important;
  }
  .pe-xl-29 {
    padding-inline-end: 7.25rem !important;
  }
  .pe-xl-30 {
    padding-inline-end: 7.5rem !important;
  }
  .pe-xl-31 {
    padding-inline-end: 7.75rem !important;
  }
  .pe-xl-32 {
    padding-inline-end: 8rem !important;
  }
  .pe-xl-33 {
    padding-inline-end: 8.25rem !important;
  }
  .pe-xl-34 {
    padding-inline-end: 8.5rem !important;
  }
  .pe-xl-35 {
    padding-inline-end: 8.75rem !important;
  }
  .pe-xl-36 {
    padding-inline-end: 9rem !important;
  }
  .pe-xl-37 {
    padding-inline-end: 9.25rem !important;
  }
  .pe-xl-38 {
    padding-inline-end: 9.5rem !important;
  }
  .pe-xl-39 {
    padding-inline-end: 9.75rem !important;
  }
  .pe-xl-40 {
    padding-inline-end: 10rem !important;
  }
  .pb-xl-0 {
    padding-block-end: 0 !important;
  }
  .pb-xl-1 {
    padding-block-end: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-block-end: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-block-end: 0.75rem !important;
  }
  .pb-xl-4 {
    padding-block-end: 1rem !important;
  }
  .pb-xl-5 {
    padding-block-end: 1.25rem !important;
  }
  .pb-xl-6 {
    padding-block-end: 1.5rem !important;
  }
  .pb-xl-7 {
    padding-block-end: 1.75rem !important;
  }
  .pb-xl-8 {
    padding-block-end: 2rem !important;
  }
  .pb-xl-9 {
    padding-block-end: 2.25rem !important;
  }
  .pb-xl-10 {
    padding-block-end: 2.5rem !important;
  }
  .pb-xl-11 {
    padding-block-end: 2.75rem !important;
  }
  .pb-xl-12 {
    padding-block-end: 3rem !important;
  }
  .pb-xl-13 {
    padding-block-end: 3.25rem !important;
  }
  .pb-xl-14 {
    padding-block-end: 3.5rem !important;
  }
  .pb-xl-15 {
    padding-block-end: 3.75rem !important;
  }
  .pb-xl-16 {
    padding-block-end: 4rem !important;
  }
  .pb-xl-17 {
    padding-block-end: 4.25rem !important;
  }
  .pb-xl-18 {
    padding-block-end: 4.5rem !important;
  }
  .pb-xl-19 {
    padding-block-end: 4.75rem !important;
  }
  .pb-xl-20 {
    padding-block-end: 5rem !important;
  }
  .pb-xl-21 {
    padding-block-end: 5.25rem !important;
  }
  .pb-xl-22 {
    padding-block-end: 5.5rem !important;
  }
  .pb-xl-23 {
    padding-block-end: 5.75rem !important;
  }
  .pb-xl-24 {
    padding-block-end: 6rem !important;
  }
  .pb-xl-25 {
    padding-block-end: 6.25rem !important;
  }
  .pb-xl-26 {
    padding-block-end: 6.5rem !important;
  }
  .pb-xl-27 {
    padding-block-end: 6.75rem !important;
  }
  .pb-xl-28 {
    padding-block-end: 7rem !important;
  }
  .pb-xl-29 {
    padding-block-end: 7.25rem !important;
  }
  .pb-xl-30 {
    padding-block-end: 7.5rem !important;
  }
  .pb-xl-31 {
    padding-block-end: 7.75rem !important;
  }
  .pb-xl-32 {
    padding-block-end: 8rem !important;
  }
  .pb-xl-33 {
    padding-block-end: 8.25rem !important;
  }
  .pb-xl-34 {
    padding-block-end: 8.5rem !important;
  }
  .pb-xl-35 {
    padding-block-end: 8.75rem !important;
  }
  .pb-xl-36 {
    padding-block-end: 9rem !important;
  }
  .pb-xl-37 {
    padding-block-end: 9.25rem !important;
  }
  .pb-xl-38 {
    padding-block-end: 9.5rem !important;
  }
  .pb-xl-39 {
    padding-block-end: 9.75rem !important;
  }
  .pb-xl-40 {
    padding-block-end: 10rem !important;
  }
  .ps-xl-0 {
    padding-inline-start: 0 !important;
  }
  .ps-xl-1 {
    padding-inline-start: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-inline-start: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-inline-start: 0.75rem !important;
  }
  .ps-xl-4 {
    padding-inline-start: 1rem !important;
  }
  .ps-xl-5 {
    padding-inline-start: 1.25rem !important;
  }
  .ps-xl-6 {
    padding-inline-start: 1.5rem !important;
  }
  .ps-xl-7 {
    padding-inline-start: 1.75rem !important;
  }
  .ps-xl-8 {
    padding-inline-start: 2rem !important;
  }
  .ps-xl-9 {
    padding-inline-start: 2.25rem !important;
  }
  .ps-xl-10 {
    padding-inline-start: 2.5rem !important;
  }
  .ps-xl-11 {
    padding-inline-start: 2.75rem !important;
  }
  .ps-xl-12 {
    padding-inline-start: 3rem !important;
  }
  .ps-xl-13 {
    padding-inline-start: 3.25rem !important;
  }
  .ps-xl-14 {
    padding-inline-start: 3.5rem !important;
  }
  .ps-xl-15 {
    padding-inline-start: 3.75rem !important;
  }
  .ps-xl-16 {
    padding-inline-start: 4rem !important;
  }
  .ps-xl-17 {
    padding-inline-start: 4.25rem !important;
  }
  .ps-xl-18 {
    padding-inline-start: 4.5rem !important;
  }
  .ps-xl-19 {
    padding-inline-start: 4.75rem !important;
  }
  .ps-xl-20 {
    padding-inline-start: 5rem !important;
  }
  .ps-xl-21 {
    padding-inline-start: 5.25rem !important;
  }
  .ps-xl-22 {
    padding-inline-start: 5.5rem !important;
  }
  .ps-xl-23 {
    padding-inline-start: 5.75rem !important;
  }
  .ps-xl-24 {
    padding-inline-start: 6rem !important;
  }
  .ps-xl-25 {
    padding-inline-start: 6.25rem !important;
  }
  .ps-xl-26 {
    padding-inline-start: 6.5rem !important;
  }
  .ps-xl-27 {
    padding-inline-start: 6.75rem !important;
  }
  .ps-xl-28 {
    padding-inline-start: 7rem !important;
  }
  .ps-xl-29 {
    padding-inline-start: 7.25rem !important;
  }
  .ps-xl-30 {
    padding-inline-start: 7.5rem !important;
  }
  .ps-xl-31 {
    padding-inline-start: 7.75rem !important;
  }
  .ps-xl-32 {
    padding-inline-start: 8rem !important;
  }
  .ps-xl-33 {
    padding-inline-start: 8.25rem !important;
  }
  .ps-xl-34 {
    padding-inline-start: 8.5rem !important;
  }
  .ps-xl-35 {
    padding-inline-start: 8.75rem !important;
  }
  .ps-xl-36 {
    padding-inline-start: 9rem !important;
  }
  .ps-xl-37 {
    padding-inline-start: 9.25rem !important;
  }
  .ps-xl-38 {
    padding-inline-start: 9.5rem !important;
  }
  .ps-xl-39 {
    padding-inline-start: 9.75rem !important;
  }
  .ps-xl-40 {
    padding-inline-start: 10rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 0.75rem !important;
  }
  .gap-xl-4 {
    gap: 1rem !important;
  }
  .gap-xl-5 {
    gap: 1.25rem !important;
  }
  .gap-xl-6 {
    gap: 1.5rem !important;
  }
  .gap-xl-7 {
    gap: 1.75rem !important;
  }
  .gap-xl-8 {
    gap: 2rem !important;
  }
  .gap-xl-9 {
    gap: 2.25rem !important;
  }
  .gap-xl-10 {
    gap: 2.5rem !important;
  }
  .gap-xl-11 {
    gap: 2.75rem !important;
  }
  .gap-xl-12 {
    gap: 3rem !important;
  }
  .gap-xl-13 {
    gap: 3.25rem !important;
  }
  .gap-xl-14 {
    gap: 3.5rem !important;
  }
  .gap-xl-15 {
    gap: 3.75rem !important;
  }
  .gap-xl-16 {
    gap: 4rem !important;
  }
  .gap-xl-17 {
    gap: 4.25rem !important;
  }
  .gap-xl-18 {
    gap: 4.5rem !important;
  }
  .gap-xl-19 {
    gap: 4.75rem !important;
  }
  .gap-xl-20 {
    gap: 5rem !important;
  }
  .gap-xl-21 {
    gap: 5.25rem !important;
  }
  .gap-xl-22 {
    gap: 5.5rem !important;
  }
  .gap-xl-23 {
    gap: 5.75rem !important;
  }
  .gap-xl-24 {
    gap: 6rem !important;
  }
  .gap-xl-25 {
    gap: 6.25rem !important;
  }
  .gap-xl-26 {
    gap: 6.5rem !important;
  }
  .gap-xl-27 {
    gap: 6.75rem !important;
  }
  .gap-xl-28 {
    gap: 7rem !important;
  }
  .gap-xl-29 {
    gap: 7.25rem !important;
  }
  .gap-xl-30 {
    gap: 7.5rem !important;
  }
  .gap-xl-31 {
    gap: 7.75rem !important;
  }
  .gap-xl-32 {
    gap: 8rem !important;
  }
  .gap-xl-33 {
    gap: 8.25rem !important;
  }
  .gap-xl-34 {
    gap: 8.5rem !important;
  }
  .gap-xl-35 {
    gap: 8.75rem !important;
  }
  .gap-xl-36 {
    gap: 9rem !important;
  }
  .gap-xl-37 {
    gap: 9.25rem !important;
  }
  .gap-xl-38 {
    gap: 9.5rem !important;
  }
  .gap-xl-39 {
    gap: 9.75rem !important;
  }
  .gap-xl-40 {
    gap: 10rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 2rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xl-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-xl-12 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-xl-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xl-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-xl-16 {
    row-gap: 4rem !important;
  }
  .row-gap-xl-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-xl-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xl-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-xl-20 {
    row-gap: 5rem !important;
  }
  .row-gap-xl-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-xl-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-xl-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-xl-24 {
    row-gap: 6rem !important;
  }
  .row-gap-xl-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-xl-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-xl-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-xl-28 {
    row-gap: 7rem !important;
  }
  .row-gap-xl-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-xl-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-xl-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-xl-32 {
    row-gap: 8rem !important;
  }
  .row-gap-xl-33 {
    row-gap: 8.25rem !important;
  }
  .row-gap-xl-34 {
    row-gap: 8.5rem !important;
  }
  .row-gap-xl-35 {
    row-gap: 8.75rem !important;
  }
  .row-gap-xl-36 {
    row-gap: 9rem !important;
  }
  .row-gap-xl-37 {
    row-gap: 9.25rem !important;
  }
  .row-gap-xl-38 {
    row-gap: 9.5rem !important;
  }
  .row-gap-xl-39 {
    row-gap: 9.75rem !important;
  }
  .row-gap-xl-40 {
    row-gap: 10rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 2rem !important;
  }
  .column-gap-xl-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xl-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xl-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-xl-12 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-xl-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xl-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-xl-16 {
    column-gap: 4rem !important;
  }
  .column-gap-xl-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-xl-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xl-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-xl-20 {
    column-gap: 5rem !important;
  }
  .column-gap-xl-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-xl-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-xl-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-xl-24 {
    column-gap: 6rem !important;
  }
  .column-gap-xl-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-xl-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-xl-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-xl-28 {
    column-gap: 7rem !important;
  }
  .column-gap-xl-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-xl-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-xl-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-xl-32 {
    column-gap: 8rem !important;
  }
  .column-gap-xl-33 {
    column-gap: 8.25rem !important;
  }
  .column-gap-xl-34 {
    column-gap: 8.5rem !important;
  }
  .column-gap-xl-35 {
    column-gap: 8.75rem !important;
  }
  .column-gap-xl-36 {
    column-gap: 9rem !important;
  }
  .column-gap-xl-37 {
    column-gap: 9.25rem !important;
  }
  .column-gap-xl-38 {
    column-gap: 9.5rem !important;
  }
  .column-gap-xl-39 {
    column-gap: 9.75rem !important;
  }
  .column-gap-xl-40 {
    column-gap: 10rem !important;
  }
  .text-xl-start {
    text-align: start !important;
  }
  .text-xl-end {
    text-align: end !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .border-xxl {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 0.75rem !important;
  }
  .m-xxl-4 {
    margin: 1rem !important;
  }
  .m-xxl-5 {
    margin: 1.25rem !important;
  }
  .m-xxl-6 {
    margin: 1.5rem !important;
  }
  .m-xxl-7 {
    margin: 1.75rem !important;
  }
  .m-xxl-8 {
    margin: 2rem !important;
  }
  .m-xxl-9 {
    margin: 2.25rem !important;
  }
  .m-xxl-10 {
    margin: 2.5rem !important;
  }
  .m-xxl-11 {
    margin: 2.75rem !important;
  }
  .m-xxl-12 {
    margin: 3rem !important;
  }
  .m-xxl-13 {
    margin: 3.25rem !important;
  }
  .m-xxl-14 {
    margin: 3.5rem !important;
  }
  .m-xxl-15 {
    margin: 3.75rem !important;
  }
  .m-xxl-16 {
    margin: 4rem !important;
  }
  .m-xxl-17 {
    margin: 4.25rem !important;
  }
  .m-xxl-18 {
    margin: 4.5rem !important;
  }
  .m-xxl-19 {
    margin: 4.75rem !important;
  }
  .m-xxl-20 {
    margin: 5rem !important;
  }
  .m-xxl-21 {
    margin: 5.25rem !important;
  }
  .m-xxl-22 {
    margin: 5.5rem !important;
  }
  .m-xxl-23 {
    margin: 5.75rem !important;
  }
  .m-xxl-24 {
    margin: 6rem !important;
  }
  .m-xxl-25 {
    margin: 6.25rem !important;
  }
  .m-xxl-26 {
    margin: 6.5rem !important;
  }
  .m-xxl-27 {
    margin: 6.75rem !important;
  }
  .m-xxl-28 {
    margin: 7rem !important;
  }
  .m-xxl-29 {
    margin: 7.25rem !important;
  }
  .m-xxl-30 {
    margin: 7.5rem !important;
  }
  .m-xxl-31 {
    margin: 7.75rem !important;
  }
  .m-xxl-32 {
    margin: 8rem !important;
  }
  .m-xxl-33 {
    margin: 8.25rem !important;
  }
  .m-xxl-34 {
    margin: 8.5rem !important;
  }
  .m-xxl-35 {
    margin: 8.75rem !important;
  }
  .m-xxl-36 {
    margin: 9rem !important;
  }
  .m-xxl-37 {
    margin: 9.25rem !important;
  }
  .m-xxl-38 {
    margin: 9.5rem !important;
  }
  .m-xxl-39 {
    margin: 9.75rem !important;
  }
  .m-xxl-40 {
    margin: 10rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-inline: 0 !important;
  }
  .mx-xxl-1 {
    margin-inline: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-inline: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-inline: 0.75rem !important;
  }
  .mx-xxl-4 {
    margin-inline: 1rem !important;
  }
  .mx-xxl-5 {
    margin-inline: 1.25rem !important;
  }
  .mx-xxl-6 {
    margin-inline: 1.5rem !important;
  }
  .mx-xxl-7 {
    margin-inline: 1.75rem !important;
  }
  .mx-xxl-8 {
    margin-inline: 2rem !important;
  }
  .mx-xxl-9 {
    margin-inline: 2.25rem !important;
  }
  .mx-xxl-10 {
    margin-inline: 2.5rem !important;
  }
  .mx-xxl-11 {
    margin-inline: 2.75rem !important;
  }
  .mx-xxl-12 {
    margin-inline: 3rem !important;
  }
  .mx-xxl-13 {
    margin-inline: 3.25rem !important;
  }
  .mx-xxl-14 {
    margin-inline: 3.5rem !important;
  }
  .mx-xxl-15 {
    margin-inline: 3.75rem !important;
  }
  .mx-xxl-16 {
    margin-inline: 4rem !important;
  }
  .mx-xxl-17 {
    margin-inline: 4.25rem !important;
  }
  .mx-xxl-18 {
    margin-inline: 4.5rem !important;
  }
  .mx-xxl-19 {
    margin-inline: 4.75rem !important;
  }
  .mx-xxl-20 {
    margin-inline: 5rem !important;
  }
  .mx-xxl-21 {
    margin-inline: 5.25rem !important;
  }
  .mx-xxl-22 {
    margin-inline: 5.5rem !important;
  }
  .mx-xxl-23 {
    margin-inline: 5.75rem !important;
  }
  .mx-xxl-24 {
    margin-inline: 6rem !important;
  }
  .mx-xxl-25 {
    margin-inline: 6.25rem !important;
  }
  .mx-xxl-26 {
    margin-inline: 6.5rem !important;
  }
  .mx-xxl-27 {
    margin-inline: 6.75rem !important;
  }
  .mx-xxl-28 {
    margin-inline: 7rem !important;
  }
  .mx-xxl-29 {
    margin-inline: 7.25rem !important;
  }
  .mx-xxl-30 {
    margin-inline: 7.5rem !important;
  }
  .mx-xxl-31 {
    margin-inline: 7.75rem !important;
  }
  .mx-xxl-32 {
    margin-inline: 8rem !important;
  }
  .mx-xxl-33 {
    margin-inline: 8.25rem !important;
  }
  .mx-xxl-34 {
    margin-inline: 8.5rem !important;
  }
  .mx-xxl-35 {
    margin-inline: 8.75rem !important;
  }
  .mx-xxl-36 {
    margin-inline: 9rem !important;
  }
  .mx-xxl-37 {
    margin-inline: 9.25rem !important;
  }
  .mx-xxl-38 {
    margin-inline: 9.5rem !important;
  }
  .mx-xxl-39 {
    margin-inline: 9.75rem !important;
  }
  .mx-xxl-40 {
    margin-inline: 10rem !important;
  }
  .mx-xxl-auto {
    margin-inline: auto !important;
  }
  .my-xxl-0 {
    margin-block: 0 !important;
  }
  .my-xxl-1 {
    margin-block: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-block: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-block: 0.75rem !important;
  }
  .my-xxl-4 {
    margin-block: 1rem !important;
  }
  .my-xxl-5 {
    margin-block: 1.25rem !important;
  }
  .my-xxl-6 {
    margin-block: 1.5rem !important;
  }
  .my-xxl-7 {
    margin-block: 1.75rem !important;
  }
  .my-xxl-8 {
    margin-block: 2rem !important;
  }
  .my-xxl-9 {
    margin-block: 2.25rem !important;
  }
  .my-xxl-10 {
    margin-block: 2.5rem !important;
  }
  .my-xxl-11 {
    margin-block: 2.75rem !important;
  }
  .my-xxl-12 {
    margin-block: 3rem !important;
  }
  .my-xxl-13 {
    margin-block: 3.25rem !important;
  }
  .my-xxl-14 {
    margin-block: 3.5rem !important;
  }
  .my-xxl-15 {
    margin-block: 3.75rem !important;
  }
  .my-xxl-16 {
    margin-block: 4rem !important;
  }
  .my-xxl-17 {
    margin-block: 4.25rem !important;
  }
  .my-xxl-18 {
    margin-block: 4.5rem !important;
  }
  .my-xxl-19 {
    margin-block: 4.75rem !important;
  }
  .my-xxl-20 {
    margin-block: 5rem !important;
  }
  .my-xxl-21 {
    margin-block: 5.25rem !important;
  }
  .my-xxl-22 {
    margin-block: 5.5rem !important;
  }
  .my-xxl-23 {
    margin-block: 5.75rem !important;
  }
  .my-xxl-24 {
    margin-block: 6rem !important;
  }
  .my-xxl-25 {
    margin-block: 6.25rem !important;
  }
  .my-xxl-26 {
    margin-block: 6.5rem !important;
  }
  .my-xxl-27 {
    margin-block: 6.75rem !important;
  }
  .my-xxl-28 {
    margin-block: 7rem !important;
  }
  .my-xxl-29 {
    margin-block: 7.25rem !important;
  }
  .my-xxl-30 {
    margin-block: 7.5rem !important;
  }
  .my-xxl-31 {
    margin-block: 7.75rem !important;
  }
  .my-xxl-32 {
    margin-block: 8rem !important;
  }
  .my-xxl-33 {
    margin-block: 8.25rem !important;
  }
  .my-xxl-34 {
    margin-block: 8.5rem !important;
  }
  .my-xxl-35 {
    margin-block: 8.75rem !important;
  }
  .my-xxl-36 {
    margin-block: 9rem !important;
  }
  .my-xxl-37 {
    margin-block: 9.25rem !important;
  }
  .my-xxl-38 {
    margin-block: 9.5rem !important;
  }
  .my-xxl-39 {
    margin-block: 9.75rem !important;
  }
  .my-xxl-40 {
    margin-block: 10rem !important;
  }
  .my-xxl-auto {
    margin-block: auto !important;
  }
  .mt-xxl-0 {
    margin-block-start: 0 !important;
  }
  .mt-xxl-1 {
    margin-block-start: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-block-start: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-block-start: 0.75rem !important;
  }
  .mt-xxl-4 {
    margin-block-start: 1rem !important;
  }
  .mt-xxl-5 {
    margin-block-start: 1.25rem !important;
  }
  .mt-xxl-6 {
    margin-block-start: 1.5rem !important;
  }
  .mt-xxl-7 {
    margin-block-start: 1.75rem !important;
  }
  .mt-xxl-8 {
    margin-block-start: 2rem !important;
  }
  .mt-xxl-9 {
    margin-block-start: 2.25rem !important;
  }
  .mt-xxl-10 {
    margin-block-start: 2.5rem !important;
  }
  .mt-xxl-11 {
    margin-block-start: 2.75rem !important;
  }
  .mt-xxl-12 {
    margin-block-start: 3rem !important;
  }
  .mt-xxl-13 {
    margin-block-start: 3.25rem !important;
  }
  .mt-xxl-14 {
    margin-block-start: 3.5rem !important;
  }
  .mt-xxl-15 {
    margin-block-start: 3.75rem !important;
  }
  .mt-xxl-16 {
    margin-block-start: 4rem !important;
  }
  .mt-xxl-17 {
    margin-block-start: 4.25rem !important;
  }
  .mt-xxl-18 {
    margin-block-start: 4.5rem !important;
  }
  .mt-xxl-19 {
    margin-block-start: 4.75rem !important;
  }
  .mt-xxl-20 {
    margin-block-start: 5rem !important;
  }
  .mt-xxl-21 {
    margin-block-start: 5.25rem !important;
  }
  .mt-xxl-22 {
    margin-block-start: 5.5rem !important;
  }
  .mt-xxl-23 {
    margin-block-start: 5.75rem !important;
  }
  .mt-xxl-24 {
    margin-block-start: 6rem !important;
  }
  .mt-xxl-25 {
    margin-block-start: 6.25rem !important;
  }
  .mt-xxl-26 {
    margin-block-start: 6.5rem !important;
  }
  .mt-xxl-27 {
    margin-block-start: 6.75rem !important;
  }
  .mt-xxl-28 {
    margin-block-start: 7rem !important;
  }
  .mt-xxl-29 {
    margin-block-start: 7.25rem !important;
  }
  .mt-xxl-30 {
    margin-block-start: 7.5rem !important;
  }
  .mt-xxl-31 {
    margin-block-start: 7.75rem !important;
  }
  .mt-xxl-32 {
    margin-block-start: 8rem !important;
  }
  .mt-xxl-33 {
    margin-block-start: 8.25rem !important;
  }
  .mt-xxl-34 {
    margin-block-start: 8.5rem !important;
  }
  .mt-xxl-35 {
    margin-block-start: 8.75rem !important;
  }
  .mt-xxl-36 {
    margin-block-start: 9rem !important;
  }
  .mt-xxl-37 {
    margin-block-start: 9.25rem !important;
  }
  .mt-xxl-38 {
    margin-block-start: 9.5rem !important;
  }
  .mt-xxl-39 {
    margin-block-start: 9.75rem !important;
  }
  .mt-xxl-40 {
    margin-block-start: 10rem !important;
  }
  .mt-xxl-auto {
    margin-block-start: auto !important;
  }
  .me-xxl-0 {
    margin-inline-end: 0 !important;
  }
  .me-xxl-1 {
    margin-inline-end: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-inline-end: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-inline-end: 0.75rem !important;
  }
  .me-xxl-4 {
    margin-inline-end: 1rem !important;
  }
  .me-xxl-5 {
    margin-inline-end: 1.25rem !important;
  }
  .me-xxl-6 {
    margin-inline-end: 1.5rem !important;
  }
  .me-xxl-7 {
    margin-inline-end: 1.75rem !important;
  }
  .me-xxl-8 {
    margin-inline-end: 2rem !important;
  }
  .me-xxl-9 {
    margin-inline-end: 2.25rem !important;
  }
  .me-xxl-10 {
    margin-inline-end: 2.5rem !important;
  }
  .me-xxl-11 {
    margin-inline-end: 2.75rem !important;
  }
  .me-xxl-12 {
    margin-inline-end: 3rem !important;
  }
  .me-xxl-13 {
    margin-inline-end: 3.25rem !important;
  }
  .me-xxl-14 {
    margin-inline-end: 3.5rem !important;
  }
  .me-xxl-15 {
    margin-inline-end: 3.75rem !important;
  }
  .me-xxl-16 {
    margin-inline-end: 4rem !important;
  }
  .me-xxl-17 {
    margin-inline-end: 4.25rem !important;
  }
  .me-xxl-18 {
    margin-inline-end: 4.5rem !important;
  }
  .me-xxl-19 {
    margin-inline-end: 4.75rem !important;
  }
  .me-xxl-20 {
    margin-inline-end: 5rem !important;
  }
  .me-xxl-21 {
    margin-inline-end: 5.25rem !important;
  }
  .me-xxl-22 {
    margin-inline-end: 5.5rem !important;
  }
  .me-xxl-23 {
    margin-inline-end: 5.75rem !important;
  }
  .me-xxl-24 {
    margin-inline-end: 6rem !important;
  }
  .me-xxl-25 {
    margin-inline-end: 6.25rem !important;
  }
  .me-xxl-26 {
    margin-inline-end: 6.5rem !important;
  }
  .me-xxl-27 {
    margin-inline-end: 6.75rem !important;
  }
  .me-xxl-28 {
    margin-inline-end: 7rem !important;
  }
  .me-xxl-29 {
    margin-inline-end: 7.25rem !important;
  }
  .me-xxl-30 {
    margin-inline-end: 7.5rem !important;
  }
  .me-xxl-31 {
    margin-inline-end: 7.75rem !important;
  }
  .me-xxl-32 {
    margin-inline-end: 8rem !important;
  }
  .me-xxl-33 {
    margin-inline-end: 8.25rem !important;
  }
  .me-xxl-34 {
    margin-inline-end: 8.5rem !important;
  }
  .me-xxl-35 {
    margin-inline-end: 8.75rem !important;
  }
  .me-xxl-36 {
    margin-inline-end: 9rem !important;
  }
  .me-xxl-37 {
    margin-inline-end: 9.25rem !important;
  }
  .me-xxl-38 {
    margin-inline-end: 9.5rem !important;
  }
  .me-xxl-39 {
    margin-inline-end: 9.75rem !important;
  }
  .me-xxl-40 {
    margin-inline-end: 10rem !important;
  }
  .me-xxl-auto {
    margin-inline-end: auto !important;
  }
  .mb-xxl-0 {
    margin-block-end: 0 !important;
  }
  .mb-xxl-1 {
    margin-block-end: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-block-end: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-block-end: 0.75rem !important;
  }
  .mb-xxl-4 {
    margin-block-end: 1rem !important;
  }
  .mb-xxl-5 {
    margin-block-end: 1.25rem !important;
  }
  .mb-xxl-6 {
    margin-block-end: 1.5rem !important;
  }
  .mb-xxl-7 {
    margin-block-end: 1.75rem !important;
  }
  .mb-xxl-8 {
    margin-block-end: 2rem !important;
  }
  .mb-xxl-9 {
    margin-block-end: 2.25rem !important;
  }
  .mb-xxl-10 {
    margin-block-end: 2.5rem !important;
  }
  .mb-xxl-11 {
    margin-block-end: 2.75rem !important;
  }
  .mb-xxl-12 {
    margin-block-end: 3rem !important;
  }
  .mb-xxl-13 {
    margin-block-end: 3.25rem !important;
  }
  .mb-xxl-14 {
    margin-block-end: 3.5rem !important;
  }
  .mb-xxl-15 {
    margin-block-end: 3.75rem !important;
  }
  .mb-xxl-16 {
    margin-block-end: 4rem !important;
  }
  .mb-xxl-17 {
    margin-block-end: 4.25rem !important;
  }
  .mb-xxl-18 {
    margin-block-end: 4.5rem !important;
  }
  .mb-xxl-19 {
    margin-block-end: 4.75rem !important;
  }
  .mb-xxl-20 {
    margin-block-end: 5rem !important;
  }
  .mb-xxl-21 {
    margin-block-end: 5.25rem !important;
  }
  .mb-xxl-22 {
    margin-block-end: 5.5rem !important;
  }
  .mb-xxl-23 {
    margin-block-end: 5.75rem !important;
  }
  .mb-xxl-24 {
    margin-block-end: 6rem !important;
  }
  .mb-xxl-25 {
    margin-block-end: 6.25rem !important;
  }
  .mb-xxl-26 {
    margin-block-end: 6.5rem !important;
  }
  .mb-xxl-27 {
    margin-block-end: 6.75rem !important;
  }
  .mb-xxl-28 {
    margin-block-end: 7rem !important;
  }
  .mb-xxl-29 {
    margin-block-end: 7.25rem !important;
  }
  .mb-xxl-30 {
    margin-block-end: 7.5rem !important;
  }
  .mb-xxl-31 {
    margin-block-end: 7.75rem !important;
  }
  .mb-xxl-32 {
    margin-block-end: 8rem !important;
  }
  .mb-xxl-33 {
    margin-block-end: 8.25rem !important;
  }
  .mb-xxl-34 {
    margin-block-end: 8.5rem !important;
  }
  .mb-xxl-35 {
    margin-block-end: 8.75rem !important;
  }
  .mb-xxl-36 {
    margin-block-end: 9rem !important;
  }
  .mb-xxl-37 {
    margin-block-end: 9.25rem !important;
  }
  .mb-xxl-38 {
    margin-block-end: 9.5rem !important;
  }
  .mb-xxl-39 {
    margin-block-end: 9.75rem !important;
  }
  .mb-xxl-40 {
    margin-block-end: 10rem !important;
  }
  .mb-xxl-auto {
    margin-block-end: auto !important;
  }
  .ms-xxl-0 {
    margin-inline-start: 0 !important;
  }
  .ms-xxl-1 {
    margin-inline-start: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-inline-start: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-inline-start: 0.75rem !important;
  }
  .ms-xxl-4 {
    margin-inline-start: 1rem !important;
  }
  .ms-xxl-5 {
    margin-inline-start: 1.25rem !important;
  }
  .ms-xxl-6 {
    margin-inline-start: 1.5rem !important;
  }
  .ms-xxl-7 {
    margin-inline-start: 1.75rem !important;
  }
  .ms-xxl-8 {
    margin-inline-start: 2rem !important;
  }
  .ms-xxl-9 {
    margin-inline-start: 2.25rem !important;
  }
  .ms-xxl-10 {
    margin-inline-start: 2.5rem !important;
  }
  .ms-xxl-11 {
    margin-inline-start: 2.75rem !important;
  }
  .ms-xxl-12 {
    margin-inline-start: 3rem !important;
  }
  .ms-xxl-13 {
    margin-inline-start: 3.25rem !important;
  }
  .ms-xxl-14 {
    margin-inline-start: 3.5rem !important;
  }
  .ms-xxl-15 {
    margin-inline-start: 3.75rem !important;
  }
  .ms-xxl-16 {
    margin-inline-start: 4rem !important;
  }
  .ms-xxl-17 {
    margin-inline-start: 4.25rem !important;
  }
  .ms-xxl-18 {
    margin-inline-start: 4.5rem !important;
  }
  .ms-xxl-19 {
    margin-inline-start: 4.75rem !important;
  }
  .ms-xxl-20 {
    margin-inline-start: 5rem !important;
  }
  .ms-xxl-21 {
    margin-inline-start: 5.25rem !important;
  }
  .ms-xxl-22 {
    margin-inline-start: 5.5rem !important;
  }
  .ms-xxl-23 {
    margin-inline-start: 5.75rem !important;
  }
  .ms-xxl-24 {
    margin-inline-start: 6rem !important;
  }
  .ms-xxl-25 {
    margin-inline-start: 6.25rem !important;
  }
  .ms-xxl-26 {
    margin-inline-start: 6.5rem !important;
  }
  .ms-xxl-27 {
    margin-inline-start: 6.75rem !important;
  }
  .ms-xxl-28 {
    margin-inline-start: 7rem !important;
  }
  .ms-xxl-29 {
    margin-inline-start: 7.25rem !important;
  }
  .ms-xxl-30 {
    margin-inline-start: 7.5rem !important;
  }
  .ms-xxl-31 {
    margin-inline-start: 7.75rem !important;
  }
  .ms-xxl-32 {
    margin-inline-start: 8rem !important;
  }
  .ms-xxl-33 {
    margin-inline-start: 8.25rem !important;
  }
  .ms-xxl-34 {
    margin-inline-start: 8.5rem !important;
  }
  .ms-xxl-35 {
    margin-inline-start: 8.75rem !important;
  }
  .ms-xxl-36 {
    margin-inline-start: 9rem !important;
  }
  .ms-xxl-37 {
    margin-inline-start: 9.25rem !important;
  }
  .ms-xxl-38 {
    margin-inline-start: 9.5rem !important;
  }
  .ms-xxl-39 {
    margin-inline-start: 9.75rem !important;
  }
  .ms-xxl-40 {
    margin-inline-start: 10rem !important;
  }
  .ms-xxl-auto {
    margin-inline-start: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 0.75rem !important;
  }
  .p-xxl-4 {
    padding: 1rem !important;
  }
  .p-xxl-5 {
    padding: 1.25rem !important;
  }
  .p-xxl-6 {
    padding: 1.5rem !important;
  }
  .p-xxl-7 {
    padding: 1.75rem !important;
  }
  .p-xxl-8 {
    padding: 2rem !important;
  }
  .p-xxl-9 {
    padding: 2.25rem !important;
  }
  .p-xxl-10 {
    padding: 2.5rem !important;
  }
  .p-xxl-11 {
    padding: 2.75rem !important;
  }
  .p-xxl-12 {
    padding: 3rem !important;
  }
  .p-xxl-13 {
    padding: 3.25rem !important;
  }
  .p-xxl-14 {
    padding: 3.5rem !important;
  }
  .p-xxl-15 {
    padding: 3.75rem !important;
  }
  .p-xxl-16 {
    padding: 4rem !important;
  }
  .p-xxl-17 {
    padding: 4.25rem !important;
  }
  .p-xxl-18 {
    padding: 4.5rem !important;
  }
  .p-xxl-19 {
    padding: 4.75rem !important;
  }
  .p-xxl-20 {
    padding: 5rem !important;
  }
  .p-xxl-21 {
    padding: 5.25rem !important;
  }
  .p-xxl-22 {
    padding: 5.5rem !important;
  }
  .p-xxl-23 {
    padding: 5.75rem !important;
  }
  .p-xxl-24 {
    padding: 6rem !important;
  }
  .p-xxl-25 {
    padding: 6.25rem !important;
  }
  .p-xxl-26 {
    padding: 6.5rem !important;
  }
  .p-xxl-27 {
    padding: 6.75rem !important;
  }
  .p-xxl-28 {
    padding: 7rem !important;
  }
  .p-xxl-29 {
    padding: 7.25rem !important;
  }
  .p-xxl-30 {
    padding: 7.5rem !important;
  }
  .p-xxl-31 {
    padding: 7.75rem !important;
  }
  .p-xxl-32 {
    padding: 8rem !important;
  }
  .p-xxl-33 {
    padding: 8.25rem !important;
  }
  .p-xxl-34 {
    padding: 8.5rem !important;
  }
  .p-xxl-35 {
    padding: 8.75rem !important;
  }
  .p-xxl-36 {
    padding: 9rem !important;
  }
  .p-xxl-37 {
    padding: 9.25rem !important;
  }
  .p-xxl-38 {
    padding: 9.5rem !important;
  }
  .p-xxl-39 {
    padding: 9.75rem !important;
  }
  .p-xxl-40 {
    padding: 10rem !important;
  }
  .px-xxl-0 {
    padding-inline: 0 !important;
  }
  .px-xxl-1 {
    padding-inline: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-inline: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-inline: 0.75rem !important;
  }
  .px-xxl-4 {
    padding-inline: 1rem !important;
  }
  .px-xxl-5 {
    padding-inline: 1.25rem !important;
  }
  .px-xxl-6 {
    padding-inline: 1.5rem !important;
  }
  .px-xxl-7 {
    padding-inline: 1.75rem !important;
  }
  .px-xxl-8 {
    padding-inline: 2rem !important;
  }
  .px-xxl-9 {
    padding-inline: 2.25rem !important;
  }
  .px-xxl-10 {
    padding-inline: 2.5rem !important;
  }
  .px-xxl-11 {
    padding-inline: 2.75rem !important;
  }
  .px-xxl-12 {
    padding-inline: 3rem !important;
  }
  .px-xxl-13 {
    padding-inline: 3.25rem !important;
  }
  .px-xxl-14 {
    padding-inline: 3.5rem !important;
  }
  .px-xxl-15 {
    padding-inline: 3.75rem !important;
  }
  .px-xxl-16 {
    padding-inline: 4rem !important;
  }
  .px-xxl-17 {
    padding-inline: 4.25rem !important;
  }
  .px-xxl-18 {
    padding-inline: 4.5rem !important;
  }
  .px-xxl-19 {
    padding-inline: 4.75rem !important;
  }
  .px-xxl-20 {
    padding-inline: 5rem !important;
  }
  .px-xxl-21 {
    padding-inline: 5.25rem !important;
  }
  .px-xxl-22 {
    padding-inline: 5.5rem !important;
  }
  .px-xxl-23 {
    padding-inline: 5.75rem !important;
  }
  .px-xxl-24 {
    padding-inline: 6rem !important;
  }
  .px-xxl-25 {
    padding-inline: 6.25rem !important;
  }
  .px-xxl-26 {
    padding-inline: 6.5rem !important;
  }
  .px-xxl-27 {
    padding-inline: 6.75rem !important;
  }
  .px-xxl-28 {
    padding-inline: 7rem !important;
  }
  .px-xxl-29 {
    padding-inline: 7.25rem !important;
  }
  .px-xxl-30 {
    padding-inline: 7.5rem !important;
  }
  .px-xxl-31 {
    padding-inline: 7.75rem !important;
  }
  .px-xxl-32 {
    padding-inline: 8rem !important;
  }
  .px-xxl-33 {
    padding-inline: 8.25rem !important;
  }
  .px-xxl-34 {
    padding-inline: 8.5rem !important;
  }
  .px-xxl-35 {
    padding-inline: 8.75rem !important;
  }
  .px-xxl-36 {
    padding-inline: 9rem !important;
  }
  .px-xxl-37 {
    padding-inline: 9.25rem !important;
  }
  .px-xxl-38 {
    padding-inline: 9.5rem !important;
  }
  .px-xxl-39 {
    padding-inline: 9.75rem !important;
  }
  .px-xxl-40 {
    padding-inline: 10rem !important;
  }
  .py-xxl-0 {
    padding-block: 0 !important;
  }
  .py-xxl-1 {
    padding-block: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-block: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-block: 0.75rem !important;
  }
  .py-xxl-4 {
    padding-block: 1rem !important;
  }
  .py-xxl-5 {
    padding-block: 1.25rem !important;
  }
  .py-xxl-6 {
    padding-block: 1.5rem !important;
  }
  .py-xxl-7 {
    padding-block: 1.75rem !important;
  }
  .py-xxl-8 {
    padding-block: 2rem !important;
  }
  .py-xxl-9 {
    padding-block: 2.25rem !important;
  }
  .py-xxl-10 {
    padding-block: 2.5rem !important;
  }
  .py-xxl-11 {
    padding-block: 2.75rem !important;
  }
  .py-xxl-12 {
    padding-block: 3rem !important;
  }
  .py-xxl-13 {
    padding-block: 3.25rem !important;
  }
  .py-xxl-14 {
    padding-block: 3.5rem !important;
  }
  .py-xxl-15 {
    padding-block: 3.75rem !important;
  }
  .py-xxl-16 {
    padding-block: 4rem !important;
  }
  .py-xxl-17 {
    padding-block: 4.25rem !important;
  }
  .py-xxl-18 {
    padding-block: 4.5rem !important;
  }
  .py-xxl-19 {
    padding-block: 4.75rem !important;
  }
  .py-xxl-20 {
    padding-block: 5rem !important;
  }
  .py-xxl-21 {
    padding-block: 5.25rem !important;
  }
  .py-xxl-22 {
    padding-block: 5.5rem !important;
  }
  .py-xxl-23 {
    padding-block: 5.75rem !important;
  }
  .py-xxl-24 {
    padding-block: 6rem !important;
  }
  .py-xxl-25 {
    padding-block: 6.25rem !important;
  }
  .py-xxl-26 {
    padding-block: 6.5rem !important;
  }
  .py-xxl-27 {
    padding-block: 6.75rem !important;
  }
  .py-xxl-28 {
    padding-block: 7rem !important;
  }
  .py-xxl-29 {
    padding-block: 7.25rem !important;
  }
  .py-xxl-30 {
    padding-block: 7.5rem !important;
  }
  .py-xxl-31 {
    padding-block: 7.75rem !important;
  }
  .py-xxl-32 {
    padding-block: 8rem !important;
  }
  .py-xxl-33 {
    padding-block: 8.25rem !important;
  }
  .py-xxl-34 {
    padding-block: 8.5rem !important;
  }
  .py-xxl-35 {
    padding-block: 8.75rem !important;
  }
  .py-xxl-36 {
    padding-block: 9rem !important;
  }
  .py-xxl-37 {
    padding-block: 9.25rem !important;
  }
  .py-xxl-38 {
    padding-block: 9.5rem !important;
  }
  .py-xxl-39 {
    padding-block: 9.75rem !important;
  }
  .py-xxl-40 {
    padding-block: 10rem !important;
  }
  .pt-xxl-0 {
    padding-block-start: 0 !important;
  }
  .pt-xxl-1 {
    padding-block-start: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-block-start: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-block-start: 0.75rem !important;
  }
  .pt-xxl-4 {
    padding-block-start: 1rem !important;
  }
  .pt-xxl-5 {
    padding-block-start: 1.25rem !important;
  }
  .pt-xxl-6 {
    padding-block-start: 1.5rem !important;
  }
  .pt-xxl-7 {
    padding-block-start: 1.75rem !important;
  }
  .pt-xxl-8 {
    padding-block-start: 2rem !important;
  }
  .pt-xxl-9 {
    padding-block-start: 2.25rem !important;
  }
  .pt-xxl-10 {
    padding-block-start: 2.5rem !important;
  }
  .pt-xxl-11 {
    padding-block-start: 2.75rem !important;
  }
  .pt-xxl-12 {
    padding-block-start: 3rem !important;
  }
  .pt-xxl-13 {
    padding-block-start: 3.25rem !important;
  }
  .pt-xxl-14 {
    padding-block-start: 3.5rem !important;
  }
  .pt-xxl-15 {
    padding-block-start: 3.75rem !important;
  }
  .pt-xxl-16 {
    padding-block-start: 4rem !important;
  }
  .pt-xxl-17 {
    padding-block-start: 4.25rem !important;
  }
  .pt-xxl-18 {
    padding-block-start: 4.5rem !important;
  }
  .pt-xxl-19 {
    padding-block-start: 4.75rem !important;
  }
  .pt-xxl-20 {
    padding-block-start: 5rem !important;
  }
  .pt-xxl-21 {
    padding-block-start: 5.25rem !important;
  }
  .pt-xxl-22 {
    padding-block-start: 5.5rem !important;
  }
  .pt-xxl-23 {
    padding-block-start: 5.75rem !important;
  }
  .pt-xxl-24 {
    padding-block-start: 6rem !important;
  }
  .pt-xxl-25 {
    padding-block-start: 6.25rem !important;
  }
  .pt-xxl-26 {
    padding-block-start: 6.5rem !important;
  }
  .pt-xxl-27 {
    padding-block-start: 6.75rem !important;
  }
  .pt-xxl-28 {
    padding-block-start: 7rem !important;
  }
  .pt-xxl-29 {
    padding-block-start: 7.25rem !important;
  }
  .pt-xxl-30 {
    padding-block-start: 7.5rem !important;
  }
  .pt-xxl-31 {
    padding-block-start: 7.75rem !important;
  }
  .pt-xxl-32 {
    padding-block-start: 8rem !important;
  }
  .pt-xxl-33 {
    padding-block-start: 8.25rem !important;
  }
  .pt-xxl-34 {
    padding-block-start: 8.5rem !important;
  }
  .pt-xxl-35 {
    padding-block-start: 8.75rem !important;
  }
  .pt-xxl-36 {
    padding-block-start: 9rem !important;
  }
  .pt-xxl-37 {
    padding-block-start: 9.25rem !important;
  }
  .pt-xxl-38 {
    padding-block-start: 9.5rem !important;
  }
  .pt-xxl-39 {
    padding-block-start: 9.75rem !important;
  }
  .pt-xxl-40 {
    padding-block-start: 10rem !important;
  }
  .pe-xxl-0 {
    padding-inline-end: 0 !important;
  }
  .pe-xxl-1 {
    padding-inline-end: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-inline-end: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-inline-end: 0.75rem !important;
  }
  .pe-xxl-4 {
    padding-inline-end: 1rem !important;
  }
  .pe-xxl-5 {
    padding-inline-end: 1.25rem !important;
  }
  .pe-xxl-6 {
    padding-inline-end: 1.5rem !important;
  }
  .pe-xxl-7 {
    padding-inline-end: 1.75rem !important;
  }
  .pe-xxl-8 {
    padding-inline-end: 2rem !important;
  }
  .pe-xxl-9 {
    padding-inline-end: 2.25rem !important;
  }
  .pe-xxl-10 {
    padding-inline-end: 2.5rem !important;
  }
  .pe-xxl-11 {
    padding-inline-end: 2.75rem !important;
  }
  .pe-xxl-12 {
    padding-inline-end: 3rem !important;
  }
  .pe-xxl-13 {
    padding-inline-end: 3.25rem !important;
  }
  .pe-xxl-14 {
    padding-inline-end: 3.5rem !important;
  }
  .pe-xxl-15 {
    padding-inline-end: 3.75rem !important;
  }
  .pe-xxl-16 {
    padding-inline-end: 4rem !important;
  }
  .pe-xxl-17 {
    padding-inline-end: 4.25rem !important;
  }
  .pe-xxl-18 {
    padding-inline-end: 4.5rem !important;
  }
  .pe-xxl-19 {
    padding-inline-end: 4.75rem !important;
  }
  .pe-xxl-20 {
    padding-inline-end: 5rem !important;
  }
  .pe-xxl-21 {
    padding-inline-end: 5.25rem !important;
  }
  .pe-xxl-22 {
    padding-inline-end: 5.5rem !important;
  }
  .pe-xxl-23 {
    padding-inline-end: 5.75rem !important;
  }
  .pe-xxl-24 {
    padding-inline-end: 6rem !important;
  }
  .pe-xxl-25 {
    padding-inline-end: 6.25rem !important;
  }
  .pe-xxl-26 {
    padding-inline-end: 6.5rem !important;
  }
  .pe-xxl-27 {
    padding-inline-end: 6.75rem !important;
  }
  .pe-xxl-28 {
    padding-inline-end: 7rem !important;
  }
  .pe-xxl-29 {
    padding-inline-end: 7.25rem !important;
  }
  .pe-xxl-30 {
    padding-inline-end: 7.5rem !important;
  }
  .pe-xxl-31 {
    padding-inline-end: 7.75rem !important;
  }
  .pe-xxl-32 {
    padding-inline-end: 8rem !important;
  }
  .pe-xxl-33 {
    padding-inline-end: 8.25rem !important;
  }
  .pe-xxl-34 {
    padding-inline-end: 8.5rem !important;
  }
  .pe-xxl-35 {
    padding-inline-end: 8.75rem !important;
  }
  .pe-xxl-36 {
    padding-inline-end: 9rem !important;
  }
  .pe-xxl-37 {
    padding-inline-end: 9.25rem !important;
  }
  .pe-xxl-38 {
    padding-inline-end: 9.5rem !important;
  }
  .pe-xxl-39 {
    padding-inline-end: 9.75rem !important;
  }
  .pe-xxl-40 {
    padding-inline-end: 10rem !important;
  }
  .pb-xxl-0 {
    padding-block-end: 0 !important;
  }
  .pb-xxl-1 {
    padding-block-end: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-block-end: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-block-end: 0.75rem !important;
  }
  .pb-xxl-4 {
    padding-block-end: 1rem !important;
  }
  .pb-xxl-5 {
    padding-block-end: 1.25rem !important;
  }
  .pb-xxl-6 {
    padding-block-end: 1.5rem !important;
  }
  .pb-xxl-7 {
    padding-block-end: 1.75rem !important;
  }
  .pb-xxl-8 {
    padding-block-end: 2rem !important;
  }
  .pb-xxl-9 {
    padding-block-end: 2.25rem !important;
  }
  .pb-xxl-10 {
    padding-block-end: 2.5rem !important;
  }
  .pb-xxl-11 {
    padding-block-end: 2.75rem !important;
  }
  .pb-xxl-12 {
    padding-block-end: 3rem !important;
  }
  .pb-xxl-13 {
    padding-block-end: 3.25rem !important;
  }
  .pb-xxl-14 {
    padding-block-end: 3.5rem !important;
  }
  .pb-xxl-15 {
    padding-block-end: 3.75rem !important;
  }
  .pb-xxl-16 {
    padding-block-end: 4rem !important;
  }
  .pb-xxl-17 {
    padding-block-end: 4.25rem !important;
  }
  .pb-xxl-18 {
    padding-block-end: 4.5rem !important;
  }
  .pb-xxl-19 {
    padding-block-end: 4.75rem !important;
  }
  .pb-xxl-20 {
    padding-block-end: 5rem !important;
  }
  .pb-xxl-21 {
    padding-block-end: 5.25rem !important;
  }
  .pb-xxl-22 {
    padding-block-end: 5.5rem !important;
  }
  .pb-xxl-23 {
    padding-block-end: 5.75rem !important;
  }
  .pb-xxl-24 {
    padding-block-end: 6rem !important;
  }
  .pb-xxl-25 {
    padding-block-end: 6.25rem !important;
  }
  .pb-xxl-26 {
    padding-block-end: 6.5rem !important;
  }
  .pb-xxl-27 {
    padding-block-end: 6.75rem !important;
  }
  .pb-xxl-28 {
    padding-block-end: 7rem !important;
  }
  .pb-xxl-29 {
    padding-block-end: 7.25rem !important;
  }
  .pb-xxl-30 {
    padding-block-end: 7.5rem !important;
  }
  .pb-xxl-31 {
    padding-block-end: 7.75rem !important;
  }
  .pb-xxl-32 {
    padding-block-end: 8rem !important;
  }
  .pb-xxl-33 {
    padding-block-end: 8.25rem !important;
  }
  .pb-xxl-34 {
    padding-block-end: 8.5rem !important;
  }
  .pb-xxl-35 {
    padding-block-end: 8.75rem !important;
  }
  .pb-xxl-36 {
    padding-block-end: 9rem !important;
  }
  .pb-xxl-37 {
    padding-block-end: 9.25rem !important;
  }
  .pb-xxl-38 {
    padding-block-end: 9.5rem !important;
  }
  .pb-xxl-39 {
    padding-block-end: 9.75rem !important;
  }
  .pb-xxl-40 {
    padding-block-end: 10rem !important;
  }
  .ps-xxl-0 {
    padding-inline-start: 0 !important;
  }
  .ps-xxl-1 {
    padding-inline-start: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-inline-start: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-inline-start: 0.75rem !important;
  }
  .ps-xxl-4 {
    padding-inline-start: 1rem !important;
  }
  .ps-xxl-5 {
    padding-inline-start: 1.25rem !important;
  }
  .ps-xxl-6 {
    padding-inline-start: 1.5rem !important;
  }
  .ps-xxl-7 {
    padding-inline-start: 1.75rem !important;
  }
  .ps-xxl-8 {
    padding-inline-start: 2rem !important;
  }
  .ps-xxl-9 {
    padding-inline-start: 2.25rem !important;
  }
  .ps-xxl-10 {
    padding-inline-start: 2.5rem !important;
  }
  .ps-xxl-11 {
    padding-inline-start: 2.75rem !important;
  }
  .ps-xxl-12 {
    padding-inline-start: 3rem !important;
  }
  .ps-xxl-13 {
    padding-inline-start: 3.25rem !important;
  }
  .ps-xxl-14 {
    padding-inline-start: 3.5rem !important;
  }
  .ps-xxl-15 {
    padding-inline-start: 3.75rem !important;
  }
  .ps-xxl-16 {
    padding-inline-start: 4rem !important;
  }
  .ps-xxl-17 {
    padding-inline-start: 4.25rem !important;
  }
  .ps-xxl-18 {
    padding-inline-start: 4.5rem !important;
  }
  .ps-xxl-19 {
    padding-inline-start: 4.75rem !important;
  }
  .ps-xxl-20 {
    padding-inline-start: 5rem !important;
  }
  .ps-xxl-21 {
    padding-inline-start: 5.25rem !important;
  }
  .ps-xxl-22 {
    padding-inline-start: 5.5rem !important;
  }
  .ps-xxl-23 {
    padding-inline-start: 5.75rem !important;
  }
  .ps-xxl-24 {
    padding-inline-start: 6rem !important;
  }
  .ps-xxl-25 {
    padding-inline-start: 6.25rem !important;
  }
  .ps-xxl-26 {
    padding-inline-start: 6.5rem !important;
  }
  .ps-xxl-27 {
    padding-inline-start: 6.75rem !important;
  }
  .ps-xxl-28 {
    padding-inline-start: 7rem !important;
  }
  .ps-xxl-29 {
    padding-inline-start: 7.25rem !important;
  }
  .ps-xxl-30 {
    padding-inline-start: 7.5rem !important;
  }
  .ps-xxl-31 {
    padding-inline-start: 7.75rem !important;
  }
  .ps-xxl-32 {
    padding-inline-start: 8rem !important;
  }
  .ps-xxl-33 {
    padding-inline-start: 8.25rem !important;
  }
  .ps-xxl-34 {
    padding-inline-start: 8.5rem !important;
  }
  .ps-xxl-35 {
    padding-inline-start: 8.75rem !important;
  }
  .ps-xxl-36 {
    padding-inline-start: 9rem !important;
  }
  .ps-xxl-37 {
    padding-inline-start: 9.25rem !important;
  }
  .ps-xxl-38 {
    padding-inline-start: 9.5rem !important;
  }
  .ps-xxl-39 {
    padding-inline-start: 9.75rem !important;
  }
  .ps-xxl-40 {
    padding-inline-start: 10rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 0.75rem !important;
  }
  .gap-xxl-4 {
    gap: 1rem !important;
  }
  .gap-xxl-5 {
    gap: 1.25rem !important;
  }
  .gap-xxl-6 {
    gap: 1.5rem !important;
  }
  .gap-xxl-7 {
    gap: 1.75rem !important;
  }
  .gap-xxl-8 {
    gap: 2rem !important;
  }
  .gap-xxl-9 {
    gap: 2.25rem !important;
  }
  .gap-xxl-10 {
    gap: 2.5rem !important;
  }
  .gap-xxl-11 {
    gap: 2.75rem !important;
  }
  .gap-xxl-12 {
    gap: 3rem !important;
  }
  .gap-xxl-13 {
    gap: 3.25rem !important;
  }
  .gap-xxl-14 {
    gap: 3.5rem !important;
  }
  .gap-xxl-15 {
    gap: 3.75rem !important;
  }
  .gap-xxl-16 {
    gap: 4rem !important;
  }
  .gap-xxl-17 {
    gap: 4.25rem !important;
  }
  .gap-xxl-18 {
    gap: 4.5rem !important;
  }
  .gap-xxl-19 {
    gap: 4.75rem !important;
  }
  .gap-xxl-20 {
    gap: 5rem !important;
  }
  .gap-xxl-21 {
    gap: 5.25rem !important;
  }
  .gap-xxl-22 {
    gap: 5.5rem !important;
  }
  .gap-xxl-23 {
    gap: 5.75rem !important;
  }
  .gap-xxl-24 {
    gap: 6rem !important;
  }
  .gap-xxl-25 {
    gap: 6.25rem !important;
  }
  .gap-xxl-26 {
    gap: 6.5rem !important;
  }
  .gap-xxl-27 {
    gap: 6.75rem !important;
  }
  .gap-xxl-28 {
    gap: 7rem !important;
  }
  .gap-xxl-29 {
    gap: 7.25rem !important;
  }
  .gap-xxl-30 {
    gap: 7.5rem !important;
  }
  .gap-xxl-31 {
    gap: 7.75rem !important;
  }
  .gap-xxl-32 {
    gap: 8rem !important;
  }
  .gap-xxl-33 {
    gap: 8.25rem !important;
  }
  .gap-xxl-34 {
    gap: 8.5rem !important;
  }
  .gap-xxl-35 {
    gap: 8.75rem !important;
  }
  .gap-xxl-36 {
    gap: 9rem !important;
  }
  .gap-xxl-37 {
    gap: 9.25rem !important;
  }
  .gap-xxl-38 {
    gap: 9.5rem !important;
  }
  .gap-xxl-39 {
    gap: 9.75rem !important;
  }
  .gap-xxl-40 {
    gap: 10rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 2rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xxl-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-xxl-12 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-xxl-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xxl-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-xxl-16 {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-xxl-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xxl-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-xxl-20 {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-xxl-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-xxl-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-xxl-24 {
    row-gap: 6rem !important;
  }
  .row-gap-xxl-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-xxl-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-xxl-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-xxl-28 {
    row-gap: 7rem !important;
  }
  .row-gap-xxl-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-xxl-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-xxl-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-xxl-32 {
    row-gap: 8rem !important;
  }
  .row-gap-xxl-33 {
    row-gap: 8.25rem !important;
  }
  .row-gap-xxl-34 {
    row-gap: 8.5rem !important;
  }
  .row-gap-xxl-35 {
    row-gap: 8.75rem !important;
  }
  .row-gap-xxl-36 {
    row-gap: 9rem !important;
  }
  .row-gap-xxl-37 {
    row-gap: 9.25rem !important;
  }
  .row-gap-xxl-38 {
    row-gap: 9.5rem !important;
  }
  .row-gap-xxl-39 {
    row-gap: 9.75rem !important;
  }
  .row-gap-xxl-40 {
    row-gap: 10rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 2rem !important;
  }
  .column-gap-xxl-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xxl-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xxl-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-xxl-12 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-xxl-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xxl-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-xxl-16 {
    column-gap: 4rem !important;
  }
  .column-gap-xxl-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-xxl-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xxl-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-xxl-20 {
    column-gap: 5rem !important;
  }
  .column-gap-xxl-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-xxl-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-xxl-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-xxl-24 {
    column-gap: 6rem !important;
  }
  .column-gap-xxl-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-xxl-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-xxl-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-xxl-28 {
    column-gap: 7rem !important;
  }
  .column-gap-xxl-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-xxl-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-xxl-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-xxl-32 {
    column-gap: 8rem !important;
  }
  .column-gap-xxl-33 {
    column-gap: 8.25rem !important;
  }
  .column-gap-xxl-34 {
    column-gap: 8.5rem !important;
  }
  .column-gap-xxl-35 {
    column-gap: 8.75rem !important;
  }
  .column-gap-xxl-36 {
    column-gap: 9rem !important;
  }
  .column-gap-xxl-37 {
    column-gap: 9.25rem !important;
  }
  .column-gap-xxl-38 {
    column-gap: 9.5rem !important;
  }
  .column-gap-xxl-39 {
    column-gap: 9.75rem !important;
  }
  .column-gap-xxl-40 {
    column-gap: 10rem !important;
  }
  .text-xxl-start {
    text-align: start !important;
  }
  .text-xxl-end {
    text-align: end !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .float-max-start {
    float: left !important;
  }
  .float-max-end {
    float: right !important;
  }
  .float-max-none {
    float: none !important;
  }
  .object-fit-max-contain {
    object-fit: contain !important;
  }
  .object-fit-max-cover {
    object-fit: cover !important;
  }
  .object-fit-max-fill {
    object-fit: fill !important;
  }
  .object-fit-max-scale {
    object-fit: scale-down !important;
  }
  .object-fit-max-none {
    object-fit: none !important;
  }
  .d-max-inline {
    display: inline !important;
  }
  .d-max-inline-block {
    display: inline-block !important;
  }
  .d-max-block {
    display: block !important;
  }
  .d-max-grid {
    display: grid !important;
  }
  .d-max-inline-grid {
    display: inline-grid !important;
  }
  .d-max-table {
    display: table !important;
  }
  .d-max-table-row {
    display: table-row !important;
  }
  .d-max-table-cell {
    display: table-cell !important;
  }
  .d-max-flex {
    display: flex !important;
  }
  .d-max-inline-flex {
    display: inline-flex !important;
  }
  .d-max-none {
    display: none !important;
  }
  .border-max {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-max-0 {
    border: 0 !important;
  }
  .flex-max-fill {
    flex: 1 1 auto !important;
  }
  .flex-max-row {
    flex-direction: row !important;
  }
  .flex-max-column {
    flex-direction: column !important;
  }
  .flex-max-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-max-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-max-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-max-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-max-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-max-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-max-wrap {
    flex-wrap: wrap !important;
  }
  .flex-max-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-max-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-max-start {
    justify-content: flex-start !important;
  }
  .justify-content-max-end {
    justify-content: flex-end !important;
  }
  .justify-content-max-center {
    justify-content: center !important;
  }
  .justify-content-max-between {
    justify-content: space-between !important;
  }
  .justify-content-max-around {
    justify-content: space-around !important;
  }
  .justify-content-max-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-max-start {
    align-items: flex-start !important;
  }
  .align-items-max-end {
    align-items: flex-end !important;
  }
  .align-items-max-center {
    align-items: center !important;
  }
  .align-items-max-baseline {
    align-items: baseline !important;
  }
  .align-items-max-stretch {
    align-items: stretch !important;
  }
  .align-content-max-start {
    align-content: flex-start !important;
  }
  .align-content-max-end {
    align-content: flex-end !important;
  }
  .align-content-max-center {
    align-content: center !important;
  }
  .align-content-max-between {
    align-content: space-between !important;
  }
  .align-content-max-around {
    align-content: space-around !important;
  }
  .align-content-max-stretch {
    align-content: stretch !important;
  }
  .align-self-max-auto {
    align-self: auto !important;
  }
  .align-self-max-start {
    align-self: flex-start !important;
  }
  .align-self-max-end {
    align-self: flex-end !important;
  }
  .align-self-max-center {
    align-self: center !important;
  }
  .align-self-max-baseline {
    align-self: baseline !important;
  }
  .align-self-max-stretch {
    align-self: stretch !important;
  }
  .order-max-first {
    order: -1 !important;
  }
  .order-max-0 {
    order: 0 !important;
  }
  .order-max-1 {
    order: 1 !important;
  }
  .order-max-2 {
    order: 2 !important;
  }
  .order-max-3 {
    order: 3 !important;
  }
  .order-max-4 {
    order: 4 !important;
  }
  .order-max-5 {
    order: 5 !important;
  }
  .order-max-last {
    order: 6 !important;
  }
  .m-max-0 {
    margin: 0 !important;
  }
  .m-max-1 {
    margin: 0.25rem !important;
  }
  .m-max-2 {
    margin: 0.5rem !important;
  }
  .m-max-3 {
    margin: 0.75rem !important;
  }
  .m-max-4 {
    margin: 1rem !important;
  }
  .m-max-5 {
    margin: 1.25rem !important;
  }
  .m-max-6 {
    margin: 1.5rem !important;
  }
  .m-max-7 {
    margin: 1.75rem !important;
  }
  .m-max-8 {
    margin: 2rem !important;
  }
  .m-max-9 {
    margin: 2.25rem !important;
  }
  .m-max-10 {
    margin: 2.5rem !important;
  }
  .m-max-11 {
    margin: 2.75rem !important;
  }
  .m-max-12 {
    margin: 3rem !important;
  }
  .m-max-13 {
    margin: 3.25rem !important;
  }
  .m-max-14 {
    margin: 3.5rem !important;
  }
  .m-max-15 {
    margin: 3.75rem !important;
  }
  .m-max-16 {
    margin: 4rem !important;
  }
  .m-max-17 {
    margin: 4.25rem !important;
  }
  .m-max-18 {
    margin: 4.5rem !important;
  }
  .m-max-19 {
    margin: 4.75rem !important;
  }
  .m-max-20 {
    margin: 5rem !important;
  }
  .m-max-21 {
    margin: 5.25rem !important;
  }
  .m-max-22 {
    margin: 5.5rem !important;
  }
  .m-max-23 {
    margin: 5.75rem !important;
  }
  .m-max-24 {
    margin: 6rem !important;
  }
  .m-max-25 {
    margin: 6.25rem !important;
  }
  .m-max-26 {
    margin: 6.5rem !important;
  }
  .m-max-27 {
    margin: 6.75rem !important;
  }
  .m-max-28 {
    margin: 7rem !important;
  }
  .m-max-29 {
    margin: 7.25rem !important;
  }
  .m-max-30 {
    margin: 7.5rem !important;
  }
  .m-max-31 {
    margin: 7.75rem !important;
  }
  .m-max-32 {
    margin: 8rem !important;
  }
  .m-max-33 {
    margin: 8.25rem !important;
  }
  .m-max-34 {
    margin: 8.5rem !important;
  }
  .m-max-35 {
    margin: 8.75rem !important;
  }
  .m-max-36 {
    margin: 9rem !important;
  }
  .m-max-37 {
    margin: 9.25rem !important;
  }
  .m-max-38 {
    margin: 9.5rem !important;
  }
  .m-max-39 {
    margin: 9.75rem !important;
  }
  .m-max-40 {
    margin: 10rem !important;
  }
  .m-max-auto {
    margin: auto !important;
  }
  .mx-max-0 {
    margin-inline: 0 !important;
  }
  .mx-max-1 {
    margin-inline: 0.25rem !important;
  }
  .mx-max-2 {
    margin-inline: 0.5rem !important;
  }
  .mx-max-3 {
    margin-inline: 0.75rem !important;
  }
  .mx-max-4 {
    margin-inline: 1rem !important;
  }
  .mx-max-5 {
    margin-inline: 1.25rem !important;
  }
  .mx-max-6 {
    margin-inline: 1.5rem !important;
  }
  .mx-max-7 {
    margin-inline: 1.75rem !important;
  }
  .mx-max-8 {
    margin-inline: 2rem !important;
  }
  .mx-max-9 {
    margin-inline: 2.25rem !important;
  }
  .mx-max-10 {
    margin-inline: 2.5rem !important;
  }
  .mx-max-11 {
    margin-inline: 2.75rem !important;
  }
  .mx-max-12 {
    margin-inline: 3rem !important;
  }
  .mx-max-13 {
    margin-inline: 3.25rem !important;
  }
  .mx-max-14 {
    margin-inline: 3.5rem !important;
  }
  .mx-max-15 {
    margin-inline: 3.75rem !important;
  }
  .mx-max-16 {
    margin-inline: 4rem !important;
  }
  .mx-max-17 {
    margin-inline: 4.25rem !important;
  }
  .mx-max-18 {
    margin-inline: 4.5rem !important;
  }
  .mx-max-19 {
    margin-inline: 4.75rem !important;
  }
  .mx-max-20 {
    margin-inline: 5rem !important;
  }
  .mx-max-21 {
    margin-inline: 5.25rem !important;
  }
  .mx-max-22 {
    margin-inline: 5.5rem !important;
  }
  .mx-max-23 {
    margin-inline: 5.75rem !important;
  }
  .mx-max-24 {
    margin-inline: 6rem !important;
  }
  .mx-max-25 {
    margin-inline: 6.25rem !important;
  }
  .mx-max-26 {
    margin-inline: 6.5rem !important;
  }
  .mx-max-27 {
    margin-inline: 6.75rem !important;
  }
  .mx-max-28 {
    margin-inline: 7rem !important;
  }
  .mx-max-29 {
    margin-inline: 7.25rem !important;
  }
  .mx-max-30 {
    margin-inline: 7.5rem !important;
  }
  .mx-max-31 {
    margin-inline: 7.75rem !important;
  }
  .mx-max-32 {
    margin-inline: 8rem !important;
  }
  .mx-max-33 {
    margin-inline: 8.25rem !important;
  }
  .mx-max-34 {
    margin-inline: 8.5rem !important;
  }
  .mx-max-35 {
    margin-inline: 8.75rem !important;
  }
  .mx-max-36 {
    margin-inline: 9rem !important;
  }
  .mx-max-37 {
    margin-inline: 9.25rem !important;
  }
  .mx-max-38 {
    margin-inline: 9.5rem !important;
  }
  .mx-max-39 {
    margin-inline: 9.75rem !important;
  }
  .mx-max-40 {
    margin-inline: 10rem !important;
  }
  .mx-max-auto {
    margin-inline: auto !important;
  }
  .my-max-0 {
    margin-block: 0 !important;
  }
  .my-max-1 {
    margin-block: 0.25rem !important;
  }
  .my-max-2 {
    margin-block: 0.5rem !important;
  }
  .my-max-3 {
    margin-block: 0.75rem !important;
  }
  .my-max-4 {
    margin-block: 1rem !important;
  }
  .my-max-5 {
    margin-block: 1.25rem !important;
  }
  .my-max-6 {
    margin-block: 1.5rem !important;
  }
  .my-max-7 {
    margin-block: 1.75rem !important;
  }
  .my-max-8 {
    margin-block: 2rem !important;
  }
  .my-max-9 {
    margin-block: 2.25rem !important;
  }
  .my-max-10 {
    margin-block: 2.5rem !important;
  }
  .my-max-11 {
    margin-block: 2.75rem !important;
  }
  .my-max-12 {
    margin-block: 3rem !important;
  }
  .my-max-13 {
    margin-block: 3.25rem !important;
  }
  .my-max-14 {
    margin-block: 3.5rem !important;
  }
  .my-max-15 {
    margin-block: 3.75rem !important;
  }
  .my-max-16 {
    margin-block: 4rem !important;
  }
  .my-max-17 {
    margin-block: 4.25rem !important;
  }
  .my-max-18 {
    margin-block: 4.5rem !important;
  }
  .my-max-19 {
    margin-block: 4.75rem !important;
  }
  .my-max-20 {
    margin-block: 5rem !important;
  }
  .my-max-21 {
    margin-block: 5.25rem !important;
  }
  .my-max-22 {
    margin-block: 5.5rem !important;
  }
  .my-max-23 {
    margin-block: 5.75rem !important;
  }
  .my-max-24 {
    margin-block: 6rem !important;
  }
  .my-max-25 {
    margin-block: 6.25rem !important;
  }
  .my-max-26 {
    margin-block: 6.5rem !important;
  }
  .my-max-27 {
    margin-block: 6.75rem !important;
  }
  .my-max-28 {
    margin-block: 7rem !important;
  }
  .my-max-29 {
    margin-block: 7.25rem !important;
  }
  .my-max-30 {
    margin-block: 7.5rem !important;
  }
  .my-max-31 {
    margin-block: 7.75rem !important;
  }
  .my-max-32 {
    margin-block: 8rem !important;
  }
  .my-max-33 {
    margin-block: 8.25rem !important;
  }
  .my-max-34 {
    margin-block: 8.5rem !important;
  }
  .my-max-35 {
    margin-block: 8.75rem !important;
  }
  .my-max-36 {
    margin-block: 9rem !important;
  }
  .my-max-37 {
    margin-block: 9.25rem !important;
  }
  .my-max-38 {
    margin-block: 9.5rem !important;
  }
  .my-max-39 {
    margin-block: 9.75rem !important;
  }
  .my-max-40 {
    margin-block: 10rem !important;
  }
  .my-max-auto {
    margin-block: auto !important;
  }
  .mt-max-0 {
    margin-block-start: 0 !important;
  }
  .mt-max-1 {
    margin-block-start: 0.25rem !important;
  }
  .mt-max-2 {
    margin-block-start: 0.5rem !important;
  }
  .mt-max-3 {
    margin-block-start: 0.75rem !important;
  }
  .mt-max-4 {
    margin-block-start: 1rem !important;
  }
  .mt-max-5 {
    margin-block-start: 1.25rem !important;
  }
  .mt-max-6 {
    margin-block-start: 1.5rem !important;
  }
  .mt-max-7 {
    margin-block-start: 1.75rem !important;
  }
  .mt-max-8 {
    margin-block-start: 2rem !important;
  }
  .mt-max-9 {
    margin-block-start: 2.25rem !important;
  }
  .mt-max-10 {
    margin-block-start: 2.5rem !important;
  }
  .mt-max-11 {
    margin-block-start: 2.75rem !important;
  }
  .mt-max-12 {
    margin-block-start: 3rem !important;
  }
  .mt-max-13 {
    margin-block-start: 3.25rem !important;
  }
  .mt-max-14 {
    margin-block-start: 3.5rem !important;
  }
  .mt-max-15 {
    margin-block-start: 3.75rem !important;
  }
  .mt-max-16 {
    margin-block-start: 4rem !important;
  }
  .mt-max-17 {
    margin-block-start: 4.25rem !important;
  }
  .mt-max-18 {
    margin-block-start: 4.5rem !important;
  }
  .mt-max-19 {
    margin-block-start: 4.75rem !important;
  }
  .mt-max-20 {
    margin-block-start: 5rem !important;
  }
  .mt-max-21 {
    margin-block-start: 5.25rem !important;
  }
  .mt-max-22 {
    margin-block-start: 5.5rem !important;
  }
  .mt-max-23 {
    margin-block-start: 5.75rem !important;
  }
  .mt-max-24 {
    margin-block-start: 6rem !important;
  }
  .mt-max-25 {
    margin-block-start: 6.25rem !important;
  }
  .mt-max-26 {
    margin-block-start: 6.5rem !important;
  }
  .mt-max-27 {
    margin-block-start: 6.75rem !important;
  }
  .mt-max-28 {
    margin-block-start: 7rem !important;
  }
  .mt-max-29 {
    margin-block-start: 7.25rem !important;
  }
  .mt-max-30 {
    margin-block-start: 7.5rem !important;
  }
  .mt-max-31 {
    margin-block-start: 7.75rem !important;
  }
  .mt-max-32 {
    margin-block-start: 8rem !important;
  }
  .mt-max-33 {
    margin-block-start: 8.25rem !important;
  }
  .mt-max-34 {
    margin-block-start: 8.5rem !important;
  }
  .mt-max-35 {
    margin-block-start: 8.75rem !important;
  }
  .mt-max-36 {
    margin-block-start: 9rem !important;
  }
  .mt-max-37 {
    margin-block-start: 9.25rem !important;
  }
  .mt-max-38 {
    margin-block-start: 9.5rem !important;
  }
  .mt-max-39 {
    margin-block-start: 9.75rem !important;
  }
  .mt-max-40 {
    margin-block-start: 10rem !important;
  }
  .mt-max-auto {
    margin-block-start: auto !important;
  }
  .me-max-0 {
    margin-inline-end: 0 !important;
  }
  .me-max-1 {
    margin-inline-end: 0.25rem !important;
  }
  .me-max-2 {
    margin-inline-end: 0.5rem !important;
  }
  .me-max-3 {
    margin-inline-end: 0.75rem !important;
  }
  .me-max-4 {
    margin-inline-end: 1rem !important;
  }
  .me-max-5 {
    margin-inline-end: 1.25rem !important;
  }
  .me-max-6 {
    margin-inline-end: 1.5rem !important;
  }
  .me-max-7 {
    margin-inline-end: 1.75rem !important;
  }
  .me-max-8 {
    margin-inline-end: 2rem !important;
  }
  .me-max-9 {
    margin-inline-end: 2.25rem !important;
  }
  .me-max-10 {
    margin-inline-end: 2.5rem !important;
  }
  .me-max-11 {
    margin-inline-end: 2.75rem !important;
  }
  .me-max-12 {
    margin-inline-end: 3rem !important;
  }
  .me-max-13 {
    margin-inline-end: 3.25rem !important;
  }
  .me-max-14 {
    margin-inline-end: 3.5rem !important;
  }
  .me-max-15 {
    margin-inline-end: 3.75rem !important;
  }
  .me-max-16 {
    margin-inline-end: 4rem !important;
  }
  .me-max-17 {
    margin-inline-end: 4.25rem !important;
  }
  .me-max-18 {
    margin-inline-end: 4.5rem !important;
  }
  .me-max-19 {
    margin-inline-end: 4.75rem !important;
  }
  .me-max-20 {
    margin-inline-end: 5rem !important;
  }
  .me-max-21 {
    margin-inline-end: 5.25rem !important;
  }
  .me-max-22 {
    margin-inline-end: 5.5rem !important;
  }
  .me-max-23 {
    margin-inline-end: 5.75rem !important;
  }
  .me-max-24 {
    margin-inline-end: 6rem !important;
  }
  .me-max-25 {
    margin-inline-end: 6.25rem !important;
  }
  .me-max-26 {
    margin-inline-end: 6.5rem !important;
  }
  .me-max-27 {
    margin-inline-end: 6.75rem !important;
  }
  .me-max-28 {
    margin-inline-end: 7rem !important;
  }
  .me-max-29 {
    margin-inline-end: 7.25rem !important;
  }
  .me-max-30 {
    margin-inline-end: 7.5rem !important;
  }
  .me-max-31 {
    margin-inline-end: 7.75rem !important;
  }
  .me-max-32 {
    margin-inline-end: 8rem !important;
  }
  .me-max-33 {
    margin-inline-end: 8.25rem !important;
  }
  .me-max-34 {
    margin-inline-end: 8.5rem !important;
  }
  .me-max-35 {
    margin-inline-end: 8.75rem !important;
  }
  .me-max-36 {
    margin-inline-end: 9rem !important;
  }
  .me-max-37 {
    margin-inline-end: 9.25rem !important;
  }
  .me-max-38 {
    margin-inline-end: 9.5rem !important;
  }
  .me-max-39 {
    margin-inline-end: 9.75rem !important;
  }
  .me-max-40 {
    margin-inline-end: 10rem !important;
  }
  .me-max-auto {
    margin-inline-end: auto !important;
  }
  .mb-max-0 {
    margin-block-end: 0 !important;
  }
  .mb-max-1 {
    margin-block-end: 0.25rem !important;
  }
  .mb-max-2 {
    margin-block-end: 0.5rem !important;
  }
  .mb-max-3 {
    margin-block-end: 0.75rem !important;
  }
  .mb-max-4 {
    margin-block-end: 1rem !important;
  }
  .mb-max-5 {
    margin-block-end: 1.25rem !important;
  }
  .mb-max-6 {
    margin-block-end: 1.5rem !important;
  }
  .mb-max-7 {
    margin-block-end: 1.75rem !important;
  }
  .mb-max-8 {
    margin-block-end: 2rem !important;
  }
  .mb-max-9 {
    margin-block-end: 2.25rem !important;
  }
  .mb-max-10 {
    margin-block-end: 2.5rem !important;
  }
  .mb-max-11 {
    margin-block-end: 2.75rem !important;
  }
  .mb-max-12 {
    margin-block-end: 3rem !important;
  }
  .mb-max-13 {
    margin-block-end: 3.25rem !important;
  }
  .mb-max-14 {
    margin-block-end: 3.5rem !important;
  }
  .mb-max-15 {
    margin-block-end: 3.75rem !important;
  }
  .mb-max-16 {
    margin-block-end: 4rem !important;
  }
  .mb-max-17 {
    margin-block-end: 4.25rem !important;
  }
  .mb-max-18 {
    margin-block-end: 4.5rem !important;
  }
  .mb-max-19 {
    margin-block-end: 4.75rem !important;
  }
  .mb-max-20 {
    margin-block-end: 5rem !important;
  }
  .mb-max-21 {
    margin-block-end: 5.25rem !important;
  }
  .mb-max-22 {
    margin-block-end: 5.5rem !important;
  }
  .mb-max-23 {
    margin-block-end: 5.75rem !important;
  }
  .mb-max-24 {
    margin-block-end: 6rem !important;
  }
  .mb-max-25 {
    margin-block-end: 6.25rem !important;
  }
  .mb-max-26 {
    margin-block-end: 6.5rem !important;
  }
  .mb-max-27 {
    margin-block-end: 6.75rem !important;
  }
  .mb-max-28 {
    margin-block-end: 7rem !important;
  }
  .mb-max-29 {
    margin-block-end: 7.25rem !important;
  }
  .mb-max-30 {
    margin-block-end: 7.5rem !important;
  }
  .mb-max-31 {
    margin-block-end: 7.75rem !important;
  }
  .mb-max-32 {
    margin-block-end: 8rem !important;
  }
  .mb-max-33 {
    margin-block-end: 8.25rem !important;
  }
  .mb-max-34 {
    margin-block-end: 8.5rem !important;
  }
  .mb-max-35 {
    margin-block-end: 8.75rem !important;
  }
  .mb-max-36 {
    margin-block-end: 9rem !important;
  }
  .mb-max-37 {
    margin-block-end: 9.25rem !important;
  }
  .mb-max-38 {
    margin-block-end: 9.5rem !important;
  }
  .mb-max-39 {
    margin-block-end: 9.75rem !important;
  }
  .mb-max-40 {
    margin-block-end: 10rem !important;
  }
  .mb-max-auto {
    margin-block-end: auto !important;
  }
  .ms-max-0 {
    margin-inline-start: 0 !important;
  }
  .ms-max-1 {
    margin-inline-start: 0.25rem !important;
  }
  .ms-max-2 {
    margin-inline-start: 0.5rem !important;
  }
  .ms-max-3 {
    margin-inline-start: 0.75rem !important;
  }
  .ms-max-4 {
    margin-inline-start: 1rem !important;
  }
  .ms-max-5 {
    margin-inline-start: 1.25rem !important;
  }
  .ms-max-6 {
    margin-inline-start: 1.5rem !important;
  }
  .ms-max-7 {
    margin-inline-start: 1.75rem !important;
  }
  .ms-max-8 {
    margin-inline-start: 2rem !important;
  }
  .ms-max-9 {
    margin-inline-start: 2.25rem !important;
  }
  .ms-max-10 {
    margin-inline-start: 2.5rem !important;
  }
  .ms-max-11 {
    margin-inline-start: 2.75rem !important;
  }
  .ms-max-12 {
    margin-inline-start: 3rem !important;
  }
  .ms-max-13 {
    margin-inline-start: 3.25rem !important;
  }
  .ms-max-14 {
    margin-inline-start: 3.5rem !important;
  }
  .ms-max-15 {
    margin-inline-start: 3.75rem !important;
  }
  .ms-max-16 {
    margin-inline-start: 4rem !important;
  }
  .ms-max-17 {
    margin-inline-start: 4.25rem !important;
  }
  .ms-max-18 {
    margin-inline-start: 4.5rem !important;
  }
  .ms-max-19 {
    margin-inline-start: 4.75rem !important;
  }
  .ms-max-20 {
    margin-inline-start: 5rem !important;
  }
  .ms-max-21 {
    margin-inline-start: 5.25rem !important;
  }
  .ms-max-22 {
    margin-inline-start: 5.5rem !important;
  }
  .ms-max-23 {
    margin-inline-start: 5.75rem !important;
  }
  .ms-max-24 {
    margin-inline-start: 6rem !important;
  }
  .ms-max-25 {
    margin-inline-start: 6.25rem !important;
  }
  .ms-max-26 {
    margin-inline-start: 6.5rem !important;
  }
  .ms-max-27 {
    margin-inline-start: 6.75rem !important;
  }
  .ms-max-28 {
    margin-inline-start: 7rem !important;
  }
  .ms-max-29 {
    margin-inline-start: 7.25rem !important;
  }
  .ms-max-30 {
    margin-inline-start: 7.5rem !important;
  }
  .ms-max-31 {
    margin-inline-start: 7.75rem !important;
  }
  .ms-max-32 {
    margin-inline-start: 8rem !important;
  }
  .ms-max-33 {
    margin-inline-start: 8.25rem !important;
  }
  .ms-max-34 {
    margin-inline-start: 8.5rem !important;
  }
  .ms-max-35 {
    margin-inline-start: 8.75rem !important;
  }
  .ms-max-36 {
    margin-inline-start: 9rem !important;
  }
  .ms-max-37 {
    margin-inline-start: 9.25rem !important;
  }
  .ms-max-38 {
    margin-inline-start: 9.5rem !important;
  }
  .ms-max-39 {
    margin-inline-start: 9.75rem !important;
  }
  .ms-max-40 {
    margin-inline-start: 10rem !important;
  }
  .ms-max-auto {
    margin-inline-start: auto !important;
  }
  .p-max-0 {
    padding: 0 !important;
  }
  .p-max-1 {
    padding: 0.25rem !important;
  }
  .p-max-2 {
    padding: 0.5rem !important;
  }
  .p-max-3 {
    padding: 0.75rem !important;
  }
  .p-max-4 {
    padding: 1rem !important;
  }
  .p-max-5 {
    padding: 1.25rem !important;
  }
  .p-max-6 {
    padding: 1.5rem !important;
  }
  .p-max-7 {
    padding: 1.75rem !important;
  }
  .p-max-8 {
    padding: 2rem !important;
  }
  .p-max-9 {
    padding: 2.25rem !important;
  }
  .p-max-10 {
    padding: 2.5rem !important;
  }
  .p-max-11 {
    padding: 2.75rem !important;
  }
  .p-max-12 {
    padding: 3rem !important;
  }
  .p-max-13 {
    padding: 3.25rem !important;
  }
  .p-max-14 {
    padding: 3.5rem !important;
  }
  .p-max-15 {
    padding: 3.75rem !important;
  }
  .p-max-16 {
    padding: 4rem !important;
  }
  .p-max-17 {
    padding: 4.25rem !important;
  }
  .p-max-18 {
    padding: 4.5rem !important;
  }
  .p-max-19 {
    padding: 4.75rem !important;
  }
  .p-max-20 {
    padding: 5rem !important;
  }
  .p-max-21 {
    padding: 5.25rem !important;
  }
  .p-max-22 {
    padding: 5.5rem !important;
  }
  .p-max-23 {
    padding: 5.75rem !important;
  }
  .p-max-24 {
    padding: 6rem !important;
  }
  .p-max-25 {
    padding: 6.25rem !important;
  }
  .p-max-26 {
    padding: 6.5rem !important;
  }
  .p-max-27 {
    padding: 6.75rem !important;
  }
  .p-max-28 {
    padding: 7rem !important;
  }
  .p-max-29 {
    padding: 7.25rem !important;
  }
  .p-max-30 {
    padding: 7.5rem !important;
  }
  .p-max-31 {
    padding: 7.75rem !important;
  }
  .p-max-32 {
    padding: 8rem !important;
  }
  .p-max-33 {
    padding: 8.25rem !important;
  }
  .p-max-34 {
    padding: 8.5rem !important;
  }
  .p-max-35 {
    padding: 8.75rem !important;
  }
  .p-max-36 {
    padding: 9rem !important;
  }
  .p-max-37 {
    padding: 9.25rem !important;
  }
  .p-max-38 {
    padding: 9.5rem !important;
  }
  .p-max-39 {
    padding: 9.75rem !important;
  }
  .p-max-40 {
    padding: 10rem !important;
  }
  .px-max-0 {
    padding-inline: 0 !important;
  }
  .px-max-1 {
    padding-inline: 0.25rem !important;
  }
  .px-max-2 {
    padding-inline: 0.5rem !important;
  }
  .px-max-3 {
    padding-inline: 0.75rem !important;
  }
  .px-max-4 {
    padding-inline: 1rem !important;
  }
  .px-max-5 {
    padding-inline: 1.25rem !important;
  }
  .px-max-6 {
    padding-inline: 1.5rem !important;
  }
  .px-max-7 {
    padding-inline: 1.75rem !important;
  }
  .px-max-8 {
    padding-inline: 2rem !important;
  }
  .px-max-9 {
    padding-inline: 2.25rem !important;
  }
  .px-max-10 {
    padding-inline: 2.5rem !important;
  }
  .px-max-11 {
    padding-inline: 2.75rem !important;
  }
  .px-max-12 {
    padding-inline: 3rem !important;
  }
  .px-max-13 {
    padding-inline: 3.25rem !important;
  }
  .px-max-14 {
    padding-inline: 3.5rem !important;
  }
  .px-max-15 {
    padding-inline: 3.75rem !important;
  }
  .px-max-16 {
    padding-inline: 4rem !important;
  }
  .px-max-17 {
    padding-inline: 4.25rem !important;
  }
  .px-max-18 {
    padding-inline: 4.5rem !important;
  }
  .px-max-19 {
    padding-inline: 4.75rem !important;
  }
  .px-max-20 {
    padding-inline: 5rem !important;
  }
  .px-max-21 {
    padding-inline: 5.25rem !important;
  }
  .px-max-22 {
    padding-inline: 5.5rem !important;
  }
  .px-max-23 {
    padding-inline: 5.75rem !important;
  }
  .px-max-24 {
    padding-inline: 6rem !important;
  }
  .px-max-25 {
    padding-inline: 6.25rem !important;
  }
  .px-max-26 {
    padding-inline: 6.5rem !important;
  }
  .px-max-27 {
    padding-inline: 6.75rem !important;
  }
  .px-max-28 {
    padding-inline: 7rem !important;
  }
  .px-max-29 {
    padding-inline: 7.25rem !important;
  }
  .px-max-30 {
    padding-inline: 7.5rem !important;
  }
  .px-max-31 {
    padding-inline: 7.75rem !important;
  }
  .px-max-32 {
    padding-inline: 8rem !important;
  }
  .px-max-33 {
    padding-inline: 8.25rem !important;
  }
  .px-max-34 {
    padding-inline: 8.5rem !important;
  }
  .px-max-35 {
    padding-inline: 8.75rem !important;
  }
  .px-max-36 {
    padding-inline: 9rem !important;
  }
  .px-max-37 {
    padding-inline: 9.25rem !important;
  }
  .px-max-38 {
    padding-inline: 9.5rem !important;
  }
  .px-max-39 {
    padding-inline: 9.75rem !important;
  }
  .px-max-40 {
    padding-inline: 10rem !important;
  }
  .py-max-0 {
    padding-block: 0 !important;
  }
  .py-max-1 {
    padding-block: 0.25rem !important;
  }
  .py-max-2 {
    padding-block: 0.5rem !important;
  }
  .py-max-3 {
    padding-block: 0.75rem !important;
  }
  .py-max-4 {
    padding-block: 1rem !important;
  }
  .py-max-5 {
    padding-block: 1.25rem !important;
  }
  .py-max-6 {
    padding-block: 1.5rem !important;
  }
  .py-max-7 {
    padding-block: 1.75rem !important;
  }
  .py-max-8 {
    padding-block: 2rem !important;
  }
  .py-max-9 {
    padding-block: 2.25rem !important;
  }
  .py-max-10 {
    padding-block: 2.5rem !important;
  }
  .py-max-11 {
    padding-block: 2.75rem !important;
  }
  .py-max-12 {
    padding-block: 3rem !important;
  }
  .py-max-13 {
    padding-block: 3.25rem !important;
  }
  .py-max-14 {
    padding-block: 3.5rem !important;
  }
  .py-max-15 {
    padding-block: 3.75rem !important;
  }
  .py-max-16 {
    padding-block: 4rem !important;
  }
  .py-max-17 {
    padding-block: 4.25rem !important;
  }
  .py-max-18 {
    padding-block: 4.5rem !important;
  }
  .py-max-19 {
    padding-block: 4.75rem !important;
  }
  .py-max-20 {
    padding-block: 5rem !important;
  }
  .py-max-21 {
    padding-block: 5.25rem !important;
  }
  .py-max-22 {
    padding-block: 5.5rem !important;
  }
  .py-max-23 {
    padding-block: 5.75rem !important;
  }
  .py-max-24 {
    padding-block: 6rem !important;
  }
  .py-max-25 {
    padding-block: 6.25rem !important;
  }
  .py-max-26 {
    padding-block: 6.5rem !important;
  }
  .py-max-27 {
    padding-block: 6.75rem !important;
  }
  .py-max-28 {
    padding-block: 7rem !important;
  }
  .py-max-29 {
    padding-block: 7.25rem !important;
  }
  .py-max-30 {
    padding-block: 7.5rem !important;
  }
  .py-max-31 {
    padding-block: 7.75rem !important;
  }
  .py-max-32 {
    padding-block: 8rem !important;
  }
  .py-max-33 {
    padding-block: 8.25rem !important;
  }
  .py-max-34 {
    padding-block: 8.5rem !important;
  }
  .py-max-35 {
    padding-block: 8.75rem !important;
  }
  .py-max-36 {
    padding-block: 9rem !important;
  }
  .py-max-37 {
    padding-block: 9.25rem !important;
  }
  .py-max-38 {
    padding-block: 9.5rem !important;
  }
  .py-max-39 {
    padding-block: 9.75rem !important;
  }
  .py-max-40 {
    padding-block: 10rem !important;
  }
  .pt-max-0 {
    padding-block-start: 0 !important;
  }
  .pt-max-1 {
    padding-block-start: 0.25rem !important;
  }
  .pt-max-2 {
    padding-block-start: 0.5rem !important;
  }
  .pt-max-3 {
    padding-block-start: 0.75rem !important;
  }
  .pt-max-4 {
    padding-block-start: 1rem !important;
  }
  .pt-max-5 {
    padding-block-start: 1.25rem !important;
  }
  .pt-max-6 {
    padding-block-start: 1.5rem !important;
  }
  .pt-max-7 {
    padding-block-start: 1.75rem !important;
  }
  .pt-max-8 {
    padding-block-start: 2rem !important;
  }
  .pt-max-9 {
    padding-block-start: 2.25rem !important;
  }
  .pt-max-10 {
    padding-block-start: 2.5rem !important;
  }
  .pt-max-11 {
    padding-block-start: 2.75rem !important;
  }
  .pt-max-12 {
    padding-block-start: 3rem !important;
  }
  .pt-max-13 {
    padding-block-start: 3.25rem !important;
  }
  .pt-max-14 {
    padding-block-start: 3.5rem !important;
  }
  .pt-max-15 {
    padding-block-start: 3.75rem !important;
  }
  .pt-max-16 {
    padding-block-start: 4rem !important;
  }
  .pt-max-17 {
    padding-block-start: 4.25rem !important;
  }
  .pt-max-18 {
    padding-block-start: 4.5rem !important;
  }
  .pt-max-19 {
    padding-block-start: 4.75rem !important;
  }
  .pt-max-20 {
    padding-block-start: 5rem !important;
  }
  .pt-max-21 {
    padding-block-start: 5.25rem !important;
  }
  .pt-max-22 {
    padding-block-start: 5.5rem !important;
  }
  .pt-max-23 {
    padding-block-start: 5.75rem !important;
  }
  .pt-max-24 {
    padding-block-start: 6rem !important;
  }
  .pt-max-25 {
    padding-block-start: 6.25rem !important;
  }
  .pt-max-26 {
    padding-block-start: 6.5rem !important;
  }
  .pt-max-27 {
    padding-block-start: 6.75rem !important;
  }
  .pt-max-28 {
    padding-block-start: 7rem !important;
  }
  .pt-max-29 {
    padding-block-start: 7.25rem !important;
  }
  .pt-max-30 {
    padding-block-start: 7.5rem !important;
  }
  .pt-max-31 {
    padding-block-start: 7.75rem !important;
  }
  .pt-max-32 {
    padding-block-start: 8rem !important;
  }
  .pt-max-33 {
    padding-block-start: 8.25rem !important;
  }
  .pt-max-34 {
    padding-block-start: 8.5rem !important;
  }
  .pt-max-35 {
    padding-block-start: 8.75rem !important;
  }
  .pt-max-36 {
    padding-block-start: 9rem !important;
  }
  .pt-max-37 {
    padding-block-start: 9.25rem !important;
  }
  .pt-max-38 {
    padding-block-start: 9.5rem !important;
  }
  .pt-max-39 {
    padding-block-start: 9.75rem !important;
  }
  .pt-max-40 {
    padding-block-start: 10rem !important;
  }
  .pe-max-0 {
    padding-inline-end: 0 !important;
  }
  .pe-max-1 {
    padding-inline-end: 0.25rem !important;
  }
  .pe-max-2 {
    padding-inline-end: 0.5rem !important;
  }
  .pe-max-3 {
    padding-inline-end: 0.75rem !important;
  }
  .pe-max-4 {
    padding-inline-end: 1rem !important;
  }
  .pe-max-5 {
    padding-inline-end: 1.25rem !important;
  }
  .pe-max-6 {
    padding-inline-end: 1.5rem !important;
  }
  .pe-max-7 {
    padding-inline-end: 1.75rem !important;
  }
  .pe-max-8 {
    padding-inline-end: 2rem !important;
  }
  .pe-max-9 {
    padding-inline-end: 2.25rem !important;
  }
  .pe-max-10 {
    padding-inline-end: 2.5rem !important;
  }
  .pe-max-11 {
    padding-inline-end: 2.75rem !important;
  }
  .pe-max-12 {
    padding-inline-end: 3rem !important;
  }
  .pe-max-13 {
    padding-inline-end: 3.25rem !important;
  }
  .pe-max-14 {
    padding-inline-end: 3.5rem !important;
  }
  .pe-max-15 {
    padding-inline-end: 3.75rem !important;
  }
  .pe-max-16 {
    padding-inline-end: 4rem !important;
  }
  .pe-max-17 {
    padding-inline-end: 4.25rem !important;
  }
  .pe-max-18 {
    padding-inline-end: 4.5rem !important;
  }
  .pe-max-19 {
    padding-inline-end: 4.75rem !important;
  }
  .pe-max-20 {
    padding-inline-end: 5rem !important;
  }
  .pe-max-21 {
    padding-inline-end: 5.25rem !important;
  }
  .pe-max-22 {
    padding-inline-end: 5.5rem !important;
  }
  .pe-max-23 {
    padding-inline-end: 5.75rem !important;
  }
  .pe-max-24 {
    padding-inline-end: 6rem !important;
  }
  .pe-max-25 {
    padding-inline-end: 6.25rem !important;
  }
  .pe-max-26 {
    padding-inline-end: 6.5rem !important;
  }
  .pe-max-27 {
    padding-inline-end: 6.75rem !important;
  }
  .pe-max-28 {
    padding-inline-end: 7rem !important;
  }
  .pe-max-29 {
    padding-inline-end: 7.25rem !important;
  }
  .pe-max-30 {
    padding-inline-end: 7.5rem !important;
  }
  .pe-max-31 {
    padding-inline-end: 7.75rem !important;
  }
  .pe-max-32 {
    padding-inline-end: 8rem !important;
  }
  .pe-max-33 {
    padding-inline-end: 8.25rem !important;
  }
  .pe-max-34 {
    padding-inline-end: 8.5rem !important;
  }
  .pe-max-35 {
    padding-inline-end: 8.75rem !important;
  }
  .pe-max-36 {
    padding-inline-end: 9rem !important;
  }
  .pe-max-37 {
    padding-inline-end: 9.25rem !important;
  }
  .pe-max-38 {
    padding-inline-end: 9.5rem !important;
  }
  .pe-max-39 {
    padding-inline-end: 9.75rem !important;
  }
  .pe-max-40 {
    padding-inline-end: 10rem !important;
  }
  .pb-max-0 {
    padding-block-end: 0 !important;
  }
  .pb-max-1 {
    padding-block-end: 0.25rem !important;
  }
  .pb-max-2 {
    padding-block-end: 0.5rem !important;
  }
  .pb-max-3 {
    padding-block-end: 0.75rem !important;
  }
  .pb-max-4 {
    padding-block-end: 1rem !important;
  }
  .pb-max-5 {
    padding-block-end: 1.25rem !important;
  }
  .pb-max-6 {
    padding-block-end: 1.5rem !important;
  }
  .pb-max-7 {
    padding-block-end: 1.75rem !important;
  }
  .pb-max-8 {
    padding-block-end: 2rem !important;
  }
  .pb-max-9 {
    padding-block-end: 2.25rem !important;
  }
  .pb-max-10 {
    padding-block-end: 2.5rem !important;
  }
  .pb-max-11 {
    padding-block-end: 2.75rem !important;
  }
  .pb-max-12 {
    padding-block-end: 3rem !important;
  }
  .pb-max-13 {
    padding-block-end: 3.25rem !important;
  }
  .pb-max-14 {
    padding-block-end: 3.5rem !important;
  }
  .pb-max-15 {
    padding-block-end: 3.75rem !important;
  }
  .pb-max-16 {
    padding-block-end: 4rem !important;
  }
  .pb-max-17 {
    padding-block-end: 4.25rem !important;
  }
  .pb-max-18 {
    padding-block-end: 4.5rem !important;
  }
  .pb-max-19 {
    padding-block-end: 4.75rem !important;
  }
  .pb-max-20 {
    padding-block-end: 5rem !important;
  }
  .pb-max-21 {
    padding-block-end: 5.25rem !important;
  }
  .pb-max-22 {
    padding-block-end: 5.5rem !important;
  }
  .pb-max-23 {
    padding-block-end: 5.75rem !important;
  }
  .pb-max-24 {
    padding-block-end: 6rem !important;
  }
  .pb-max-25 {
    padding-block-end: 6.25rem !important;
  }
  .pb-max-26 {
    padding-block-end: 6.5rem !important;
  }
  .pb-max-27 {
    padding-block-end: 6.75rem !important;
  }
  .pb-max-28 {
    padding-block-end: 7rem !important;
  }
  .pb-max-29 {
    padding-block-end: 7.25rem !important;
  }
  .pb-max-30 {
    padding-block-end: 7.5rem !important;
  }
  .pb-max-31 {
    padding-block-end: 7.75rem !important;
  }
  .pb-max-32 {
    padding-block-end: 8rem !important;
  }
  .pb-max-33 {
    padding-block-end: 8.25rem !important;
  }
  .pb-max-34 {
    padding-block-end: 8.5rem !important;
  }
  .pb-max-35 {
    padding-block-end: 8.75rem !important;
  }
  .pb-max-36 {
    padding-block-end: 9rem !important;
  }
  .pb-max-37 {
    padding-block-end: 9.25rem !important;
  }
  .pb-max-38 {
    padding-block-end: 9.5rem !important;
  }
  .pb-max-39 {
    padding-block-end: 9.75rem !important;
  }
  .pb-max-40 {
    padding-block-end: 10rem !important;
  }
  .ps-max-0 {
    padding-inline-start: 0 !important;
  }
  .ps-max-1 {
    padding-inline-start: 0.25rem !important;
  }
  .ps-max-2 {
    padding-inline-start: 0.5rem !important;
  }
  .ps-max-3 {
    padding-inline-start: 0.75rem !important;
  }
  .ps-max-4 {
    padding-inline-start: 1rem !important;
  }
  .ps-max-5 {
    padding-inline-start: 1.25rem !important;
  }
  .ps-max-6 {
    padding-inline-start: 1.5rem !important;
  }
  .ps-max-7 {
    padding-inline-start: 1.75rem !important;
  }
  .ps-max-8 {
    padding-inline-start: 2rem !important;
  }
  .ps-max-9 {
    padding-inline-start: 2.25rem !important;
  }
  .ps-max-10 {
    padding-inline-start: 2.5rem !important;
  }
  .ps-max-11 {
    padding-inline-start: 2.75rem !important;
  }
  .ps-max-12 {
    padding-inline-start: 3rem !important;
  }
  .ps-max-13 {
    padding-inline-start: 3.25rem !important;
  }
  .ps-max-14 {
    padding-inline-start: 3.5rem !important;
  }
  .ps-max-15 {
    padding-inline-start: 3.75rem !important;
  }
  .ps-max-16 {
    padding-inline-start: 4rem !important;
  }
  .ps-max-17 {
    padding-inline-start: 4.25rem !important;
  }
  .ps-max-18 {
    padding-inline-start: 4.5rem !important;
  }
  .ps-max-19 {
    padding-inline-start: 4.75rem !important;
  }
  .ps-max-20 {
    padding-inline-start: 5rem !important;
  }
  .ps-max-21 {
    padding-inline-start: 5.25rem !important;
  }
  .ps-max-22 {
    padding-inline-start: 5.5rem !important;
  }
  .ps-max-23 {
    padding-inline-start: 5.75rem !important;
  }
  .ps-max-24 {
    padding-inline-start: 6rem !important;
  }
  .ps-max-25 {
    padding-inline-start: 6.25rem !important;
  }
  .ps-max-26 {
    padding-inline-start: 6.5rem !important;
  }
  .ps-max-27 {
    padding-inline-start: 6.75rem !important;
  }
  .ps-max-28 {
    padding-inline-start: 7rem !important;
  }
  .ps-max-29 {
    padding-inline-start: 7.25rem !important;
  }
  .ps-max-30 {
    padding-inline-start: 7.5rem !important;
  }
  .ps-max-31 {
    padding-inline-start: 7.75rem !important;
  }
  .ps-max-32 {
    padding-inline-start: 8rem !important;
  }
  .ps-max-33 {
    padding-inline-start: 8.25rem !important;
  }
  .ps-max-34 {
    padding-inline-start: 8.5rem !important;
  }
  .ps-max-35 {
    padding-inline-start: 8.75rem !important;
  }
  .ps-max-36 {
    padding-inline-start: 9rem !important;
  }
  .ps-max-37 {
    padding-inline-start: 9.25rem !important;
  }
  .ps-max-38 {
    padding-inline-start: 9.5rem !important;
  }
  .ps-max-39 {
    padding-inline-start: 9.75rem !important;
  }
  .ps-max-40 {
    padding-inline-start: 10rem !important;
  }
  .gap-max-0 {
    gap: 0 !important;
  }
  .gap-max-1 {
    gap: 0.25rem !important;
  }
  .gap-max-2 {
    gap: 0.5rem !important;
  }
  .gap-max-3 {
    gap: 0.75rem !important;
  }
  .gap-max-4 {
    gap: 1rem !important;
  }
  .gap-max-5 {
    gap: 1.25rem !important;
  }
  .gap-max-6 {
    gap: 1.5rem !important;
  }
  .gap-max-7 {
    gap: 1.75rem !important;
  }
  .gap-max-8 {
    gap: 2rem !important;
  }
  .gap-max-9 {
    gap: 2.25rem !important;
  }
  .gap-max-10 {
    gap: 2.5rem !important;
  }
  .gap-max-11 {
    gap: 2.75rem !important;
  }
  .gap-max-12 {
    gap: 3rem !important;
  }
  .gap-max-13 {
    gap: 3.25rem !important;
  }
  .gap-max-14 {
    gap: 3.5rem !important;
  }
  .gap-max-15 {
    gap: 3.75rem !important;
  }
  .gap-max-16 {
    gap: 4rem !important;
  }
  .gap-max-17 {
    gap: 4.25rem !important;
  }
  .gap-max-18 {
    gap: 4.5rem !important;
  }
  .gap-max-19 {
    gap: 4.75rem !important;
  }
  .gap-max-20 {
    gap: 5rem !important;
  }
  .gap-max-21 {
    gap: 5.25rem !important;
  }
  .gap-max-22 {
    gap: 5.5rem !important;
  }
  .gap-max-23 {
    gap: 5.75rem !important;
  }
  .gap-max-24 {
    gap: 6rem !important;
  }
  .gap-max-25 {
    gap: 6.25rem !important;
  }
  .gap-max-26 {
    gap: 6.5rem !important;
  }
  .gap-max-27 {
    gap: 6.75rem !important;
  }
  .gap-max-28 {
    gap: 7rem !important;
  }
  .gap-max-29 {
    gap: 7.25rem !important;
  }
  .gap-max-30 {
    gap: 7.5rem !important;
  }
  .gap-max-31 {
    gap: 7.75rem !important;
  }
  .gap-max-32 {
    gap: 8rem !important;
  }
  .gap-max-33 {
    gap: 8.25rem !important;
  }
  .gap-max-34 {
    gap: 8.5rem !important;
  }
  .gap-max-35 {
    gap: 8.75rem !important;
  }
  .gap-max-36 {
    gap: 9rem !important;
  }
  .gap-max-37 {
    gap: 9.25rem !important;
  }
  .gap-max-38 {
    gap: 9.5rem !important;
  }
  .gap-max-39 {
    gap: 9.75rem !important;
  }
  .gap-max-40 {
    gap: 10rem !important;
  }
  .row-gap-max-0 {
    row-gap: 0 !important;
  }
  .row-gap-max-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-max-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-max-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-max-4 {
    row-gap: 1rem !important;
  }
  .row-gap-max-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-max-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-max-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-max-8 {
    row-gap: 2rem !important;
  }
  .row-gap-max-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-max-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-max-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-max-12 {
    row-gap: 3rem !important;
  }
  .row-gap-max-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-max-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-max-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-max-16 {
    row-gap: 4rem !important;
  }
  .row-gap-max-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-max-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-max-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-max-20 {
    row-gap: 5rem !important;
  }
  .row-gap-max-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-max-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-max-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-max-24 {
    row-gap: 6rem !important;
  }
  .row-gap-max-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-max-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-max-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-max-28 {
    row-gap: 7rem !important;
  }
  .row-gap-max-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-max-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-max-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-max-32 {
    row-gap: 8rem !important;
  }
  .row-gap-max-33 {
    row-gap: 8.25rem !important;
  }
  .row-gap-max-34 {
    row-gap: 8.5rem !important;
  }
  .row-gap-max-35 {
    row-gap: 8.75rem !important;
  }
  .row-gap-max-36 {
    row-gap: 9rem !important;
  }
  .row-gap-max-37 {
    row-gap: 9.25rem !important;
  }
  .row-gap-max-38 {
    row-gap: 9.5rem !important;
  }
  .row-gap-max-39 {
    row-gap: 9.75rem !important;
  }
  .row-gap-max-40 {
    row-gap: 10rem !important;
  }
  .column-gap-max-0 {
    column-gap: 0 !important;
  }
  .column-gap-max-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-max-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-max-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-max-4 {
    column-gap: 1rem !important;
  }
  .column-gap-max-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-max-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-max-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-max-8 {
    column-gap: 2rem !important;
  }
  .column-gap-max-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-max-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-max-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-max-12 {
    column-gap: 3rem !important;
  }
  .column-gap-max-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-max-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-max-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-max-16 {
    column-gap: 4rem !important;
  }
  .column-gap-max-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-max-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-max-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-max-20 {
    column-gap: 5rem !important;
  }
  .column-gap-max-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-max-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-max-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-max-24 {
    column-gap: 6rem !important;
  }
  .column-gap-max-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-max-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-max-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-max-28 {
    column-gap: 7rem !important;
  }
  .column-gap-max-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-max-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-max-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-max-32 {
    column-gap: 8rem !important;
  }
  .column-gap-max-33 {
    column-gap: 8.25rem !important;
  }
  .column-gap-max-34 {
    column-gap: 8.5rem !important;
  }
  .column-gap-max-35 {
    column-gap: 8.75rem !important;
  }
  .column-gap-max-36 {
    column-gap: 9rem !important;
  }
  .column-gap-max-37 {
    column-gap: 9.25rem !important;
  }
  .column-gap-max-38 {
    column-gap: 9.5rem !important;
  }
  .column-gap-max-39 {
    column-gap: 9.75rem !important;
  }
  .column-gap-max-40 {
    column-gap: 10rem !important;
  }
  .text-max-start {
    text-align: start !important;
  }
  .text-max-end {
    text-align: end !important;
  }
  .text-max-center {
    text-align: center !important;
  }
}
@media (min-width: 1920px) {
  .float-xmax-start {
    float: left !important;
  }
  .float-xmax-end {
    float: right !important;
  }
  .float-xmax-none {
    float: none !important;
  }
  .object-fit-xmax-contain {
    object-fit: contain !important;
  }
  .object-fit-xmax-cover {
    object-fit: cover !important;
  }
  .object-fit-xmax-fill {
    object-fit: fill !important;
  }
  .object-fit-xmax-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xmax-none {
    object-fit: none !important;
  }
  .d-xmax-inline {
    display: inline !important;
  }
  .d-xmax-inline-block {
    display: inline-block !important;
  }
  .d-xmax-block {
    display: block !important;
  }
  .d-xmax-grid {
    display: grid !important;
  }
  .d-xmax-inline-grid {
    display: inline-grid !important;
  }
  .d-xmax-table {
    display: table !important;
  }
  .d-xmax-table-row {
    display: table-row !important;
  }
  .d-xmax-table-cell {
    display: table-cell !important;
  }
  .d-xmax-flex {
    display: flex !important;
  }
  .d-xmax-inline-flex {
    display: inline-flex !important;
  }
  .d-xmax-none {
    display: none !important;
  }
  .border-xmax {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xmax-0 {
    border: 0 !important;
  }
  .flex-xmax-fill {
    flex: 1 1 auto !important;
  }
  .flex-xmax-row {
    flex-direction: row !important;
  }
  .flex-xmax-column {
    flex-direction: column !important;
  }
  .flex-xmax-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xmax-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xmax-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xmax-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xmax-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xmax-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xmax-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xmax-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xmax-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xmax-start {
    justify-content: flex-start !important;
  }
  .justify-content-xmax-end {
    justify-content: flex-end !important;
  }
  .justify-content-xmax-center {
    justify-content: center !important;
  }
  .justify-content-xmax-between {
    justify-content: space-between !important;
  }
  .justify-content-xmax-around {
    justify-content: space-around !important;
  }
  .justify-content-xmax-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xmax-start {
    align-items: flex-start !important;
  }
  .align-items-xmax-end {
    align-items: flex-end !important;
  }
  .align-items-xmax-center {
    align-items: center !important;
  }
  .align-items-xmax-baseline {
    align-items: baseline !important;
  }
  .align-items-xmax-stretch {
    align-items: stretch !important;
  }
  .align-content-xmax-start {
    align-content: flex-start !important;
  }
  .align-content-xmax-end {
    align-content: flex-end !important;
  }
  .align-content-xmax-center {
    align-content: center !important;
  }
  .align-content-xmax-between {
    align-content: space-between !important;
  }
  .align-content-xmax-around {
    align-content: space-around !important;
  }
  .align-content-xmax-stretch {
    align-content: stretch !important;
  }
  .align-self-xmax-auto {
    align-self: auto !important;
  }
  .align-self-xmax-start {
    align-self: flex-start !important;
  }
  .align-self-xmax-end {
    align-self: flex-end !important;
  }
  .align-self-xmax-center {
    align-self: center !important;
  }
  .align-self-xmax-baseline {
    align-self: baseline !important;
  }
  .align-self-xmax-stretch {
    align-self: stretch !important;
  }
  .order-xmax-first {
    order: -1 !important;
  }
  .order-xmax-0 {
    order: 0 !important;
  }
  .order-xmax-1 {
    order: 1 !important;
  }
  .order-xmax-2 {
    order: 2 !important;
  }
  .order-xmax-3 {
    order: 3 !important;
  }
  .order-xmax-4 {
    order: 4 !important;
  }
  .order-xmax-5 {
    order: 5 !important;
  }
  .order-xmax-last {
    order: 6 !important;
  }
  .m-xmax-0 {
    margin: 0 !important;
  }
  .m-xmax-1 {
    margin: 0.25rem !important;
  }
  .m-xmax-2 {
    margin: 0.5rem !important;
  }
  .m-xmax-3 {
    margin: 0.75rem !important;
  }
  .m-xmax-4 {
    margin: 1rem !important;
  }
  .m-xmax-5 {
    margin: 1.25rem !important;
  }
  .m-xmax-6 {
    margin: 1.5rem !important;
  }
  .m-xmax-7 {
    margin: 1.75rem !important;
  }
  .m-xmax-8 {
    margin: 2rem !important;
  }
  .m-xmax-9 {
    margin: 2.25rem !important;
  }
  .m-xmax-10 {
    margin: 2.5rem !important;
  }
  .m-xmax-11 {
    margin: 2.75rem !important;
  }
  .m-xmax-12 {
    margin: 3rem !important;
  }
  .m-xmax-13 {
    margin: 3.25rem !important;
  }
  .m-xmax-14 {
    margin: 3.5rem !important;
  }
  .m-xmax-15 {
    margin: 3.75rem !important;
  }
  .m-xmax-16 {
    margin: 4rem !important;
  }
  .m-xmax-17 {
    margin: 4.25rem !important;
  }
  .m-xmax-18 {
    margin: 4.5rem !important;
  }
  .m-xmax-19 {
    margin: 4.75rem !important;
  }
  .m-xmax-20 {
    margin: 5rem !important;
  }
  .m-xmax-21 {
    margin: 5.25rem !important;
  }
  .m-xmax-22 {
    margin: 5.5rem !important;
  }
  .m-xmax-23 {
    margin: 5.75rem !important;
  }
  .m-xmax-24 {
    margin: 6rem !important;
  }
  .m-xmax-25 {
    margin: 6.25rem !important;
  }
  .m-xmax-26 {
    margin: 6.5rem !important;
  }
  .m-xmax-27 {
    margin: 6.75rem !important;
  }
  .m-xmax-28 {
    margin: 7rem !important;
  }
  .m-xmax-29 {
    margin: 7.25rem !important;
  }
  .m-xmax-30 {
    margin: 7.5rem !important;
  }
  .m-xmax-31 {
    margin: 7.75rem !important;
  }
  .m-xmax-32 {
    margin: 8rem !important;
  }
  .m-xmax-33 {
    margin: 8.25rem !important;
  }
  .m-xmax-34 {
    margin: 8.5rem !important;
  }
  .m-xmax-35 {
    margin: 8.75rem !important;
  }
  .m-xmax-36 {
    margin: 9rem !important;
  }
  .m-xmax-37 {
    margin: 9.25rem !important;
  }
  .m-xmax-38 {
    margin: 9.5rem !important;
  }
  .m-xmax-39 {
    margin: 9.75rem !important;
  }
  .m-xmax-40 {
    margin: 10rem !important;
  }
  .m-xmax-auto {
    margin: auto !important;
  }
  .mx-xmax-0 {
    margin-inline: 0 !important;
  }
  .mx-xmax-1 {
    margin-inline: 0.25rem !important;
  }
  .mx-xmax-2 {
    margin-inline: 0.5rem !important;
  }
  .mx-xmax-3 {
    margin-inline: 0.75rem !important;
  }
  .mx-xmax-4 {
    margin-inline: 1rem !important;
  }
  .mx-xmax-5 {
    margin-inline: 1.25rem !important;
  }
  .mx-xmax-6 {
    margin-inline: 1.5rem !important;
  }
  .mx-xmax-7 {
    margin-inline: 1.75rem !important;
  }
  .mx-xmax-8 {
    margin-inline: 2rem !important;
  }
  .mx-xmax-9 {
    margin-inline: 2.25rem !important;
  }
  .mx-xmax-10 {
    margin-inline: 2.5rem !important;
  }
  .mx-xmax-11 {
    margin-inline: 2.75rem !important;
  }
  .mx-xmax-12 {
    margin-inline: 3rem !important;
  }
  .mx-xmax-13 {
    margin-inline: 3.25rem !important;
  }
  .mx-xmax-14 {
    margin-inline: 3.5rem !important;
  }
  .mx-xmax-15 {
    margin-inline: 3.75rem !important;
  }
  .mx-xmax-16 {
    margin-inline: 4rem !important;
  }
  .mx-xmax-17 {
    margin-inline: 4.25rem !important;
  }
  .mx-xmax-18 {
    margin-inline: 4.5rem !important;
  }
  .mx-xmax-19 {
    margin-inline: 4.75rem !important;
  }
  .mx-xmax-20 {
    margin-inline: 5rem !important;
  }
  .mx-xmax-21 {
    margin-inline: 5.25rem !important;
  }
  .mx-xmax-22 {
    margin-inline: 5.5rem !important;
  }
  .mx-xmax-23 {
    margin-inline: 5.75rem !important;
  }
  .mx-xmax-24 {
    margin-inline: 6rem !important;
  }
  .mx-xmax-25 {
    margin-inline: 6.25rem !important;
  }
  .mx-xmax-26 {
    margin-inline: 6.5rem !important;
  }
  .mx-xmax-27 {
    margin-inline: 6.75rem !important;
  }
  .mx-xmax-28 {
    margin-inline: 7rem !important;
  }
  .mx-xmax-29 {
    margin-inline: 7.25rem !important;
  }
  .mx-xmax-30 {
    margin-inline: 7.5rem !important;
  }
  .mx-xmax-31 {
    margin-inline: 7.75rem !important;
  }
  .mx-xmax-32 {
    margin-inline: 8rem !important;
  }
  .mx-xmax-33 {
    margin-inline: 8.25rem !important;
  }
  .mx-xmax-34 {
    margin-inline: 8.5rem !important;
  }
  .mx-xmax-35 {
    margin-inline: 8.75rem !important;
  }
  .mx-xmax-36 {
    margin-inline: 9rem !important;
  }
  .mx-xmax-37 {
    margin-inline: 9.25rem !important;
  }
  .mx-xmax-38 {
    margin-inline: 9.5rem !important;
  }
  .mx-xmax-39 {
    margin-inline: 9.75rem !important;
  }
  .mx-xmax-40 {
    margin-inline: 10rem !important;
  }
  .mx-xmax-auto {
    margin-inline: auto !important;
  }
  .my-xmax-0 {
    margin-block: 0 !important;
  }
  .my-xmax-1 {
    margin-block: 0.25rem !important;
  }
  .my-xmax-2 {
    margin-block: 0.5rem !important;
  }
  .my-xmax-3 {
    margin-block: 0.75rem !important;
  }
  .my-xmax-4 {
    margin-block: 1rem !important;
  }
  .my-xmax-5 {
    margin-block: 1.25rem !important;
  }
  .my-xmax-6 {
    margin-block: 1.5rem !important;
  }
  .my-xmax-7 {
    margin-block: 1.75rem !important;
  }
  .my-xmax-8 {
    margin-block: 2rem !important;
  }
  .my-xmax-9 {
    margin-block: 2.25rem !important;
  }
  .my-xmax-10 {
    margin-block: 2.5rem !important;
  }
  .my-xmax-11 {
    margin-block: 2.75rem !important;
  }
  .my-xmax-12 {
    margin-block: 3rem !important;
  }
  .my-xmax-13 {
    margin-block: 3.25rem !important;
  }
  .my-xmax-14 {
    margin-block: 3.5rem !important;
  }
  .my-xmax-15 {
    margin-block: 3.75rem !important;
  }
  .my-xmax-16 {
    margin-block: 4rem !important;
  }
  .my-xmax-17 {
    margin-block: 4.25rem !important;
  }
  .my-xmax-18 {
    margin-block: 4.5rem !important;
  }
  .my-xmax-19 {
    margin-block: 4.75rem !important;
  }
  .my-xmax-20 {
    margin-block: 5rem !important;
  }
  .my-xmax-21 {
    margin-block: 5.25rem !important;
  }
  .my-xmax-22 {
    margin-block: 5.5rem !important;
  }
  .my-xmax-23 {
    margin-block: 5.75rem !important;
  }
  .my-xmax-24 {
    margin-block: 6rem !important;
  }
  .my-xmax-25 {
    margin-block: 6.25rem !important;
  }
  .my-xmax-26 {
    margin-block: 6.5rem !important;
  }
  .my-xmax-27 {
    margin-block: 6.75rem !important;
  }
  .my-xmax-28 {
    margin-block: 7rem !important;
  }
  .my-xmax-29 {
    margin-block: 7.25rem !important;
  }
  .my-xmax-30 {
    margin-block: 7.5rem !important;
  }
  .my-xmax-31 {
    margin-block: 7.75rem !important;
  }
  .my-xmax-32 {
    margin-block: 8rem !important;
  }
  .my-xmax-33 {
    margin-block: 8.25rem !important;
  }
  .my-xmax-34 {
    margin-block: 8.5rem !important;
  }
  .my-xmax-35 {
    margin-block: 8.75rem !important;
  }
  .my-xmax-36 {
    margin-block: 9rem !important;
  }
  .my-xmax-37 {
    margin-block: 9.25rem !important;
  }
  .my-xmax-38 {
    margin-block: 9.5rem !important;
  }
  .my-xmax-39 {
    margin-block: 9.75rem !important;
  }
  .my-xmax-40 {
    margin-block: 10rem !important;
  }
  .my-xmax-auto {
    margin-block: auto !important;
  }
  .mt-xmax-0 {
    margin-block-start: 0 !important;
  }
  .mt-xmax-1 {
    margin-block-start: 0.25rem !important;
  }
  .mt-xmax-2 {
    margin-block-start: 0.5rem !important;
  }
  .mt-xmax-3 {
    margin-block-start: 0.75rem !important;
  }
  .mt-xmax-4 {
    margin-block-start: 1rem !important;
  }
  .mt-xmax-5 {
    margin-block-start: 1.25rem !important;
  }
  .mt-xmax-6 {
    margin-block-start: 1.5rem !important;
  }
  .mt-xmax-7 {
    margin-block-start: 1.75rem !important;
  }
  .mt-xmax-8 {
    margin-block-start: 2rem !important;
  }
  .mt-xmax-9 {
    margin-block-start: 2.25rem !important;
  }
  .mt-xmax-10 {
    margin-block-start: 2.5rem !important;
  }
  .mt-xmax-11 {
    margin-block-start: 2.75rem !important;
  }
  .mt-xmax-12 {
    margin-block-start: 3rem !important;
  }
  .mt-xmax-13 {
    margin-block-start: 3.25rem !important;
  }
  .mt-xmax-14 {
    margin-block-start: 3.5rem !important;
  }
  .mt-xmax-15 {
    margin-block-start: 3.75rem !important;
  }
  .mt-xmax-16 {
    margin-block-start: 4rem !important;
  }
  .mt-xmax-17 {
    margin-block-start: 4.25rem !important;
  }
  .mt-xmax-18 {
    margin-block-start: 4.5rem !important;
  }
  .mt-xmax-19 {
    margin-block-start: 4.75rem !important;
  }
  .mt-xmax-20 {
    margin-block-start: 5rem !important;
  }
  .mt-xmax-21 {
    margin-block-start: 5.25rem !important;
  }
  .mt-xmax-22 {
    margin-block-start: 5.5rem !important;
  }
  .mt-xmax-23 {
    margin-block-start: 5.75rem !important;
  }
  .mt-xmax-24 {
    margin-block-start: 6rem !important;
  }
  .mt-xmax-25 {
    margin-block-start: 6.25rem !important;
  }
  .mt-xmax-26 {
    margin-block-start: 6.5rem !important;
  }
  .mt-xmax-27 {
    margin-block-start: 6.75rem !important;
  }
  .mt-xmax-28 {
    margin-block-start: 7rem !important;
  }
  .mt-xmax-29 {
    margin-block-start: 7.25rem !important;
  }
  .mt-xmax-30 {
    margin-block-start: 7.5rem !important;
  }
  .mt-xmax-31 {
    margin-block-start: 7.75rem !important;
  }
  .mt-xmax-32 {
    margin-block-start: 8rem !important;
  }
  .mt-xmax-33 {
    margin-block-start: 8.25rem !important;
  }
  .mt-xmax-34 {
    margin-block-start: 8.5rem !important;
  }
  .mt-xmax-35 {
    margin-block-start: 8.75rem !important;
  }
  .mt-xmax-36 {
    margin-block-start: 9rem !important;
  }
  .mt-xmax-37 {
    margin-block-start: 9.25rem !important;
  }
  .mt-xmax-38 {
    margin-block-start: 9.5rem !important;
  }
  .mt-xmax-39 {
    margin-block-start: 9.75rem !important;
  }
  .mt-xmax-40 {
    margin-block-start: 10rem !important;
  }
  .mt-xmax-auto {
    margin-block-start: auto !important;
  }
  .me-xmax-0 {
    margin-inline-end: 0 !important;
  }
  .me-xmax-1 {
    margin-inline-end: 0.25rem !important;
  }
  .me-xmax-2 {
    margin-inline-end: 0.5rem !important;
  }
  .me-xmax-3 {
    margin-inline-end: 0.75rem !important;
  }
  .me-xmax-4 {
    margin-inline-end: 1rem !important;
  }
  .me-xmax-5 {
    margin-inline-end: 1.25rem !important;
  }
  .me-xmax-6 {
    margin-inline-end: 1.5rem !important;
  }
  .me-xmax-7 {
    margin-inline-end: 1.75rem !important;
  }
  .me-xmax-8 {
    margin-inline-end: 2rem !important;
  }
  .me-xmax-9 {
    margin-inline-end: 2.25rem !important;
  }
  .me-xmax-10 {
    margin-inline-end: 2.5rem !important;
  }
  .me-xmax-11 {
    margin-inline-end: 2.75rem !important;
  }
  .me-xmax-12 {
    margin-inline-end: 3rem !important;
  }
  .me-xmax-13 {
    margin-inline-end: 3.25rem !important;
  }
  .me-xmax-14 {
    margin-inline-end: 3.5rem !important;
  }
  .me-xmax-15 {
    margin-inline-end: 3.75rem !important;
  }
  .me-xmax-16 {
    margin-inline-end: 4rem !important;
  }
  .me-xmax-17 {
    margin-inline-end: 4.25rem !important;
  }
  .me-xmax-18 {
    margin-inline-end: 4.5rem !important;
  }
  .me-xmax-19 {
    margin-inline-end: 4.75rem !important;
  }
  .me-xmax-20 {
    margin-inline-end: 5rem !important;
  }
  .me-xmax-21 {
    margin-inline-end: 5.25rem !important;
  }
  .me-xmax-22 {
    margin-inline-end: 5.5rem !important;
  }
  .me-xmax-23 {
    margin-inline-end: 5.75rem !important;
  }
  .me-xmax-24 {
    margin-inline-end: 6rem !important;
  }
  .me-xmax-25 {
    margin-inline-end: 6.25rem !important;
  }
  .me-xmax-26 {
    margin-inline-end: 6.5rem !important;
  }
  .me-xmax-27 {
    margin-inline-end: 6.75rem !important;
  }
  .me-xmax-28 {
    margin-inline-end: 7rem !important;
  }
  .me-xmax-29 {
    margin-inline-end: 7.25rem !important;
  }
  .me-xmax-30 {
    margin-inline-end: 7.5rem !important;
  }
  .me-xmax-31 {
    margin-inline-end: 7.75rem !important;
  }
  .me-xmax-32 {
    margin-inline-end: 8rem !important;
  }
  .me-xmax-33 {
    margin-inline-end: 8.25rem !important;
  }
  .me-xmax-34 {
    margin-inline-end: 8.5rem !important;
  }
  .me-xmax-35 {
    margin-inline-end: 8.75rem !important;
  }
  .me-xmax-36 {
    margin-inline-end: 9rem !important;
  }
  .me-xmax-37 {
    margin-inline-end: 9.25rem !important;
  }
  .me-xmax-38 {
    margin-inline-end: 9.5rem !important;
  }
  .me-xmax-39 {
    margin-inline-end: 9.75rem !important;
  }
  .me-xmax-40 {
    margin-inline-end: 10rem !important;
  }
  .me-xmax-auto {
    margin-inline-end: auto !important;
  }
  .mb-xmax-0 {
    margin-block-end: 0 !important;
  }
  .mb-xmax-1 {
    margin-block-end: 0.25rem !important;
  }
  .mb-xmax-2 {
    margin-block-end: 0.5rem !important;
  }
  .mb-xmax-3 {
    margin-block-end: 0.75rem !important;
  }
  .mb-xmax-4 {
    margin-block-end: 1rem !important;
  }
  .mb-xmax-5 {
    margin-block-end: 1.25rem !important;
  }
  .mb-xmax-6 {
    margin-block-end: 1.5rem !important;
  }
  .mb-xmax-7 {
    margin-block-end: 1.75rem !important;
  }
  .mb-xmax-8 {
    margin-block-end: 2rem !important;
  }
  .mb-xmax-9 {
    margin-block-end: 2.25rem !important;
  }
  .mb-xmax-10 {
    margin-block-end: 2.5rem !important;
  }
  .mb-xmax-11 {
    margin-block-end: 2.75rem !important;
  }
  .mb-xmax-12 {
    margin-block-end: 3rem !important;
  }
  .mb-xmax-13 {
    margin-block-end: 3.25rem !important;
  }
  .mb-xmax-14 {
    margin-block-end: 3.5rem !important;
  }
  .mb-xmax-15 {
    margin-block-end: 3.75rem !important;
  }
  .mb-xmax-16 {
    margin-block-end: 4rem !important;
  }
  .mb-xmax-17 {
    margin-block-end: 4.25rem !important;
  }
  .mb-xmax-18 {
    margin-block-end: 4.5rem !important;
  }
  .mb-xmax-19 {
    margin-block-end: 4.75rem !important;
  }
  .mb-xmax-20 {
    margin-block-end: 5rem !important;
  }
  .mb-xmax-21 {
    margin-block-end: 5.25rem !important;
  }
  .mb-xmax-22 {
    margin-block-end: 5.5rem !important;
  }
  .mb-xmax-23 {
    margin-block-end: 5.75rem !important;
  }
  .mb-xmax-24 {
    margin-block-end: 6rem !important;
  }
  .mb-xmax-25 {
    margin-block-end: 6.25rem !important;
  }
  .mb-xmax-26 {
    margin-block-end: 6.5rem !important;
  }
  .mb-xmax-27 {
    margin-block-end: 6.75rem !important;
  }
  .mb-xmax-28 {
    margin-block-end: 7rem !important;
  }
  .mb-xmax-29 {
    margin-block-end: 7.25rem !important;
  }
  .mb-xmax-30 {
    margin-block-end: 7.5rem !important;
  }
  .mb-xmax-31 {
    margin-block-end: 7.75rem !important;
  }
  .mb-xmax-32 {
    margin-block-end: 8rem !important;
  }
  .mb-xmax-33 {
    margin-block-end: 8.25rem !important;
  }
  .mb-xmax-34 {
    margin-block-end: 8.5rem !important;
  }
  .mb-xmax-35 {
    margin-block-end: 8.75rem !important;
  }
  .mb-xmax-36 {
    margin-block-end: 9rem !important;
  }
  .mb-xmax-37 {
    margin-block-end: 9.25rem !important;
  }
  .mb-xmax-38 {
    margin-block-end: 9.5rem !important;
  }
  .mb-xmax-39 {
    margin-block-end: 9.75rem !important;
  }
  .mb-xmax-40 {
    margin-block-end: 10rem !important;
  }
  .mb-xmax-auto {
    margin-block-end: auto !important;
  }
  .ms-xmax-0 {
    margin-inline-start: 0 !important;
  }
  .ms-xmax-1 {
    margin-inline-start: 0.25rem !important;
  }
  .ms-xmax-2 {
    margin-inline-start: 0.5rem !important;
  }
  .ms-xmax-3 {
    margin-inline-start: 0.75rem !important;
  }
  .ms-xmax-4 {
    margin-inline-start: 1rem !important;
  }
  .ms-xmax-5 {
    margin-inline-start: 1.25rem !important;
  }
  .ms-xmax-6 {
    margin-inline-start: 1.5rem !important;
  }
  .ms-xmax-7 {
    margin-inline-start: 1.75rem !important;
  }
  .ms-xmax-8 {
    margin-inline-start: 2rem !important;
  }
  .ms-xmax-9 {
    margin-inline-start: 2.25rem !important;
  }
  .ms-xmax-10 {
    margin-inline-start: 2.5rem !important;
  }
  .ms-xmax-11 {
    margin-inline-start: 2.75rem !important;
  }
  .ms-xmax-12 {
    margin-inline-start: 3rem !important;
  }
  .ms-xmax-13 {
    margin-inline-start: 3.25rem !important;
  }
  .ms-xmax-14 {
    margin-inline-start: 3.5rem !important;
  }
  .ms-xmax-15 {
    margin-inline-start: 3.75rem !important;
  }
  .ms-xmax-16 {
    margin-inline-start: 4rem !important;
  }
  .ms-xmax-17 {
    margin-inline-start: 4.25rem !important;
  }
  .ms-xmax-18 {
    margin-inline-start: 4.5rem !important;
  }
  .ms-xmax-19 {
    margin-inline-start: 4.75rem !important;
  }
  .ms-xmax-20 {
    margin-inline-start: 5rem !important;
  }
  .ms-xmax-21 {
    margin-inline-start: 5.25rem !important;
  }
  .ms-xmax-22 {
    margin-inline-start: 5.5rem !important;
  }
  .ms-xmax-23 {
    margin-inline-start: 5.75rem !important;
  }
  .ms-xmax-24 {
    margin-inline-start: 6rem !important;
  }
  .ms-xmax-25 {
    margin-inline-start: 6.25rem !important;
  }
  .ms-xmax-26 {
    margin-inline-start: 6.5rem !important;
  }
  .ms-xmax-27 {
    margin-inline-start: 6.75rem !important;
  }
  .ms-xmax-28 {
    margin-inline-start: 7rem !important;
  }
  .ms-xmax-29 {
    margin-inline-start: 7.25rem !important;
  }
  .ms-xmax-30 {
    margin-inline-start: 7.5rem !important;
  }
  .ms-xmax-31 {
    margin-inline-start: 7.75rem !important;
  }
  .ms-xmax-32 {
    margin-inline-start: 8rem !important;
  }
  .ms-xmax-33 {
    margin-inline-start: 8.25rem !important;
  }
  .ms-xmax-34 {
    margin-inline-start: 8.5rem !important;
  }
  .ms-xmax-35 {
    margin-inline-start: 8.75rem !important;
  }
  .ms-xmax-36 {
    margin-inline-start: 9rem !important;
  }
  .ms-xmax-37 {
    margin-inline-start: 9.25rem !important;
  }
  .ms-xmax-38 {
    margin-inline-start: 9.5rem !important;
  }
  .ms-xmax-39 {
    margin-inline-start: 9.75rem !important;
  }
  .ms-xmax-40 {
    margin-inline-start: 10rem !important;
  }
  .ms-xmax-auto {
    margin-inline-start: auto !important;
  }
  .p-xmax-0 {
    padding: 0 !important;
  }
  .p-xmax-1 {
    padding: 0.25rem !important;
  }
  .p-xmax-2 {
    padding: 0.5rem !important;
  }
  .p-xmax-3 {
    padding: 0.75rem !important;
  }
  .p-xmax-4 {
    padding: 1rem !important;
  }
  .p-xmax-5 {
    padding: 1.25rem !important;
  }
  .p-xmax-6 {
    padding: 1.5rem !important;
  }
  .p-xmax-7 {
    padding: 1.75rem !important;
  }
  .p-xmax-8 {
    padding: 2rem !important;
  }
  .p-xmax-9 {
    padding: 2.25rem !important;
  }
  .p-xmax-10 {
    padding: 2.5rem !important;
  }
  .p-xmax-11 {
    padding: 2.75rem !important;
  }
  .p-xmax-12 {
    padding: 3rem !important;
  }
  .p-xmax-13 {
    padding: 3.25rem !important;
  }
  .p-xmax-14 {
    padding: 3.5rem !important;
  }
  .p-xmax-15 {
    padding: 3.75rem !important;
  }
  .p-xmax-16 {
    padding: 4rem !important;
  }
  .p-xmax-17 {
    padding: 4.25rem !important;
  }
  .p-xmax-18 {
    padding: 4.5rem !important;
  }
  .p-xmax-19 {
    padding: 4.75rem !important;
  }
  .p-xmax-20 {
    padding: 5rem !important;
  }
  .p-xmax-21 {
    padding: 5.25rem !important;
  }
  .p-xmax-22 {
    padding: 5.5rem !important;
  }
  .p-xmax-23 {
    padding: 5.75rem !important;
  }
  .p-xmax-24 {
    padding: 6rem !important;
  }
  .p-xmax-25 {
    padding: 6.25rem !important;
  }
  .p-xmax-26 {
    padding: 6.5rem !important;
  }
  .p-xmax-27 {
    padding: 6.75rem !important;
  }
  .p-xmax-28 {
    padding: 7rem !important;
  }
  .p-xmax-29 {
    padding: 7.25rem !important;
  }
  .p-xmax-30 {
    padding: 7.5rem !important;
  }
  .p-xmax-31 {
    padding: 7.75rem !important;
  }
  .p-xmax-32 {
    padding: 8rem !important;
  }
  .p-xmax-33 {
    padding: 8.25rem !important;
  }
  .p-xmax-34 {
    padding: 8.5rem !important;
  }
  .p-xmax-35 {
    padding: 8.75rem !important;
  }
  .p-xmax-36 {
    padding: 9rem !important;
  }
  .p-xmax-37 {
    padding: 9.25rem !important;
  }
  .p-xmax-38 {
    padding: 9.5rem !important;
  }
  .p-xmax-39 {
    padding: 9.75rem !important;
  }
  .p-xmax-40 {
    padding: 10rem !important;
  }
  .px-xmax-0 {
    padding-inline: 0 !important;
  }
  .px-xmax-1 {
    padding-inline: 0.25rem !important;
  }
  .px-xmax-2 {
    padding-inline: 0.5rem !important;
  }
  .px-xmax-3 {
    padding-inline: 0.75rem !important;
  }
  .px-xmax-4 {
    padding-inline: 1rem !important;
  }
  .px-xmax-5 {
    padding-inline: 1.25rem !important;
  }
  .px-xmax-6 {
    padding-inline: 1.5rem !important;
  }
  .px-xmax-7 {
    padding-inline: 1.75rem !important;
  }
  .px-xmax-8 {
    padding-inline: 2rem !important;
  }
  .px-xmax-9 {
    padding-inline: 2.25rem !important;
  }
  .px-xmax-10 {
    padding-inline: 2.5rem !important;
  }
  .px-xmax-11 {
    padding-inline: 2.75rem !important;
  }
  .px-xmax-12 {
    padding-inline: 3rem !important;
  }
  .px-xmax-13 {
    padding-inline: 3.25rem !important;
  }
  .px-xmax-14 {
    padding-inline: 3.5rem !important;
  }
  .px-xmax-15 {
    padding-inline: 3.75rem !important;
  }
  .px-xmax-16 {
    padding-inline: 4rem !important;
  }
  .px-xmax-17 {
    padding-inline: 4.25rem !important;
  }
  .px-xmax-18 {
    padding-inline: 4.5rem !important;
  }
  .px-xmax-19 {
    padding-inline: 4.75rem !important;
  }
  .px-xmax-20 {
    padding-inline: 5rem !important;
  }
  .px-xmax-21 {
    padding-inline: 5.25rem !important;
  }
  .px-xmax-22 {
    padding-inline: 5.5rem !important;
  }
  .px-xmax-23 {
    padding-inline: 5.75rem !important;
  }
  .px-xmax-24 {
    padding-inline: 6rem !important;
  }
  .px-xmax-25 {
    padding-inline: 6.25rem !important;
  }
  .px-xmax-26 {
    padding-inline: 6.5rem !important;
  }
  .px-xmax-27 {
    padding-inline: 6.75rem !important;
  }
  .px-xmax-28 {
    padding-inline: 7rem !important;
  }
  .px-xmax-29 {
    padding-inline: 7.25rem !important;
  }
  .px-xmax-30 {
    padding-inline: 7.5rem !important;
  }
  .px-xmax-31 {
    padding-inline: 7.75rem !important;
  }
  .px-xmax-32 {
    padding-inline: 8rem !important;
  }
  .px-xmax-33 {
    padding-inline: 8.25rem !important;
  }
  .px-xmax-34 {
    padding-inline: 8.5rem !important;
  }
  .px-xmax-35 {
    padding-inline: 8.75rem !important;
  }
  .px-xmax-36 {
    padding-inline: 9rem !important;
  }
  .px-xmax-37 {
    padding-inline: 9.25rem !important;
  }
  .px-xmax-38 {
    padding-inline: 9.5rem !important;
  }
  .px-xmax-39 {
    padding-inline: 9.75rem !important;
  }
  .px-xmax-40 {
    padding-inline: 10rem !important;
  }
  .py-xmax-0 {
    padding-block: 0 !important;
  }
  .py-xmax-1 {
    padding-block: 0.25rem !important;
  }
  .py-xmax-2 {
    padding-block: 0.5rem !important;
  }
  .py-xmax-3 {
    padding-block: 0.75rem !important;
  }
  .py-xmax-4 {
    padding-block: 1rem !important;
  }
  .py-xmax-5 {
    padding-block: 1.25rem !important;
  }
  .py-xmax-6 {
    padding-block: 1.5rem !important;
  }
  .py-xmax-7 {
    padding-block: 1.75rem !important;
  }
  .py-xmax-8 {
    padding-block: 2rem !important;
  }
  .py-xmax-9 {
    padding-block: 2.25rem !important;
  }
  .py-xmax-10 {
    padding-block: 2.5rem !important;
  }
  .py-xmax-11 {
    padding-block: 2.75rem !important;
  }
  .py-xmax-12 {
    padding-block: 3rem !important;
  }
  .py-xmax-13 {
    padding-block: 3.25rem !important;
  }
  .py-xmax-14 {
    padding-block: 3.5rem !important;
  }
  .py-xmax-15 {
    padding-block: 3.75rem !important;
  }
  .py-xmax-16 {
    padding-block: 4rem !important;
  }
  .py-xmax-17 {
    padding-block: 4.25rem !important;
  }
  .py-xmax-18 {
    padding-block: 4.5rem !important;
  }
  .py-xmax-19 {
    padding-block: 4.75rem !important;
  }
  .py-xmax-20 {
    padding-block: 5rem !important;
  }
  .py-xmax-21 {
    padding-block: 5.25rem !important;
  }
  .py-xmax-22 {
    padding-block: 5.5rem !important;
  }
  .py-xmax-23 {
    padding-block: 5.75rem !important;
  }
  .py-xmax-24 {
    padding-block: 6rem !important;
  }
  .py-xmax-25 {
    padding-block: 6.25rem !important;
  }
  .py-xmax-26 {
    padding-block: 6.5rem !important;
  }
  .py-xmax-27 {
    padding-block: 6.75rem !important;
  }
  .py-xmax-28 {
    padding-block: 7rem !important;
  }
  .py-xmax-29 {
    padding-block: 7.25rem !important;
  }
  .py-xmax-30 {
    padding-block: 7.5rem !important;
  }
  .py-xmax-31 {
    padding-block: 7.75rem !important;
  }
  .py-xmax-32 {
    padding-block: 8rem !important;
  }
  .py-xmax-33 {
    padding-block: 8.25rem !important;
  }
  .py-xmax-34 {
    padding-block: 8.5rem !important;
  }
  .py-xmax-35 {
    padding-block: 8.75rem !important;
  }
  .py-xmax-36 {
    padding-block: 9rem !important;
  }
  .py-xmax-37 {
    padding-block: 9.25rem !important;
  }
  .py-xmax-38 {
    padding-block: 9.5rem !important;
  }
  .py-xmax-39 {
    padding-block: 9.75rem !important;
  }
  .py-xmax-40 {
    padding-block: 10rem !important;
  }
  .pt-xmax-0 {
    padding-block-start: 0 !important;
  }
  .pt-xmax-1 {
    padding-block-start: 0.25rem !important;
  }
  .pt-xmax-2 {
    padding-block-start: 0.5rem !important;
  }
  .pt-xmax-3 {
    padding-block-start: 0.75rem !important;
  }
  .pt-xmax-4 {
    padding-block-start: 1rem !important;
  }
  .pt-xmax-5 {
    padding-block-start: 1.25rem !important;
  }
  .pt-xmax-6 {
    padding-block-start: 1.5rem !important;
  }
  .pt-xmax-7 {
    padding-block-start: 1.75rem !important;
  }
  .pt-xmax-8 {
    padding-block-start: 2rem !important;
  }
  .pt-xmax-9 {
    padding-block-start: 2.25rem !important;
  }
  .pt-xmax-10 {
    padding-block-start: 2.5rem !important;
  }
  .pt-xmax-11 {
    padding-block-start: 2.75rem !important;
  }
  .pt-xmax-12 {
    padding-block-start: 3rem !important;
  }
  .pt-xmax-13 {
    padding-block-start: 3.25rem !important;
  }
  .pt-xmax-14 {
    padding-block-start: 3.5rem !important;
  }
  .pt-xmax-15 {
    padding-block-start: 3.75rem !important;
  }
  .pt-xmax-16 {
    padding-block-start: 4rem !important;
  }
  .pt-xmax-17 {
    padding-block-start: 4.25rem !important;
  }
  .pt-xmax-18 {
    padding-block-start: 4.5rem !important;
  }
  .pt-xmax-19 {
    padding-block-start: 4.75rem !important;
  }
  .pt-xmax-20 {
    padding-block-start: 5rem !important;
  }
  .pt-xmax-21 {
    padding-block-start: 5.25rem !important;
  }
  .pt-xmax-22 {
    padding-block-start: 5.5rem !important;
  }
  .pt-xmax-23 {
    padding-block-start: 5.75rem !important;
  }
  .pt-xmax-24 {
    padding-block-start: 6rem !important;
  }
  .pt-xmax-25 {
    padding-block-start: 6.25rem !important;
  }
  .pt-xmax-26 {
    padding-block-start: 6.5rem !important;
  }
  .pt-xmax-27 {
    padding-block-start: 6.75rem !important;
  }
  .pt-xmax-28 {
    padding-block-start: 7rem !important;
  }
  .pt-xmax-29 {
    padding-block-start: 7.25rem !important;
  }
  .pt-xmax-30 {
    padding-block-start: 7.5rem !important;
  }
  .pt-xmax-31 {
    padding-block-start: 7.75rem !important;
  }
  .pt-xmax-32 {
    padding-block-start: 8rem !important;
  }
  .pt-xmax-33 {
    padding-block-start: 8.25rem !important;
  }
  .pt-xmax-34 {
    padding-block-start: 8.5rem !important;
  }
  .pt-xmax-35 {
    padding-block-start: 8.75rem !important;
  }
  .pt-xmax-36 {
    padding-block-start: 9rem !important;
  }
  .pt-xmax-37 {
    padding-block-start: 9.25rem !important;
  }
  .pt-xmax-38 {
    padding-block-start: 9.5rem !important;
  }
  .pt-xmax-39 {
    padding-block-start: 9.75rem !important;
  }
  .pt-xmax-40 {
    padding-block-start: 10rem !important;
  }
  .pe-xmax-0 {
    padding-inline-end: 0 !important;
  }
  .pe-xmax-1 {
    padding-inline-end: 0.25rem !important;
  }
  .pe-xmax-2 {
    padding-inline-end: 0.5rem !important;
  }
  .pe-xmax-3 {
    padding-inline-end: 0.75rem !important;
  }
  .pe-xmax-4 {
    padding-inline-end: 1rem !important;
  }
  .pe-xmax-5 {
    padding-inline-end: 1.25rem !important;
  }
  .pe-xmax-6 {
    padding-inline-end: 1.5rem !important;
  }
  .pe-xmax-7 {
    padding-inline-end: 1.75rem !important;
  }
  .pe-xmax-8 {
    padding-inline-end: 2rem !important;
  }
  .pe-xmax-9 {
    padding-inline-end: 2.25rem !important;
  }
  .pe-xmax-10 {
    padding-inline-end: 2.5rem !important;
  }
  .pe-xmax-11 {
    padding-inline-end: 2.75rem !important;
  }
  .pe-xmax-12 {
    padding-inline-end: 3rem !important;
  }
  .pe-xmax-13 {
    padding-inline-end: 3.25rem !important;
  }
  .pe-xmax-14 {
    padding-inline-end: 3.5rem !important;
  }
  .pe-xmax-15 {
    padding-inline-end: 3.75rem !important;
  }
  .pe-xmax-16 {
    padding-inline-end: 4rem !important;
  }
  .pe-xmax-17 {
    padding-inline-end: 4.25rem !important;
  }
  .pe-xmax-18 {
    padding-inline-end: 4.5rem !important;
  }
  .pe-xmax-19 {
    padding-inline-end: 4.75rem !important;
  }
  .pe-xmax-20 {
    padding-inline-end: 5rem !important;
  }
  .pe-xmax-21 {
    padding-inline-end: 5.25rem !important;
  }
  .pe-xmax-22 {
    padding-inline-end: 5.5rem !important;
  }
  .pe-xmax-23 {
    padding-inline-end: 5.75rem !important;
  }
  .pe-xmax-24 {
    padding-inline-end: 6rem !important;
  }
  .pe-xmax-25 {
    padding-inline-end: 6.25rem !important;
  }
  .pe-xmax-26 {
    padding-inline-end: 6.5rem !important;
  }
  .pe-xmax-27 {
    padding-inline-end: 6.75rem !important;
  }
  .pe-xmax-28 {
    padding-inline-end: 7rem !important;
  }
  .pe-xmax-29 {
    padding-inline-end: 7.25rem !important;
  }
  .pe-xmax-30 {
    padding-inline-end: 7.5rem !important;
  }
  .pe-xmax-31 {
    padding-inline-end: 7.75rem !important;
  }
  .pe-xmax-32 {
    padding-inline-end: 8rem !important;
  }
  .pe-xmax-33 {
    padding-inline-end: 8.25rem !important;
  }
  .pe-xmax-34 {
    padding-inline-end: 8.5rem !important;
  }
  .pe-xmax-35 {
    padding-inline-end: 8.75rem !important;
  }
  .pe-xmax-36 {
    padding-inline-end: 9rem !important;
  }
  .pe-xmax-37 {
    padding-inline-end: 9.25rem !important;
  }
  .pe-xmax-38 {
    padding-inline-end: 9.5rem !important;
  }
  .pe-xmax-39 {
    padding-inline-end: 9.75rem !important;
  }
  .pe-xmax-40 {
    padding-inline-end: 10rem !important;
  }
  .pb-xmax-0 {
    padding-block-end: 0 !important;
  }
  .pb-xmax-1 {
    padding-block-end: 0.25rem !important;
  }
  .pb-xmax-2 {
    padding-block-end: 0.5rem !important;
  }
  .pb-xmax-3 {
    padding-block-end: 0.75rem !important;
  }
  .pb-xmax-4 {
    padding-block-end: 1rem !important;
  }
  .pb-xmax-5 {
    padding-block-end: 1.25rem !important;
  }
  .pb-xmax-6 {
    padding-block-end: 1.5rem !important;
  }
  .pb-xmax-7 {
    padding-block-end: 1.75rem !important;
  }
  .pb-xmax-8 {
    padding-block-end: 2rem !important;
  }
  .pb-xmax-9 {
    padding-block-end: 2.25rem !important;
  }
  .pb-xmax-10 {
    padding-block-end: 2.5rem !important;
  }
  .pb-xmax-11 {
    padding-block-end: 2.75rem !important;
  }
  .pb-xmax-12 {
    padding-block-end: 3rem !important;
  }
  .pb-xmax-13 {
    padding-block-end: 3.25rem !important;
  }
  .pb-xmax-14 {
    padding-block-end: 3.5rem !important;
  }
  .pb-xmax-15 {
    padding-block-end: 3.75rem !important;
  }
  .pb-xmax-16 {
    padding-block-end: 4rem !important;
  }
  .pb-xmax-17 {
    padding-block-end: 4.25rem !important;
  }
  .pb-xmax-18 {
    padding-block-end: 4.5rem !important;
  }
  .pb-xmax-19 {
    padding-block-end: 4.75rem !important;
  }
  .pb-xmax-20 {
    padding-block-end: 5rem !important;
  }
  .pb-xmax-21 {
    padding-block-end: 5.25rem !important;
  }
  .pb-xmax-22 {
    padding-block-end: 5.5rem !important;
  }
  .pb-xmax-23 {
    padding-block-end: 5.75rem !important;
  }
  .pb-xmax-24 {
    padding-block-end: 6rem !important;
  }
  .pb-xmax-25 {
    padding-block-end: 6.25rem !important;
  }
  .pb-xmax-26 {
    padding-block-end: 6.5rem !important;
  }
  .pb-xmax-27 {
    padding-block-end: 6.75rem !important;
  }
  .pb-xmax-28 {
    padding-block-end: 7rem !important;
  }
  .pb-xmax-29 {
    padding-block-end: 7.25rem !important;
  }
  .pb-xmax-30 {
    padding-block-end: 7.5rem !important;
  }
  .pb-xmax-31 {
    padding-block-end: 7.75rem !important;
  }
  .pb-xmax-32 {
    padding-block-end: 8rem !important;
  }
  .pb-xmax-33 {
    padding-block-end: 8.25rem !important;
  }
  .pb-xmax-34 {
    padding-block-end: 8.5rem !important;
  }
  .pb-xmax-35 {
    padding-block-end: 8.75rem !important;
  }
  .pb-xmax-36 {
    padding-block-end: 9rem !important;
  }
  .pb-xmax-37 {
    padding-block-end: 9.25rem !important;
  }
  .pb-xmax-38 {
    padding-block-end: 9.5rem !important;
  }
  .pb-xmax-39 {
    padding-block-end: 9.75rem !important;
  }
  .pb-xmax-40 {
    padding-block-end: 10rem !important;
  }
  .ps-xmax-0 {
    padding-inline-start: 0 !important;
  }
  .ps-xmax-1 {
    padding-inline-start: 0.25rem !important;
  }
  .ps-xmax-2 {
    padding-inline-start: 0.5rem !important;
  }
  .ps-xmax-3 {
    padding-inline-start: 0.75rem !important;
  }
  .ps-xmax-4 {
    padding-inline-start: 1rem !important;
  }
  .ps-xmax-5 {
    padding-inline-start: 1.25rem !important;
  }
  .ps-xmax-6 {
    padding-inline-start: 1.5rem !important;
  }
  .ps-xmax-7 {
    padding-inline-start: 1.75rem !important;
  }
  .ps-xmax-8 {
    padding-inline-start: 2rem !important;
  }
  .ps-xmax-9 {
    padding-inline-start: 2.25rem !important;
  }
  .ps-xmax-10 {
    padding-inline-start: 2.5rem !important;
  }
  .ps-xmax-11 {
    padding-inline-start: 2.75rem !important;
  }
  .ps-xmax-12 {
    padding-inline-start: 3rem !important;
  }
  .ps-xmax-13 {
    padding-inline-start: 3.25rem !important;
  }
  .ps-xmax-14 {
    padding-inline-start: 3.5rem !important;
  }
  .ps-xmax-15 {
    padding-inline-start: 3.75rem !important;
  }
  .ps-xmax-16 {
    padding-inline-start: 4rem !important;
  }
  .ps-xmax-17 {
    padding-inline-start: 4.25rem !important;
  }
  .ps-xmax-18 {
    padding-inline-start: 4.5rem !important;
  }
  .ps-xmax-19 {
    padding-inline-start: 4.75rem !important;
  }
  .ps-xmax-20 {
    padding-inline-start: 5rem !important;
  }
  .ps-xmax-21 {
    padding-inline-start: 5.25rem !important;
  }
  .ps-xmax-22 {
    padding-inline-start: 5.5rem !important;
  }
  .ps-xmax-23 {
    padding-inline-start: 5.75rem !important;
  }
  .ps-xmax-24 {
    padding-inline-start: 6rem !important;
  }
  .ps-xmax-25 {
    padding-inline-start: 6.25rem !important;
  }
  .ps-xmax-26 {
    padding-inline-start: 6.5rem !important;
  }
  .ps-xmax-27 {
    padding-inline-start: 6.75rem !important;
  }
  .ps-xmax-28 {
    padding-inline-start: 7rem !important;
  }
  .ps-xmax-29 {
    padding-inline-start: 7.25rem !important;
  }
  .ps-xmax-30 {
    padding-inline-start: 7.5rem !important;
  }
  .ps-xmax-31 {
    padding-inline-start: 7.75rem !important;
  }
  .ps-xmax-32 {
    padding-inline-start: 8rem !important;
  }
  .ps-xmax-33 {
    padding-inline-start: 8.25rem !important;
  }
  .ps-xmax-34 {
    padding-inline-start: 8.5rem !important;
  }
  .ps-xmax-35 {
    padding-inline-start: 8.75rem !important;
  }
  .ps-xmax-36 {
    padding-inline-start: 9rem !important;
  }
  .ps-xmax-37 {
    padding-inline-start: 9.25rem !important;
  }
  .ps-xmax-38 {
    padding-inline-start: 9.5rem !important;
  }
  .ps-xmax-39 {
    padding-inline-start: 9.75rem !important;
  }
  .ps-xmax-40 {
    padding-inline-start: 10rem !important;
  }
  .gap-xmax-0 {
    gap: 0 !important;
  }
  .gap-xmax-1 {
    gap: 0.25rem !important;
  }
  .gap-xmax-2 {
    gap: 0.5rem !important;
  }
  .gap-xmax-3 {
    gap: 0.75rem !important;
  }
  .gap-xmax-4 {
    gap: 1rem !important;
  }
  .gap-xmax-5 {
    gap: 1.25rem !important;
  }
  .gap-xmax-6 {
    gap: 1.5rem !important;
  }
  .gap-xmax-7 {
    gap: 1.75rem !important;
  }
  .gap-xmax-8 {
    gap: 2rem !important;
  }
  .gap-xmax-9 {
    gap: 2.25rem !important;
  }
  .gap-xmax-10 {
    gap: 2.5rem !important;
  }
  .gap-xmax-11 {
    gap: 2.75rem !important;
  }
  .gap-xmax-12 {
    gap: 3rem !important;
  }
  .gap-xmax-13 {
    gap: 3.25rem !important;
  }
  .gap-xmax-14 {
    gap: 3.5rem !important;
  }
  .gap-xmax-15 {
    gap: 3.75rem !important;
  }
  .gap-xmax-16 {
    gap: 4rem !important;
  }
  .gap-xmax-17 {
    gap: 4.25rem !important;
  }
  .gap-xmax-18 {
    gap: 4.5rem !important;
  }
  .gap-xmax-19 {
    gap: 4.75rem !important;
  }
  .gap-xmax-20 {
    gap: 5rem !important;
  }
  .gap-xmax-21 {
    gap: 5.25rem !important;
  }
  .gap-xmax-22 {
    gap: 5.5rem !important;
  }
  .gap-xmax-23 {
    gap: 5.75rem !important;
  }
  .gap-xmax-24 {
    gap: 6rem !important;
  }
  .gap-xmax-25 {
    gap: 6.25rem !important;
  }
  .gap-xmax-26 {
    gap: 6.5rem !important;
  }
  .gap-xmax-27 {
    gap: 6.75rem !important;
  }
  .gap-xmax-28 {
    gap: 7rem !important;
  }
  .gap-xmax-29 {
    gap: 7.25rem !important;
  }
  .gap-xmax-30 {
    gap: 7.5rem !important;
  }
  .gap-xmax-31 {
    gap: 7.75rem !important;
  }
  .gap-xmax-32 {
    gap: 8rem !important;
  }
  .gap-xmax-33 {
    gap: 8.25rem !important;
  }
  .gap-xmax-34 {
    gap: 8.5rem !important;
  }
  .gap-xmax-35 {
    gap: 8.75rem !important;
  }
  .gap-xmax-36 {
    gap: 9rem !important;
  }
  .gap-xmax-37 {
    gap: 9.25rem !important;
  }
  .gap-xmax-38 {
    gap: 9.5rem !important;
  }
  .gap-xmax-39 {
    gap: 9.75rem !important;
  }
  .gap-xmax-40 {
    gap: 10rem !important;
  }
  .row-gap-xmax-0 {
    row-gap: 0 !important;
  }
  .row-gap-xmax-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xmax-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xmax-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xmax-4 {
    row-gap: 1rem !important;
  }
  .row-gap-xmax-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xmax-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xmax-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-xmax-8 {
    row-gap: 2rem !important;
  }
  .row-gap-xmax-9 {
    row-gap: 2.25rem !important;
  }
  .row-gap-xmax-10 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xmax-11 {
    row-gap: 2.75rem !important;
  }
  .row-gap-xmax-12 {
    row-gap: 3rem !important;
  }
  .row-gap-xmax-13 {
    row-gap: 3.25rem !important;
  }
  .row-gap-xmax-14 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xmax-15 {
    row-gap: 3.75rem !important;
  }
  .row-gap-xmax-16 {
    row-gap: 4rem !important;
  }
  .row-gap-xmax-17 {
    row-gap: 4.25rem !important;
  }
  .row-gap-xmax-18 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xmax-19 {
    row-gap: 4.75rem !important;
  }
  .row-gap-xmax-20 {
    row-gap: 5rem !important;
  }
  .row-gap-xmax-21 {
    row-gap: 5.25rem !important;
  }
  .row-gap-xmax-22 {
    row-gap: 5.5rem !important;
  }
  .row-gap-xmax-23 {
    row-gap: 5.75rem !important;
  }
  .row-gap-xmax-24 {
    row-gap: 6rem !important;
  }
  .row-gap-xmax-25 {
    row-gap: 6.25rem !important;
  }
  .row-gap-xmax-26 {
    row-gap: 6.5rem !important;
  }
  .row-gap-xmax-27 {
    row-gap: 6.75rem !important;
  }
  .row-gap-xmax-28 {
    row-gap: 7rem !important;
  }
  .row-gap-xmax-29 {
    row-gap: 7.25rem !important;
  }
  .row-gap-xmax-30 {
    row-gap: 7.5rem !important;
  }
  .row-gap-xmax-31 {
    row-gap: 7.75rem !important;
  }
  .row-gap-xmax-32 {
    row-gap: 8rem !important;
  }
  .row-gap-xmax-33 {
    row-gap: 8.25rem !important;
  }
  .row-gap-xmax-34 {
    row-gap: 8.5rem !important;
  }
  .row-gap-xmax-35 {
    row-gap: 8.75rem !important;
  }
  .row-gap-xmax-36 {
    row-gap: 9rem !important;
  }
  .row-gap-xmax-37 {
    row-gap: 9.25rem !important;
  }
  .row-gap-xmax-38 {
    row-gap: 9.5rem !important;
  }
  .row-gap-xmax-39 {
    row-gap: 9.75rem !important;
  }
  .row-gap-xmax-40 {
    row-gap: 10rem !important;
  }
  .column-gap-xmax-0 {
    column-gap: 0 !important;
  }
  .column-gap-xmax-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xmax-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xmax-3 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xmax-4 {
    column-gap: 1rem !important;
  }
  .column-gap-xmax-5 {
    column-gap: 1.25rem !important;
  }
  .column-gap-xmax-6 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xmax-7 {
    column-gap: 1.75rem !important;
  }
  .column-gap-xmax-8 {
    column-gap: 2rem !important;
  }
  .column-gap-xmax-9 {
    column-gap: 2.25rem !important;
  }
  .column-gap-xmax-10 {
    column-gap: 2.5rem !important;
  }
  .column-gap-xmax-11 {
    column-gap: 2.75rem !important;
  }
  .column-gap-xmax-12 {
    column-gap: 3rem !important;
  }
  .column-gap-xmax-13 {
    column-gap: 3.25rem !important;
  }
  .column-gap-xmax-14 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xmax-15 {
    column-gap: 3.75rem !important;
  }
  .column-gap-xmax-16 {
    column-gap: 4rem !important;
  }
  .column-gap-xmax-17 {
    column-gap: 4.25rem !important;
  }
  .column-gap-xmax-18 {
    column-gap: 4.5rem !important;
  }
  .column-gap-xmax-19 {
    column-gap: 4.75rem !important;
  }
  .column-gap-xmax-20 {
    column-gap: 5rem !important;
  }
  .column-gap-xmax-21 {
    column-gap: 5.25rem !important;
  }
  .column-gap-xmax-22 {
    column-gap: 5.5rem !important;
  }
  .column-gap-xmax-23 {
    column-gap: 5.75rem !important;
  }
  .column-gap-xmax-24 {
    column-gap: 6rem !important;
  }
  .column-gap-xmax-25 {
    column-gap: 6.25rem !important;
  }
  .column-gap-xmax-26 {
    column-gap: 6.5rem !important;
  }
  .column-gap-xmax-27 {
    column-gap: 6.75rem !important;
  }
  .column-gap-xmax-28 {
    column-gap: 7rem !important;
  }
  .column-gap-xmax-29 {
    column-gap: 7.25rem !important;
  }
  .column-gap-xmax-30 {
    column-gap: 7.5rem !important;
  }
  .column-gap-xmax-31 {
    column-gap: 7.75rem !important;
  }
  .column-gap-xmax-32 {
    column-gap: 8rem !important;
  }
  .column-gap-xmax-33 {
    column-gap: 8.25rem !important;
  }
  .column-gap-xmax-34 {
    column-gap: 8.5rem !important;
  }
  .column-gap-xmax-35 {
    column-gap: 8.75rem !important;
  }
  .column-gap-xmax-36 {
    column-gap: 9rem !important;
  }
  .column-gap-xmax-37 {
    column-gap: 9.25rem !important;
  }
  .column-gap-xmax-38 {
    column-gap: 9.5rem !important;
  }
  .column-gap-xmax-39 {
    column-gap: 9.75rem !important;
  }
  .column-gap-xmax-40 {
    column-gap: 10rem !important;
  }
  .text-xmax-start {
    text-align: start !important;
  }
  .text-xmax-end {
    text-align: end !important;
  }
  .text-xmax-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.preloader {
  position: relative;
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: hsl(var(--black));
  position: fixed;
  inset: 0;
  z-index: 9999;
}
.preloader__img {
  width: 80px;
  height: 80px;
  animation: imageBeat 2s infinite ease;
}
.preloader__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

:root {
  --d1: clamp(2.4rem, 2.526vw + 1rem, 5rem);
  --d2: clamp(2rem, 2.105vw + 1rem, 4.5rem);
  --d3: clamp(1.6rem, 1.684vw + 1rem, 4rem);
  --d4: clamp(1.2rem, 1.263vw + 1rem, 3.5rem);
  --h1: clamp(2rem, 2.105vw + 1rem, 4.5rem);
  --h2: clamp(2.4rem, 2.526vw + 1rem, 3rem);
  --h3: clamp(1.6rem, 1.684vw + 1rem, 2rem);
  --h4: clamp(1.25rem, 1.473vw + 1rem, 1.75rem);
  --h5: clamp(1.2rem, 1.263vw + 1rem, 1.5rem);
  --h6: clamp(1.1rem, 0.947vw + 1rem, 1.125rem);
  --fb: 214 89% 52%;
  --tw: 203 89% 53%;
  --yt: 0 100% 50%;
  --ins: 340 75% 54%;
  --in: 201 100% 35%;
  --pin: 351 100% 45%;
  --wapp: 142 70% 49%;
  --dbl: 337 79% 61%;
  --be: 219 100% 55%;
  --vim: 195 83% 51%;
  --wc: 138 91% 38%;
  --rdt: 16 100% 50%;
  --skp: 196 100% 47%;
  --snap: 59 100% 50%;
  --twitch: 264 100% 64%;
  --qra: 13 100% 33%;
  --primary-10: 354 98% 59%;
  --secondary-10: 95 50% 52%;
  --neutral-0: 0 0% 0%;
  --neutral-5: 0 0% 5%;
  --neutral-7: 0 0% 11%;
  --neutral-10: 240 7% 11%;
  --neutral-20: 240 4% 20%;
  --neutral-30: 255 3% 28%;
  --neutral-40: 255 2% 38%;
  --neutral-50: 270 2% 47%;
  --neutral-60: 270 2% 57%;
  --neutral-70: 276 3% 68%;
  --neutral-80: 276 5% 78%;
  --neutral-90: 288 9% 89%;
  --neutral-95: 288 19% 95%;
  --neutral-99: 300 100% 99%;
  --neutral-100: 0 0% 100%;
  --neutral-variant-0: 0 0% 0%;
  --neutral-variant-10: 240 13% 12%;
  --neutral-variant-20: 240 8% 20%;
  --neutral-variant-30: 240 6% 29%;
  --neutral-variant-40: 246 5% 38%;
  --neutral-variant-50: 246 4% 48%;
  --neutral-variant-60: 246 5% 58%;
  --neutral-variant-70: 252 6% 69%;
  --neutral-variant-80: 251 10% 79%;
  --neutral-variant-90: 251 22% 90%;
  --neutral-variant-95: 256 52% 96%;
  --neutral-variant-99: 300 100% 99%;
  --neutral-variant-100: 0 0% 100%;
  --surface-1: 256 73% 97%;
  --surface-2: 252 71% 96%;
  --surface-3: 246 70% 95%;
  --surface-4: 246 67% 94%;
  --surface-5: 243 70% 94%;
  --black: 0 0% 0%;
  --white: 0 0% 100%;
}

html {
  font-size: 100%;
}

body {
  position: relative;
  background-color: hsl(var(--black));
  font-family: var(--ff-2);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: hsl(var(--white));
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

form {
  margin: 0;
}

p {
  margin: 0 0 1rem;
}

.d1,
.d2,
.d3,
.d4 {
  margin: 0 0 1rem;
  font-family: var(--ff-1);
  font-weight: 700;
  line-height: 1.2;
  color: hsl(var(--white));
}

.d1 {
  font-size: var(--d1);
}

.d2 {
  font-size: var(--d2);
}

.d3 {
  font-size: var(--d3);
}

.d4 {
  font-size: var(--d4);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1rem;
  font-family: var(--ff-1);
  font-weight: 700;
  line-height: 1.2;
  color: hsl(var(--white));
}

h1,
.h1 {
  font-size: var(--h1);
}

h2,
.h2 {
  font-size: var(--h2);
}

h3,
.h3 {
  font-size: var(--h3);
}

h4,
.h4 {
  font-size: var(--h4);
}

h5,
.h5 {
  font-size: var(--h5);
}

h6,
.h6 {
  font-size: var(--h6);
  letter-spacing: 0.05em;
  line-height: 1.5;
}

.fs-10 {
  font-size: 0.625rem;
}

.fs-12 {
  font-size: 0.75rem;
}

small,
.fs-14 {
  font-size: 0.875rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.fs-24 {
  font-size: 1.5rem;
}

.fs-32 {
  font-size: 2rem;
}

.fs-36 {
  font-size: 2.25rem;
}

.fs-40 {
  font-size: 2.5rem;
}

.fs-44 {
  font-size: 2.75rem;
}

.fs-48 {
  font-size: 3rem;
}

.fs-52 {
  font-size: 3.25rem;
}

.fs-56 {
  font-size: 3.5rem;
}

.fs-60 {
  font-size: 3.75rem;
}

.max-text-24 {
  max-width: 24ch;
}

.max-text-32 {
  max-width: 32ch;
}

.max-text-40 {
  max-width: 40ch;
}

.max-text-50 {
  max-width: 50ch;
}

.max-text-60 {
  max-width: 60ch;
}

.max-text-70 {
  max-width: 70ch;
}

.logo {
  --logo-height: 2.25rem;
  height: var(--logo-height);
}
.logo__img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.list {
  --direction: column;
  display: flex;
  flex-direction: var(--direction);
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-row {
  --direction: row;
}
@media (min-width: 0) {
  .list-xs-row {
    --direction: row;
  }
}
@media (min-width: 375px) {
  .list-xsm-row {
    --direction: row;
  }
}
@media (min-width: 576px) {
  .list-sm-row {
    --direction: row;
  }
}
@media (min-width: 768px) {
  .list-md-row {
    --direction: row;
  }
}
@media (min-width: 992px) {
  .list-lg-row {
    --direction: row;
  }
}
@media (min-width: 1200px) {
  .list-xl-row {
    --direction: row;
  }
}
@media (min-width: 1400px) {
  .list-xxl-row {
    --direction: row;
  }
}
@media (min-width: 1600px) {
  .list-max-row {
    --direction: row;
  }
}
@media (min-width: 1920px) {
  .list-xmax-row {
    --direction: row;
  }
}
.list-column {
  --direction: column;
}
@media (min-width: 0) {
  .list-xs-column {
    --direction: column;
  }
}
@media (min-width: 375px) {
  .list-xsm-column {
    --direction: column;
  }
}
@media (min-width: 576px) {
  .list-sm-column {
    --direction: column;
  }
}
@media (min-width: 768px) {
  .list-md-column {
    --direction: column;
  }
}
@media (min-width: 992px) {
  .list-lg-column {
    --direction: column;
  }
}
@media (min-width: 1200px) {
  .list-xl-column {
    --direction: column;
  }
}
@media (min-width: 1400px) {
  .list-xxl-column {
    --direction: column;
  }
}
@media (min-width: 1600px) {
  .list-max-column {
    --direction: column;
  }
}
@media (min-width: 1920px) {
  .list-xmax-column {
    --direction: column;
  }
}
.list-disc {
  list-style: disc;
  padding-inline-start: 1rem;
}
.list-dot {
  --dot-clr: hsl(var(--white));
  --dot-size: 0.25rem;
}
.list-dot > li + li {
  padding-inline-start: var(--dot-size);
  margin-inline-start: var(--dot-size);
  position: relative;
  isolation: isolate;
}
.list-dot > li + li::before {
  content: "";
  width: calc(var(--dot-size) / 2);
  height: calc(var(--dot-size) / 2);
  border-radius: 50%;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 0;
  transform: translate(-50%, -50%);
  background-color: var(--dot-clr);
}
.list-dot-sm {
  --dot-size: 0.5rem;
}
.list-dot-md {
  --dot-size: 0.75rem;
}
.list-dot-lg {
  --dot-size: 1rem;
}
.list-dot-xl {
  --dot-size: 1.25rem;
}
.list-dot-xxl {
  --dot-size: 1.5rem;
}
.list-dot-primary-10 {
  --dot-clr: hsl(var(--primary-10));
}
.list-dot-secondary-10 {
  --dot-clr: hsl(var(--secondary-10));
}
.list-dot-neutral-0 {
  --dot-clr: hsl(var(--neutral-0));
}
.list-dot-neutral-5 {
  --dot-clr: hsl(var(--neutral-5));
}
.list-dot-neutral-7 {
  --dot-clr: hsl(var(--neutral-7));
}
.list-dot-neutral-10 {
  --dot-clr: hsl(var(--neutral-10));
}
.list-dot-neutral-20 {
  --dot-clr: hsl(var(--neutral-20));
}
.list-dot-neutral-30 {
  --dot-clr: hsl(var(--neutral-30));
}
.list-dot-neutral-40 {
  --dot-clr: hsl(var(--neutral-40));
}
.list-dot-neutral-50 {
  --dot-clr: hsl(var(--neutral-50));
}
.list-dot-neutral-60 {
  --dot-clr: hsl(var(--neutral-60));
}
.list-dot-neutral-70 {
  --dot-clr: hsl(var(--neutral-70));
}
.list-dot-neutral-80 {
  --dot-clr: hsl(var(--neutral-80));
}
.list-dot-neutral-90 {
  --dot-clr: hsl(var(--neutral-90));
}
.list-dot-neutral-95 {
  --dot-clr: hsl(var(--neutral-95));
}
.list-dot-neutral-99 {
  --dot-clr: hsl(var(--neutral-99));
}
.list-dot-neutral-100 {
  --dot-clr: hsl(var(--neutral-100));
}
.list-dot-neutral-variant-0 {
  --dot-clr: hsl(var(--neutral-variant-0));
}
.list-dot-neutral-variant-10 {
  --dot-clr: hsl(var(--neutral-variant-10));
}
.list-dot-neutral-variant-20 {
  --dot-clr: hsl(var(--neutral-variant-20));
}
.list-dot-neutral-variant-30 {
  --dot-clr: hsl(var(--neutral-variant-30));
}
.list-dot-neutral-variant-40 {
  --dot-clr: hsl(var(--neutral-variant-40));
}
.list-dot-neutral-variant-50 {
  --dot-clr: hsl(var(--neutral-variant-50));
}
.list-dot-neutral-variant-60 {
  --dot-clr: hsl(var(--neutral-variant-60));
}
.list-dot-neutral-variant-70 {
  --dot-clr: hsl(var(--neutral-variant-70));
}
.list-dot-neutral-variant-80 {
  --dot-clr: hsl(var(--neutral-variant-80));
}
.list-dot-neutral-variant-90 {
  --dot-clr: hsl(var(--neutral-variant-90));
}
.list-dot-neutral-variant-95 {
  --dot-clr: hsl(var(--neutral-variant-95));
}
.list-dot-neutral-variant-99 {
  --dot-clr: hsl(var(--neutral-variant-99));
}
.list-dot-neutral-variant-100 {
  --dot-clr: hsl(var(--neutral-variant-100));
}
.list-dot-surface-1 {
  --dot-clr: hsl(var(--surface-1));
}
.list-dot-surface-2 {
  --dot-clr: hsl(var(--surface-2));
}
.list-dot-surface-3 {
  --dot-clr: hsl(var(--surface-3));
}
.list-dot-surface-4 {
  --dot-clr: hsl(var(--surface-4));
}
.list-dot-surface-5 {
  --dot-clr: hsl(var(--surface-5));
}
.list-flush li + li {
  border-top: 1px solid hsl(var(--neutral-50)/0.5);
}

.section-space-xsm-y {
  padding-block-start: clamp(1rem, 1.68vw + 1rem, 2rem);
  padding-block-end: clamp(1rem, 1.68vw + 1rem, 2rem);
}

.section-space-sm-y {
  padding-block-start: clamp(2rem, 3.25vw + 1rem, 3.75rem);
  padding-block-end: clamp(2rem, 3.25vw + 1rem, 3.75rem);
}

.section-space-y {
  padding-block-start: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
  padding-block-end: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
}

.section-space-top {
  padding-block-start: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
}

.section-space-sm-top {
  padding-block-start: clamp(2rem, 3.25vw + 1rem, 3.75rem);
}

.section-space-xsm-top {
  padding-block-start: clamp(1rem, 1.68vw + 1rem, 2rem);
}

.section-space-bottom {
  padding-block-end: clamp(3.75rem, 6.5vw + 1rem, 7.5rem);
}

.section-space-sm-bottom {
  padding-block-end: clamp(2rem, 3.25vw + 1rem, 3.75rem);
}

.section-space-xsm-bottom {
  padding-block-end: clamp(1rem, 1.68vw + 1rem, 2rem);
}

.header {
  z-index: 999;
  transition: all 0.3s ease;
  background-color: hsl(var(--primary-300)/0.03);
}
.header-top {
  background-color: hsl(var(--primary-300)/0.03);
}
@media (min-width: 992px) {
  .header--light .menu-link {
    color: hsl(var(--neutral-10));
  }
}
@media (min-width: 992px) {
  .header--light .menu-link.has-sub {
    color: hsl(var(--neutral-10));
  }
}
.header--light [data-bs-toggle] {
  background-color: hsl(var(--primary-50));
}
.header--fixed {
  position: fixed;
  width: 100%;
}
.header--sticky {
  position: sticky;
  top: 0;
}
.header-crossed .header--fixed {
  top: 0;
  border-color: transparent !important;
}
.header-crossed .header--fixed::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background-color: hsl(var(--neutral-900));
}
.header-crossed .header--sticky {
  background-color: hsl(var(--white));
  box-shadow: 0 0.25rem 1rem hsl(var(--black)/0.1);
  border-color: transparent !important;
}

@media (min-width: 1920px) {
  .hero {
    padding-block: 12.5rem;
  }
}
@media (min-width: 992px) {
  .hero::after {
    content: "";
    position: absolute;
    inset-block: 0;
    inset-inline-start: 58%;
    inset-inline-end: 0;
    background-image: url(../img/hero-img.png);
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }
}
@media (min-width: 1920px) {
  .hero::after {
    inset-inline-end: 7.5rem;
    background-position: left bottom;
    inset-inline-start: 56%;
  }
}

.partner-tab {
  box-shadow: 10px 10px 30px hsl(var(--neutral-10)/0.05);
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: hsl(var(--neutral-0));
}
.partner-tab__body {
  padding: 40px 16px;
  border-block-start: 1px solid hsl(var(--neutral-10)/0.2);
  background-color: hsl(var(--neutral-100)/0.05);
}
@media (min-width: 576px) {
  .partner-tab__body {
    padding-inline: 1.5rem;
  }
}
@media (min-width: 768px) {
  .partner-tab__body {
    padding-inline: 2.5rem;
  }
}
@media (min-width: 375px) {
  .partner-tab__body li {
    width: calc(50% - 0.75rem);
  }
}
@media (min-width: 768px) {
  .partner-tab__body li {
    width: calc(33% - 0.75rem);
  }
}
@media (min-width: 992px) {
  .partner-tab__body li {
    width: calc(25% - 1rem);
  }
}
@media (min-width: 1200px) {
  .partner-tab__body li {
    width: calc(20% - 1rem);
  }
}
.partner-tab__nav {
  overflow: auto;
}
@media (min-width: 1200px) {
  .partner-tab__nav {
    overflow: unset;
  }
}
.partner-tab__list {
  flex-direction: row;
}
.partner-tab__link {
  flex-shrink: 0;
  border: 1px solid hsl(var(--neutral-100)/0.05);
  background-color: hsl(var(--neutral-100)/0.05);
  color: hsl(var(--neutral-100));
}
.partner-tab__link.active {
  position: relative;
  isolation: isolate;
  background-color: hsl(var(--neutral-100));
  color: hsl(var(--neutral-0));
}
@media (min-width: 1200px) {
  .partner-tab__link.active::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: hsl(var(--neutral-100)) transparent transparent transparent;
  }
}
.partner-tab__link.active .partner-tab__icon {
  fill: hsl(var(--neutral-0));
}
.partner-tab__icon {
  fill: hsl(var(--neutral-100));
}

.contact-section {
  position: relative;
  isolation: isolate;
}
.contact-section::after {
  content: "";
  position: absolute;
  inset-inline: 0;
  inset-block-start: 50%;
  inset-block-end: 0;
  background-color: hsl(var(--neutral-7));
  z-index: -1;
}

.ff-1 {
  font-family: var(--ff-1);
}

.ff-2 {
  font-family: var(--ff-2);
}

.active-clr {
  color: hsl(var(--primary-10)) !important;
}

.active-bg {
  background-color: hsl(var(--primary-10)) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--bs-border-color);
}

.form-select:focus {
  box-shadow: none;
  border-color: var(--bs-border-color);
}

.link {
  text-decoration: none;
  transition: all 0.3s ease;
}

.overflow-x-auto {
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}
.overflow-x-auto::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.overflow-x-auto::-webkit-scrollbar-track {
  background: transparent;
}
.overflow-x-auto::-webkit-scrollbar-thumb {
  background: transparent;
}

.overflow-y-auto {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
}
.overflow-y-auto::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}
.overflow-y-auto::-webkit-scrollbar-thumb {
  background: transparent;
}

.inset-0 {
  inset: 0;
}

.pointer-none {
  pointer-events: none;
}

.transition {
  transition: all 0.3s ease;
}

.place-content-center {
  place-content: center;
}

.place-items-center {
  place-items: center;
}

.position-center {
  inset-inline-start: 50%;
  inset-block-start: 50%;
  transform: translate(-50%, -50%);
}

.\:focus-outline-0:focus {
  outline: none;
}

.grayscale {
  filter: grayscale(1);
}

.\:grayscale:hover {
  filter: grayscale(1);
}

.grayscale-0 {
  filter: grayscale(0);
}

.\:grayscale-0:hover {
  filter: grayscale(0);
}

@keyframes pulse {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes imageBeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}
.bg-fb {
  background: hsl(var(--fb));
}

.\:bg-fb {
  transition: all 0.3s ease;
}
.\:bg-fb:hover {
  background: hsl(var(--fb));
}

.bg-tw {
  background: hsl(var(--tw));
}

.\:bg-tw {
  transition: all 0.3s ease;
}
.\:bg-tw:hover {
  background: hsl(var(--tw));
}

.bg-yt {
  background: hsl(var(--yt));
}

.\:bg-yt {
  transition: all 0.3s ease;
}
.\:bg-yt:hover {
  background: hsl(var(--yt));
}

.bg-ins {
  background: hsl(var(--ins));
}

.\:bg-ins {
  transition: all 0.3s ease;
}
.\:bg-ins:hover {
  background: hsl(var(--ins));
}

.bg-in {
  background: hsl(var(--in));
}

.\:bg-in {
  transition: all 0.3s ease;
}
.\:bg-in:hover {
  background: hsl(var(--in));
}

.bg-pin {
  background: hsl(var(--pin));
}

.\:bg-pin {
  transition: all 0.3s ease;
}
.\:bg-pin:hover {
  background: hsl(var(--pin));
}

.bg-wapp {
  background: hsl(var(--wapp));
}

.\:bg-wapp {
  transition: all 0.3s ease;
}
.\:bg-wapp:hover {
  background: hsl(var(--wapp));
}

.bg-dbl {
  background: hsl(var(--dbl));
}

.\:bg-dbl {
  transition: all 0.3s ease;
}
.\:bg-dbl:hover {
  background: hsl(var(--dbl));
}

.bg-be {
  background: hsl(var(--be));
}

.\:bg-be {
  transition: all 0.3s ease;
}
.\:bg-be:hover {
  background: hsl(var(--be));
}

.bg-vim {
  background: hsl(var(--vim));
}

.\:bg-vim {
  transition: all 0.3s ease;
}
.\:bg-vim:hover {
  background: hsl(var(--vim));
}

.bg-wc {
  background: hsl(var(--wc));
}

.\:bg-wc {
  transition: all 0.3s ease;
}
.\:bg-wc:hover {
  background: hsl(var(--wc));
}

.bg-rdt {
  background: hsl(var(--rdt));
}

.\:bg-rdt {
  transition: all 0.3s ease;
}
.\:bg-rdt:hover {
  background: hsl(var(--rdt));
}

.bg-skp {
  background: hsl(var(--skp));
}

.\:bg-skp {
  transition: all 0.3s ease;
}
.\:bg-skp:hover {
  background: hsl(var(--skp));
}

.bg-snap {
  background: hsl(var(--snap));
}

.\:bg-snap {
  transition: all 0.3s ease;
}
.\:bg-snap:hover {
  background: hsl(var(--snap));
}

.bg-twitch {
  background: hsl(var(--twitch));
}

.\:bg-twitch {
  transition: all 0.3s ease;
}
.\:bg-twitch:hover {
  background: hsl(var(--twitch));
}

.bg-qra {
  background: hsl(var(--qra));
}

.\:bg-qra {
  transition: all 0.3s ease;
}
.\:bg-qra:hover {
  background: hsl(var(--qra));
}

.bg-primary-10 {
  --bg-opacity: 1;
  background-color: hsl(var(--primary-10)/var(--bg-opacity));
}

.\:bg-primary-10:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--primary-10)/var(--bg-opacity));
}

.bg-secondary-10 {
  --bg-opacity: 1;
  background-color: hsl(var(--secondary-10)/var(--bg-opacity));
}

.\:bg-secondary-10:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--secondary-10)/var(--bg-opacity));
}

.bg-neutral-0 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-0)/var(--bg-opacity));
}

.\:bg-neutral-0:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-0)/var(--bg-opacity));
}

.bg-neutral-5 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-5)/var(--bg-opacity));
}

.\:bg-neutral-5:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-5)/var(--bg-opacity));
}

.bg-neutral-7 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-7)/var(--bg-opacity));
}

.\:bg-neutral-7:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-7)/var(--bg-opacity));
}

.bg-neutral-10 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-10)/var(--bg-opacity));
}

.\:bg-neutral-10:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-10)/var(--bg-opacity));
}

.bg-neutral-20 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-20)/var(--bg-opacity));
}

.\:bg-neutral-20:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-20)/var(--bg-opacity));
}

.bg-neutral-30 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-30)/var(--bg-opacity));
}

.\:bg-neutral-30:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-30)/var(--bg-opacity));
}

.bg-neutral-40 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-40)/var(--bg-opacity));
}

.\:bg-neutral-40:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-40)/var(--bg-opacity));
}

.bg-neutral-50 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-50)/var(--bg-opacity));
}

.\:bg-neutral-50:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-50)/var(--bg-opacity));
}

.bg-neutral-60 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-60)/var(--bg-opacity));
}

.\:bg-neutral-60:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-60)/var(--bg-opacity));
}

.bg-neutral-70 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-70)/var(--bg-opacity));
}

.\:bg-neutral-70:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-70)/var(--bg-opacity));
}

.bg-neutral-80 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-80)/var(--bg-opacity));
}

.\:bg-neutral-80:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-80)/var(--bg-opacity));
}

.bg-neutral-90 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-90)/var(--bg-opacity));
}

.\:bg-neutral-90:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-90)/var(--bg-opacity));
}

.bg-neutral-95 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-95)/var(--bg-opacity));
}

.\:bg-neutral-95:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-95)/var(--bg-opacity));
}

.bg-neutral-99 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-99)/var(--bg-opacity));
}

.\:bg-neutral-99:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-99)/var(--bg-opacity));
}

.bg-neutral-100 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-100)/var(--bg-opacity));
}

.\:bg-neutral-100:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-100)/var(--bg-opacity));
}

.bg-neutral-variant-0 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-0)/var(--bg-opacity));
}

.\:bg-neutral-variant-0:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-0)/var(--bg-opacity));
}

.bg-neutral-variant-10 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-10)/var(--bg-opacity));
}

.\:bg-neutral-variant-10:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-10)/var(--bg-opacity));
}

.bg-neutral-variant-20 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-20)/var(--bg-opacity));
}

.\:bg-neutral-variant-20:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-20)/var(--bg-opacity));
}

.bg-neutral-variant-30 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-30)/var(--bg-opacity));
}

.\:bg-neutral-variant-30:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-30)/var(--bg-opacity));
}

.bg-neutral-variant-40 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-40)/var(--bg-opacity));
}

.\:bg-neutral-variant-40:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-40)/var(--bg-opacity));
}

.bg-neutral-variant-50 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-50)/var(--bg-opacity));
}

.\:bg-neutral-variant-50:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-50)/var(--bg-opacity));
}

.bg-neutral-variant-60 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-60)/var(--bg-opacity));
}

.\:bg-neutral-variant-60:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-60)/var(--bg-opacity));
}

.bg-neutral-variant-70 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-70)/var(--bg-opacity));
}

.\:bg-neutral-variant-70:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-70)/var(--bg-opacity));
}

.bg-neutral-variant-80 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-80)/var(--bg-opacity));
}

.\:bg-neutral-variant-80:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-80)/var(--bg-opacity));
}

.bg-neutral-variant-90 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-90)/var(--bg-opacity));
}

.\:bg-neutral-variant-90:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-90)/var(--bg-opacity));
}

.bg-neutral-variant-95 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-95)/var(--bg-opacity));
}

.\:bg-neutral-variant-95:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-95)/var(--bg-opacity));
}

.bg-neutral-variant-99 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-99)/var(--bg-opacity));
}

.\:bg-neutral-variant-99:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-99)/var(--bg-opacity));
}

.bg-neutral-variant-100 {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-100)/var(--bg-opacity));
}

.\:bg-neutral-variant-100:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--neutral-variant-100)/var(--bg-opacity));
}

.bg-surface-1 {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-1)/var(--bg-opacity));
}

.\:bg-surface-1:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-1)/var(--bg-opacity));
}

.bg-surface-2 {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-2)/var(--bg-opacity));
}

.\:bg-surface-2:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-2)/var(--bg-opacity));
}

.bg-surface-3 {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-3)/var(--bg-opacity));
}

.\:bg-surface-3:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-3)/var(--bg-opacity));
}

.bg-surface-4 {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-4)/var(--bg-opacity));
}

.\:bg-surface-4:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-4)/var(--bg-opacity));
}

.bg-surface-5 {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-5)/var(--bg-opacity));
}

.\:bg-surface-5:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-5)/var(--bg-opacity));
}

.primary {
  --bg-opacity: 1;
  background-color: hsl(var(--primary)/var(--bg-opacity));
}

.primary-container {
  --bg-opacity: 1;
  background-color: hsl(var(--primary-container)/var(--bg-opacity));
}

.secondary {
  --bg-opacity: 1;
  background-color: hsl(var(--secondary)/var(--bg-opacity));
}

.secondary-container {
  --bg-opacity: 1;
  background-color: hsl(var(--secondary-container)/var(--bg-opacity));
}

.tertiary {
  --bg-opacity: 1;
  background-color: hsl(var(--tertiary)/var(--bg-opacity));
}

.tertiary-container {
  --bg-opacity: 1;
  background-color: hsl(var(--tertiary-container)/var(--bg-opacity));
}

.error {
  --bg-opacity: 1;
  background-color: hsl(var(--error)/var(--bg-opacity));
}

.error-container {
  --bg-opacity: 1;
  background-color: hsl(var(--error-container)/var(--bg-opacity));
}

.background {
  --bg-opacity: 1;
  background-color: hsl(var(--background)/var(--bg-opacity));
}

.surface {
  --bg-opacity: 1;
  background-color: hsl(var(--surface)/var(--bg-opacity));
}

.surface-variant {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-variant)/var(--bg-opacity));
}

.surface-1 {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-1)/var(--bg-opacity));
}

.surface-2 {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-2)/var(--bg-opacity));
}

.surface-3 {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-3)/var(--bg-opacity));
}

.surface-4 {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-4)/var(--bg-opacity));
}

.surface-5 {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-5)/var(--bg-opacity));
}

.\:primary:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--primary)/var(--bg-opacity));
}

.\:primary-container:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--primary-container)/var(--bg-opacity));
}

.\:secondary:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--secondary)/var(--bg-opacity));
}

.\:secondary-container:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--secondary-container)/var(--bg-opacity));
}

.\:tertiary:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--tertiary)/var(--bg-opacity));
}

.\:tertiary-container:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--tertiary-container)/var(--bg-opacity));
}

.\:error:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--error)/var(--bg-opacity));
}

.\:error-container:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--error-container));
}

.\:background:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--background)/var(--bg-opacity));
}

.\:surface:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--surface)/var(--bg-opacity));
}

.\:surface-1:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-1)/var(--bg-opacity));
}

.\:surface-2:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-2)/var(--bg-opacity));
}

.\:surface-3:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-3)/var(--bg-opacity));
}

.\:surface-4:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-4)/var(--bg-opacity));
}

.\:surface-5:hover {
  --bg-opacity: 1;
  background-color: hsl(var(--surface-5)/var(--bg-opacity));
}

.bg-overlay {
  --overlay-clr: hsl(var(--primary-10));
  background: linear-gradient(transparent, var(--overlay-clr));
}

.bg-opacity-05 {
  --bg-opacity: 0.05;
}

.bg-opacity-1 {
  --bg-opacity: 0.1;
}

.bg-opacity-2 {
  --bg-opacity: 0.2;
}

.bg-opacity-3 {
  --bg-opacity: 0.3;
}

.bg-opacity-4 {
  --bg-opacity: 0.4;
}

.bg-opacity-5 {
  --bg-opacity: 0.5;
}

.bg-opacity-6 {
  --bg-opacity: 0.6;
}

.bg-opacity-7 {
  --bg-opacity: 0.7;
}

.bg-opacity-8 {
  --bg-opacity: 0.8;
}

.bg-opacity-9 {
  --bg-opacity: 0.9;
}

.bg-opacity-100 {
  --bg-opacity: 1;
}

.\:bg-opacity-05:hover {
  --bg-opacity: 0.05;
}

.\:bg-opacity-1:hover {
  --bg-opacity: 0.1;
}

.\:bg-opacity-2:hover {
  --bg-opacity: 0.2;
}

.\:bg-opacity-3:hover {
  --bg-opacity: 0.3;
}

.\:bg-opacity-4:hover {
  --bg-opacity: 0.4;
}

.\:bg-opacity-5:hover {
  --bg-opacity: 0.5;
}

.\:bg-opacity-6:hover {
  --bg-opacity: 0.6;
}

.\:bg-opacity-7:hover {
  --bg-opacity: 0.7;
}

.\:bg-opacity-8:hover {
  --bg-opacity: 0.8;
}

.\:bg-opacity-9:hover {
  --bg-opacity: 0.9;
}

.\:bg-opacity-100:hover {
  --bg-opacity: 1;
}

.clr-fb {
  color: hsl(var(--fb));
}

.\:clr-fb:hover {
  color: hsl(var(--fb));
}

.clr-tw {
  color: hsl(var(--tw));
}

.\:clr-tw:hover {
  color: hsl(var(--tw));
}

.clr-yt {
  color: hsl(var(--yt));
}

.\:clr-yt:hover {
  color: hsl(var(--yt));
}

.clr-ins {
  color: hsl(var(--ins));
}

.\:clr-ins:hover {
  color: hsl(var(--ins));
}

.clr-in {
  color: hsl(var(--in));
}

.\:clr-in:hover {
  color: hsl(var(--in));
}

.clr-pin {
  color: hsl(var(--pin));
}

.\:clr-pin:hover {
  color: hsl(var(--pin));
}

.clr-wapp {
  color: hsl(var(--wapp));
}

.\:clr-wapp:hover {
  color: hsl(var(--wapp));
}

.clr-dbl {
  color: hsl(var(--dbl));
}

.\:clr-dbl:hover {
  color: hsl(var(--dbl));
}

.clr-be {
  color: hsl(var(--be));
}

.\:clr-be:hover {
  color: hsl(var(--be));
}

.clr-vim {
  color: hsl(var(--vim));
}

.\:clr-vim:hover {
  color: hsl(var(--vim));
}

.clr-wc {
  color: hsl(var(--wc));
}

.\:clr-wc:hover {
  color: hsl(var(--wc));
}

.clr-rdt {
  color: hsl(var(--rdt));
}

.\:clr-rdt:hover {
  color: hsl(var(--rdt));
}

.clr-skp {
  color: hsl(var(--skp));
}

.\:clr-skp:hover {
  color: hsl(var(--skp));
}

.clr-snap {
  color: hsl(var(--snap));
}

.\:clr-snap:hover {
  color: hsl(var(--snap));
}

.clr-twitch {
  color: hsl(var(--twitch));
}

.\:clr-twitch:hover {
  color: hsl(var(--twitch));
}

.clr-qra {
  color: hsl(var(--qra));
}

.\:clr-qra:hover {
  color: hsl(var(--qra));
}

.clr-primary-10 {
  color: hsl(var(--primary-10));
}

.\:clr-primary-10:hover {
  color: hsl(var(--primary-10));
}

.clr-secondary-10 {
  color: hsl(var(--secondary-10));
}

.\:clr-secondary-10:hover {
  color: hsl(var(--secondary-10));
}

.clr-neutral-0 {
  color: hsl(var(--neutral-0));
}

.\:clr-neutral-0:hover {
  color: hsl(var(--neutral-0));
}

.clr-neutral-5 {
  color: hsl(var(--neutral-5));
}

.\:clr-neutral-5:hover {
  color: hsl(var(--neutral-5));
}

.clr-neutral-7 {
  color: hsl(var(--neutral-7));
}

.\:clr-neutral-7:hover {
  color: hsl(var(--neutral-7));
}

.clr-neutral-10 {
  color: hsl(var(--neutral-10));
}

.\:clr-neutral-10:hover {
  color: hsl(var(--neutral-10));
}

.clr-neutral-20 {
  color: hsl(var(--neutral-20));
}

.\:clr-neutral-20:hover {
  color: hsl(var(--neutral-20));
}

.clr-neutral-30 {
  color: hsl(var(--neutral-30));
}

.\:clr-neutral-30:hover {
  color: hsl(var(--neutral-30));
}

.clr-neutral-40 {
  color: hsl(var(--neutral-40));
}

.\:clr-neutral-40:hover {
  color: hsl(var(--neutral-40));
}

.clr-neutral-50 {
  color: hsl(var(--neutral-50));
}

.\:clr-neutral-50:hover {
  color: hsl(var(--neutral-50));
}

.clr-neutral-60 {
  color: hsl(var(--neutral-60));
}

.\:clr-neutral-60:hover {
  color: hsl(var(--neutral-60));
}

.clr-neutral-70 {
  color: hsl(var(--neutral-70));
}

.\:clr-neutral-70:hover {
  color: hsl(var(--neutral-70));
}

.clr-neutral-80 {
  color: hsl(var(--neutral-80));
}

.\:clr-neutral-80:hover {
  color: hsl(var(--neutral-80));
}

.clr-neutral-90 {
  color: hsl(var(--neutral-90));
}

.\:clr-neutral-90:hover {
  color: hsl(var(--neutral-90));
}

.clr-neutral-95 {
  color: hsl(var(--neutral-95));
}

.\:clr-neutral-95:hover {
  color: hsl(var(--neutral-95));
}

.clr-neutral-99 {
  color: hsl(var(--neutral-99));
}

.\:clr-neutral-99:hover {
  color: hsl(var(--neutral-99));
}

.clr-neutral-100 {
  color: hsl(var(--neutral-100));
}

.\:clr-neutral-100:hover {
  color: hsl(var(--neutral-100));
}

.clr-neutral-variant-0 {
  color: hsl(var(--neutral-variant-0));
}

.\:clr-neutral-variant-0:hover {
  color: hsl(var(--neutral-variant-0));
}

.clr-neutral-variant-10 {
  color: hsl(var(--neutral-variant-10));
}

.\:clr-neutral-variant-10:hover {
  color: hsl(var(--neutral-variant-10));
}

.clr-neutral-variant-20 {
  color: hsl(var(--neutral-variant-20));
}

.\:clr-neutral-variant-20:hover {
  color: hsl(var(--neutral-variant-20));
}

.clr-neutral-variant-30 {
  color: hsl(var(--neutral-variant-30));
}

.\:clr-neutral-variant-30:hover {
  color: hsl(var(--neutral-variant-30));
}

.clr-neutral-variant-40 {
  color: hsl(var(--neutral-variant-40));
}

.\:clr-neutral-variant-40:hover {
  color: hsl(var(--neutral-variant-40));
}

.clr-neutral-variant-50 {
  color: hsl(var(--neutral-variant-50));
}

.\:clr-neutral-variant-50:hover {
  color: hsl(var(--neutral-variant-50));
}

.clr-neutral-variant-60 {
  color: hsl(var(--neutral-variant-60));
}

.\:clr-neutral-variant-60:hover {
  color: hsl(var(--neutral-variant-60));
}

.clr-neutral-variant-70 {
  color: hsl(var(--neutral-variant-70));
}

.\:clr-neutral-variant-70:hover {
  color: hsl(var(--neutral-variant-70));
}

.clr-neutral-variant-80 {
  color: hsl(var(--neutral-variant-80));
}

.\:clr-neutral-variant-80:hover {
  color: hsl(var(--neutral-variant-80));
}

.clr-neutral-variant-90 {
  color: hsl(var(--neutral-variant-90));
}

.\:clr-neutral-variant-90:hover {
  color: hsl(var(--neutral-variant-90));
}

.clr-neutral-variant-95 {
  color: hsl(var(--neutral-variant-95));
}

.\:clr-neutral-variant-95:hover {
  color: hsl(var(--neutral-variant-95));
}

.clr-neutral-variant-99 {
  color: hsl(var(--neutral-variant-99));
}

.\:clr-neutral-variant-99:hover {
  color: hsl(var(--neutral-variant-99));
}

.clr-neutral-variant-100 {
  color: hsl(var(--neutral-variant-100));
}

.\:clr-neutral-variant-100:hover {
  color: hsl(var(--neutral-variant-100));
}

.clr-surface-1 {
  color: hsl(var(--surface-1));
}

.\:clr-surface-1:hover {
  color: hsl(var(--surface-1));
}

.clr-surface-2 {
  color: hsl(var(--surface-2));
}

.\:clr-surface-2:hover {
  color: hsl(var(--surface-2));
}

.clr-surface-3 {
  color: hsl(var(--surface-3));
}

.\:clr-surface-3:hover {
  color: hsl(var(--surface-3));
}

.clr-surface-4 {
  color: hsl(var(--surface-4));
}

.\:clr-surface-4:hover {
  color: hsl(var(--surface-4));
}

.clr-surface-5 {
  color: hsl(var(--surface-5));
}

.\:clr-surface-5:hover {
  color: hsl(var(--surface-5));
}

.clr-primary {
  color: hsl(var(--primary));
}

.on-primary {
  color: hsl(var(--on-primary));
}

.clr-primary-container {
  color: hsl(var(--primary-container));
}

.on-primary-container {
  color: hsl(var(--on-primary-container));
}

.clr-secondary {
  color: hsl(var(--secondary));
}

.on-secondary {
  color: hsl(var(--on-secondary));
}

.clr-secondary-container {
  color: hsl(var(--secondary-container));
}

.on-secondary-container {
  color: hsl(var(--on-secondary-container));
}

.clr-tertiary {
  color: hsl(var(--tertiary));
}

.on-tertiary {
  color: hsl(var(--on-tertiary));
}

.clr-tertiary-container {
  color: hsl(var(--tertiary-container));
}

.on-tertiary-container {
  color: hsl(var(--on-tertiary-container));
}

.clr-error {
  color: hsl(var(--error));
}

.on-error {
  color: hsl(var(--on-error));
}

.clr-error-container {
  color: hsl(var(--error-container));
}

.on-error-container {
  color: hsl(var(--on-error-container));
}

.clr-background {
  color: hsl(var(--background));
}

.on-background {
  color: hsl(var(--on-background));
}

.clr-surface {
  color: hsl(var(--surface));
}

.on-surface {
  color: hsl(var(--on-surface));
}

.clr-surface-variant {
  color: hsl(var(--surface-variant));
}

.on-surface-variant {
  color: hsl(var(--on-surface-variant));
}

.\:clr-primary:hover {
  color: hsl(var(--primary));
}

.\:on-primary:hover {
  color: hsl(var(--on-primary));
}

.\:clr-primary-container:hover {
  color: hsl(var(--primary-container));
}

.\:on-primary-container:hover {
  color: hsl(var(--on-primary-container));
}

.\:clr-secondary:hover {
  color: hsl(var(--secondary));
}

.\:on-secondary:hover {
  color: hsl(var(--on-secondary));
}

.\:clr-secondary-container:hover {
  color: hsl(var(--secondary-container));
}

.\:on-secondary-container:hover {
  color: hsl(var(--on-secondary-container));
}

.\:clr-tertiary:hover {
  color: hsl(var(--tertiary));
}

.\:on-tertiary:hover {
  color: hsl(var(--on-tertiary));
}

.\:clr-tertiary-container:hover {
  color: hsl(var(--tertiary-container));
}

.\:on-tertiary-container:hover {
  color: hsl(var(--on-tertiary-container));
}

.\:clr-error:hover {
  color: hsl(var(--error));
}

.\:on-error:hover {
  color: hsl(var(--on-error));
}

.\:clr-error-container:hover {
  color: hsl(var(--error-container));
}

.\:on-error-container:hover {
  color: hsl(var(--on-error-container));
}

.\:clr-background:hover {
  color: hsl(var(--background));
}

.\:on-background:hover {
  color: hsl(var(--on-background));
}

.\:clr-surface:hover {
  color: hsl(var(--surface));
}

.\:on-surface:hover {
  color: hsl(var(--on-surface));
}

.\:clr-surface-variant:hover {
  color: hsl(var(--surface-variant));
}

.\:on-surface-variant:hover {
  color: hsl(var(--on-surface-variant));
}

.border-primary-10 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--primary-10) / var(--border-opacity)
  );
}

.\:border-primary-10:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--primary-10) / var(--border-opacity)
  );
}

.border-secondary-10 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--secondary-10) / var(--border-opacity)
  );
}

.\:border-secondary-10:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--secondary-10) / var(--border-opacity)
  );
}

.border-neutral-0 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-0) / var(--border-opacity)
  );
}

.\:border-neutral-0:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-0) / var(--border-opacity)
  );
}

.border-neutral-5 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-5) / var(--border-opacity)
  );
}

.\:border-neutral-5:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-5) / var(--border-opacity)
  );
}

.border-neutral-7 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-7) / var(--border-opacity)
  );
}

.\:border-neutral-7:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-7) / var(--border-opacity)
  );
}

.border-neutral-10 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-10) / var(--border-opacity)
  );
}

.\:border-neutral-10:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-10) / var(--border-opacity)
  );
}

.border-neutral-20 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-20) / var(--border-opacity)
  );
}

.\:border-neutral-20:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-20) / var(--border-opacity)
  );
}

.border-neutral-30 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-30) / var(--border-opacity)
  );
}

.\:border-neutral-30:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-30) / var(--border-opacity)
  );
}

.border-neutral-40 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-40) / var(--border-opacity)
  );
}

.\:border-neutral-40:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-40) / var(--border-opacity)
  );
}

.border-neutral-50 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-50) / var(--border-opacity)
  );
}

.\:border-neutral-50:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-50) / var(--border-opacity)
  );
}

.border-neutral-60 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-60) / var(--border-opacity)
  );
}

.\:border-neutral-60:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-60) / var(--border-opacity)
  );
}

.border-neutral-70 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-70) / var(--border-opacity)
  );
}

.\:border-neutral-70:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-70) / var(--border-opacity)
  );
}

.border-neutral-80 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-80) / var(--border-opacity)
  );
}

.\:border-neutral-80:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-80) / var(--border-opacity)
  );
}

.border-neutral-90 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-90) / var(--border-opacity)
  );
}

.\:border-neutral-90:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-90) / var(--border-opacity)
  );
}

.border-neutral-95 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-95) / var(--border-opacity)
  );
}

.\:border-neutral-95:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-95) / var(--border-opacity)
  );
}

.border-neutral-99 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-99) / var(--border-opacity)
  );
}

.\:border-neutral-99:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-99) / var(--border-opacity)
  );
}

.border-neutral-100 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-100) / var(--border-opacity)
  );
}

.\:border-neutral-100:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-100) / var(--border-opacity)
  );
}

.border-neutral-variant-0 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-0) / var(--border-opacity)
  );
}

.\:border-neutral-variant-0:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-0) / var(--border-opacity)
  );
}

.border-neutral-variant-10 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-10) / var(--border-opacity)
  );
}

.\:border-neutral-variant-10:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-10) / var(--border-opacity)
  );
}

.border-neutral-variant-20 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-20) / var(--border-opacity)
  );
}

.\:border-neutral-variant-20:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-20) / var(--border-opacity)
  );
}

.border-neutral-variant-30 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-30) / var(--border-opacity)
  );
}

.\:border-neutral-variant-30:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-30) / var(--border-opacity)
  );
}

.border-neutral-variant-40 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-40) / var(--border-opacity)
  );
}

.\:border-neutral-variant-40:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-40) / var(--border-opacity)
  );
}

.border-neutral-variant-50 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-50) / var(--border-opacity)
  );
}

.\:border-neutral-variant-50:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-50) / var(--border-opacity)
  );
}

.border-neutral-variant-60 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-60) / var(--border-opacity)
  );
}

.\:border-neutral-variant-60:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-60) / var(--border-opacity)
  );
}

.border-neutral-variant-70 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-70) / var(--border-opacity)
  );
}

.\:border-neutral-variant-70:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-70) / var(--border-opacity)
  );
}

.border-neutral-variant-80 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-80) / var(--border-opacity)
  );
}

.\:border-neutral-variant-80:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-80) / var(--border-opacity)
  );
}

.border-neutral-variant-90 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-90) / var(--border-opacity)
  );
}

.\:border-neutral-variant-90:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-90) / var(--border-opacity)
  );
}

.border-neutral-variant-95 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-95) / var(--border-opacity)
  );
}

.\:border-neutral-variant-95:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-95) / var(--border-opacity)
  );
}

.border-neutral-variant-99 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-99) / var(--border-opacity)
  );
}

.\:border-neutral-variant-99:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-99) / var(--border-opacity)
  );
}

.border-neutral-variant-100 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-100) / var(--border-opacity)
  );
}

.\:border-neutral-variant-100:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--neutral-variant-100) / var(--border-opacity)
  );
}

.border-surface-1 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--surface-1) / var(--border-opacity)
  );
}

.\:border-surface-1:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--surface-1) / var(--border-opacity)
  );
}

.border-surface-2 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--surface-2) / var(--border-opacity)
  );
}

.\:border-surface-2:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--surface-2) / var(--border-opacity)
  );
}

.border-surface-3 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--surface-3) / var(--border-opacity)
  );
}

.\:border-surface-3:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--surface-3) / var(--border-opacity)
  );
}

.border-surface-4 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--surface-4) / var(--border-opacity)
  );
}

.\:border-surface-4:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--surface-4) / var(--border-opacity)
  );
}

.border-surface-5 {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--surface-5) / var(--border-opacity)
  );
}

.\:border-surface-5:hover {
  --border-opacity: 1;
  --bs-border-color: hsl(
  	var(--surface-5) / var(--border-opacity)
  );
}

.border-2x {
  --bs-border-width: 2px;
}

.border-dotted {
  --bs-border-style: dotted;
}

.border-dashed {
  --bs-border-style: dashed;
}

.border-opacity-1 {
  --border-opacity: 0.1;
}

.border-opacity-2 {
  --border-opacity: 0.2;
}

.border-opacity-3 {
  --border-opacity: 0.3;
}

.border-opacity-4 {
  --border-opacity: 0.4;
}

.border-opacity-5 {
  --border-opacity: 0.5;
}

.border-opacity-6 {
  --border-opacity: 0.6;
}

.border-opacity-7 {
  --border-opacity: 0.7;
}

.border-opacity-8 {
  --border-opacity: 0.8;
}

.border-opacity-9 {
  --border-opacity: 0.9;
}

.border-opacity-100 {
  --border-opacity: 1;
}

.\:border-opacity-1:hover {
  --border-opacity: 0.1;
}

.\:border-opacity-2:hover {
  --border-opacity: 0.2;
}

.\:border-opacity-3:hover {
  --border-opacity: 0.3;
}

.\:border-opacity-4:hover {
  --border-opacity: 0.4;
}

.\:border-opacity-5:hover {
  --border-opacity: 0.5;
}

.\:border-opacity-6:hover {
  --border-opacity: 0.6;
}

.\:border-opacity-7:hover {
  --border-opacity: 0.7;
}

.\:border-opacity-8:hover {
  --border-opacity: 0.8;
}

.\:border-opacity-9:hover {
  --border-opacity: 0.9;
}

.\:border-opacity-100:hover {
  --border-opacity: 1;
}

.w-0 {
  width: 0;
}

.h-0 {
  height: 0;
}

.w-1 {
  width: 0.25rem;
}

.h-1 {
  height: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.h-2 {
  height: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.h-3 {
  height: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.h-4 {
  height: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.h-5 {
  height: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.h-6 {
  height: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.h-7 {
  height: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.h-8 {
  height: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.h-9 {
  height: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.h-10 {
  height: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.h-11 {
  height: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.h-12 {
  height: 3rem;
}

.w-13 {
  width: 3.25rem;
}

.h-13 {
  height: 3.25rem;
}

.w-14 {
  width: 3.5rem;
}

.h-14 {
  height: 3.5rem;
}

.w-15 {
  width: 3.75rem;
}

.h-15 {
  height: 3.75rem;
}

.w-16 {
  width: 4rem;
}

.h-16 {
  height: 4rem;
}

.w-17 {
  width: 4.25rem;
}

.h-17 {
  height: 4.25rem;
}

.w-18 {
  width: 4.5rem;
}

.h-18 {
  height: 4.5rem;
}

.w-19 {
  width: 4.75rem;
}

.h-19 {
  height: 4.75rem;
}

.w-20 {
  width: 5rem;
}

.h-20 {
  height: 5rem;
}

.w-21 {
  width: 5.25rem;
}

.h-21 {
  height: 5.25rem;
}

.w-22 {
  width: 5.5rem;
}

.h-22 {
  height: 5.5rem;
}

.w-23 {
  width: 5.75rem;
}

.h-23 {
  height: 5.75rem;
}

.w-24 {
  width: 6rem;
}

.h-24 {
  height: 6rem;
}

.w-25 {
  width: 6.25rem;
}

.h-25 {
  height: 6.25rem;
}

.w-26 {
  width: 6.5rem;
}

.h-26 {
  height: 6.5rem;
}

.w-27 {
  width: 6.75rem;
}

.h-27 {
  height: 6.75rem;
}

.w-28 {
  width: 7rem;
}

.h-28 {
  height: 7rem;
}

.w-29 {
  width: 7.25rem;
}

.h-29 {
  height: 7.25rem;
}

.w-30 {
  width: 7.5rem;
}

.h-30 {
  height: 7.5rem;
}

.w-31 {
  width: 7.75rem;
}

.h-31 {
  height: 7.75rem;
}

.w-32 {
  width: 8rem;
}

.h-32 {
  height: 8rem;
}

.w-33 {
  width: 8.25rem;
}

.h-33 {
  height: 8.25rem;
}

.w-34 {
  width: 8.5rem;
}

.h-34 {
  height: 8.5rem;
}

.w-35 {
  width: 8.75rem;
}

.h-35 {
  height: 8.75rem;
}

.w-36 {
  width: 9rem;
}

.h-36 {
  height: 9rem;
}

.w-37 {
  width: 9.25rem;
}

.h-37 {
  height: 9.25rem;
}

.w-38 {
  width: 9.5rem;
}

.h-38 {
  height: 9.5rem;
}

.w-39 {
  width: 9.75rem;
}

.h-39 {
  height: 9.75rem;
}

.w-40 {
  width: 10rem;
}

.h-40 {
  height: 10rem;
}

/*---------------------------------------
    Template Name:  
    Description:    
    Author Name:    
    Author URI:     
    Theme URI:      
    Version:        1.0
-----------------------------------------
    Table of contents
-----------------------------------------
    Global Style
    Components Style
    Sections Style
    Utilities Style
---------------------------------------*/