$flex-directions: (row, column);
$colors: (
	primary: (
		10: 354 98% 59%,
	),
	secondary: (
		10: 95 50% 52%,
	),
	neutral: (
		0: 0 0% 0%,
		5: 0 0% 5%,
		7: 0 0% 11%,
		10: 240 7% 11%,
		20: 240 4% 20%,
		30: 255 3% 28%,
		40: 255 2% 38%,
		50: 270 2% 47%,
		60: 270 2% 57%,
		70: 276 3% 68%,
		80: 276 5% 78%,
		90: 288 9% 89%,
		95: 288 19% 95%,
		99: 300 100% 99%,
		100: 0 0% 100%,
	),
	neutral-variant: (
		0: 0 0% 0%,
		10: 240 13% 12%,
		20: 240 8% 20%,
		30: 240 6% 29%,
		40: 246 5% 38%,
		50: 246 4% 48%,
		60: 246 5% 58%,
		70: 252 6% 69%,
		80: 251 10% 79%,
		90: 251 22% 90%,
		95: 256 52% 96%,
		99: 300 100% 99%,
		100: 0 0% 100%,
	),
	surface: (
		1: 256 73% 97%,
		2: 252 71% 96%,
		3: 246 70% 95%,
		4: 246 67% 94%,
		5: 243 70% 94%,
	),
);
$brand-colors: (
	fb: 214 89% 52%,
	tw: 203 89% 53%,
	yt: 0 100% 50%,
	ins: 340 75% 54%,
	in: 201 100% 35%,
	pin: 351 100% 45%,
	wapp: 142 70% 49%,
	dbl: 337 79% 61%,
	be: 219 100% 55%,
	vim: 195 83% 51%,
	wc: 138 91% 38%,
	rdt: 16 100% 50%,
	skp: 196 100% 47%,
	snap: 59 100% 50%,
	twitch: 264 100% 64%,
	qra: 13 100% 33%,
);

:root {
	// Font Size (19px = 1vw @screen 1920px)
	--d1: clamp(2.4rem, 2.526vw + 1rem, 5rem); //80px
	--d2: clamp(2rem, 2.105vw + 1rem, 4.5rem); //72px
	--d3: clamp(1.6rem, 1.684vw + 1rem, 4rem); //64px
	--d4: clamp(1.2rem, 1.263vw + 1rem, 3.5rem); //56px

	--h1: clamp(2rem, 2.105vw + 1rem, 4.5rem); //72px
	--h2: clamp(2.4rem, 2.526vw + 1rem, 3rem); //48px
	--h3: clamp(1.6rem, 1.684vw + 1rem, 2rem); //32px
	--h4: clamp(1.25rem, 1.473vw + 1rem, 1.75rem); //28px
	--h5: clamp(1.2rem, 1.263vw + 1rem, 1.5rem); //24px
	--h6: clamp(1.1rem, 0.947vw + 1rem, 1.125rem); //18px

	//Brand Colors
	@each $brand, $values in $brand-colors {
		--#{$brand}: #{$values};
	}

	// Reference Colors
	@each $color, $shades in $colors {
		@each $shade, $value in $shades {
			--#{$color}-#{$shade}: #{$value};
		}
	}

	--black: 0 0% 0%;
	--white: 0 0% 100%;
}
