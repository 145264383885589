.contact-section {
    position: relative;
    isolation: isolate;
    &::after {
        content: '';
        position: absolute;
        inset-inline: 0;
        inset-block-start: 50%;
        inset-block-end: 0;
        background-color: hsl(var(--neutral-7));
        z-index: -1;
    }
}